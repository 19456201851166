import { useEffect } from "react";


export default function Loader(props) {
  useEffect(() => {
    //(props,"............");
  }, []);

  return (
    // <div className={props.isLoading ? "" : "overlay spin-overlay"}>
    <>
      {props.isLoading  ? null : (
        <div class="overlay" style={{position:"fixed"}}>
          <div
            class="spinner-border Loader theme-color align-center"
            role="status"
          >
            <span class="visually-hidden"></span>
          </div>
        </div>
      )}
    </>

    // </div>
  );
}
