
import React, { useState, useContext, useEffect } from 'react';
import ReactSlider from 'react-slider';
import { getBindParams } from '../AI Service/Api';
import { ConfigContext } from './ConfigContext';
import Select from 'react-select';

const ConfigurationButton = ({ isImage }) => {
  const { config, setConfig } = useContext(ConfigContext);

  const [showConfigPopUp, setShowConfigPopUp] = useState(false);
  const [maxResponse, setMaxResponse] = useState(config.maxResponse || 4096);
  const [temperature, setTemperature] = useState(config.temperature || 0.7);
  const [topP, setTopP] = useState(config.topP || 0.2);
  const [frequencyPenalty, setFrequencyPenalty] = useState(config.frequencyPenalty || 0.1);
  const [presencePenalty, setPresencePenalty] = useState(config.presencePenalty || 0.1);
  const [modelOptions, setModelOptions] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [defaultModel, setDefaultModel] = useState(null);

  useEffect(() => {
    fetchBindParams();
  }, []);

  useEffect(() => {
    if (isImage && modelOptions.length > 0) {
      const visionModel = modelOptions.find(option => option.label.toLowerCase().includes('vision'));
      if (visionModel) {
        setSelectedModel(visionModel);
        setHyperparametersFromModel(visionModel.model);
      }
    }
  }, [isImage, modelOptions]);

  const fetchBindParams = async () => {
    try {
      const response = await getBindParams({});
      const models = response.data.modelConfiguration;
      const defaultModelInfo = response.data.defaultModel[0];

      const options = models.map(model => ({
        value: model.modelConfigurationID,
        label: model.displayName,
        model: model
      }));

      setModelOptions(options);

      const defaultModel = options.find(option => option.label === defaultModelInfo.default_model_name);
      if (defaultModel) {
        setDefaultModel(defaultModel);
        if (!isImage) {
          setSelectedModel(defaultModel);
          setHyperparametersFromModel(defaultModel.model);
        }
      } else {
        console.warn(`Default model "${defaultModelInfo.default_model_name}" not found in the configuration`);
      }
    } catch (error) {
      console.error("Error fetching bind params:", error);
    }
  };

  const setHyperparametersFromModel = (model) => {
    setMaxResponse(parseInt(model.MaxTokens));
    setTemperature(parseFloat(model.Temperature));
    setTopP(parseFloat(model.TopP));
    setFrequencyPenalty(parseFloat(model.FrequencyPenalty));
    setPresencePenalty(parseFloat(model.PresencePenalty));
  };

  const handlePopUp = () => {
    setShowConfigPopUp(prev => !prev);
  };

  const handleReset = () => {
    if (defaultModel) {
      setSelectedModel(defaultModel);
      setHyperparametersFromModel(defaultModel.model);
    }
  };

  const handleCancel = () => {
    handleReset();
    setShowConfigPopUp(false);
  };

  const handleApply = () => {
    setConfig({
      maxResponse,
      temperature,
      topP,
      frequencyPenalty,
      presencePenalty,
      selectedModel: selectedModel ? selectedModel.value : null
    });
    setShowConfigPopUp(false);
  };

  const handleModelChange = (selectedOption) => {
    setSelectedModel(selectedOption);
    setHyperparametersFromModel(selectedOption.model);
  };

  const handleSliderChange = (setter) => (value) => {
    setter(value);
  };

  const isBedrockOrClaudeM = () => {
    if (!selectedModel) return false;
    return selectedModel.model.modelName.toLowerCase().includes('bedrock') ||
      selectedModel.model.modelName.toLowerCase().includes('claude');
  };
  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%',
    }),
    control: (provided) => ({
      ...provided,
      minHeight: '38px',
      height: '38px',
      backgroundColor: 'inherit',
      width: '100%',
    }),
    valueContainer: (provided) => ({
      ...provided,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'var(--select-input-color, currentColor)',
      maxWidth: 'calc(100% - 8px)',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transition: 'all 0.2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
      color: 'var(--select-indicator-color, #495057)',
      ':hover': {
        color: 'var(--select-indicator-hover-color, #495057)',
      },
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: 'var(--select-indicator-color, #495057)',
      ':hover': {
        color: 'var(--select-indicator-hover-color, #495057)',
      },
    }),
    input: (provided) => ({
      ...provided,
      color: 'var(--select-input-color, currentColor)',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'var(--select-placeholder-color, #6c757d)',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'var(--select-bg, #fff)',
      color: 'var(--select-color, #333)',
      width: '100%',
      zIndex: 1010,
      maxHeight: '200px', // Adjust this value as needed
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '200px', // Adjust this value as needed
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? 'var(--select-option-focus-bg, #f0f0f0)'
        : 'var(--select-bg, #fff)',
      color: 'var(--select-color, #333)',
      ':active': {
        backgroundColor: 'var(--select-option-active-bg, #e0e0e0)',
      },
    }),
  };
  

  return (
    <div className="btn-group" id="chat-configure-parent">
    <button className="btn btn-light border rounded-2 configurations border-color-10 bg-color-10 custom-dropdown-li py-2" onClick={handlePopUp}>
      <img className='config-icon' src="images/settings.svg" alt="setting" />
    </button>

    {showConfigPopUp && (
      <div className="dropdown-menu rounded-3 custom-dpd cust-right p-4" id='chat-configure-child' style={{ 
        position: 'absolute',
        top: '100%',
        right: 0,
        width: '550px',
        zIndex: 1005,
        display: 'block',
        maxHeight: '80vh', // Adjust this value as needed
        overflowY: 'auto',
      }}>
        <div className="row row-gap-32px">
          <div className="col-md-12">
            <div className="d-flex justify-content-between">
              <h4 className="font-16 text-color-1 font-medium">Configurations</h4>
              <img src="images/Close-icon.svg" alt="close" className="cursor-pointer filter-icons" width="16" onClick={handleCancel} />
            </div>
          </div>

          <div className="col-md-6">
            <div className="dropdown-wrapper" style={{ position: 'relative', width: '100%' }}>
              <label htmlFor="model" className="form-label label-color font-15 font-semibold text-color-2 mb-2">Choose Model</label>
              <Select
                value={selectedModel}
                onChange={handleModelChange}
                options={modelOptions}
                styles={customStyles}
                className="bg-color-4"
                classNamePrefix="select"
                placeholder="Select a model"
                isClearable={false}
              />
            </div>
          </div>

            <div className="col-md-6">
              <label htmlFor="mx-rsponse" className="form-label label-color font-15 font-semibold text-color-2 mb-2 dec-hp-config" data-title="Limits the maximum size or number of items in the API response">Max Response</label>
              <div className="d-flex flex-row align-items-center gap-3">
                <ReactSlider
                  className="slider"
                  thumbClassName="thumb"
                  trackClassName="track"
                  value={maxResponse}
                  onChange={handleSliderChange(setMaxResponse)}
                  min={1}
                  max={selectedModel ? parseInt(selectedModel.model.MaxTokens) : 4096}
                />
                <input
                  type="number"
                  className="form-control input-field shadow-none p-2 font-14 font-regular border-radius-3 bg-transparent text-color-2 rounded-3"
                  value={maxResponse}
                  onChange={(e) => setMaxResponse(parseInt(e.target.value) || 0)}
                  min={1}
                  max={selectedModel ? parseInt(selectedModel.model.MaxTokens) : 4096}
                  style={{ width: '75px' }}
                />
              </div>
            </div>

            <div className="col-md-6">
              <label htmlFor="Temperature_" className="form-label font-15 font-semibold text-color-2 mb-2 dec-hp-config margin-left" data-title="Controls the randomness or creativity of the generated output (higher values for more randomness).">Temperature</label>
              <div className="d-flex flex-row align-items-center gap-3">
                <ReactSlider
                  className="slider"
                  thumbClassName="thumb"
                  trackClassName="track"
                  value={temperature}
                  onChange={handleSliderChange(setTemperature)}
                  min={0}
                  max={1}
                  step={0.01}
                />
                <input
                  type="number"
                  className="form-control input-field shadow-none p-2 font-14 font-regular border-radius-3 bg-transparent text-color-2 rounded-3"
                  value={temperature}
                  onChange={(e) => setTemperature(parseFloat(e.target.value) || 0)}
                  min={0}
                  max={1}
                  step={0.01}
                  style={{ width: '75px' }}
                />
              </div>
            </div>

            {!isBedrockOrClaudeM() && (
              <>
                <div className="col-md-6">
                  <label htmlFor="Top_P" className="form-label font-15 font-semibold text-color-2 mb-2 dec-hp-config" data-title="Top-p sampling picks words until their combined chance meets a certain limit, ensuring variety and sense">Top P</label>
                  <div className="d-flex flex-row align-items-center gap-3">
                    <ReactSlider
                      className="slider"
                      thumbClassName="thumb"
                      trackClassName="track"
                      value={topP}
                      onChange={handleSliderChange(setTopP)}
                      min={0}
                      max={1}
                      step={0.01}
                    />
                    <input
                      type="number"
                      className="form-control input-field shadow-none p-2 font-14 font-regular border-radius-3 bg-transparent text-color-2 rounded-3"
                      value={topP}
                      onChange={(e) => setTopP(parseFloat(e.target.value) || 0)}
                      min={0}
                      max={1}
                      step={0.01}
                      style={{ width: '75px' }}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <label htmlFor="Frequency_Penalty" className="form-label font-15 font-semibold text-color-2 mb-2 dec-hp-config" data-title="Discourages the model from repeating tokens to promote more varied output.">Frequency Penalty</label>
                  <div className="d-flex flex-row align-items-center gap-3">
                    <ReactSlider
                      className="slider"
                      thumbClassName="thumb"
                      trackClassName="track"
                      value={frequencyPenalty}
                      onChange={handleSliderChange(setFrequencyPenalty)}
                      min={0}
                      max={1}
                      step={0.01}
                    />
                    <input
                      type="number"
                      className="form-control input-field shadow-none p-2 font-14 font-regular border-radius-3 bg-transparent text-color-2 rounded-3"
                      value={frequencyPenalty}
                      onChange={(e) => setFrequencyPenalty(parseFloat(e.target.value) || 0)}
                      min={0}
                      max={1}
                      step={0.01}
                      style={{ width: '75px' }}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <label htmlFor="Presence_Penalty" className="form-label font-15 font-semibold text-color-2 mb-2 dec-hp-config" data-title="Encourages the model to introduce new topics by penalizing repeated tokens.">Presence Penalty</label>
                  <div className="d-flex flex-row align-items-center gap-3">
                    <ReactSlider
                      className="slider"
                      thumbClassName="thumb"
                      trackClassName="track"
                      value={presencePenalty}
                      onChange={handleSliderChange(setPresencePenalty)}
                      min={0}
                      max={1}
                      step={0.01}
                    />
                    <input
                      type="number"
                      className="form-control input-field shadow-none p-2 font-14 font-regular border-radius-3 bg-transparent text-color-2 rounded-3"
                      value={presencePenalty}
                      onChange={(e) => setPresencePenalty(parseFloat(e.target.value) || 0)}
                      min={0}
                      max={1}
                      step={0.01}
                      style={{ width: '75px' }}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="col-md-12">
              <div className="d-flex justify-content-end align-items-center">
                <button type="button" className="btn btn-outline-secondary font-14 px-4 py-2 me-3 font-bold" onClick={handleReset}>Reset</button>
                <button type="button" className="btn primary-btn font-14 font-bold border-radius-5 color-white px-4 py-2" onClick={handleApply}>Apply</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfigurationButton;