import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles'; // Assuming you're using Material-UI for theming
import { useEffect, useRef, useState } from 'react';
const BarGraphComp = ({ AsamiUsageData }) => {
    const theme = useTheme();
    const chartRef = useRef(null);
    const [textColor, setTextColor] = useState('#000000');
    const [chartKey, setChartKey] = useState(0);

    useEffect(() => {
        const updateTextColor = () => {
            if (chartRef.current) {
                const card = chartRef.current.closest('.custom-dashboard-section');
                if (card) {
                    const bgColor = window.getComputedStyle(card).backgroundColor;
                    const rgb = bgColor.match(/\d+/g);
                    if (rgb) {
                        const brightness = (parseInt(rgb[0]) * 299 + parseInt(rgb[1]) * 587 + parseInt(rgb[2]) * 114) / 1000;
                        setTextColor(brightness > 128 ? '#000000' : '#ffffff');
                    }
                }
            }
        };

        const resizeObserver = new ResizeObserver(updateTextColor);

        if (chartRef.current) {
            const card = chartRef.current.closest('.custom-dashboard-section');
            if (card) {
                resizeObserver.observe(card);
            }
        }

        updateTextColor();
        setChartKey(prev => prev + 1);

        return () => resizeObserver.disconnect();
    }, [theme]); // Re-run when theme changes

    let labels = [];
    let yAxis = [];

    AsamiUsageData?.forEach((ele) => {
        if (ele.Practice) {
            labels.push(ele.Practice);
            yAxis.push(ele.AsamiUsage);
        } else if (ele.Project) {
            labels.push(ele.Project);
            yAxis.push(ele.AsamiUsage);
        }
    });

    const formatNumber = (value) => {
        if (value >= 1000000) {
            return (value / 1000000).toFixed(1) + 'M';
        } else if (value >= 1000) {
            return (value / 1000).toFixed(1) + 'K';
        }
        return value.toString();
    };

    const series = [
        {
            name: 'Asami Usage',
            data: yAxis,
        },
    ];

    const options = {
        chart: {
            type: 'bar',
            toolbar: { show: false },
            background: 'transparent',
            foreColor: textColor,
        },
        plotOptions: {
            bar: {
                borderRadius: 7,
                borderRadiusApplication: 'end',
                columnWidth: '40%',
                dataLabels: { position: 'top' },
            }
        },
        dataLabels: {
            enabled: true,
            formatter: (val) => formatNumber(val),
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: [textColor]
            }
        },
        colors: [theme.palette.mode === 'dark' ? '#8c7ae6' : '#BEB1FF'],
        xaxis: {
            categories: labels, // Keep the full labels for the tooltip
            labels: {
                style: {
                    colors: textColor
                },
                formatter: (val) => val.length > 5 ? val.substring(0, 5) + '...' : val, // Truncate labels for x-axis display
            },
            scrollbar: { enabled: false }
        },
        yaxis: {
            labels: {
                formatter: (val) => formatNumber(val),
                style: {
                    colors: textColor
                }
            },
            scrollbar: { enabled: false }
        },
        grid: {
            row: { colors: ['transparent'] },
            borderColor: textColor === '#ffffff' ? '#888888' : '#f1f1f1',
            strokeDashArray: [5]
        },
        states: {
            hover: { filter: { type: 'darken', value: 0.8 } }
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: (val) => `${val} chats`
            },
            x: {
                // Use a custom formatter for the tooltip to show the full label
                formatter: (val, { series, seriesIndex, dataPointIndex, w }) => {
                    return w.globals.labels[dataPointIndex];
                }
            },
            theme: textColor === '#ffffff' ? 'dark' : 'light',
        },

    };

    return (
        <div id="chart" ref={chartRef}>
            <ReactApexChart
                options={options}
                series={series}
                type="bar"
                height={272}
                key={`${chartKey}-${textColor}`}
            />
        </div>
    );
};

export default BarGraphComp;