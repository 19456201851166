// PS_MI_01: Import necessary modules
import React, { useState, useEffect } from 'react';
import { getIssues, ExistData, similarityCheck,getIssuesConfig } from "../../AI Service/Api";
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { ConfigPopup } from './ConfigPopUp';
import Loader from '../../Other-Components/loader';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// PS_MI_02 to PS_MI_68 - fetch the Manage issues grid and entire functionality associated on that page
export function ManageIssues(navOpen) {
  const location = useLocation();
  const history = useHistory()
  const { projectIdFromMP, projectName } = location.state || {};

  let projectIdFromLocal = localStorage.getItem('projectIdFromMP');
  let projectNameFromLocal = localStorage.getItem('projectNameFromLocal');
  //(projectIdFromMP,"aslla")
  // PS_MI_04 - Declare the state variables
  const [issues, setIssues] = useState([]);
  const [statusDropdown, setStatusDropdown] = useState([]);
  const [flag, setFlag] = useState("");
  const [filterAlert, setFilterAlert] = useState("");
  const [showFilter, setShowFilter] = useState("");
  const [cancel, setCancel] = useState(false);
  const [projectID, setProjectID] = useState(projectIdFromMP);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchDupIssueID, setSearchDupIssueID] = useState("");
  const [searchIssueID, setSearchIssueID] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [endOffset, setEndOffset] = useState(10);
  const [workItemType, setWorkItemType] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [isAscendingIssueID, setIsAscendingIssueID] = useState(true);
  const [isProjectTimeline, setIsProjectTimeline] = useState(true);
  const [isAscendingWorkItem, setIsAscendingWorkItem] = useState(true);
  const [isAscendingAssignedTo, setIsAscendingAssignedTo] = useState(true);
  const [isAscendingStatus, setIsAscendingStatus] = useState(true);
  const [lengthOfRecords, setLengthOfRecords] = useState("");
  const [issueID, setIssueID] = useState("");
  const [showConfigPop, setConfigPopUp] = useState(false)
  const [editScenario, setEditScenario] = useState("CONFIG")
  const [uniqueIdFromMP, setUniqueIdFromMp] = useState()

  const [url, setUrl] = useState()
  const [PAT, setPAT] = useState()

  const [description, setDescription] = useState("");
  const [functions15, setFunctions15] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSimilarityCheckDone, setIsSimilarityCheckDone] = useState(false);

  const [repoName, setRepoName] = useState()
  const [repoUrl , setRepoUrl] = useState()
  const [IssueIDs, setIssuesIDs] = useState([]);
  const [IssuesConfigValue, setIssuesConfigValue] = useState([]);
  let project_image_from_loc = localStorage.getItem('project_image')
  // PS_MI_05 to PS_MI_22- useEffect will be rendered based on the dependency array
  useEffect(() => {
    fetchIssues();
  }, [searchIssueID, workItemType, sortOrder, endOffset, showFilter]);

  useEffect(() => {
    if (IssueIDs.length > 0) {
      fetchConfigIssues();
    }
  }, [IssueIDs]);

  

  const fetchInitialData = async () => {
     
    try {
      const reqBody = {
        projectID: projectIdFromLocal
      }
      let response = await ExistData(reqBody)
      //(response.Responsedata[0], "125")
      setUrl(response.Responsedata[0].project_url)
      setPAT(response.Responsedata[0].PAT)
      setRepoName(response.Responsedata[0].Repo_data[0].Repo_name)
     
      setRepoUrl(response.Responsedata[0].Repo_data[0].Repo_Url)


    }
    catch (error) {
      //(error)
    }
  }
//(url , PAT , "asfaeggggg")
  useEffect(() => {
     
    fetchInitialData()
    setWorkItemType("issue")
  }, [])

  function convertDate(val, index){
    const date = new Date(val);
    // Get year, month, and day
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const day = date.getUTCDate().toString().padStart(2, '0');
 
    // Combine into desired format
    const formattedDate = `${year}-${month}-${day}`;
 
    console.log(formattedDate);
    if(index=='from'){
      setFromDate(formattedDate);
    }
    else if(index=='to'){
      setToDate(formattedDate)
    }
  }
  // PS_MI_06 - To fetch issue details by passing the request payload to the getIssues API
  const fetchIssues = async () => {
    //(projectID, "jumbalaka")
    const data = {
      projectID: projectID,
      issueID: searchIssueID,
      filterStatus: filterStatus,
      fromDate: fromDate,
      toDate: toDate,
      workItemType: "issue",
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      offsetValue: endOffset
    };

    if(showFilter !=true && cancel!=true ){
      
      let response = await getIssues(data);
      setIsLoading(true)
      
      //(response.data, "prasanth")
  
      if (response.status == 200) {
        setIssues(response.data[0].issues);
        setLengthOfRecords(response.data[0].issues.length);
        setTotalRecords(response.data[0].totalRecords);
        const uniqueProjectIDs = [...new Set(response.data[0].issues.map(issues => issues.issueID))]
          .map(issueID => ({ issueID }));
        setIssuesIDs(uniqueProjectIDs);
      }
    }
    else{
      setCancel(false)
    }
   
  };

  
const fetchConfigIssues = async () => {
  try {
    
    let data = {
      "issues": IssueIDs
    }
    const response = await getIssuesConfig(data);
    setIsLoading(true)
    if (response.status === 200) {
      setIssuesConfigValue(response.data[0].issues.issues)
      setStatusDropdown(response.data[0].statusDropdownResult);
      
    }
  } catch (error) {
    console.error('Error in issueFetchIsconfig:', error);
  }
};

const getIsConfigured = (issueID) => {
  const config = IssuesConfigValue.find(item => item.issueID === issueID);
  return config ? config.isConfigured : null;
}

  console.log("IssuesConfigValue",IssuesConfigValue)
  console.log("setIssuesIDs",IssueIDs)


  // PS_MI_34 to PS_MI_36 - To check whether the user chooses atleast any one of the field
  function filterValidation() {
    let flag = 0;

    if (filterStatus != "") {
      flag++;
    }
    if (fromDate != "") {
      flag++;
    }
    if (toDate != "") {
      flag++;
    }
    if (flag != 0) {
      setShowFilter(false);
      setFilterAlert("");
      fetchIssues();
    } else {
      setFilterAlert("alert");
    }
  }

  
  const currentRoute = location.pathname;
  const askAsami = (issueID, workitem) => {
    //debugger;
    localStorage.setItem('workItemFromMI', workitem)
    localStorage.setItem('collectionNameFromMT', repoName)
    localStorage.setItem('issueIDFromMI', issueID)
    setIssueID(issueID)
    const pathname = `/chat`;
 
    const state =  {
          yesFixButton: true,
          startConversation: true,
          hidePrompts: true,
          sendMessage: false,
          fixButton: true,
          tfsCollectionName: repoName,
          projectName: projectNameFromLocal,
          workItem: workitem,
        }
 
    // Store the state in sessionStorage
    sessionStorage.setItem('issueState', JSON.stringify(state));
 
    // Construct the full URL
    const fullURL = `${pathname}`;
 
    // Use replaceState to update the URL without navigation
    // history.push(fullURL)
 
    // Force a reload
    window.location.assign(fullURL);
 
 
  };

  //(fromDate, toDate);


  // PS_MI_42 to PS_MI_43 - To make the state variables null when the user clicks cancel in filter to fetch the initial grid
  const cancelFilter = () => {
    setShowFilter(false);
    setFilterStatus("");
    setFromDate("");
    setToDate("");
    setFilterAlert("");
  }


  const handleDescription = async () => {
    let obj = {
      "question": description,
      "projectName" : projectNameFromLocal,
      "collectionName": repoName
    }
    setIsLoading(false)
    let response = await similarityCheck(obj);
    if (response.status == 200) {
      setIsLoading(true)
      //(response, "SimilarityCheckResponse")
      let content = response.data.Response
      //(content, "SimilarityCheckContent")
      setFunctions15(content);
      setIsSimilarityCheckDone(true);
      history.push({
        pathname: '/chat',
        state: { functions15: content, isSimilarityCheckDone: true, projectID: projectID, Repo_url: repoUrl, PAT: PAT }
      });
    }
  }


  return (
    <>
       {!isLoading ? (<div >
     
        <Loader isLoading={isLoading} />
      </div>): (
        <>
        <div className="container-fluid"> 
        <div className="row justify-content-lg-end ms-lg-3 ms-xl-0 justify-content-center">
        <div className={navOpen.navOpen == "Close" ? `col-11 me-xl-4 me-lg-3` : `col-lg-9 me-md-3 me-lg-4 me-xl-5 col-11`}>
        <div className="row">
              <div className="col-md-12 px-4 pe-lg-0 pe-2 pt-3">
                <h1 className="color-black-v1 font-24 font-bold mt-5 mb-4 d-flex align-items-center">
                  <a href="/ManageProject">
                    <img
                      src="images-new/back-black-arrow.svg"
                      className="me-3 mb-1 icon-filter"
                      alt="back-arrow"
                      title="Back"
                    />
                  </a>
                  <span className='text-color-1'>
                  <img src={project_image_from_loc} alt="Profile"
                  className="me-2 mb-1"
                  style={{width:'50px'}}/>
                    {projectNameFromLocal}
                  </span>
                 
                </h1>
                <div className="my-4 d-grid d-md-flex justify-content-md-between align-items-center">
                  <h2 className="font-bold font-20 mb-0 text-nowrap text-color-1">
                    Manage Issues ({issues.length})
                  </h2>
                  <div className="table-header d-md-flex align-items-center">
                    <div className="p-2 px-3 border-radius-35 d-flex justify-content-between me-3 mt-3 mt-md-0 search-bg border-color-10">
                      <span className="flex-nowrap text-nowrap text-color-9">Integrated with</span>
                      <img src="./images-new/avalon-v2.svg" alt="iocn" className="ms-2" />
                    </div>
                    {/*search*/}
                    <div className="d-flex align-items-center mt-3 mt-md-0">
                      <div className="input-group transparent-grp w-225 me-3 m-0 search-bg">
                        {/* PS_MI_23 to PS_MI_28 - when the user searches the issue ID */}
                       
<input
                          type="text"
                          className="form-control custom-search-input  text-color-4 border-0 bg-transparent focus-none font-14 font-medium pe-0 ps-3"
                          placeholder="Search" onChange={(e) => {
                            setSearchDupIssueID(e.target.value);
                          }}
                          onKeyUp={(e) => {
                            if (e.key === "Enter") {
                              setSearchIssueID(e.target.value)
                              // setSearchDupIssueID("")
                            }
                          }}
                          value={searchDupIssueID}
                        />
                        <button className="btn focus-none bg-transparent border-0" type="button" onClick={(e) => {
                          setSearchIssueID(searchDupIssueID);
                          // setSearchDupIssueID("")
                        }}>
 
                          <img className='filter-icons' src="images-new/input-search.svg" alt="input-search-icon" />
                        </button>
                      </div>
                      <div className="dropdown me-2">
                        <button
                          type="button"
                          className="btn font-16 font-semibold align-items-center d-flex transparent-grp py-2 theme-focus inter-btn search-bg"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onClick={() => {
                            setShowFilter(true);
                          }}
                        >
                          <img className='filter-icons' src="images-new/adv-filter.svg" alt="filter-icon" />
                        </button>
                        {/* advanced filter starts here */}
                        <span className=" dropdown-menu-end p-3 filter-dropdown-bg w-filter-dropdown end-0 bg-color-4" style={{ display: showFilter ? "block" : "none" }}>
                          <span className="d-flex justify-content-between align-items-center mb-3">
                            <span className="font-18  font-semibold text-color-1">Advanced Filter</span>
                            <span className="" 
                            onClick={() => {
                              setCancel(true);
                              setShowFilter(false);
                              
                            }}>
                              <img
                                src="images-new/flter-close-icon.svg"
                                alt="flter-close-icon"
                                className="cursor-pointer close-icon-filter"
                              />
                            </span>
                          </span>
                          <span className="row ">
                            <span className="mb-3 d-block col-md-12">
                              <span className="mb-3">
                                <label
                                  htmlFor="from"
                                  className="form-label label-color font-15 font-semibold text-color-9"
                                >
                                  Status
                                </label>
                                <select className="form-select bg-color-4 custom-border-3 rounded-3 text-color-2" id="model-type"
                                  value={filterStatus}
                                  onChange={(e) => {
                                    setFilterStatus(e.target.value);
                                  }}
                                  onClick={(e)=>e.currentTarget.classList.toggle('open')}>
                                  <option selected>Select</option>
                                  {statusDropdown.map((value, index) => (

                                    <option
                                      value={value.issue_status}
                                      // id={value.approvalStatusID}
                                      key={index}
                                    >
                                      {value.issue_status}
                                    </option>
                                  ))};
                                </select>
                              </span>
                            </span>
                          </span>
                          <span className="mb-2 d-block">
                            <span className="d-block font-semibold font-16 text-color-9">
                              Date Range
                            </span>
                          </span>
                          <span className="row mb-2">
                            <span className="mb-3 d-block col-md-6">
                            <span className="mb-3">
                                <label htmlFor="from" className="form-label text-color-9 font-15 font-semibold">From</label>
                                <div className='position-relative'>
                                  <DatePicker
                                    id="from"
                                    selected={fromDate}
                                    onChange={(date) => {
                                      convertDate(date,'from')
                                      setToDate(null);
                                    }}
                                    className="form-control input-field font-14 font-regular border-radius-3 text-color-1 bg-color-4 custom-border-3"
                                    dateFormat="MM-dd-yyyy"
                                   placeholderText="mm-dd-yyyy"
                                  />
                                  <img
                                    className="calender-icon"
                                    src="images/calender-icon.svg"
                                    alt="calendar-icon"
                                    onClick={() => document.getElementById('from').focus()}
                                  />
                                </div>
                              </span>
                            </span>
                            <span className="mb-3 d-block col-md-6">
                            <span className="mb-3">
                                <label htmlFor="to" className="form-label text-color-9 font-15 font-semibold">To</label>
                                <div className='position-relative'>
                                  <DatePicker
                                    id="to"
                                    selected={toDate}
                                    onChange={(date) => convertDate(date,'to')}
                                    minDate={fromDate}
                                    className="form-control input-field font-14 font-regular border-radius-3 text-color-1 bg-color-4 custom-border-3"
                                    dateFormat="MM-dd-yyyy"
                                    placeholderText="mm-dd-yyyy"
                                  />
                                  <img
                                    className="calender-icon"
                                    src="images/calender-icon.svg"
                                    alt="calendar-icon"
                                    onClick={() => document.getElementById('to').focus()}
                                  />
                                </div>
                              </span>
                            </span>
                          </span>
                          <span className="w-100 d-flex justify-content-end gap-3 mt-3 mb-1">
                            <button className="btn custom-outline-btn font-14 font-semibold px-3 m-0"
                              onClick={() => {
                                cancelFilter();
                              }}>
                              Cancel
                            </button>
                            <button className="btn primary-btn font-14 font-semibold border-radius-5 px-3 py-2 color-white m-0"
                              onClick={() => {
                                setFlag("filter")
                                {
                                  filterValidation();
                                }
                              }}>
                              Apply
                            </button>
                          </span>
                          {filterAlert == "alert" ? (
                            <div className="span-color">
                              Select,atleast one filter!
                            </div>
                          ) : null}
                        </span>
                        {/* advanced filter ends here */}
                      </div>
                      <div className="dropdown" style={{position:'relative'}}>
                    <button
                      id="end-point-config"
                      className="btn input-field font-14 story-drpdwn-btn font-regular border-radius-3 bg-color-12 border-0 w-100 d-flex justify-content-between"
                      type="button"
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    >
                      <span className="issue-color font-medium font-14 d-flex">
                        <img
                          src="images-new/task-icon.svg"
                          alt="Issues"
                          className="me-2"
                        />
                        Issue
                      </span>
                      <span className="ps-1">
                        <img
                          src="images-new/drpdwn-arrow.svg"
                          alt="dropdown-icon"
                          className="ms-1"
                        />
                      </span>
                    </button>
                    {isDropdownOpen && (
                      <ul className="dropdown-menu border-0 shadow-sm py-2 w-100 bg-color-4 show mt-2" style={{position:'absolute', top: '100%', right: '0%', zIndex: '1000', display: 'block'}}>
                        <li className="font-14 font-medium color-black px-2">
                          <a className="dropdown-item py-2 custom-dropdown-li text-color-1" onClick={() => {
                            history.push({
                              pathname: "/ManageTasks",
                              state: {
                                projectIdFromMP: projectID,
                              }
                            })
                          }}>
                            <img
                              src="images-new/task-icon.svg"
                              alt="Tasks"
                              className="me-2 icon-width"
                            />
                            View by Task
                          </a>
                        </li>
                        <li className="font-14 font-medium color-black px-2">
                          <a className="dropdown-item py-2 custom-dropdown-li text-color-1" 
                          onClick={() => {
                            sessionStorage.setItem('isModule', 'issues');
                            console.log(sessionStorage.getItem('isModule', 'issues'), 'ooooooo')
                          }}
                          >
                            <img
                              src="images-new/issues-icon.svg"
                              alt="Issues"
                              className="me-2"
                            />
                            View by Issue
                          </a>
                        </li>
                        {/* <li className="font-14 font-medium color-black">
                          <a className="dropdown-item py-2 custom-dropdown-li" onClick={() => {
                            history.push({
                              pathname: "/ManageStory",
                              state: {
                                projectIdFromMP: projectIdFromLocal,
                              }
                            })
                          }}>
                            <img
                              src="images-new/story-icon.svg"
                              alt="Story"
                              className="me-2 icon-width"
                            />
                            View by Story
                          </a>
                        </li> */}
                      </ul>
                    )}
                  </div>
                    </div>
                  </div>
                  {/*search*/}
                </div>
                {/*grid starts here*/}
                
                  <div className="col-md-12">
                    <div className="table-responsive rounded-3 transparent-grp mb-4">
                      <table className="table theme-table font-regular font-14 mb-0">
                        <thead>
                          {/* PS_MI_45 to PS_MI_52 - When the user clicks on particular column to sort */}
                          <tr className="grey-color-v5">
                            <th className="ps-5 py-4"
                            
                              onClick={() => {
                                setSortColumn("project_issue_key")
                                setIsAscendingIssueID(!isAscendingIssueID);
                                setSortOrder(
                                  isAscendingIssueID
                                    ? "Desc"
                                    : "Asc"
                                );
                              }}>
                              Issue ID
                              <span className="cursor-pointer ms-1">
                                <img className='icon-filter' src={
                                  isAscendingIssueID
                                    ? "images-new/sort-up.svg"
                                    : "images-new/sort-down.svg"
                                } alt={isAscendingIssueID ? "sort-up" : "sort-down"} />
                              </span>
                            </th>

                            {/* <th className="ps-5 py-4"
                            >
                            Chat Link
                            
                            </th> */}

                            <th className="text-start"
                              onClick={() => {
                                setSortColumn("work_item")
                                setIsAscendingWorkItem(!isAscendingWorkItem);
                                setSortOrder(
                                  isAscendingWorkItem
                                    ? "Desc"
                                    : "Asc"
                                );
                              }}>
                              Work Item
                              <span className="cursor-pointer ms-1">
                                <img className='icon-filter' src={
                                  isAscendingWorkItem
                                    ? "images-new/sort-up.svg"
                                    : "images-new/sort-down.svg"
                                } alt={isAscendingWorkItem ? "sort-up" : "sort-down"} />
                              </span>
                            </th>

                            <th className="text-start"
                         onClick={() => {
                          setSortColumn("date_range")
                          setIsProjectTimeline(!isProjectTimeline);
                          setSortOrder(
                            isProjectTimeline
                              ? "Desc"
                              : "Asc"
                          );
                        }}
                        >
                          Project Timeline
                          <span className="cursor-pointer ms-1">
                          <img className='icon-filter' src={
                                  isProjectTimeline
                                    ? "images-new/sort-up.svg"
                                    : "images-new/sort-down.svg"
                                } alt={isProjectTimeline ? "sort-up" : "sort-down"} />
                          </span>
                        </th>

                            <th className="text-center"
                              onClick={() => {
                                setSortColumn("assigned_to")
                                setIsAscendingAssignedTo(!isAscendingAssignedTo);
                                setSortOrder(
                                  isAscendingAssignedTo
                                    ? "Desc"
                                    : "Asc"
                                );
                              }}>
                              Assigned To
                              <span className="cursor-pointer ms-1">
                                <img className='icon-filter' src={
                                  isAscendingAssignedTo
                                    ? "images-new/sort-up.svg"
                                    : "images-new/sort-down.svg"
                                } alt={isAscendingAssignedTo ? "sort-up" : "sort-down"} />
                              </span>
                            </th>

                            <th className="text-start"
                              onClick={() => {
                                setSortColumn("Status")
                                setIsAscendingStatus(!isAscendingStatus);
                                setSortOrder(
                                  isAscendingStatus
                                    ? "Desc"
                                    : "Asc"
                                );
                              }}>
                              Status
                              <span className="cursor-pointer ms-1">
                                <img className='icon-filter' src={
                                  isAscendingStatus
                                    ? "images-new/sort-up.svg"
                                    : "images-new/sort-down.svg"
                                } alt={isAscendingStatus ? "sort-up" : "sort-down"} />
                              </span>
                            </th>
                            <th className="text-center pe-5">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                        {issues.length !== 0 ? (
                          (issues.map((issue, index) => (
                            <React.Fragment key={index}>
                              {index % 2 === 0 ?
                                <tr className="" key={index}>

                                  <td className="ps-5 py-4">
                                    {getIsConfigured(issue.issueID)  ? (
                                      <a
                                      href="#" className="text-decoration-none link-color" 
                                      onClick={()=>{
                                        if (currentRoute === '/manageissue' || currentRoute === '/ManageIssue') {
                                          askAsami(issue.issueID, issue.workItem);
                                        }
                                    }}
                                    >
                                        {issue.issueID}
                                      </a>
                                    ) : (
                                      <span  
                                      onClick={()=>{
                                        if (currentRoute === '/ManageIssue') {
                                          askAsami(issue.issueID, issue.workItem);
                                        }
                                    }}>
                                      {issue.issueID}
                                    </span>
                                    )}
                                  </td>
                                  {/* <td className="text-start">{issue.workItem}</td> */}
                                  <td className="text-start">{issue.workItem}</td>
                                  <td className="text-start">{issue.ProjectTimeline}</td>
                                  <td className="text-center">
                                  {issue.imageUrl ? 
                                      <img 
                                        src={`data:image/jpeg;base64,${issue.imageUrl}`} 
                                        alt="Profile" 
                                        className="profile-img-sm me-2" 
                                        title={issue.AssignedTo}
                                      /> :
                                      <img 
                                        src="images-new/default-profile-image.png" 
                                        alt="Profile" 
                                        className="profile-img-sm me-2" 
                                        title={issue.AssignedTo}
                                      />
                                    }
                        
                                  </td>
                                  
                                  <td className="text-start">
                                  {issue.Status === "proposed" ? (
                                  <div className='d-flex text-start align-items-center gap-2'><span className="status-dot in-hold-bg" />
                                    <span className="in-hold">Proposed</span></div>
                                )  : issue.Status === "closed" ? (
                                  <div className='d-flex text-start align-items-center gap-2'><span className="status-dot in-closed-bg" />
                                    <span className="closed">Closed</span></div>
                                ) : 
                                issue.Status === "active" ? (
                                  <div className='d-flex text-start align-items-center gap-2'><span className="status-dot in-progress-bg" />
                                    <span className="in-progress">Active</span></div>
                                ) :
                                (issue.Status === "open" ? (
                                  <div className='d-flex text-start align-items-center gap-2'><span class="status-dot danger-bg"></span>
                                    <span class="in-delay">Open</span></div>
                                ) :
                                  <span>Unknown status</span>
                                )}   
                                  </td>
                                  <td className="text-center pe-5">
                                    <button type="button" className="btn border-0">

                                      {getIsConfigured(issue.issueID) ? (
                                        <a onClick={() => {
                                          setEditScenario("update")
                                          setConfigPopUp(true)
                                          setUniqueIdFromMp(issue.issueID)
                                          localStorage.setItem('uniqueIdFromLocal', issue.issueID)
                                        }}>
                                          <img
                                            className="actions-right"
                                            src="images-new/edit-icons.svg"
                                            alt="icon"
                                          />
                                        </a>
                                      ) : (
                                        <a onClick={() => {
                                          setEditScenario("config")
                                          setConfigPopUp(true)
                                          setUniqueIdFromMp(issue.issueID)
                                          localStorage.setItem('uniqueIdFromLocal', issue.issueID)
                                        }}>
                                          <img
                                        className="actions-right"
                                        src="images-new/right-icon.svg"
                                        alt="icon"
                                      />
                                      </a>
                                      )}
                                    </button>
                                  </td>
                                </tr> :
                                <tr key={index}>

<td className="ps-5">
                                  {getIsConfigured(issue.issueID)  ? (
                                      <a
                                      href="#" className="text-decoration-none link-color" 
                                      onClick={()=>{
                                        if (currentRoute === '/manageissue' || currentRoute === '/ManageIssue') {
                                          askAsami(issue.issueID, issue.workItem);
                                        }
                                    }}
                                    >
                                        {issue.issueID}
                                      </a>
                                    ) : (
                                      <span  
                                      onClick={()=>{
                                        if (currentRoute === '/ManageIssue') {
                                          askAsami(issue.issueID, issue.workItem);
                                        }
                                    }}>
                                      {issue.issueID}
                                    </span>
                                    )}
                                  </td>

                                  <td className="text-start">{issue.workItem}</td>
                                  <td className="text-start">{issue.ProjectTimeline}</td>
                                  <td className="text-center">
                                  {issue.imageUrl ?<img src={`data:image/jpeg;base64,${issue.imageUrl}`} alt="Profile" class="profile-img-sm me-2"  title ={issue.AssignedTo}/>
                          :
                          <img src="images-new/default-profile-image.png" alt="Profile" class="profile-img-sm me-2"  title ={issue.AssignedTo}/>
                          }
                        
                              </td>
                                  <td className="text-start">
                                  {issue.Status === "proposed" ? (
                                  <div className='d-flex text-start align-items-center gap-2'><span className="status-dot in-progress-bg" />
                                    <span className="in-progress">Proposed</span></div>
                                ) : issue.Status === "resolved" ? (
                                  <div className='d-flex text-start align-items-center gap-2'><span class="status-dot not-started-bg "></span>
                                    <span class="not-started">Resolved</span></div>
                                ) : issue.Status === "closed" ? (
                                  <div className='d-flex text-start align-items-center gap-2'><span className="status-dot in-progress-bg" />
                                    <span className="in-progress">Closed</span></div>
                                ) : (issue.Status === "open" ? (
                                  <div className='d-flex text-start align-items-center gap-2'><span class="status-dot danger-bg"></span>
                                    <span class="in-delay">Open</span></div>
                                ) :
                                  <span>Unknown status</span>
                                )}  
                                  </td>
                                  <td className="text-center pe-5">
                                    <button type="button" className="btn border-0">

                                    {getIsConfigured(issue.issueID) ? (
                                        <a onClick={() => {
                                          setEditScenario("update")
                                          setConfigPopUp(true)
                                          setUniqueIdFromMp(issue.issueID)
                                          localStorage.setItem('uniqueIdFromLocal', issue.issueID)
                                        }}>
                                          <img
                                            className="actions-right"
                                            src="images-new/edit-icons.svg"
                                            alt="icon"
                                          />
                                        </a>
                                      ) : (
                                        <a onClick={() => {
                                          setEditScenario("config")
                                          setConfigPopUp(true)
                                          setUniqueIdFromMp(issue.issueID)
                                          localStorage.setItem('uniqueIdFromLocal', issue.issueID)
                                        }}>
                                          <img
                                        className="actions-right"
                                        src="images-new/right-icon.svg"
                                        alt="icon"
                                      />
                                      </a>
                                      )}
                                    </button>
                                  </td>
                                </tr>
                              }
                            </React.Fragment>
                        
                        )))):(
                                                
                                              <tr>
                                              <td colSpan={6} className="text-center py-5 vh-50">
                                                <img
                                                  src="images-new/no-record-img.svg"
                                                  alt="no-record-img"
                                                  className="no-record-img mb-5"
                                                />
                                                <span className="font-20 font-medium w-100 d-block">
                                                  Sorry! No issue is assigned to you.
                                                </span>
                                              </td>
                                            </tr>
                                              )}
                        

                        </tbody>
                      </table>
                    </div>
                    <p className="font-regular font-14 text-color-9 my-3">
                        # of Records :{" "}
                        <span className="font-bold font-14 text-color-1">
                          {" "}
                          {issues.length}
                        </span>{" "}
                        out of{" "}
                        <span className="font-bold font-14 text-color-1">
                          {totalRecords}
                        </span>
                      </p>
                    <div className="text-center mb-5">
                      {totalRecords > endOffset ?
                        <button className="btn gradient-btn border-radius-4 font-14 font-semibold py-2 px-3"
                          onClick={() => {
                            setEndOffset(prevEndOffset => prevEndOffset + 10);
                          }}
                          hidden={totalRecords < 10 || lengthOfRecords < 10} >
                          <span className="color-gradient">Load More</span>
                        </button> :
                        <div></div>}

                    </div>
                  </div>
                {/*grid starts here*/}
              </div>
              {/*manage request tab*/}
          </div>
            {/*Tab inner content end here*/}
          </div>
          </div>
          </div>



          <div className="modal fade" id="issues" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered custom-popup-issues border-1">
              <div className="modal-content p-3 border-0">
                <div className="modal-header border-0 p-0">
                  <h5 className="modal-title text-dark font-22 font-bold ">Add Description</h5>
                  <button type="button" className="custom-close me-0" data-bs-dismiss="modal" aria-label="Close"><img src="images/close-popup.svg" alt="close-icon" /></button>
                </div>
                <div className="modal-body popup-scrl pt-2">
                  <div className="row">
                    {/* content */}
                    <div className="col-md-12 col-sm-12 mt-4 p-0">
                      <label htmlFor="description" className="form-label label-color font-15 font-semibold color-black-v1 mb-2">Description</label>
                      <textarea className="form-control font-14 font-regular border-radius-3 resize-none" value={description} id="description" rows={5} placeholder="Description" defaultValue={""} onChange={(e)=> setDescription(e.target.value)} />
                    </div>
                  </div>
                </div>
                <div className="modal-footer border-0 gap-3">
                  <button type="button" className="btn btn-outline-secondary font-14 px-4 pb-2 m-0 font-medium" data-bs-dismiss="modal">Cancel</button>
                  <button type="button" className="btn primary-btn font-14 font-semibold border-radius-5 color-white pb-2 px-4 m-0" onClick={handleDescription}>Save</button>
                </div>
              </div>
            </div>
          </div>
          {showConfigPop ? <ConfigPopup projectIdFromMP={projectIdFromLocal} TypeOfData={editScenario} setShowPopUp={setConfigPopUp} typeOfGrid={"issue"}  uniqueIdFromMP={localStorage.getItem('uniqueIdFromLocal')} url={url} PAT={PAT} /> : <h1></h1>}
        </>
      )}
    </>
  )
}


export default ManageIssues