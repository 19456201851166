import shortid from "shortid";

export const SIDEBAR_ITEM = "sidebarItem";
export const ROW = "row";
export const COLUMN = "column";
export const COMPONENT = "component";

export const SIDEBAR_ITEMS = [
  [
    {
      id: shortid.generate(),
      type: SIDEBAR_ITEM,
      component: {
        type: "1-Column",
        content: "",
        image: "images/one-column.svg",
      },
    },
    {
      id: shortid.generate(),
      type: SIDEBAR_ITEM,
      component: {
        type: "2-Column",
        content: "",
        image: "images/two-column.svg",
      },
    },
  ],
  [
    {
      id: shortid.generate(),
      type: SIDEBAR_ITEM,
      component: {
        type: "Text Field",
        content: "Text Field",
        image: "images/Text-Field.svg",
        general: {
          labelName: "",
          placeHolder: "",
          inputType: "",
          enableHelpText: false,
          helpText: "",
          isMandatory: true,
          otherValidations: "",
        },
        dataSource: {
          propertyToBind: {
            textProperty: "",
          },
        },
        validation: {
          labelName: false,
          inputType: false,
          textProperty: false,
          helpText: false,
        },
      },
    },
  ],
  [
    {
      id: shortid.generate(),
      type: SIDEBAR_ITEM,
      component: {
        type: "Drop Down",
        content: "Drop Down",
        image: "images/Dropdown.svg",
        general: {
          labelName: "",
          placeHolder: "",
          dataSource: 1,
          options: [],
          enableHelpText: false,
          helpText: "",
          isMandatory: true,
        },
        dataSource: {
          apiLink: "",
          responseJson: "",
          propertyToBind: {
            valueProperty: "",
            textProperty: "",
          },
        },
        validation: {
          labelName: false,
          apiLink: false,
          valueProperty: false,
          textProperty: false,
          helpText: false,
          options: false,
        },
      },
    },
    {
      id: shortid.generate(),
      type: SIDEBAR_ITEM,
      component: {
        type: "Checkbox",
        content: "Checkbox",
        image: "images/Checkbox.svg",
        general: {
          labelName: "",
          alignment: 1,
          dataSource: 1,
          options: [],
          enableHelpText: false,
          helpText: "",
          isMandatory: true,
        },
        dataSource: {
          apiLink: "",
          responseJson: "",
          propertyToBind: {
            valueProperty: "",
            textProperty: "",
          },
        },
        validation: {
          labelName: false,
          alignment: false,
          apiLink: false,
          valueProperty: false,
          textProperty: false,
          helpText: false,
          options: false,
        },
      },
    },
    {
      id: shortid.generate(),
      type: SIDEBAR_ITEM,
      component: {
        type: "Radio",
        content: "Radio",
        image: "images/Radio.svg",
        general: {
          labelName: "",
          alignment: 1,
          dataSource: 1,
          options: [],
          enableHelpText: false,
          helpText: "",
          isMandatory: true,
        },
        dataSource: {
          apiLink: "",
          responseJson: "",
          propertyToBind: {
            valueProperty: "",
            textProperty: "",
          },
        },
        validation: {
          labelName: false,
          alignment: false,
          apiLink: false,
          valueProperty: false,
          textProperty: false,
          helpText: false,
          options: false,
        },
      },
    },
    {
      id: shortid.generate(),
      type: SIDEBAR_ITEM,
      component: {
        type: "Type Ahead",
        content: "Type Ahead",
        image: "images/Typeahead.svg",
        general: {
          labelName: "",
          placeHolder: "",
          dataSource: 1,
          options: [],
          enableHelpText: false,
          helpText: "",
          isMandatory: true,
        },
        dataSource: {
          apiLink: "",
          responseJson: "",
          propertyToBind: {
            valueProperty: "",
            textProperty: "",
          },
        },
        validation: {
          labelName: false,
          apiLink: false,
          textProperty: false,
          valueProperty: false,
          helpText: false,
          options: false,
        },
      },
    },
  ],
  [
    {
      id: shortid.generate(),
      type: SIDEBAR_ITEM,
      component: {
        type: "Date Picker",
        content: "Date Picker",
        image: "images/Date-picker.svg",
        general: {
          labelName: "",
          enableHelpText: false,
          helpText: "",
          isMandatory: true,
        },
        dataSource: {
          propertyToBind: {
            textProperty: "",
          },
        },
        validation: {
          labelName: false,
          textProperty: false,
          helpText: false,
        },
      },
    },
    {
      id: shortid.generate(),
      type: SIDEBAR_ITEM,
      component: {
        type: "Time Picker",
        content: "Time Picker",
        image: "images/Time-Picker.svg",
        general: {
          labelName: "",
          enableHelpText: false,
          helpText: "",
          isMandatory: true,
        },
        dataSource: {
          propertyToBind: {
            textProperty: "",
          },
        },
        validation: {
          labelName: false,
          textProperty: false,
          helpText: false,
        },
      },
    },
  ],
];
