import React, { useEffect, useRef, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';

const LineBarGraph = ({ costGraph }) => {
    const theme = useTheme();
    const chartRef = useRef(null);
    const [textColor, setTextColor] = useState('#000000');
    const [chartKey, setChartKey] = useState(0);

    useEffect(() => {
        const updateTextColor = () => {
            if (chartRef.current) {
                const card = chartRef.current.closest('.custom-dashboard-section');
                if (card) {
                    const bgColor = window.getComputedStyle(card).backgroundColor;
                    const rgb = bgColor.match(/\d+/g);
                    if (rgb) {
                        const brightness = (parseInt(rgb[0]) * 299 + parseInt(rgb[1]) * 587 + parseInt(rgb[2]) * 114) / 1000;
                        setTextColor(brightness > 128 ? '#000000' : '#ffffff');
                    }
                }
            }
        };

        const resizeObserver = new ResizeObserver(updateTextColor);

        if (chartRef.current) {
            const card = chartRef.current.closest('.custom-dashboard-section');
            if (card) {
                resizeObserver.observe(card);
            }
        }

        updateTextColor();
        setChartKey(prev => prev + 1);

        return () => resizeObserver.disconnect();
    }, [theme]);

    let labels = [];
    let yAxis = [];
    let condition = "";

    // Filter out entries with empty names and sort the data
    const filteredAndSortedData = costGraph
        .filter(ele =>
            (ele.practiceName && ele.practiceName.trim().length > 0) ||
            (ele.projectName && ele.projectName.trim().length > 0) ||
            (ele.userName && ele.userName.trim().length > 0)
        )
        .sort((a, b) => {
            const nameA = a.practiceName || a.projectName || a.userName || '';
            const nameB = b.practiceName || b.projectName || b.userName || '';
            return nameA.localeCompare(nameB);
        });

    filteredAndSortedData.forEach((ele) => {
        if (ele.practiceName && ele.practiceName.trim().length > 0) {
            labels.push(ele.practiceName);
        } else if (ele.projectName && ele.projectName.trim().length > 0) {
            labels.push(ele.projectName);
        } else if (ele.userName && ele.userName.trim().length > 0) {
            labels.push(ele.userName);
            condition = "Individual";
        }
        yAxis.push(ele.Cost != null ? parseFloat(ele.Cost.toFixed(2)) : 0);
    });

    const series = [
        {
            name: 'Cost',
            type: 'bar',
            data: yAxis,
        },
    ];

    const options = {
        chart: {
            height: 350,
            type: 'bar',
            toolbar: { show: false },
            background: 'transparent',
            foreColor: textColor,
        },
        plotOptions: {
            bar: {
                borderRadius: 7,
                borderRadiusApplication: 'end',
                columnWidth: '40%',
                dataLabels: { position: 'top' },
            }
        },
        colors: [theme.palette.mode === 'dark' ? '#8c7ae6' : '#BEB1FF'],
        dataLabels: {
            enabled: true,
            formatter: (val) => `$${parseInt(val)}`,
            offsetY: -20,
            style: { fontSize: '12px', colors: [textColor] },
            dropShadow: { enabled: false }
        },
        xaxis: {
            categories: labels,
            labels: {
                
                style: { colors: textColor },
                formatter: (val) => val.length > 5 ? val.substring(0, 5) + '...' : val,
            },
            title: {
                
                style: { color: textColor }
            },
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    if (val >= 1000000) return `$${(val / 1000000).toFixed(1)}M`;
                    if (val >= 1000) return `$${(val / 1000).toFixed(1)}K`;
                    return `$${val.toFixed(0)}`;
                },
                style: { colors: textColor }
            },
        },
        states: {
            hover: {
                filter: {
                    type: 'darken',
                    value: 0.8
                }
            }
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: (val) => `$${val.toFixed(2)}`
            },
            x: {
                // Use a custom formatter for the tooltip to show the full label
                formatter: (val, { series, seriesIndex, dataPointIndex, w }) => {
                    return w.globals.labels[dataPointIndex];
                }
            },
            theme: textColor === '#ffffff' ? 'dark' : 'light', // Adapting tooltip theme to text color
        },

        legend: { show: false },
        grid: {
            row: { colors: ['transparent'] },
            borderColor: textColor === '#ffffff' ? '#888888' : '#f1f1f1',
            strokeDashArray: [5]
        },
    };

    return (
        <div id="chart" ref={chartRef}>
            <ReactApexChart options={options} series={series} type="bar" height={272} key={`${chartKey}-${textColor}`} />
        </div>
    );
};

export default LineBarGraph;