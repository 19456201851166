// ConfigContext.js
import React, { createContext, useState } from 'react';

export const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {
    const [config, setConfig] = useState({
        maxResponse: 4096,
        temperature: 0.7,
        topP: 0.2,
        frequencyPenalty: 0.1,
        presencePenalty: 0.1
    });

    return (
        <ConfigContext.Provider value={{ config, setConfig }}>
            {children}
        </ConfigContext.Provider>
    );
};
