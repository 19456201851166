import React from "react";

export function Header() {
  return (
    <nav class="navbar navbar-light fixed-top bg-white border-bottom border-light  header-nav-pad service-header">
      <a class="navbar-brand ps-2 cursor-point">
        <img src="images/Logo.svg" alt="CodeGen Logo" />
      </a>
      <div class="d-flex pe-2">
        <div class="dropdown">
          <button
            class="btn p-0 cursor-point"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {/* <img src="images/User-image.svg" alt="user-image" /> */}
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <a class="dropdown-item cursor-point">Logout</a>
            </li>
          </ul>
        </div>
      </div>
      <div hidden={true} className="validationmsgpopup">
        <div
          class="alert alert-warning alert-dismissible fade show validationmsgpopupalert"
          role="alert"
        >
          <strong>Something went wrong</strong>
          <button
            type="button"
            class="btn-close validationmsgclosebtn "
            aria-label="Close"
          ></button>
        </div>
      </div>
      {/* <a href="../Help-Page/Service-Builder-OrganizeMethods.html" target="_blank" class="help-btn">Need Help?</a> */}
      <div className="d-flex cursor-pointer">
        <img src="images/help-web-button.svg" className="help-btn-img" />
        <a
          href="../Help-Page/codegen-helpcenter-home.html"
          target="_blank"
          class="help-btn ms-1"
        >
          Need Help
        </a>
      </div>
    </nav>
  );
}

export default Error;
