//PS_AAI_6.1,Declare all the import statements
import { useEffect, useState, useContext } from "react";
import { getInputTypeAndTechStack } from "../AI Service/Api";
import toggleContext from "./Context";

/**
 * PS_AAI_6.2 to PS_AAI_6.4 declare the state variable and useEffect for the component
 * @returns empty input based on the props value
 */
export default function ChoicesComponent({addOptionProp}) {
  const toggle = useContext(toggleContext);
  const [addOption, setaddOption] = useState([]);
  const [toggleBtn, settoggleBtn] = useState("");
  const [editChoices, setEditChoices] = useState([])
  const [editChoicesCount, setEditChoicesCount] = useState(0)
  useEffect(() => {

    if (toggle) {
      //(toggle);
      //(toggle?.editChoices?.length, "edit");
      //(toggle?.options, "edit");
      settoggleBtn(toggle?.chs);
      setaddOption(toggle?.options);
      let newChoices = toggle?.editChoices
      if(toggle?.editChoices[toggle?.editChoices.length-1] == "" && toggle?.editChoices.length>2){
        toggle?.editChoices.pop()
      }
      if(toggle?.options < newChoices?.length && (toggle.options > 2 && newChoices?.length > 2)){
        addOptionProp(newChoices?.length)
      }
      if(toggle?.options < 2 ){
        addOptionProp(2)
      }
      setEditChoices(newChoices)
      if (toggle?.options && toggle?.editChoices?.length) {
        setEditChoicesCount(toggle?.options - toggle?.editChoices?.length)
      }

    }
  }, [toggle]);

  /**
   * PS_AAI_6.5 to PS_AAI_6.10 check the toggleBtn condition and bind the dropdown
   * PS_AAI_6.11 to PS_AAI_6.14 retun the checkbox input field
   * PS_AAI_6.15 to PS_AAI_6.18 return the radio box
   *  @returns the empty inputs based on the toggleBtn
   */
  const bindChoices = () => {
    switch (toggleBtn) {


      case "dropdown":
        //(toggleBtn, editChoices.length, editChoicesCount, !editChoices.length && !editChoicesCount);

        if (editChoices.length == 0) {
          editChoices.push("")
          editChoices.push("")
        }

        return (
          <>{!editChoices.length && !editChoicesCount ? (<div className="row pb-3">
            <div className="col-md-6 col-sm-12 mb-3">
              <div className="input-field pe-2 bg-color custom-border-3">
                <ul className="list-unstyled common-dropdown-scroll-popup mb-0">
                  <li className="custom-border-bottom text-color-2 font-14 py-2">Add Choices</li>
                  {/* {//(addOption, "add option")}
                  {//(addOption, "add option")} */}
                  {Array.from({ length: addOption }, (_, index) => (
                    <li className="font-14 py-2">
                      <input
                        type="text"
                        className="form-control theme-focus input-field 1 text-color-4 bg-color custom-border-3"
                        id="old-values"
                        placeholder="Enter choices"
                        onChange={(e) => {
                          // const updatedChoices = [...editChoices];
                          // updatedChoices[index] = e.target.value;
                          // setEditChoices(updatedChoices);
                        }}
                        onBlur={(e) => {
                          //(e.target.value);
                          const updatedChoices = [e.target.value]

                          toggle?.functions(e, updatedChoices);
                        }}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>) : editChoices.length && !editChoicesCount ? (<div className="row pb-3">
            <div className="col-md-6 col-sm-12 mb-3">
              <div className="input-field pe-2 bg-color custom-border-3">
                <ul className="list-unstyled common-dropdown-scroll-popup mb-0">
                  <li className="custom-border-bottom text-color-2 font-14 py-2">Add Choices</li>
                  {/* {//(addOption, "add option")} */}
                  {editChoices?.map((choice, index) => {
                    return (
                      <><li className="font-14 py-2">
                        <input
                          type="text"
                          className="form-control theme-focus input-field 2 text-color-4 bg-color custom-border-3"
                          id="old-values"
                          value={choice}
                          placeholder="Enter choices"
                          onChange={(e) => {
                            const updatedChoices = [...editChoices];
                            updatedChoices[index] = e.target.value;
                            setEditChoices(updatedChoices);
                          }}
                          onBlur={(e) => {
                            //(e.target.value);
                            const updatedChoices = [...editChoices];
                            updatedChoices[index] = e.target.value;
                            setEditChoices(updatedChoices);

                            e.target.value = updatedChoices[index];

                            toggle?.functions(e, updatedChoices);

                          }}
                        />
                      </li></>
                    )

                  })}
                </ul>
              </div>
            </div>
          </div>) : editChoices.length && editChoicesCount ? (<div className="row pb-3">
            <div className="col-md-6 col-sm-12 mb-3">
              <div className="input-field pe-2 bg-color custom-border-3">
                <ul className="list-unstyled common-dropdown-scroll-popup mb-0">
                  <li className="custom-border-bottom text-color-2 font-14 py-2">Add Choices</li>
                  {/* {//(addOption, "add option")} */}
                  {editChoices?.map((choice, index) => {
                    return (<><li className="font-14 py-2">
                      <input
                        type="text"
                        className="form-control theme-focus input-field 3 text-color-4 bg-color custom-border-3"
                        id="old-values"
                        placeholder="Enter choices"
                        value={choice}
                        onChange={(e) => {
                          const updatedChoices = [...editChoices];
                          updatedChoices[index] = e.target.value;
                          setEditChoices(updatedChoices);
                        }}
                        onBlur={(e) => {
                          //(e.target.value);
                          const updatedChoices = [...editChoices];
                          updatedChoices[index] = e.target.value;
                          setEditChoices(updatedChoices);

                          e.target.value = updatedChoices[index];

                          toggle?.functions(e, updatedChoices);

                        }}
                      />
                    </li></>)
                  })}
                  {Array.from({ length: editChoicesCount }, (_, index) => (<li className="font-14 py-2">

                    <input
                      type="text"
                      className="form-control theme-focus input-field text-color-4 bg-color custom-border-3"
                      id="old-values"
                      placeholder="Enter choices"
                      onBlur={(e) => {
                        toggle?.functions(e);
                      }}
                    />
                  </li>))}

                </ul>
              </div>
            </div>
          </div>) : null}</>
        );



      case "radio":
        //(toggleBtn, addOption, editChoices.length, editChoicesCount, !editChoices.length && !editChoicesCount, "radio");
        return (
          <>
            {!editChoicesCount && editChoices.length ? (
              <div className="w-100">
                {editChoices?.map((choice, index) => {
                  return (
                    <div className="col-md-5 col-sm-12 mb-3 d-flex align-items-center">
                      <label className="me-3">
                        <span className="circle-label d-block" />
                      </label>
                      <input
                        type="text"
                        className="form-control theme-focus input-field custom-border-3 text-color-4 bg-color"
                        value={choice}
                        id="old-values"
                        placeholder="Enter the choices"
                        onChange={(e) => {
                          const updatedChoices = [...editChoices];
                          updatedChoices[index] = e.target.value;
                          setEditChoices(updatedChoices);
                        }}
                        onBlur={(e) => {
                          //(e.target.value);
                          const updatedChoices = [...editChoices];
                          updatedChoices[index] = e.target.value;
                          setEditChoices(updatedChoices);

                          e.target.value = updatedChoices[index];

                          toggle?.functions(e, updatedChoices);

                        }}
                      />
                    </div>
                  );
                })}
              </div>
            ) : editChoicesCount && editChoices.length ? (
              <div className="w-100">
                {editChoices?.map((choice, index) => {
                  return (

                    <div className="col-md-5 col-sm-12 mb-3 d-flex align-items-center">
                      <label className="me-3">
                        <span className="circle-label d-block" />
                      </label>
                      <input
                        type="text"
                        className="form-control theme-focus input-field custom-border-3 text-color-4 bg-color"
                        value={choice}
                        id="old-values"
                        placeholder="Enter the choices"
                        onChange={(e) => {
                          const updatedChoices = [...editChoices]; // Create a copy of the array
                          updatedChoices[index] = e.target.value; // Update the specific choice
                          setEditChoices(updatedChoices); // Update the state
                        }}
                        onBlur={(e) => {
                          const updatedChoices = [...editChoices];
                          updatedChoices[index] = e.target.value;
                          setEditChoices(updatedChoices);

                          toggle?.functions(e, updatedChoices);
                        }}
                      />{" "}
                    </div>
                  );
                })}

                {Array.from({ length: editChoicesCount }, (_, index) => (
                  <div className="col-md-5 col-sm-12 mb-3 d-flex align-items-center">
                    <label className="me-3">
                      <span className="circle-label d-block" />
                    </label>
                    <input
                      type="text"
                      id="old-values"
                      className="form-control theme-focus input-field text-color-4 bg-color custom-border-3"
                      placeholder="Enter the choices"
                      onBlur={(e) => {
                        toggle?.functions(e);
                      }}
                    />
                  </div>
                ))}
              </div>
            ) : !editChoices.length && !editChoicesCount ? (
              <div className="w-100">
                {Array.from({ length: addOption }, (_, index) => (
                  <div className="col-md-5 col-sm-12 mb-3 d-flex align-items-center">
                    <label className="me-3">
                      <span className="circle-label d-block" />
                    </label>
                    <input
                      type="text"
                      id="old-values"
                      className="form-control theme-focus input-field text-color-4 bg-color custom-border-3"
                      placeholder="Enter the choices"
                      onBlur={(e) => {
                        toggle?.functions(e);
                      }}
                    />
                  </div>
                ))}
              </div>
            ) : null}
          </>
        );
      case "checkbox":
        return (
          <>
            {!editChoicesCount && editChoices.length ?
              <div className="w-100">
                {editChoices?.map((choice, index) => {
                  return (
                    <div className="col-md-5 col-sm-12 mb-3 d-flex align-items-center">
                      <input type="checkbox" className="form-check-input align-middle p-2 me-4 user-checkbox update-prompt-checkbox" />
                      <span className=" d-block" />
                      <input
                        type="text"
                        className="form-control theme-focus input-field text-color-4 bg-color custom-border-3"
                        id="old-values"
                        value={choice}
                        placeholder="Enter the choices"
                        onChange={(e) => {
                          const updatedChoices = [...editChoices];
                          updatedChoices[index] = e.target.value;
                          setEditChoices(updatedChoices);
                        }}
                        onBlur={(e) => {
                          //(e.target.value);
                          const updatedChoices = [...editChoices];
                          updatedChoices[index] = e.target.value;
                          setEditChoices(updatedChoices);

                          e.target.value = updatedChoices[index];

                          toggle?.functions(e, updatedChoices);

                        }}
                      />
                    </div>)
                })}
              </div> : editChoicesCount && editChoices.length ? <div className="w-100">
                {editChoices?.map((choice, index) => {
                  return (
                    <div className="col-md-5 col-sm-12 mb-3 d-flex align-items-center">
                      <input type="checkbox" className="form-check-input align-middle p-2 me-4 user-checkbox update-prompt-checkbox" />
                      <span className=" d-block" />
                      <input
                        type="text"
                        id="old-values"
                        className="form-control theme-focus input-field text-color-4 bg-color custom-border-3"
                        value={choice}
                        placeholder="Enter the choices"
                        onChange={(e) => {
                          const updatedChoices = [...editChoices];
                          updatedChoices[index] = e.target.value;
                          setEditChoices(updatedChoices);
                        }}
                        onBlur={(e) => {
                          //(e.target.value);
                          const updatedChoices = [...editChoices];
                          updatedChoices[index] = e.target.value;
                          setEditChoices(updatedChoices);

                          e.target.value = updatedChoices[index];

                          toggle?.functions(e, updatedChoices);

                        }}
                      />
                    </div>)
                })}

                {Array.from({ length: editChoicesCount }, (_, index) => (
                  <div className="col-md-5 col-sm-12 mb-3 d-flex align-items-center">
                    <input type="checkbox" className="form-check-input align-middle p-2 me-4 user-checkbox update-prompt-checkbox" />
                    <span className=" d-block" />
                    <input
                      type="text"
                      className="form-control theme-focus input-field text-color-4 bg-color custom-border-3"
                      id="old-values"

                      placeholder="Enter the choices"

                      onBlur={(e) => {
                        toggle?.functions(e);
                      }}
                    />
                  </div>
                ))}
              </div> : !editChoices.length && !editChoicesCount ? <div className="w-100">
                {Array.from({ length: addOption }, (_, index) => (
                  <div className="col-md-5 col-sm-12 mb-3 d-flex align-items-center">
                    <input type="checkbox" className="form-check-input align-middle p-2 me-4 user-checkbox update-prompt-checkbox" />
                    <span className=" d-block" />
                    <input
                      type="text"
                      className="form-control theme-focus input-field text-color-4 bg-color custom-border-3"
                      id="old-values"

                      placeholder="Enter the choices"

                      onBlur={(e) => {
                        toggle?.functions(e);
                      }}
                    />
                  </div>))}</div> : null}

          </>
        );
    }
  };
  return <>{toggleBtn ? bindChoices() : null}</>;
}