//PS_AAI_8.1,Declare all the import statements

import { useEffect, useState, useContext } from "react";
import { getInputTypeAndTechStack } from "../AI Service/Api";
import toggleContext from "./Context";
/**
 * PS_AAI_8.2,Declare all the state varaibles
 * PS_AAI_8.3 to PS_AAI_8.12,triggers fetchInputTypeTechStack function in useEffect which fetches the data from the database by an API call
 * PS_AAI_8.13 to PS_AAI_8.14,set the response to the state variable and return it
 * PS_AAI_8.15 to PS_AAI_8.20,onclick will be triggered and return the selected value
 * @param {props value from parent component} param0
 * @returns checkbox options
 */
export default function RadioComponent({ record }) {
  const [recordData, setRecordData] = useState();
  const toggle = useContext(toggleContext);
  const [options, setOptions] = useState([]);
  const [choosenOptions, setChoosenOptions] = useState([]);
  const [hideError, sethideError] = useState(true);
  useEffect(() => {
    if (record) {
      setRecordData(record.record);
      if (record?.bindChoicesValues) {
        setOptions(record?.bindChoicesValues);
      }
    }
    // fetchInputTypeAndTechStack();
  }, [record]);

  // const fetchInputTypeAndTechStack = async () => {
  //   try {
  //     let result = await getInputTypeAndTechStack({
  //       promptFieldID: recordData?.promptFieldID,
  //     });

  //     setOptions(result.data.choices);
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };
  /**
   * PS_AAI_8.21 to PS_AAI_27,check if the value is not selected,then using bindError function bind error to the form
   * @returns the error if any
   */
  const bindError = () => {
    if (recordData) {
      const err = `${recordData.inputValueOrder}`;

      if (record.validationErrors) {
        return (
          <>
            {hideError ? (
              <span
                className="mandatory-star font-14 font-medium mb-0 d-flex"
                style={{ color: "red" }}
              >
                {record.validationErrors[err]}
              </span>
            ) : null}
          </>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };


  return (
    <>
      {record && recordData?.labelName ? (
        <label class="form-label font-15 font-semibold w-100 label-color">
          {recordData.labelName}{" "}
          {record && recordData?.required == "true" ? (
            <span class="color-red">*</span>
          ) : null}
        </label>
      ) : null}
      {options?.map((value, index) => {
        return (
          <div class="form-check form-check-inline">
            <input
              class="form-check-input custom-check-input"
              type="checkbox"
              name={value.choicesID}
              //   disabled={codeID ? codeID !== value.typeOfCodeID : false}
              value={value.choiceName}
              id={value.choicesID}
              onClick={(e) => {
                if (e.target.value) {
                  let choosenOption = choosenOptions;
                  // sethideError(false);

                  if (e.target.checked) {
                    choosenOption.push(e.target.value);
                  } else {
                    let index = choosenOption.indexOf(e.target.value);

                    {
                      index != -1
                        ? choosenOption.splice(
                            choosenOption.indexOf(e.target.value),
                            1
                          )
                        : console.log("");
                    }
                  }
                  setChoosenOptions(choosenOption);
                  record.handleFieldValues(
                    recordData.labelName,
                    choosenOptions,
                    recordData.inputValueOrder,
                    recordData.required
                  );
                  toggle?.functions(e);
                } else {
                  sethideError(true);
                }
              }}
            ></input>
            <label
              class="form-check-label font-14 font-medium"
              placeholder={
                recordData?.placeHolder ? recordData.placeHolder : null
              }
              for="API"
            >
              {value.choiceName}
            </label>
          </div>
        );
      })}
      {bindError()}
    </>
  );
}
