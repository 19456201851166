//PS_CGAI_1.1 import the necessary imports
import { useEffect } from "react";
import { fetchNotification } from "../AI Service/Api";
import { useState } from "react";
import { readNotification } from "../AI Service/Api";
import { getSessionItem } from "../util-services/encrypt-storage";
import axios from "axios";
import { useContext } from "react";
import UserContext from "../Auth/useContext";
import { useHistory } from "react-router-dom";
import { data } from "jquery";
/**
 * PS_CGAI_1.2 to PS_CGAI_1.3 declare the state variable
 * PS_CGAI_1.4 to PS_CGAI_1.6 to get the notifications for the user in useEffect
 * PS_CGAI_1.38 to PS_CGAI_1.40 user click on the particular notifiaction
 * PS_CGAI_1.55 to PS_CGAI_1.59 view more to navigate to manage request if they are user
 * @returns the user Notification
 */

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default function NotificationComponent(props) {
  const [userNotifications, setuserNotifications] = useState([]);
  const [approvalNotification, setapprovalNotification] = useState([]);
  const [flag, setflag] = useState(0);
  const [NoRecords, setNoRecords] = useState(true);
  const [viewNo, setviewNo] = useState(false);
  const history = useHistory();
  const userData = useContext(UserContext);
  const token = getSessionItem("access-token");

  const [renderAgain, setRenderAgain] = useState(props.value ?? false);

  useEffect(() => {}, [userNotifications]);

  useEffect(() => {
    if (userData) {
      showNotification();
    }
  }, []);
  useEffect(() => {
    if (userData) {
      
      showNotification();
    }
  }, [props.notificationTrigger]);

  /**
   * PS_CGAI_1.7 to PS_CGAI_1.26 to show the notification and red dot
   */
  const showNotification = async () => {
    try {
      let userImage = {
        profileImage: "",
      };
      let result = await fetchNotification({
        toUser: userData?.email,
        // toUser: "Sid@avasoft.com",
      });

      if (result.ResponseData.length != 0) {
        props.props(true);

        let data = result.ResponseData;
        //(data,"----------");

        await Promise.allSettled(
          data?.map(async (_value) => {
            try {
              let profileImage = await axios.get(
                `https://graph.microsoft.com/v1.0/users/${_value.fromUser}/photo/$value`,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                  responseType: "blob",
                }
              );

              // _value.profileImage = "data:image/png;base64," + profileImage.data;

              const reader = new FileReader();
              reader.readAsDataURL(profileImage.data);
              await timeout(500);
              _value.profileImage = reader.result;
            } catch (error) {
              _value.profileImage = "images/Default/default-profile-image.png";
            }
          })
        );

        setuserNotifications(data);
        setNoRecords(true);
      } else {
        props.props(false);
        setuserNotifications([]);
        setNoRecords(false);
        setviewNo(true);
      }
    } catch (error) {
      //(error.message, "notification");
    }
  };

  /**
   * PS_CGAI_1.27 to PS_CGAI_1.37 bind the notification for the user in html
   * @returns the notification in the form of grid
   */
  const bindNotifications = () => {
 
    if (userData?.role != "User") {
    
      return userNotifications?.map((value, index) => {
        return (
          <>
            <span
              id={value.notificationID}
              className="p-3 d-block w-100 cursor-pointer"
              onClick={(e) => {
                handleNotification(`${value.notificationID}`);
              }}
            >
              <span className="d-flex">
                {/* {bindimage(`${value.notificationID}`)} */}
                <img src={value.profileImage} className="header-profile" />
                <span className="d-block ms-2">
                  <span className="d-block font-14 font-regular">
                    <span className="font-bold" id={value.notificationID}>
                      {value.userName}
                    </span>{" "}
                    {value.content}{" "}
                    <span className="font-bold">{value.promptName}</span>
                  </span>
                  <span className="d-block font-10 font-medium grey-v2">
                    {value.createdDateTime}
                  </span>
                </span>
              </span>
            </span>
          </>
        );
      });
    } else {
      return userNotifications?.map((value, index) => {
        return (
          <>
            <span
              id={value.notificationID}
              className="p-3 d-block w-100 cursor-pointer"
              onClick={(e) => {
                handleNotification(`${value.notificationID}`);
              }}
            >
              <span className="d-flex">
                {/* {bindimage(`${value.notificationID}`)} */}
                <img src={value.profileImage} className="header-profile" />
                <span className="d-block ms-2">
                  <span className="d-block font-14 font-regular">
                    <span className="font-bold" id={value.notificationID}>
                      your request for
                    </span>{" "}
                    {value.promptName} has been{" "}
                    <span className="font-bold">
                      {value.approvalStatusName}
                    </span>
                  </span>
                  <span className="d-block font-10 font-medium grey-v2">
                    {value.createdDateTime}
                  </span>
                </span>
              </span>
            </span>
          </>
        );
      });
    }
  };

  /**
   * PS_CGAI_1.41 to PS_CGAI_1.54 to make the nootification as read
   * @param {email id} id
   */
  const handleNotification = async (id) => {
    if (userNotifications.length == 1) {
      props.props(false);

      setuserNotifications([]);

      setNoRecords(false);

      setviewNo(true);
    } else {
      //splice
    }
    let result = await readNotification({ notificationID: id });
    if (result.Success == true) {
      showNotification();
    }
  };
  return (
    <>
  
      <div>
        {/* <span
          className="dropdown-menu dropdown-menu-end notification-container theme-dropdown border-0"
          data-bs-popper="none"
        ></span>
        <span className=" width d-flex justify-content-between p-3 border-bottom grey-v1 font-semibold font-16">
          Notifications
          <span className="cursor-pointer">
            <img
              src="images/Default/close-icon.svg"
              alt="close-icon"
              onClick={(e) => {
                props.notification(false);
              }}
            />
          </span>
        </span> */}

        {userData && bindNotifications()}
        <div hidden={NoRecords}>
          <span className="p-3 d-block w-100">
            <span className="d-flex justify-content-center">
              <span className="d-block font-14 font-medium grey-v2">
                No Records found
              </span>
            </span>
          </span>
        </div>
        {userData?.role != "User" ? (
          <span
            className="width border-top p-3 d-block "
            disabled={viewNo ? true : false}
          >
            <a
              disabled={viewNo ? true : false}
              className="text-decoration-none font-medium theme-color"
              onClick={() => {
                props.setTab("request-logs");

                history.push("/asami-manage-request");
              }}
            >
              View all notifications
            </a>
          </span>
        ) : null}
      </div>
      {/* <div>
        <span className=" width d-flex justify-content-between p-3 border-bottom grey-v1 font-semibold font-12">
          Notifications
          <span className="cursor-pointer">
            <img src="images/Default/close-icon.svg" alt="close-icon" />
          </span>
        </span>
        {bindNotifications()}
        <div hidden={NoRecords}>
          <span className="p-3 d-block w-100">
            <span className="d-flex justify-content-center">
              <span className="d-block font-10 font-medium grey-v2">
                No Records found
              </span>
            </span>
          </span>
        </div>
        <span className="width border-top p-3 d-block ">
          <a
            
            disabled={viewNo}
            className="text-decoration-none font-medium theme-color font-12"
          >
            View all notifications
          </a>
        </span>
      </div> */}
    </>
  );
}
