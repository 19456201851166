//PS_CGAI_6.1 importt the necessary imports
import { useContext, useEffect, useState } from "react";
import { getInputTypeAndTechStack } from "../AI Service/Api";
import toggleContext from "./Context";
/**
 *PS_CGAI_6.2 to PS_CGAI_6.4 check the props and set to the recordData
 * @param {props from the parent as object} param0
 * @returns the type of code bindes with choice as from the backend and aslo returns empty radio,checkbox,dropdown fields as
 * empty to fill according to the toggleBtn and addOption
 */
export default function Choices({ record }) {
  const [recordData, setRecordData] = useState();
  const toggle = useContext(toggleContext);
  const [addOption, setaddOption] = useState([]);
  const [codeID, setcodeID] = useState(0);
  const [techstack, settechstack] = useState();
  const [toggleBtn, settoggleBtn] = useState("");
  const [hideError, sethideError] = useState(true);

  useEffect(() => {
    if (record) {
      setRecordData(record.record);
      if (record.codeID) {
        setcodeID(record.codeID);
      }
    } else if (toggle) {
      settoggleBtn(toggle?.chs);
      setaddOption(toggle?.options);
    }
    fetchInputTypeAndTechStack();
  }, [record, toggle]);

  /**
   * PS_CGAI_6.5 to PS_CGAI_6.13  get the type of code values from thebackend and set to the techstack state
   */
  const fetchInputTypeAndTechStack = async () => {
    try {
      let result = await getInputTypeAndTechStack();

      settechstack(result.data.typeOfCode);
    } catch (error) {
      //(error.message);
    }
  };

  useEffect(() => {}, [codeID]);

  /**
   * PS_CGAI_6.17 to PS_CGAI_6.20 give the redio options with API ,Component
   * PS_CGAI_6.28 to PS_CGAI_6.32 get the values using inCnage in hat using handleFieldValues pass the value to the parent component
   * @returns the radio options with type of code options bind using techstack state variable
   */
  const bindTypeOfCode = () => {
    return techstack?.map((value, index) => {
      return (
        <>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input custom-check-input"
              type="radio"
              name="inlineRadioOptions"
              disabled={codeID ? codeID !== value.typeOfCodeID : false}
              value={value.typeOfCodeName}
              id={value.labelName}
              onClick={(e) => {
                if (e.target.value) {
                  sethideError(false);
                  record.handleFieldValues(
                    recordData.labelName,
                    e.target.value,
                    recordData.inputValueOrder,
                    recordData.required
                  );
                  toggle?.functions(e);
                } else {
                  sethideError(true);
                }
              }}
            ></input>
            <label
              class="form-check-label font-14 font-medium"
              placeholder={
                recordData?.placeHolder ? recordData.placeHolder : null
              }
              for="API"
            >
              {value.typeOfCodeName}
            </label>
          </div>
        </>
      );
    });
  };
  /**
   * PS_CGAI_6.14 to PS_CGAI_6.16 check the addOption and toggleBtn and return the checkbox and input field as empty to get the values
   * @returns the empty checkbox ans input fields based on the value in addOption
   */
  const bindcheckbox = () => {
    return (
      <>
        {Array.from({ length: addOption }, (_, index) => (
          <>
            <div className="w-100">
              <div className="col-md-5 col-sm-12 mb-3 d-flex align-items-center">
                <input type="checkbox" className="form-check-input align-middle p-2 me-4 user-checkbox update-prompt-checkbox" />
                <span className=" d-block" />
                <input
                  type="text"
                  className="form-control theme-focus input-field"
                  placeholder="Enter the choices"
                  onBlur={(e) => {
                    toggle?.functions(e);
                  }}
                />
              </div>
            </div>
          </>
        ))}
      </>
    );
  };

  const check = (id) => {
    if (codeID) {
      if (id == codeID) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  /**
   * PS_CGAI_6.21 to PS_CGAI_6.23 based on the addOption and toggelBtn values return the empty dropdown options to et the dropdwon values
   * @returns the empty dropdown option
   */
  {
    /**
  *<div className="row pb-3">
        <div className="col-md-6 col-sm-12 mb-3">
          <div className="input-field pe-2 bg-color custom-border-3">
            <ul className="list-unstyled common-dropdown-scroll-popup mb-0">
              <li className="custom-border-bottom font-14 py-2 text-color-2 font-14 py-2">JavaScript</li>
              <li className="custom-border-bottom font-14 py-2 text-color-2 font-14 py-2">PHP</li>
              <li className="custom-border-bottom font-14 py-2 text-color-2 font-14 py-2">Angular</li>
              <li className="custom-border-bottom font-14 py-2 text-color-2 font-14 py-2">Linux</li>
              <li className="custom-border-bottom font-14 py-2 text-color-2 font-14 py-2">Apache</li>
              <li className="custom-border-bottom font-14 py-2 text-color-2 font-14 py-2">PHP</li>
              <li className="custom-border-bottom font-14 py-2 text-color-2 font-14 py-2">Angular</li>
              <li className="custom-border-bottom font-14 py-2 text-color-2 font-14 py-2">Linux</li>
              <li className="font-14 py-2">JavaScript</li>
            </ul>
          </div>
        </div>
      </div>
 */
  }
  const bindDropdown = () => {
    return Array.from({ length: addOption }, (_, index) => (
      <li className="font-14 py-2">
        <input
          type="text"
          className="form-control theme-focus input-field"
          id="choice-2"
          placeholder="Enter choices"
          onBlur={(e) => {
            toggle?.functions(e);
          }}
        />
      </li>
    ));
  };
  /**
   * PS_CGAI_6.24 to PS_CGAI_6.27 based on the addOption and togglebtn return the empty radio and text input fields to get the choices from the user
   * @returns the empty radio and text field
   */
  const bindRadio = () => {
    return (
      <>
        {Array.from({ length: addOption }, (_, index) => (
          <>
            <div className="w-100">
              <div className="col-md-5 col-sm-12 mb-3 d-flex align-items-center">
                <label htmlFor="choice-1" className="me-3">
                  <span className="circle-label d-block" />
                </label>
                <input
                  type="text"
                  className="form-control theme-focus input-field custom-border-3 text-color-4 bg-color"
                  placeholder="Enter the choices"
                  onBlur={(e) => {
                    toggle?.functions(e);
                  }}
                />
              </div>
            </div>
          </>
        ))}
      </>
    );
  };
  /**
   *
   * @returns returns the error if error is there
   */
  const bind = () => {
    if (recordData) {
      const err = `${recordData.inputValueOrder}`;

      if (record.validationErrors) {
        return (
          <>
            {hideError ? (
              <span
                className="mandatory-star font-14 font-medium mb-0"
                style={{ color: "red" }}
              >
                {record.validationErrors[err]}
              </span>
            ) : null}
          </>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  return (
    <>
      {record && recordData?.labelName ? (
        <label class="form-label font-15 font-semibold w-100 label-color">
          {recordData.labelName}{" "}
          {record && recordData?.required == "true" ? (
            <span class="color-red">*</span>
          ) : null}
        </label>
      ) : null}
      {!toggleBtn ? (
        bindTypeOfCode()
      ) : toggleBtn == "dropdown" ? (
        <div className="row pb-3">
          <div className="col-md-6 col-sm-12 mb-3">
            <div className="input-field pe-2 bg-color custom-border-3">
              <ul className="list-unstyled common-dropdown-scroll-popup mb-0">
                <li className="custom-border-bottom font-14 py-2 text-color-2 font-14 py-2">Add Choices</li>
                {bindDropdown()}
              </ul>
            </div>
          </div>
        </div>
      ) : toggleBtn == "checkbox" ? (
        bindcheckbox()
      ) : toggleBtn == "radio" ? (
        bindRadio()
      ) : null}
      <p> {bind()}</p>
    </>
  );
}
