//PS_CGAI_11.1 import  the necessarry imports
import { useEffect, useState, useContext } from "react";
import toggleContext from "./Context";

const folderJson = {
  id: "",
  name: "Folder",
  type: "folder",
  sub: [],
};

const fileJson = {
  id: "",
  name: "file",
  type: "file",
};
/**
 * PS_CGAI_11.2 declare the global variables
 * PS_CGAI_11.3 to PS_CGAI_11.6 set the props to recordData in useEffect
 * PS_CGAI_11.27 to PS_CGAI_11.31 onclick event in the html
 * @param {props from parent} record
 * @returns the file and folder structure the added
 */
export function AddFileOrFolder(record) {
  const [fileStructure, setfileStructure] = useState([]);
  const [recordData, setrecordData] = useState();
  const [editStructName, seteditStructName] = useState("");
  const toggle = useContext(toggleContext);
  const [editStructID, seteditStructID] = useState(0);
  const [hideError, sethideError] = useState(true);

  const [fileOrder, setFileOrder] = useState(1);
  const [folderOrder, setFolderOrder] = useState(1);

  const [selectedFolderID, setselectedFolderID] = useState(0);

  useEffect(() => {

    if (!toggle) {
      if (record) {
        
        setrecordData(record.record.record);
      }
    }
  }, [recordData]);

  /**
   * PS_CGAI_11.32 to PS_CGAI_11.35 when the user enters the file on edit
   * @param {*} fileStructure
   * @returns
   */
  const updateFieldOnEnter = (fileStructure) => {
    let _fileStructure = [];
    fileStructure.forEach((_value) => {
      if (_value.id === editStructID) _value.name = editStructName;

      if (_value?.sub?.length > 0) {
        _value = {
          ..._value,
          sub: updateFieldOnEnter(_value.sub),
        };
        // _fileStructure.push(_value);
      }
      _fileStructure.push(_value);
    });

    return _fileStructure;
  };
  /**
   * PS_CGAI_11.7 to PS_CGAI_11.20 bind the htl according to the user clicks on file or folder
   * @param {JSON} _fileStructure
   * @returns the html for file an folder
   */
  const bindFileStructure = (_fileStructure) => {
  
    return _fileStructure?.map((_struct, index) => {
      if (_struct.type === "folder") {
        return (
          <>
            <p className="mb-1 ">
              <span className="add-folder">
                <a
                  className="text-decoration-none custom-toggle font-10 font-medium collapsed "
                  data-bs-toggle="collapse"
                  href={"#collapseExample" + _struct.id}
                  role="button"
                  aria-expanded="false"
                  aria-controls={"collapseExample" + _struct.id}
                  selected_folder={false}
                  onClick={(e) => {
                    if (selectedFolderID == _struct.id) {
                      setselectedFolderID(0);
                      e.target.selected_folder = false;
                    } else if (!e.target.selected_folder) {
                      e.target.selected_folder = true;
                      setselectedFolderID(_struct.id);
                    } else {
                      e.target.selected_folder = false;
                    }
                  }}
                >
                  <img
                    className="me-3 collapse-arrow"
                    src="images/ai-images/file-dropdown-icon.svg"
                    alt="dropdown-icon"
                  />{" "}
                  <img
                    className="me-1"
                    src="images/ai-images/folder-icon.svg"
                    alt="folder-icon"
                  />
                  {editStructID == _struct.id ? (
                    <>
                      {" "}
                      <input
                        type="text"
                        value={editStructName}
                        onChange={(e) => seteditStructName(e.target.value)}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            setfileStructure(updateFieldOnEnter(fileStructure));
                            seteditStructID(0);
                          }
                        }}
                      />
                    </>
                  ) : (
                    <> {_struct.name}</>
                  )}
                </a>
                <span class="add-folder-actions">
                  <span
                    class="mx-2 cursor-pointer"
                    onClick={() => {
                      seteditStructID(_struct.id);
                      seteditStructName(_struct.name);
                    }}
                  >
                    <img
                      src="images/ai-images/addfolder-edit.svg"
                      alt="addfolder-edit"
                    />
                  </span>
                  <span class="cursor-pointer">
                    <img
                      src="images/ai-images/addfolder-del.svg"
                      alt="addfolder-edit"
                      onClick={() => {
                        deleteFileStructure(_struct.id);
                      }}
                    />
                  </span>
                </span>
              </span>

              {/*folder section starts here*/}
              <div className="collapse" id={"collapseExample" + _struct.id}>
                <div className="card card-body border-0 py-0 ps-4 pe-0">
                  {bindFileStructure(_struct?.sub)}
                </div>
              </div>
            </p>
          </>
        );
      } else {
        return (
          <>
            <div className="card card-body border-0 py-0 ps-4 pe-0">
              <div className="d-flex">
                <div className="d-block">
                  <img
                    className="file-custom-icon me-1"
                    src="images/ai-images/file-icon.svg"
                    alt="file"
                  />
                </div>
                <div className="d-block add-file">
                  <label className="font-10 font-medium">
                    {editStructID == _struct.id ? (
                      <>
                        {" "}
                        <input
                          type="text"
                          value={editStructName}
                          onChange={(e) => seteditStructName(e.target.value)}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              setfileStructure(
                                updateFieldOnEnter(fileStructure)
                              );
                              seteditStructID(0);
                            }
                          }}
                        />
                      </>
                    ) : (
                      <> {_struct.name}</>
                    )}
                  </label>
                  <span class="add-file-actions">
                    <span
                      class="mx-2 cursor-pointer "
                      onClick={() => {
                        seteditStructID(_struct.id);
                        seteditStructName(_struct.name);
                      }}
                    >
                      <img
                        src="images/ai-images/addfolder-edit.svg"
                        alt="addfolder-edit"
                      />
                    </span>
                    <span class="cursor-pointer">
                      <img
                        src="images/ai-images/addfolder-del.svg"
                        alt="addfolder-edit"
                        onClick={() => {
                          deleteFileStructure(_struct.id);
                        }}
                      />
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      }
    });
  };
  const bind = () => {
    if (recordData) {
      const err = `${recordData.inputValueOrder}`;
    
      if (record?.record.validationErrors) {
      
        return (
          <>
            {hideError ? (
              <span
                className="mandatory-star font-14 font-medium mb-0"
                style={{ color: "red" }}
              >
                {record?.record.validationErrors[err]}
              </span>
            ) : null}
          </>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  /**
   * PS_CGAI_11.36 to PS_CGAI_11.44 update the file or folder according to the user name
   * @param {id of folder,file} id
   * @param {json array} _fileStructure
   * @param {fole, folder} type
   * @returns
   */
  const updateFileStructure = (id, _fileStructure, type) => {

    _fileStructure.map((_value, index) => {
      if (_value.id === id) {
        let _Struct;
        if (type == "folder") {
          _Struct = JSON.parse(JSON.stringify(folderJson));
        } else {
          _Struct = JSON.parse(JSON.stringify(fileJson));
        }
        var otp = Math.floor(Math.random() * (99999 - 10000 + 1) + 10000);
        _Struct.id = parseInt(otp);
        _Struct.name += parseInt(otp);
        _value.sub.push(_Struct);
      }

      if (_value?.sub?.length > 0) {
        _value = {
          _value,
          sub: updateFileStructure(id, _value.sub, type),
        };
      }
    });


    return _fileStructure;
  };
  /**
   * PS_CGAI_11.45 to PS_CGAI_11.49 to delete the sub folder or file in the structure
   * @param {id of main folder/file} id
   * @param {the array pushed} _fileStructure
   * @returns the structure adter deleted
   */
  const deleteSubFolderStructure = (id, _fileStructure) => {
    let _localFileStruct = [];
    _fileStructure.map((_value) => {
      if (_value.id !== id) {
        if (_value?.sub?.length > 0) {
          _value = {
            ..._value,
            sub: deleteSubFolderStructure(id, _value.sub),
          };
          _localFileStruct.push(_value);
        } else {
          _localFileStruct.push(_value);
        }
      }
    });

    return _localFileStruct;
  };
  /**
   * PS_CGAI_11.50 to PS_CGAI_11.53 to delet ethe folder file in the main folders
   * @param {_struct.id} id
   */
  const deleteFileStructure = (id) => {
    let _localFileStruct = [];
    fileStructure.map((_value) => {
     

      if (_value.id !== id) {
        if (_value?.sub?.length > 0) {
          _value = {
            ..._value,
            sub: deleteSubFolderStructure(id, _value.sub),
          };

          _localFileStruct.push(_value);
        } else {
          _localFileStruct.push(_value);
        }
      }
    });
   

    setfileStructure([..._localFileStruct]);
  };
  /**
   * PS_CGAI_11.21 to PS_CGAI_11.26 generete the name and id for the particular folder or file
   * @param {file or folder} type
   */
  const handleFieldValues = (type) => {
    try {
      let _Struct;
      if (type == "folder") {
        _Struct = JSON.parse(JSON.stringify(folderJson));
        _Struct.name += fileOrder;
        setFileOrder(fileOrder + 1);
      } else {
        _Struct = JSON.parse(JSON.stringify(fileJson));
        _Struct.name += folderOrder;
        setFolderOrder(folderOrder + 1);
      }
      var otp = Math.floor(Math.random() * (99999 - 10000 + 1) + 10000);
      _Struct.id = parseInt(otp);
      // _Struct.name += fileOrder;
      let fileStructureJson = [];
    
      if (selectedFolderID === 0) {
        fileStructureJson = [...fileStructure, _Struct];
        // setfileStructure([...fileStructure, _Struct]);
      } else {
      
        fileStructureJson = [
          ...updateFileStructure(selectedFolderID, fileStructure, type),
        ];
      }
      setfileStructure(fileStructureJson);
      if (fileStructureJson) {
        sethideError(false);
        record?.record?.handleFieldValues(
          recordData?.labelName,
          JSON.stringify(fileStructureJson),
          recordData.inputValueOrder,
          recordData.required
        );
      } else {
        sethideError(true);
      }
    } catch (err) {
      //(err);
    }
  };

  return (
    <>
      <div className="col-md-12 mb-4">
        {record && recordData?.labelName ? (
          <label className="form-label font-15 font-semibold w-100 text-color-2">
            {recordData?.labelName}
       

            {/* <button
              type="button"
              className="onclick-help-btn theme-outline-btn font-14 font-semibold theme-focus ms-2"
              onClick={(e) => {
                record?.record.handleFieldValues(
                  recordData?.labelName,
                  JSON.stringify(fileStructure),
                  recordData.inputValueOrder,
                  recordData.required
                );
              }}
            >
              <img
                src="images/Default/tick-icon.svg"
                className="onclick-help-btn-img"
                style={{ width: "20px" }}
                alt="tick"
              />
            </button> */}
            {record && recordData?.required == "true" ? (
              <span class="color-red">*</span>
            ) : null}
          </label>
        ) : (
          ""
        )}

        <button
          disabled={toggle}
          type="button"
          className="btn shadow-none theme-color font-11 font-medium px-0 me-4"
          onClick={() => handleFieldValues("folder")}
        >
          <img
            className="me-2 mb-1 icon-width"
            src="images/ai-images/add-purple-icon.svg"
            alt="add-icon"
          />
          Add Folder
        </button>
        <button
          disabled={toggle}
          type="button"
          className="btn shadow-none color-light-blue font-11 font-medium px-0"
          onClick={() => handleFieldValues("file")}
        >
          <img
            className="me-2 mb-1 icon-width"
            src="images/ai-images/add-blue-icon.svg"
            alt="add-icon"
          />
          Add File
        </button>
        {bindFileStructure(fileStructure)}
      </div>
      {bind()}
    </>
  );
}
