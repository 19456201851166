import React, { useMemo, useEffect, useRef, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';

const LineGraph = ({ graphData, timePeriod }) => {
  const theme = useTheme();
  const chartRef = useRef(null);
  const [textColor, setTextColor] = useState(theme.palette.text.primary);
  const [chartKey, setChartKey] = useState(0);

  useEffect(() => {
    const updateTextColor = () => {
      if (chartRef.current) {
        const card = chartRef.current.closest('.custom-dashboard-section');
        if (card) {
          const bgColor = window.getComputedStyle(card).backgroundColor;
          const rgb = bgColor.match(/\d+/g);
          if (rgb) {
            const brightness = (parseInt(rgb[0]) * 299 + parseInt(rgb[1]) * 587 + parseInt(rgb[2]) * 114) / 1000;
            setTextColor(brightness > 128 ? '#000000' : '#ffffff');
          }
        }
      }
    };

    const resizeObserver = new ResizeObserver(updateTextColor);

    if (chartRef.current) {
      const card = chartRef.current.closest('.custom-dashboard-section');
      if (card) {
        resizeObserver.observe(card);
      }
    }

    updateTextColor();
    setChartKey(prev => prev + 1);

    return () => resizeObserver.disconnect();
  }, [theme]);
  let yAxis = [];
  let xAxis = [];

  // Sample data
  // const monthlyData = [
  //   { count: 20, xAxis: 'Week 1' },
  //   { count: 30, xAxis: 'Week 2' },
  //   { count: 50, xAxis: 'Week 3' },
  //   { count: 80, xAxis: 'Week 4' },
  // ];

  // const weeklyData = [
  //   { count: 10, xAxis: 'Mon' },
  //   { count: 25, xAxis: 'Tue' },
  //   { count: 40, xAxis: 'Wed' },
  //   { count: 60, xAxis: 'Thu' },
  //   { count: 70, xAxis: 'Fri' },
  // ];

  // const yearlyData = [
  //   { count: 100, xAxis: 'Jan' },
  //   { count: 120, xAxis: 'Feb' },
  //   { count: 80, xAxis: 'Mar' },
  //   { count: 60, xAxis: 'Apr' },
  //   { count: 40, xAxis: 'May' },
  //   { count: 20, xAxis: 'Jun' },
  //   { count: 30, xAxis: 'Jul' },
  //   { count: 50, xAxis: 'Aug' },
  //   { count: 70, xAxis: 'Sep' },
  //   { count: 90, xAxis: 'Oct' },
  //   { count: 110, xAxis: 'Nov' },
  //   { count: 130, xAxis: 'Dec' },
  // ];

  // // Use the appropriate data based on the timePeriod prop
  // if (timePeriod === 'Monthly') {
  //   graphData = monthlyData;
  // } else if (timePeriod === 'Weekly') {
  //   graphData = weeklyData;
  // } else {
  //   graphData = yearlyData;
  // }

  // graphData?.forEach(element => {
  //   yAxis.push(element.count);
  //   xAxis.push(element.xAxis);
  // });

  // let categories = [];
  // if (timePeriod === 'Yearly') {
  //   categories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  // } else if (timePeriod === 'Monthly') {
  //   categories = ['Week 1', 'Week 2', 'Week 3', 'Week 4'];
  // } else if (timePeriod === 'Weekly') {
  //   categories = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
  // }

  //   const series = useMemo(() => {
  //     const yAxisData = graphData.map(item => item.count);
  //     const average = yAxisData.reduce((a, b) => a + b, 0) / yAxisData.length;

  //     return [
  //       {
  //         name: 'Visitors',
  //         data: yAxisData,
  //       },
  //       {
  //         name: 'Average',
  //         data: Array(yAxisData.length).fill(average),
  //       }
  //     ];
  //   }, [graphData]);

  //   const options = {
  //     chart: {
  //       type: 'line',
  //       zoom: {
  //         enabled: false,
  //       },
  //       toolbar: {
  //         show: false,
  //       },
  //     },
  //     colors: ['#8A2BE2', '#FF69B4'], // Violet for the main line, Pink for the average line
  //     stroke: {
  //       curve: 'straight',
  //       width: [2, 1], // Main line is thicker, average line is thinner
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     markers: {
  //       size: 0,
  //     },
  //     xaxis: {
  //       categories: graphData.map(item => item.xAxis),
  //       labels: {
  //         style: {
  //           colors: '#6c757d',
  //         },
  //       },
  //     },
  //     yaxis: {
  //       labels: {
  //         style: {
  //           colors: '#6c757d',
  //         },
  //       },
  //     },
  //     grid: {
  //       row: {
  //         colors: ['transparent'],
  //       },
  //     },
  //     legend: {
  //       show: true,
  //       position: 'top',
  //     },
  //     tooltip: {
  //       shared: true,
  //       intersect: false,
  //       y: {
  //         formatter: function(val) {
  //           return val;
  //         }
  //       }
  //     },
  //   };

  //   return (
  //     <div id="chart">
  //       <ReactApexChart options={options} series={series} type="line"/>
  //     </div>
  //   );
  // };

  console.log(graphData, "graphdata")
  const formatValue = (value) => {
    if (value >= 1000000) {
      return Math.round(value / 1000000) + 'M';
    } else if (value >= 1000) {
      return Math.round(value / 1000) + 'K';
    }
    return Math.round(value);
  };

  const getCurrentMonthIndex = () => {
    const now = new Date();
    return now.getMonth();
  };

  const reorderMonths = (monthsArray, startIndex) => {
    return [...monthsArray.slice(startIndex), ...monthsArray.slice(0, startIndex)];
  };

  const originalMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const processedData = useMemo(() => {
    if (timePeriod === "Yearly") {
      // Get current date
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();

      // Create an array of the last 12 months
      const last12Months = [];
      for (let i = 0; i < 12; i++) {
        const d = new Date(currentYear, currentMonth - i, 1);
        last12Months.unshift(`${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}`);
      }

      // Create an object to store counts for each month
      let monthCounts = {};
      last12Months.forEach(yearMonth => monthCounts[yearMonth] = 0);

      // Process the graphData
      graphData.forEach(item => {
        if (item && item.xAxis) {
          let yearMonth;
          if (typeof item.xAxis === 'string') {
            yearMonth = item.xAxis.substring(0, 7); // Get YYYY-MM part
          } else if (typeof item.xAxis === 'number') {
            // Assume it's a month number (1-12)
            const year = new Date().getFullYear();
            yearMonth = `${year}-${String(item.xAxis).padStart(2, '0')}`;
          } else {
            console.error("Unexpected xAxis format:", item.xAxis);
            return; // Skip this item
          }

          if (monthCounts.hasOwnProperty(yearMonth)) {
            monthCounts[yearMonth] += item.count;
          }
        }
      });


      // Create the final data array
      return last12Months.map(yearMonth => {
        const [, month] = yearMonth.split('-');
        return {
          count: monthCounts[yearMonth],
          xAxis: originalMonthNames[parseInt(month) - 1] // Only use month name
        };
      });
    } else if (timePeriod === "Monthly") {
      // For monthly data, we map weeks to Week numbers
      const weekNames = ['Week 1', 'Week 2', 'Week 3', 'Week 4'];

      // Ensure there are exactly four weeks in the data
      let weeklyDataCounts = new Array(4).fill(0);

      graphData.forEach((item, index) => {
        if (index < 4) { // Only consider up to 4 weeks
          weeklyDataCounts[index] = item?.count;
        }
      });

      return weeklyDataCounts.map((count, index) => ({
        count,
        xAxis: weekNames[index]
      }));
    } else if (timePeriod === "Weekly") {
      return graphData.map(item => {
        if (item && item?.week_number) {
          const date = new Date(item?.week_number);
          return { count: item?.count, xAxis: dayNames[date.getDay()] };
        }
        return { count: 0, xAxis: 'Unknown' };
      });
    }
    return graphData; // For other time periods, return as is
  }, [graphData, timePeriod]);



  const series = useMemo(() => {
    const yAxisData = processedData?.map(item => Number(item?.count));
    const average = yAxisData?.reduce((a, b) => a + b, 0) / yAxisData.length;

    return [
      {
        name: "Visitors",
        data: yAxisData,
      },
      {
        name: "Average",
        data: Array(yAxisData.length).fill(average),
      }
    ];
  }, [processedData]);

  const options = useMemo(() => ({
    chart: {
      type: 'line',
      zoom: { enabled: false },
      toolbar: { show: false },
      background: 'transparent',
    },
    colors: ['#8A2BE2', '#FF69B4'], // Keeping graph colors constant
    stroke: {
      curve: 'smooth',
      width: [2, 1],
    },
    dataLabels: { enabled: false },
    markers: { size: 0 },
    xaxis: {
      categories: processedData?.map(item => item?.xAxis),
      labels: {
        style: { colors: textColor },
        rotate: -45,
        rotateAlways: false,
        hideOverlappingLabels: true
      },
    },
    yaxis: {
      labels: {
        style: { colors: textColor },
        formatter: (value) => formatValue(value)
      },
      min: 0,
      forceNiceScale: true,
    },
    grid: {
      row: { colors: ['transparent'] },
      borderColor: textColor === '#ffffff' ? '#888888' : '#f1f1f1',
      strokeDashArray: [5]
    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5,
      labels: {
        colors: textColor
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (value) => formatValue(value)
      },
      theme: textColor === '#ffffff' ? 'dark' : 'light', // Adapting tooltip theme to text color
    },
  }), [textColor, processedData]);

  return (
    <div id="chart" ref={chartRef}>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
      />
    </div>
  );
};


export default LineGraph;