/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Modal from "react-modal";
import FilterDropZone from "./Components/Filter-Dropzone";
import SideBarItem from "./Components/SideBarItem";
import Row from "./Components/Row";
import {
  handleMoveWithinParent,
  handleMoveToDifferentParent,
  handleMoveSidebarComponentIntoParent,
  handleRemoveItemFromLayout,
} from "./Components/helpers";

import {
  SIDEBAR_ITEMS,
  SIDEBAR_ITEM,
  COMPONENT,
  COLUMN,
} from "./Components/filter-constants";
import shortid from "shortid";

export const FilterModule = () => {
  const history = useHistory();
  const initialData = {
    layout: [],
    components: [],
  };
  // const location = useLocation();
  // if (location.search === "?View=true") {
  //   let data = JSON.parse(
  //     window.sessionStorage.getItem("viewservicebuilderdata")
  //   );
  //   if (data) {
  //     // setgridData(data);
  //   }
  // }

  let filtertoGrid = JSON.parse(window.sessionStorage.getItem("fitlertoGrid"));

  if (filtertoGrid) {
  
    initialData.layout = filtertoGrid.layout;
    initialData.components = filtertoGrid.components;
  } else {
    //("there is not filter data to show in filtertoGrid");
  }

  const [modelenable, setmodelenable] = useState(false);
  const initialLayout = initialData.layout;
  const initialComponents = initialData.components;
  const [layout, setLayout] = useState(initialLayout);
  let [components, setComponents] = useState(initialComponents);
  const [settings, setSettings] = useState(false);
  const [currentID, setCurrentID] = useState();
  let [page, setPage] = useState("general");
  const [formValidation, setFormValidation] = useState(false);
  const [layoutElements, setLayoutElements] = useState(SIDEBAR_ITEMS[0]);
  const [textElements, setTextElements] = useState(SIDEBAR_ITEMS[1]);
  const [actionElements, setActionElements] = useState(SIDEBAR_ITEMS[2]);
  const [pickerElements, setPickerElements] = useState(SIDEBAR_ITEMS[3]);
  const [logoenable, setlogoenable] = useState(false);
  const [infodata, setinfodata] = useState("");
  const [modalenable, setmodalenable] = useState(false);
  const [homeenable, sethomeenable] = useState(false);
  const [validationmsg, setvalidationmsg] = useState("");

  const handleDelete = (path, id) => {
   

    if (currentID == id || id == undefined) {
      setSettings(false);
      setPage("general");
    }

    const splitItemPath = path.split("-");
    if (splitItemPath.length == 2) {
      // if (layout[splitItemPath[0]].children.length == 1) {
      //   layout.splice(splitItemPath[0], 1);
      //   setLayout([...layout]);
      //   return;
      // }

     
  
      if (layout[splitItemPath[0]].children.length == 1) {
     
        if (
          layout[splitItemPath[0]].children[splitItemPath[1]].children.length >
            0 &&
          layout[splitItemPath[0]].children[splitItemPath[1]].children[0].id !==
            undefined
        ) {
       
          delete components[
            layout[splitItemPath[0]].children[splitItemPath[1]].children[0].id
          ];
        }
        layout.splice(splitItemPath[0], 1);
        setLayout([...layout]);
        return;
      } else {
      
        if (
          layout[splitItemPath[0]].children[splitItemPath[1]].children.length >
            0 &&
          layout[splitItemPath[0]].children[splitItemPath[1]].children[0].id !==
            undefined
        ) {
         
          delete components[
            layout[splitItemPath[0]].children[splitItemPath[1]].children[0].id
          ];
        }
      }
    }
    setLayout(handleRemoveItemFromLayout(layout, splitItemPath));

 
  
  };

  let projectname = JSON.parse(window.sessionStorage.getItem("projectName"));

  const validation = () => {

    try {
      let validate = true;
      if (layout.length == 0) {
        validate = false;
        //("Create at least 2 elements to generate a form!!");
        setvalidationmsg("Create at least 2 elements to generate a form!!");
      } else {
        layout.map((row, index) => {
          row.children.map((col, index) => {
            col.validation = true;
            if (col.children.length == 0) {
              validate = false;
              //("Each column should have at least one element!!");
              setvalidationmsg(
                "Each column should have at least one element!!"
              );
              col.validation = false;
            } else {
              col.children.map((item, index) => {
                item.validation = true;
                if (components[item.id].general.labelName !== undefined) {
                  if (
                    components[item.id].general.labelName == "" ||
                    components[item.id].general.labelName == null
                  ) {
                    item.validation = false;
                    validate = false;
                    components[item.id].validation.labelName = true;
                  } else {
                    components[item.id].validation.labelName = false;
                  }
                }
                if (components[item.id].general.inputType !== undefined) {
                  if (
                    components[item.id].general.inputType == "" ||
                    components[item.id].general.inputType == null
                  ) {
                    item.validation = false;
                    validate = false;
                    components[item.id].validation.inputType = true;
                  } else {
                    components[item.id].validation.inputType = false;
                  }
                }
                if (components[item.id].general.enableHelpText == true) {
                  if (
                    components[item.id].general.helpText == "" ||
                    components[item.id].general.helpText == null
                  ) {
                    item.validation = false;
                    validate = false;
                    components[item.id].validation.helpText = true;
                  } else {
                    components[item.id].validation.helpText = false;
                  }
                }
                if (components[item.id].general.dataSource == 0) {
                  if (components[item.id].general.options.length == 0) {
                    item.validation = false;
                    validate = false;
                    components[item.id].validation.options = true;
                  } else {
                    components[item.id].validation.options = false;
                  }
                }
                if (components[item.id].general.dataSource == 1) {
                  if (
                    components[item.id].dataSource.apiLink == "" ||
                    components[item.id].dataSource.apiLink == null
                  ) {
                    item.validation = false;
                    validate = false;
                    components[item.id].validation.apiLink = true;
                  } else {
                    components[item.id].validation.apiLink = false;
                  }
                }
                if (
                  components[item.id].dataSource.propertyToBind
                    .valueProperty !== undefined &&
                  components[item.id].general.dataSource == 1
                ) {
                  if (
                    components[item.id].dataSource.propertyToBind
                      .valueProperty == "" ||
                    components[item.id].dataSource.propertyToBind
                      .valueProperty == null
                  ) {
                    item.validation = false;
                    validate = false;
                    components[item.id].validation.valueProperty = true;
                  } else {
                    components[item.id].validation.valueProperty = false;
                  }
                }
                if (
                  components[item.id].dataSource.propertyToBind.textProperty !==
                  undefined
                ) {
                  if (
                    components[item.id].dataSource.propertyToBind
                      .textProperty == "" ||
                    components[item.id].dataSource.propertyToBind
                      .textProperty == null
                  ) {
                    item.validation = false;
                    validate = false;
                    components[item.id].validation.textProperty = true;
                  } else {
                    components[item.id].validation.textProperty = false;
                  }
                }
              });
            }
          });
        });
        setLayout([...layout]);
      }
      return validate;
    } catch (er) {
      //(er)
    }
  };
  const handleDrop = useCallback(
    (dropZone, item) => {
      const splitDropZonePath = dropZone.path.split("-");
      const pathToDropZone = splitDropZonePath.slice(0, -1).join("-");

      const newItem = { id: item.id, type: item.type };
      if (item.type === COLUMN) {
        newItem.children = item.children;
      }

      // sidebar into
      if (item.type === SIDEBAR_ITEM) {
        let newItem;

        if (
          item.component.type == "1-Column" ||
          item.component.type == "2-Column" ||
          item.component.type == "3-Column" ||
          item.component.type == "4-Column"
        ) {
          newItem = {
            type: item.component.type,
          };
        } else {
          // 1. Move sidebar item into page
          const newComponent = {
            id: shortid.generate(),
            ...item.component,
          };
          newItem = {
            id: newComponent.id,
            type: COMPONENT,
          };
          setComponents({
            ...components,
            [newComponent.id]: newComponent,
          });
        }
        setLayout(
          handleMoveSidebarComponentIntoParent(
            layout,
            splitDropZonePath,
            newItem
          )
        );
        return;
      }

      // move down here since sidebar items dont have path
      const splitItemPath = item.path.split("-");
      const pathToItem = splitItemPath.slice(0, -1).join("-");

      // 2. Pure move (no create)
      if (splitItemPath.length === splitDropZonePath.length) {
        // 2.a. move within parent
        if (pathToItem === pathToDropZone) {
          setLayout(
            handleMoveWithinParent(layout, splitDropZonePath, splitItemPath)
          );
          return;
        }

        // 2.b. OR move different parent
        // TODO FIX columns. item includes children
        setLayout(
          handleMoveToDifferentParent(
            layout,
            splitDropZonePath,
            splitItemPath,
            newItem
          )
        );
        return;
      }

      // 3. Move + Create
      setLayout(
        handleMoveToDifferentParent(
          layout,
          splitDropZonePath,
          splitItemPath,
          newItem
        )
      );
    },
    [layout, components]
  );
  let codeGenId = window.sessionStorage.getItem("codeGenId");
  const redirecttogeneratecodepage = () => {
    // history.push("/Gridbuilder?prev=" + codeGenId);
    // history.push("/Gridbuilder/filtertogrid");

    history.push({
      pathname: "/Gridbuilder",
      search: "?filtertogrid",
      state: {
        filtertogrid: true,
      },
    });
  };
  const handleSearch = (e) => {
    if (e.target.value == "") {
      setLayoutElements(SIDEBAR_ITEMS[0]);
      setTextElements(SIDEBAR_ITEMS[1]);
      setActionElements(SIDEBAR_ITEMS[2]);
      setPickerElements(SIDEBAR_ITEMS[3]);
    } else {
      setLayoutElements(
        SIDEBAR_ITEMS[0].filter((arr) =>
          arr.component.type
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        )
      );
      setTextElements(
        SIDEBAR_ITEMS[1].filter((arr) =>
          arr.component.type
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        )
      );
      setActionElements(
        SIDEBAR_ITEMS[2].filter((arr) =>
          arr.component.type
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        )
      );
      setPickerElements(
        SIDEBAR_ITEMS[3].filter((arr) =>
          arr.component.type
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  const closeSettings = () => {
    if (settings) {
      setSettings(false);
      setPage("general");
    } else {
      setSettings(true);
      setPage("general");
    }
  };

  const handleSettings = (id, type) => {
    if (currentID == id) {
      if (settings) {
        setSettings(false);
        setPage("general");
      } else {
        setSettings(true);
        setPage("general");
      }
    } else {
      setCurrentID(id);
      
      setSettings(true);
      setPage("general");
    }
  };


  const handleChange = (e, id, input) => {
   

  
    let newComponents = JSON.parse(JSON.stringify(components));
    if (input == "labelName") {
      newComponents[id].general.labelName = e.target.value;
      newComponents[id].validation.labelName = false;
      
    }
    if (input == "placeHolder") {
      newComponents[id].general.placeHolder = e.target.value;
    }
    if (input == "inputType") {
      newComponents[id].general.inputType = e.target.value;
      newComponents[id].validation.inputType = false;
    }
    if (input == "alignment") {
      for (let i = 0; i < document.getElementsByName("alignment").length; i++) {
        if (document.getElementsByName("alignment")[i].checked == true) {
          newComponents[id].general.alignment = i;
        }
      }
    }
    if (input == "dataSource") {
      for (
        let i = 0;
        i < document.getElementsByName("dataSource").length;
        i++
      ) {
        if (document.getElementsByName("dataSource")[i].checked == true) {
          newComponents[id].general.dataSource = i;
        }
      }
      newComponents[id].validation.options = false;
      newComponents[id].validation.apiLink = false;
      newComponents[id].validation.valueProperty = false;
    }
    if (input == "enableHelpText") {
      newComponents[id].general.enableHelpText =
        document.getElementById("enable-help").checked;
      newComponents[id].validation.helpText = false;
    }
    if (input == "helpText") {
      newComponents[id].general.helpText = e.target.value;
      newComponents[id].validation.helpText = false;
    }
    if (input == "isMandatory") {
      for (
        let i = 0;
        i < document.getElementsByName("isMandatory").length;
        i++
      ) {
        if (document.getElementsByName("isMandatory")[i].checked == true) {
          if (i == 0) {
            newComponents[id].general.isMandatory = true;
          } else {
            newComponents[id].general.isMandatory = false;
          }
        }
      }
    }
    if (input == "otherValidations") {
      newComponents[id].general.otherValidations = e.target.value;
    }
    if (input == "apiLink") {
      newComponents[id].dataSource.apiLink = e.target.value;
      newComponents[id].validation.apiLink = false;
    }
    if (input == "responseJson") {
      newComponents[id].dataSource.responseJson = e.target.value;
    }
    if (input == "valueProperty") {
      newComponents[id].dataSource.propertyToBind.valueProperty =
        e.target.value;
      newComponents[id].validation.valueProperty = false;
    }
    if (input == "textProperty") {
      newComponents[id].dataSource.propertyToBind.textProperty = e.target.value;
      newComponents[id].validation.textProperty = false;
    }
    if (input == "optionText") {
      newComponents[id].validation.options = false;
    }
    setComponents({ ...newComponents });
  };

  const handlePage = (e) => {
    setPage(e.target.id);
  };

  const handleAddOption = () => {
    if (document.getElementById("optionText").value !== "") {
      let val = document.getElementById("optionText").value;
      components[currentID].general.options.push(val);
      setComponents({ ...components });
      document.getElementById("optionTextError").hidden = true;
      document.getElementById("optionText").value = "";
    } else {
      document.getElementById("optionTextError").hidden = false;
    }
  };

  const handleOptionDelete = (index) => {
    components[currentID].general.options.splice(index, 1);
    setComponents({ ...components });
  };

  const buildOptions = () => {
    let id = currentID;
    return components[id].general.options.map((opt, index) => {
      return (
        <li className="static-key-dd">
          <div className="">
            <p>{opt}</p>
          </div>
          <div className="float-end">
            <button
              type="button"
              className="btn me-2 pt-0 ps-2 pe-1 close-li-dropdown cursor-point"
              onClick={() => {
                handleOptionDelete(index);
              }}
            >
              <img src="images/Element-delete.svg" alt="element-delete" />
            </button>
          </div>
        </li>
      );
    });
  };

  const buildSettings = () => {
    let id = currentID;
    if (settings) {
      return (
        <div
          id="mySidenav"
          class="sidenav2 custom-height-settings border-start"
        >
          <a class="closebtn cursor-point" onClick={closeSettings}>
            &times;
          </a>
          <div class="col-md-12 ps-3">
            <div class="row">
              <h4 class="component-type-head font14 font-semibold text-uppercase">
                {components[id].type}
              </h4>
            </div>
          </div>
          <div class="col-md-12 ps-3">
            <div class="row">
              <div class="float-start">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button
                      class={
                        page == "general"
                          ? "nav-link custom-tabs active font-16 font-medium p-2 rounded-2"
                          : "nav-link custom-tabs font-16 font-medium p-2"
                      }
                      id="general"
                      onClick={(e) => {
                        handlePage(e);
                      }}
                      data-bs-toggle="tab"
                      data-bs-target="#general"
                      type="button"
                      role="tab"
                      aria-controls="general"
                      aria-selected="true"
                    >
                      General
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class={
                        page == "dataSource"
                          ? "nav-link custom-tabs active font-16 font-medium p-2 rounded-2"
                          : "nav-link custom-tabs font-16 font-medium p-2"
                      }
                      id="dataSource"
                      onClick={(e) => {
                        handlePage(e);
                      }}
                      data-bs-toggle="tab"
                      data-bs-target="#data-source"
                      type="button"
                      role="tab"
                      aria-controls="data-source"
                      aria-selected="false"
                    >
                      Data Source
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <!-- Component Settings side panel ends here --> */}

          <div class="col-md-12 ps-3 pe-3">
            <div class="tab-content mt-3" id="myTabContent">
              {/* <!-- General settings tab starts here --> */}
              {page == "general" ? (
                <div
                  class="tab-pane fade show active"
                  id="general"
                  role="tabpanel"
                  aria-labelledby="general-tab"
                >
                  <div class="accordion" id="accordionPanelsStayOpenExample">
                    <div class="accordion-item" id="panelsStayOpen-headingOne">
                      <h2
                        class="accordion-header font16 font-semibold"
                        id="headingOne"
                      >
                        Main
                      </h2>
                      <div
                        id="panelsStayOpen-collapseOne"
                        class="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body p-0 mt-3">
                          <div class="row">
                            <div class="col-md-12">
                              {components[id].general.labelName ==
                              undefined ? null : (
                                <div class="mb-3">
                                  <label
                                    for="Label-name"
                                    class="form-label settings-label font14 font-semibold"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {components[id].type == "Checkbox" ||
                                    components[id].type == "Radio"
                                      ? "Group Label Name"
                                      : "Label Name"}
                                    <span class="required">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    class={
                                      components[id].validation.labelName
                                        ? "form-control font14 font-semibold BorderValidation input-field theme-focus"
                                        : "form-control font14 font-semibold input-field theme-focus"
                                    }
                                    id={`Label-name${id}`}
                                    onChange={(e) =>
                                      handleChange(e, id, "labelName")
                                    }
                                    maxLength={30}
                                    placeholder="Enter label name"
                                    value={components[id].general.labelName}
                                  />
                                </div>
                              )}
                              {components[id].general.placeHolder ==
                              undefined ? null : (
                                <div class="mb-3">
                                  <label
                                    for="placeholder"
                                    class="form-label settings-label font14 font-semibold "
                                    style={{ fontSize: "14px" }}
                                  >
                                    Placeholder Text
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control font14 font-semibold input-field theme-focus"
                                    id="placeholder"
                                    placeholder="Enter placeholder text"
                                    maxLength={75}
                                    onChange={(e) =>
                                      handleChange(e, id, "placeHolder")
                                    }
                                    value={components[id].general.placeHolder}
                                  />
                                </div>
                              )}
                              {components[id].general.inputType ==
                              undefined ? null : (
                                <div class="mb-3">
                                  <label
                                    for="input-type"
                                    class="form-label settings-label font14 font-semibold"
                                    style={{ fontSize: "14px" }}
                                  >
                                    Input Type <span class="required">*</span>
                                  </label>
                                  <select
                                    class={
                                      components[id].validation.inputType
                                        ? "form-select font14 font-semibold input-field theme-focus BorderValidation"
                                        : "form-select font14 font-semibold input-field theme-focus"
                                    }
                                    aria-label="Default select example"
                                    id="input-type"
                                    onChange={(e) =>
                                      handleChange(e, id, "inputType")
                                    }
                                    onClick={(e)=>e.currentTarget.classList.toggle('open')}
                                    value={components[id].general.inputType}
                                  >
                                    <option disabled value="">
                                      Select Type
                                    </option>
                                    <option value="1">Text</option>
                                    <option value="2">Number</option>
                                    <option value="3">Email</option>
                                    <option value="4">Password</option>
                                  </select>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {components[id].general.alignment == undefined ? null : (
                      <div class="accordion-item mt-2 mb-3">
                        <div class="mb-3 margingalignment">
                          <label
                            for="Label-name"
                            class="form-label settings-label font14 font-semibold"
                            style={{ fontSize: "16px" }}
                          >
                            Alignment<span class="required">*</span>
                          </label>
                        </div>
                        <div
                          id="panelsStayOpen-collapseFour"
                          class="accordion-collapse collapse show"
                          aria-labelledby="panelsStayOpen-headingfour"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body p-0 mt-1">
                            <div class="form-check form-check-inline mt-2">
                              <input
                                class="form-check-input font14 font-semibold  form-build-setting-checkbox theme-focus"
                                type="radio"
                                name="alignment"
                                checked={
                                  components[id].general.alignment == 0
                                    ? true
                                    : false
                                }
                                id="vertical-radio"
                                value="option1"
                                onChange={(e) =>
                                  handleChange(e, id, "alignment")
                                }
                              />
                              <label
                                class="form-check-label font14 font-semibold"
                                for="vertical-radio"
                              >
                                Vertical
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input font14 font-semibold  form-build-setting-checkbox theme-focus"
                                type="radio"
                                name="alignment"
                                id="horizontal-radio"
                                checked={
                                  components[id].general.alignment == 1
                                    ? true
                                    : false
                                }
                                value="option2"
                                onChange={(e) =>
                                  handleChange(e, id, "alignment")
                                }
                              />
                              <label
                                class="form-check-label font14 font-semibold"
                                for="horizontal-radio"
                              >
                                Horizontal
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {components[id].general.dataSource == undefined ? null : (
                      <div class="accordion-item mt-2 mb-3">
                        <h2
                          class="accordion-header font16 font-semibold"
                          id="panelsStayOpen-headingfour"
                        >
                          Data Source
                          <img
                            className="addmarginleft cursor-point"
                            title={
                              "You can choose whether the values entered in the element need to be Static or Dynamic."
                            }
                            src="images/info-icon.svg"
                            alt="infoicon"
                          ></img>
                        </h2>

                        <div
                          id="panelsStayOpen-collapseFour"
                          class="accordion-collapse collapse show"
                          aria-labelledby="panelsStayOpen-headingfour"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body p-0 mt-1 margimport">
                            <div class="form-check form-check-inline mt-2">
                              <input
                                class="form-check-input font14 font-semibold  form-build-setting-checkbox theme-focus"
                                type="radio"
                                checked={
                                  components[id].general.dataSource == 0
                                    ? true
                                    : false
                                }
                                name="dataSource"
                                id="static-radio"
                                value="option1"
                                onChange={(e) =>
                                  handleChange(e, id, "dataSource")
                                }
                              />
                              <label
                                class="form-check-label font14 font-semibold"
                                for="static-radio"
                              >
                                Static
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input font14 font-semibold  form-build-setting-checkbox theme-focus"
                                type="radio"
                                checked={
                                  components[id].general.dataSource == 1
                                    ? true
                                    : false
                                }
                                name="dataSource"
                                id="dynamic-radio"
                                value="option2"
                                onChange={(e) =>
                                  handleChange(e, id, "dataSource")
                                }
                              />
                              <label
                                class="form-check-label font14 font-semibold"
                                for="dynamic-radio"
                              >
                                Dynamic
                              </label>
                            </div>
                            {components[id].general.dataSource == 0 &&
                            components[id].general.options !== undefined ? (
                              <div>
                                <label
                                  for="placeholder"
                                  class="form-label settings-label font14 font-semibold mt-3"
                                  style={{ fontSize: "14px" }}
                                >
                                  {components[id].type === "Checkbox" ||
                                  components[id].type === "Radio"
                                    ? "Label Name"
                                    : "Options"}
                                  <span class="required">*</span>
                                </label>
                                <div class="d-flex mt-2">
                                  <input
                                    type="text"
                                    class={
                                      components[id].validation.options
                                        ? "form-control font14 font-semibold input-field theme-focusBorderValidation"
                                        : "form-control font14 font-semibold input-field theme-focus"
                                    }
                                    id="optionText"
                                    disabled={
                                      components[id].general.options.length == 5
                                        ? true
                                        : false
                                    }
                                    maxLength={20}
                                    placeholder={
                                      components[id].type === "Checkbox" ||
                                      components[id].type === "Radio"
                                        ? "Enter label name"
                                        : "Enter option name"
                                    }
                                    onChange={(e) => {
                                      handleChange(e, id, "optionText");
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.keyCode === 13) {
                                        handleAddOption();
                                      }
                                    }}
                                  />
                                  <button
                                    type="button"
                                    class="btn  theme-color-bg text-white theme-focus ms-2 fs-5"
                                    onClick={handleAddOption}
                                  >
                                    +
                                  </button>
                                </div>
                                <span
                                  hidden={true}
                                  id="optionTextError"
                                  className="mandatory"
                                >
                                  {components[id].type === "Checkbox" ||
                                  components[id].type === "Radio"
                                    ? "Enter label name"
                                    : "Enter an option"}
                                </span>
                                <ul className="options">{buildOptions()}</ul>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    )}

                    {/* <div class="accordion-item mt-2">
                      {components[id].general.enableHelpText == undefined ? null :
                        <div>
                          <h2 class="accordion-header font16 font-semibold" id="panelsStayOpen-headingTwo">Help Text
                          </h2>
                          <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingTwo" data-bs-parent="#accordionExample">
                            <div class="accordion-body p-0 mt-2">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="float-start">
                                    <label class="form-check-label font14 font-semibold" for="enable-help">Enable help text</label>
                                  </div>
                                  <div class="float-end form-check form-switch">
                                    <input class="form-check-input font14 font-semibold" type="checkbox" checked={components[id].general.enableHelpText} id="enable-help" onChange={e => handleChange(e, id, 'enableHelpText')} />
                                  </div>

                                </div>
                                {components[id].general.enableHelpText == true ?
                                  <div class="mb-3 mt-2">
                                    <textarea class="form-control font14 font-semibold" id="help-text" rows="3" placeholder="Enter the description" onChange={e => handleChange(e, id, 'helpText')} value={components[id].general.helpText}></textarea>
                                  </div> : null}

                              </div>
                            </div>
                          </div>
                        </div>}
                    </div> */}
                    {
                      components[id].general.isMandatory == undefined &&
                      components[id].general.otherValidation == undefined
                        ? null
                        : ""
                      // <div class="accordion-item mt-2">
                      //   <h2 class="accordion-header font16 font-semibold" id="panelsStayOpen-headingThree">Validation
                      //   <img className="addmarginleft cursor-point" title={infodata} onMouseOver={()=>{
                      //       ;
                      //       let temp="Click on the 'Yes' button if you want the element to be mandatory. Select 'No' if you don't want the element to be mandatory."
                      //       setinfodata(temp);
                      //     }}  src="images/info-icon.svg" alt="infoicon"></img>
                      //   </h2>
                      //   <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingThree" data-bs-parent="#accordionExample">
                      //     <div class="accordion-body p-0 mt-2">
                      //       <div class="mb-3 marginadjust">
                      //         <label class="form-label font14 font-semibold w-100">Mandatory</label>
                      //         <div class="form-check form-check-inline">
                      //           <input class="form-check-input font14 font-semibold margintopval" type="radio" checked={components[id].general.isMandatory} name="isMandatory" id="yes-radio" onChange={e => handleChange(e, id, 'isMandatory')} />
                      //           <label class="form-check-label font14 font-semibold" for="yes-radio">Yes</label>
                      //         </div>
                      //         <div class="form-check form-check-inline">
                      //           <input class="form-check-input font14 font-semibold margintopval" type="radio" checked={components[id].general.isMandatory == false ? true : false} name="isMandatory" id="no-radio" onChange={e => handleChange(e, id, 'isMandatory')} />
                      //           <label class="form-check-label font14 font-semibold" for="no-radio">No</label>
                      //         </div>
                      //       </div>

                      //     </div>
                      //   </div>
                      //   {components[id].general.otherValidations == undefined ? null :
                      //     <div class="mb-3">
                      //       <label for="input-type" class="form-label settings-label font14 font-semibold">Other Validations<span class="required">*</span></label>
                      //       <select class="form-select font14 font-semibold" aria-label="Default select example" id="input-type" onChange={e => handleChange(e, id, 'otherValidations')} value={components[id].general.otherValidations}>
                      //         <option selected>Open this select menu</option>
                      //         <option value="1">One</option>
                      //         <option value="2">Two</option>
                      //         <option value="3">Three</option>
                      //       </select>
                      //     </div>}
                      // </div>
                    }
                  </div>
                </div>
              ) : null}
              {/* <!-- General settings tab Ends here --> */}

              {/* <!-- Data Source tab starts here --> */}

              {page == "dataSource" ? (
                <div
                  id="data-source"
                  role="tabpanel"
                  aria-labelledby="datasource-tab"
                >
                  <div class="col-md-12">
                    {components[id].dataSource.apiLink == undefined ? null : (
                      <div class="mb-3">
                        <label
                          for="API-link"
                          class="form-label settings-label font14 font-semibold"
                          style={{ fontSize: "16px" }}
                        >
                          API Link<span class="required">*</span>
                        </label>
                        <input
                          type="text"
                          class={
                            components[id].validation.apiLink
                              ? "form-control font14 font-semibold input-field theme-focus BorderValidation"
                              : "form-control font14 font-semibold input-field theme-focus"
                          }
                          disabled={
                            components[id].general.dataSource == 0
                              ? true
                              : false
                          }
                          id="API-link"
                          placeholder="Enter API Link"
                          onChange={(e) => handleChange(e, id, "apiLink")}
                          value={components[id].dataSource.apiLink}
                        />
                      </div>
                    )}
                    {components[id].dataSource.responseJson ==
                    undefined ? null : (
                      <div class="mb-3">
                        <label
                          for="response-JSON"
                          class="form-label settings-label font14 font-semibold"
                          style={{ fontSize: "16px" }}
                        >
                          Response JSON
                          {/* <span class="required">*</span> */}
                        </label>
                        <textarea
                          class="form-control font14 font-semibold textarea-resize-none input-field theme-focus"
                          id="response-JSON"
                          rows="3"
                          placeholder="Enter Response JSON"
                          disabled={
                            components[id].general.dataSource == 0
                              ? true
                              : false
                          }
                          onChange={(e) => handleChange(e, id, "responseJson")}
                          value={components[id].dataSource.responseJson}
                        ></textarea>
                      </div>
                    )}
                    <h4 class="accordion-header font16 font-semibold pt-3">
                      Property To Bind
                      <span
                        className="mandatory"
                        hidden={
                          components[id].type === "Text Area" ||
                          components[id].type === "Text Field" ||
                          components[id].type === "Switch" ||
                          components[id].type === "Date Picker" ||
                          components[id].type === "Time Picker"
                            ? false
                            : true
                        }
                      >
                        *
                      </span>
                    </h4>
                    {components[id].dataSource.propertyToBind.valueProperty ==
                    undefined ? null : (
                      <div class="mb-3 mt-3">
                        <label
                          for="value-property"
                          class="form-label settings-label font14 font-semibold"
                          style={{ fontSize: "14px" }}
                        >
                          Value Property<span class="required">*</span>
                        </label>
                        <input
                          type="text"
                          class={
                            components[id].validation.valueProperty
                              ? "form-control font14 font-semibold input-field theme-focus BorderValidation"
                              : "form-control font14 font-semibold input-field theme-focus"
                          }
                          id="value-property"
                          placeholder="Enter value property"
                          disabled={
                            components[id].general.dataSource == 0
                              ? true
                              : false
                          }
                          onChange={(e) => handleChange(e, id, "valueProperty")}
                          value={
                            components[id].dataSource.propertyToBind
                              .valueProperty
                          }
                        />
                      </div>
                    )}
                    {components[id].dataSource.propertyToBind.textProperty ==
                    undefined ? null : (
                      <div class="mb-3 mt-3">
                        <label
                          for="text-property"
                          class="form-label settings-label font14 font-semibold"
                          style={{ fontSize: "14px" }}
                          hidden={
                            components[id].type === "Text Field" ||
                            components[id].type === "Switch" ||
                            components[id].type === "Date Picker" ||
                            components[id].type === "Time Picker"
                              ? true
                              : false
                          }
                        >
                          Text Property<span class="required">*</span>
                        </label>
                        <input
                          type="text"
                          class={
                            components[id].validation.textProperty
                              ? "form-control font14 font-semibold input-field theme-focus BorderValidation"
                              : "form-control font14 font-semibold input-field theme-focus"
                          }
                          id="text-property"
                          placeholder={
                            components[id].type === "Text Field" ||
                            components[id].type === "Switch" ||
                            components[id].type === "Date Picker" ||
                            components[id].type === "Time Picker"
                              ? "Enter property to bind"
                              : "Enter text property"
                          }
                          onChange={(e) => handleChange(e, id, "textProperty")}
                          value={
                            components[id].dataSource.propertyToBind
                              .textProperty
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
              {/* <!-- Data Source tab ends here --> */}
            </div>
          </div>
          {/* <!-- Tab Content starts here --> */}
          {/* <!-- Tab Content ends here --> */}
        </div>
      );
    }
  };

  const renderRow = (row, currentPath) => {
    return (
      <Row
        key={row.id}
        data={row}
        handleDrop={handleDrop}
        components={components}
        path={currentPath}
        handleSettings={handleSettings}
        handleDelete={handleDelete}
      />
    );
  };

  const handleSubmit = async () => {
    setSettings(false);
    if (validation()) {
     

      let req = {
        codeGenID: window.sessionStorage.getItem("codeGenId"),
        gridID: window.sessionStorage.getItem("gridID"),
        FormFieldProperties: {
          layoutData: layout,
          componentsData: [components],
        },
      };
    

      window.sessionStorage.setItem("filterDetailsNode", JSON.stringify(req));

      let filtertoGrid = {
        layout: layout,
        components: components,
      };

  

      window.sessionStorage.setItem(
        "fitlertoGrid",
        JSON.stringify(filtertoGrid)
      );

      history.push({
        pathname: "/Gridbuilder",
        search: "?filtertogrid",
        state: {
          filtertogrid: true,
        },
      });
    } else {
      //("Validation failed");
    }
  };

  // dont use index for key when mapping over items
  // causes this issue - https:
  
  
  
  //github.com/react-dnd/react-dnd/issues/342
  // style={{width: "99%"}}
  return (
    <div className="body  d-flex flex-column form-builder-body">
      {/* Modal */}
      <Modal isOpen={modelenable || homeenable} className="modal-dialog">
        <div
          className="modelmargintop"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 pb-0">
                <h5
                  className="modal-title font20 font-semibold font-main-color"
                  id="exampleModalLabel"
                ></h5>
                <button
                  type="button"
                  className="cursor-point border-0 bg-white"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setmodelenable(false);
                    sethomeenable(false);
                  }}
                >
                  <img
                    src="images/builder-css/filter-close-icon.svg"
                    alt="filter close"
                  />
                </button>
              </div>
              <div className="text-center modal-body">
                <h5
                  className="modal-title font20 font-semibold font-main-color"
                  id="exampleModalLabel"
                >
                  Are you sure?
                </h5>
                <label className=" font14 font-semibold text-secondary">
                  If you leave this page all your progress will be lost!
                </label>
                <label className="font14 font-semibold text-secondary">
                  Save before you leave!
                </label>
              </div>
              <div className="py-3 border-top-0 bg-light mt-4 text-center">
                <button
                  type="button"
                  className="btn font14 font-semibold btn-outline-secondary cursor-point"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setmodelenable(false);
                    sethomeenable(false);
                  }}
                >
                  Go back
                </button>
                <button
                  type="button"
                  className="btn btn-danger px-3 py-2 font14 font-semibold cursor-point ms-2"
                  onClick={() => {
                    if (modalenable) {
                      redirecttogeneratecodepage();
                    } else {
                      history.push("/buildform");
                    }
                    setmodelenable(false);
                    sethomeenable(false);
                  }}
                >
                  Leave
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/*Modal*/}

      {/* Modal */}
      {/* <Modal isOpen={logoenable} className="modal-dialog">
        <div
          className="modelmargintop"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 pb-0">
                <h5
                  className="modal-title font20 font-semibold font-main-color"
                  id="exampleModalLabel"
                >
                  Are you sure?
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setlogoenable(false);
                  }}
                />
              </div>
              <div className="modal-body font14 font-semibold text-secondary">
                If you leave this page all your progress will be lost!
              </div>
              <div className="modal-body font14 font-semibold text-secondary">
                Save before you leave!
              </div>
              <div className="modal-footer border-top-0 bg-light mt-4">
                <button
                  type="button"
                  className="btn font14 font-semibold text-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setlogoenable(false);
                  }}
                >
                  Go back
                </button>
                <button
                  type="button"
                  className="btn btn-danger px-3 py-2 font14 font-semibold"
                  onClick={() => {
                    history.push("/buildgrid");
                  }}
                >
                  Leave
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal> */}
      {/*Modal*/}

      {/* Modal */}
      {/* <Modal isOpen={homeenable} className="modal-dialog">
        <div
          className="modelmargintop"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 pb-0">
                <h5
                  className="modal-title font20 font-semibold font-main-color"
                  id="exampleModalLabel"
                >
                  Are you sure?
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    sethomeenable(false);
                  }}
                />
              </div>
              <div className="modal-body font14 font-semibold text-secondary">
                If you leave this page all your progress will be lost!
              </div>
              <div className="modal-body font14 font-semibold text-secondary">
                Save before you leave!
              </div>
              <div className="modal-footer border-top-0 bg-light mt-4">
                <button
                  type="button"
                  className="btn font14 font-semibold text-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    sethomeenable(false);
                  }}
                >
                  Go back
                </button>
                <button
                  type="button"
                  className="btn btn-danger px-3 py-2 font14 font-semibold"
                  onClick={() => {
                    history.push("/buildform");
                  }}
                >
                  Leave
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal> */}
      {/*Modal*/}

      {/* <!-- Header Widget --> */}

      {/* <!-- Header Widget --> */}

      {/* <!-- Sidebar starts here --> */}
      <div className="row mt-5 breadcrumb-div  w-100 mx-0">
        {/* <!-- Breadcrumb --> */}
        <div class="col-md-12 my-1">
          <ul class="breadcrumb mb-0">
            <li>
              <a
                class="text-decoration-none font14 font-semibold cursor-point  theme-color"
                onClick={() => {
                  sethomeenable(true);
                }}
              >
                Home
              </a>
            </li>
            <li className="">
              <span class="ms-2 me-2">
                <img src="images/breadcrumb-triangle.svg" alt="breadcrumb" />
              </span>
            </li>
            <li>
              <a class="text-decoration-none text-secondary font14 font-semibold cursor-point">
                {projectname}
              </a>
            </li>
          </ul>
        </div>
        {/* <!-- Breadcrumb --> */}
      </div>
      {/* <!-- Sidebar starts here --> */}

      {/* <!-- Breadcrumb --> */}

      <div className="row mt-2 mx-0">
        <div className="col-md-3 sidebar-custom  rounded-3 side-container-div">
          <div className="border rounded-3 bg-white">
            <div className="col-md-12 pt-3">
              <h4
                className="font20 font-semibold font-main-color"
                style={{ marginLeft: "18px" }}
              >
                All Components
              </h4>
              <div className="mb-4 pt-1" style={{ marginLeft: "4%" }}>
                <input
                  type="text"
                  style={{ width: "97%", padding: "11px" }}
                  onChange={(e) => handleSearch(e)}
                  // className="form-control component-search-width font14 font-semibold searchicon"
                  className="form-control font14 font-semibold searchicon padding-40 input-field focus-none"
                  id="searchbox"
                  placeholder="Search"
                />
              </div>
            </div>
            {/* <!-- Panel header & searchbox ends here -->  */}

            {/* <!-- Layout Elements starts here --> */}
            {/* <div className="custom-sidenav-filter"> */}
            <div
              className="custom-sidenav-height hidescrollbar"
              style={{ "padding-left": "3px", "padding-right": "10px" }}
            >
              {layoutElements.length == 0 ? null : (
                <div className="filter-side-padding mb-4  mt-2">
                  <h4
                    style={{ marginLeft: "18px" }}
                    className="font20 font-semibold font-main-color "
                  >
                    Layout Elements
                  </h4>
                  <div className="component-container mb-3">
                    <div className="row" style={{ marginLeft: "-26px" }}>
                      {Object.values(layoutElements).map(
                        (sideBarItem, index) => (
                          <SideBarItem
                            key={sideBarItem.id}
                            data={sideBarItem}
                          />
                        )
                      )}
                    </div>
                  </div>
                </div>
              )}

              {textElements.length == 0 &&
              actionElements.length == 0 &&
              pickerElements.length == 0 ? null : (
                <div>
                  <h4
                    class="font20 font-semibold font-main-color "
                    style={{ marginTop: "5px", marginLeft: "18px" }}
                  >
                    Form Elements
                  </h4>

                  {textElements.length == 0 ? null : (
                    <div className="component-container mb-3">
                      <h4
                        class="grey-blue font14 font-semibold text-uppercase mt-3 margin-layout grey-blue"
                        style={{ marginTop: "5px" }}
                      >
                        TEXT INPUT
                      </h4>

                      <div class="row" style={{ marginLeft: "-26px" }}>
                        {Object.values(textElements).map(
                          (sideBarItem, index) => (
                            <SideBarItem
                              key={sideBarItem.id}
                              data={sideBarItem}
                            />
                          )
                        )}
                      </div>
                    </div>
                  )}

                  {actionElements.length == 0 ? null : (
                    <div class="component-container mb-3">
                      <h4 class="grey-blue text-uppercase font14 font-semibold margin-layout grey-blue">
                        Action &amp; Selection
                      </h4>

                      <div class="row" style={{ marginLeft: "-26px" }}>
                        {Object.values(actionElements).map(
                          (sideBarItem, index) => (
                            <SideBarItem
                              key={sideBarItem.id}
                              data={sideBarItem}
                            />
                          )
                        )}
                      </div>
                    </div>
                  )}

                  {pickerElements.length == 0 ? null : (
                    <div class="component-container mb-3">
                      <h4 class="grey-blue text-uppercase font14 font-semibold margin-layout">
                        Picker
                      </h4>
                      <div class="row" style={{ marginLeft: "-26px" }}>
                        {Object.values(pickerElements).map(
                          (sideBarItem, index) => (
                            <SideBarItem
                              key={sideBarItem.id}
                              data={sideBarItem}
                            />
                          )
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/* <!-- Layout Elements Ends here -->*/}
            </div>
          </div>

          {/* <!-- Form Elements Ends here --> */}
        </div>

        {/* <!-- Drag & drop panel here --> */}
        {/* <div className="col-md-9 content bg-white margileftadjust formmargintop"> */}
        <div className="col-md-9  rounded-3 ">
          <div className="row rounded-3">
            <div className="col-md-12">
              <div className="float-start d-flex mt-4" id="formNameDiv">
                <h4 className="font20 font-semibold font-main-color  ms-2">
                  Advanced Filter
                </h4>
              </div>
            </div>
            <div className="pageContainer">
              <div className="page">
                <div className=" drag-zone dotted-spaced mb-3 drag-drop-height">
                  {layout.map((row, index) => {
                    const currentPath = `${index}`;

                    return (
                      <React.Fragment key={row.id}>
                        <FilterDropZone
                          data={{
                            path: currentPath,
                            childrenCount: layout.length,
                          }}
                          onDrop={handleDrop}
                          path={currentPath}
                        />
                        {renderRow(row, currentPath)}
                      </React.Fragment>
                    );
                  })}
                  <FilterDropZone
                    data={{
                      path: `${layout.length}`,
                      childrenCount: layout.length,
                    }}
                    onDrop={handleDrop}
                    isLast
                  />

                  {layout.length !== 0 ? null : (
                    <div class="row justify-content-center ">
                      <div class="col-md-8 text-center">
                        <img
                          src="images/Drag-empty-state.svg"
                          class="empty-state"
                          alt="empty-state"
                        />
                        <h4 class="font20 font-semibold font-main-color mt-3 ps-3 text-primary">
                          Drop Components here to build
                        </h4>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="d-flex justify-content-start">
              <button
                type="button"
                className="btn font14 font-semibold px-3 py-2 btn-outline-secondary btn-light"
                onClick={() => {
                  // handleSubmit();
                  setmodelenable(true);
                }}
              >
                Cancel
              </button>
            </div>
            <div class="d-flex justify-content-start">
              <button
                type="button"
                class="btn font14 font-semibold px-3 py-2 ms-2  theme-color-bg text-white theme-focus"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
        {/* <!-- Drag & drop panel ends here --> */}
      </div>
      {buildSettings()}

      {/* <!-- Footer Widget --> */}

      {/* <!-- Footer Widget --> */}

      {/* Modal */}
      {/* <Modal isOpen={modelenable} className="modal-dialog">
        <div
          className="modelmargintop"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 pb-0">
                <h5
                  className="modal-title font20 font-semibold font-main-color"
                  id="exampleModalLabel"
                >
                  Are you sure?
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setmodelenable(false);
                  }}
                />
              </div>
              <div className="modal-body font14 font-semibold text-secondary">
                If you leave this page all your progress will be lost!
              </div>
              <div className="modal-body font14 font-semibold text-secondary">
                Save before you leave!
              </div>
              <div className="modal-footer border-top-0 bg-light mt-4">
                <button
                  type="button"
                  className="btn font14 font-semibold text-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setmodelenable(false);
                  }}
                >
                  Go back
                </button>
                <button
                  type="button"
                  className="btn btn-danger px-3 py-2 font14 font-semibold"
                  onClick={() => {
                    redirecttogeneratecodepage();
                  }}
                >
                  Leave
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal> */}
      {/*Modal*/}
    </div>
  );
};
