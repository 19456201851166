//PS_AAI_9.1,Declare all the import statements
import { useEffect, useState, useContext } from "react";
import { getInputTypeAndTechStack } from "../AI Service/Api";
import toggleContext from "./Context";
/**
 * PS_AAI_9.2,Declare all the state varaibles
 * PS_AAI_9.3 to 9.12,triggers fetchInputTypeTechStack function in useEffect which fetches the data from the database by an API call
 * PS_AAI_9.15 to PS_AAI_9.20,onclick will be triggered and return the selected value
 * @param {props value from parent component} param0
 * @returns
 */
export default function RadioComponent({ record }) {
  const [recordData, setRecordData] = useState();
  const toggle = useContext(toggleContext);
  const [codeID, setcodeID] = useState(0);
  const [options, setOptions] = useState([]);
  const [hideError, sethideError] = useState(true);
  useEffect(() => {
    if (record) {
      setRecordData(record.record);
      if (record?.bindChoicesValues) {
        setOptions(record?.bindChoicesValues);
      }
      if (record.codeID) {
        setcodeID(record.codeID);
      }
    }
    // fetchInputTypeAndTechStack();
  }, [record]);

  useEffect(() => {
    const radioButtons = document.querySelectorAll(
      'input[name="inlineRadioOptions"]'
    );
    radioButtons.forEach((radio) => {
      radio.checked = false;
    });
  }, [codeID]);

  /**
   * PS_AAI_9.13 to PS_AAI_9.14,set the response to the state variable and return it
   */
  // const fetchInputTypeAndTechStack = async () => {
  //   try {
  //           let result = await getInputTypeAndTechStack({
  //       promptFieldID: recordData?.promptFieldID,
  //     });

  //     setOptions(result.data.choices);
  //   } catch (error) {
  //     //(error.message);
  //   }
  // };
  /**
   * PS_AAI_9.21 to PS_AAI_9.27,check if the value is not selected,then using bindError function bind error to the form
   * @returns error to the user if any
   */
  const bindError = () => {
    if (recordData) {
      const err = `${recordData.inputValueOrder}`;

      if (record.validationErrors) {
        return (
          <>
            {hideError ? (
              <span
                className="mandatory-star font-14 font-medium mb-0 d-flex"
                style={{ color: "red" }}
              >
                {record.validationErrors[err]}
              </span>
            ) : null}
          </>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  return (
    <>
      {record && recordData?.labelName ? (
        <label class="form-label font-15 font-semibold w-100 text-color-1">
          {recordData.labelName}{" "}
          {record && recordData?.required == "true" ? (
            <span class="color-red">*</span>
          ) : null}
        </label>
      ) : null}
      {options?.choiceName?.map((value, index) => {
        return (
          <div class="form-check form-check-inline">
            <input
              class="user-radio form-check-input bg-transparent text-color-1"
              type="radio"
              name={options?.input}
              disabled={codeID ? codeID != value.choicesID : null}
              // checked={codeID ? codeID == value.labelName : null}
              value={value}
              id={value.labelName}
              onChange={(e) => {
                if (e.target.checked) {
                  // sethideError(false);
                  record.handleFieldValues(
                    recordData.labelName,
                    e.target.value,
                    recordData.inputValueOrder,
                    recordData.required
                  );
                  toggle?.functions(e);
                } else {
                  sethideError(true);
                }
              }}
            ></input>
            <label
              class="form-check-label font-14 font-medium text-color-2"
              placeholder={
                recordData?.placeHolder ? recordData.placeHolder : null
              }
              for="API"
            >
              {value}
            </label>
          </div>
        );
      })}
      {bindError()}
    </>
  );
}
