import React from "react";
import { useDrag } from "react-dnd";
import { SIDEBAR_ITEM } from "./filter-constants";

const SideBarItem = ({ data }) => {
  const [{ opacity }, drag] = useDrag({
    type: SIDEBAR_ITEM,
    item: data,
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  });

  return (
    <div
      className="col-md-6 float-start"
      ref={drag}
      style={{ opacity: "1", marginTop: "10px", marginBottom: "5px" }}
    >
      <div
        className="element-list rounded font-semibold cursor-point layout-border"
        style={{ textAlign: "center" }}
      >
        <img
          src={data.component.image}
          className="icon-spacing cursor-point"
          alt="one-column"
        />
        <label>{data.component.type}</label>
      </div>
    </div>
  );
};
export default SideBarItem;
