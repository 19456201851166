
import React, { useState, useEffect, useRef } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Editor from '@monaco-editor/react';
import { useLocation, useHistory } from 'react-router-dom';
import { fetchAndValidateDiagram } from '../AI Service/replaceD2Image';

const D2Preview = ({ navOpen }) => {
    const location = useLocation();
    const history = useHistory();
    const searchParams = new URLSearchParams(location.search);
    const initialD2Code = sessionStorage.getItem('d2Code');

    const [code, setCode] = useState(initialD2Code);
    const [diagramSvg, setDiagramSvg] = useState('');
    const [copiedCode, setCopiedCode] = useState(false);
    const [copiedImage, setCopiedImage] = useState(false);
    const [chatId, setChatId] = useState(null);
    const [zoom, setZoom] = useState(1);
    const [layout, setLayout] = useState('down');
    const [error, setError] = useState(null);
    const [isDiagramFullscreen, setIsDiagramFullscreen] = useState(false);
    const [isEditorFullscreen, setIsEditorFullscreen] = useState(false);
    const ref = useRef(null);
    const mounted = useRef(true);

    useEffect(() => {
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        const storedData = sessionStorage.getItem('d2Data');
        if (storedData) {
            const { chatId } = JSON.parse(storedData);
            setChatId(chatId);
        }
    }, []);

    useEffect(() => {
        const fetchDiagram = async () => {
            if (!mounted.current) return;

            const { diagramSvg, finalCode, error } = await fetchAndValidateDiagram(code, layout);
            if (mounted.current) {
                if (diagramSvg) {
                    setDiagramSvg(diagramSvg);
                    if (finalCode !== code) {
                        setCode(finalCode); // Update the code if it has changed
                    }
                    setError(null);
                } else {
                    setError(error);
                }
            }
        };

        fetchDiagram();
    }, [code, layout]);

    const handleCopyCode = () => {
        setCopiedCode(true);
        setTimeout(() => setCopiedCode(false), 2000);
    };

    const handleCopyImage = () => {
        if (diagramSvg) {
            const blob = new Blob([diagramSvg], { type: 'image/svg+xml' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'diagram.svg';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
            // console.log('SVG download initiated');
            setCopiedImage(true);
            setTimeout(() => setCopiedImage(false), 2000);
        } else {
            console.error('No diagram SVG available');
            alert('No diagram available to download. Please try again.');
        }
    };

    const handleGoBack = () => {
        history.push(`/chat?chatID=${chatId}`);
    };

    const handleZoomIn = () => {
        setZoom(prev => Math.min(prev + 0.1));
    };

    const handleZoomOut = () => {
        setZoom(prev => Math.max(prev - 0.1));
    };

    const handleLayoutChange = (e) => {
        setLayout(e.target.value);
    };

    const toggleDiagramFullscreen = () => {
        setIsDiagramFullscreen(!isDiagramFullscreen);
    };

    const toggleEditorFullscreen = () => {
        setIsEditorFullscreen(!isEditorFullscreen);
    };

    return (
        <div className="container-fluid">
            <div className="row justify-content-lg-end ms-lg-3 ms-xl-0 justify-content-center">
                <div
                    className={
                        navOpen === "Close"
                            ? `col-11 me-xl-4 me-lg-3`
                            : `col-lg-9 me-md-2 cust-me-lg-4 col-11`
                    }
                >
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex justify-content-between align-items-center mt-3 mb-4">
                                <h3 className="font-24 font-semibold text-color-1 mb-0">
                                    <a onClick={handleGoBack}>
                                        <img
                                            src="images/back-black-arrow.svg"
                                            alt="Back"
                                            className="me-3 icon-filter"
                                        />
                                    </a>
                                    Code Snippet & D2 Diagram
                                </h3>
                                <div className="layout-selector">
                                    <label htmlFor="layout-select" className="me-2 font-24 font-semibold text-color-1">Layout : </label>
                                    <select id="layout-select" value={layout} onChange={handleLayoutChange} className="form-select form-select-sm">
                                        <option value="right">Right</option>
                                        <option value="down">Down</option>
                                        <option value="left">Left</option>
                                        <option value="up">Up</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                {!isDiagramFullscreen && (
                                    <div className={isEditorFullscreen ? "col-md-12" : "col-md-6"}>
                                        <div className="position-relative">
                                            <Editor
                                                defaultLanguage="d2"
                                                className="px-2 py-2 er-snippet code-snippet-area"
                                                value={code}
                                                onChange={setCode}
                                                options={{
                                                    minimap: { enabled: false },
                                                }}
                                                height={isEditorFullscreen ? "80vh" : "400px"}
                                            />
                                            <div className="code-btns">
                                                <CopyToClipboard text={code} onCopy={handleCopyCode}>
                                                    <button
                                                        type="button"
                                                        className={`btn textarea-btn ${copiedCode ? 'copied' : ''} me-3`}
                                                    >
                                                        {copiedCode ? (
                                                            <div>
                                                                <img src="images/copy-icon-btn.svg" alt="Copy" />
                                                                <img
                                                                    src="images/tick-icon.svg"
                                                                    alt="copied"
                                                                    className="emoji-active-tick"
                                                                />
                                                            </div>
                                                        ) : (
                                                            <img src="images/copy-icon-btn.svg" alt="Copy" />
                                                        )}
                                                    </button>
                                                </CopyToClipboard>
                                                <button
                                                    type="button"
                                                    className={`btn textarea-btn me-3`}
                                                    onClick={toggleEditorFullscreen}
                                                >
                                                    {isEditorFullscreen ? (
                                                        <img src="images/minimize-icon.svg" alt="Minimize" />
                                                    ) : (
                                                        <img src="images/maximize-icon.svg" alt="Maximize" />
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className={isDiagramFullscreen ? "col-md-12" : isEditorFullscreen ? "d-none" : "col-md-6"}>
                                    <div
                                        className="position-relative mb-200"
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            overflow: "visible",
                                        }}
                                    >
                                        <div
                                            className="px-2 py-2 er-snippet code-snippet-area div12 position-relative uml-scroll"
                                            ref={ref}
                                            style={{
                                                height: isDiagramFullscreen ? "80vh" : "400px",
                                                width: "100%",
                                                overflow: "auto",
                                            }}
                                        >
                                            {error ? (
                                                <div className="error-message" style={{ color: 'red', padding: '10px' }}>
                                                    {error}
                                                </div>
                                            ) : diagramSvg ? (
                                                <div
                                                    style={{
                                                        transform: `scale(${zoom})`,
                                                        transformOrigin: 'top left',
                                                    }}
                                                    dangerouslySetInnerHTML={{ __html: diagramSvg }}
                                                />
                                            ) : (
                                                <div>Loading diagram...</div>
                                            )}
                                        </div>
                                        <div
                                            className="btn-group cust-btngrp p-1 mt-2 position-fixed"
                                            role="group"
                                            aria-label="Basic example"
                                        >
                                            <div>
                                                <button
                                                    type="button"
                                                    className="d-block btn zoom-btn px-1 border-bottom rounded-0"
                                                    onClick={handleZoomIn}
                                                >
                                                    <img src="images/plus-icons.svg" alt="Zoom In" />
                                                </button>
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <button
                                                    type="button"
                                                    className="btn zoom-btn px-2 rounded-0"
                                                    onClick={handleZoomOut}
                                                >
                                                    <img src="images/minus-icon.svg" alt="Zoom Out" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="code-btns">
                                            <button
                                                type="button"
                                                className={`btn textarea-btn ${copiedImage ? 'downloaded' : ''} me-3`}
                                                onClick={handleCopyImage}
                                            >
                                                {copiedImage ? (
                                                    <div>
                                                        <img src="images/download-icon.svg" alt="Download" />
                                                        <img
                                                            src="images/tick-icon.svg"
                                                            alt="downloaded"
                                                            className="emoji-active-tick"
                                                        />
                                                    </div>
                                                    
                                                ) : (
                                                    <img src="images/download-icon.svg" alt="Download" />
                                                )}
                                            </button>
                                            <button
                                                type="button"
                                                className={`btn textarea-btn me-3`}
                                                onClick={toggleDiagramFullscreen}
                                            >
                                                {isDiagramFullscreen ? (
                                                    <img src="images/minimize-icon.svg" alt="Minimize" />
                                                ) : (
                                                    <img src="images/maximize-icon.svg" alt="Maximize" />
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default D2Preview;