export default function AuthCallback() {
  return (
    // <div className={props.isLoading ? "" : "overlay spin-overlay"}>
    <>
      {/* {props.isLoading ? null : ( */}
      <div class="overlay">
        <div
          class="spinner-border Loader theme-color align-center"
          role="status"
        >
          <span class="visually-hidden"></span>
        </div>
      </div>
      {/* )} */}
    </>

    // </div>
  );
}
