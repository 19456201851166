import React from 'react';
import { FileUploadProvider } from '../ContextFiles/FileUploadContext';
import { MediaUpload } from '../MediaUploadComponent';
import ChatComponent from '../ChatComponent';
import { PromptFormComponent } from '../PromptFormComponent';

 
const ChatComponentWrapper = (props) => {
  return(
  <FileUploadProvider>
    <ChatComponent {...props} />
  </FileUploadProvider>
  );
};
 
 
 
 
export { ChatComponentWrapper as ChatComponent };