// PS_AS_01 import required package
import React, { useState, useEffect } from "react";
import { fetchPromptEmj, insertGetFeedBack } from "../AI Service/Api";
import Loader from "../Other-Components/loader";
import { ClipLoader } from 'react-spinners';
import user from "../images/User.svg"
// import { ClipLoader } from 'react-spinners';


const FeedBackCard = (navOpen) => {
  // PS_AS_04 - PS_AS_09 declare state variable 
  const [getFeedback, setGetFeedback] = useState([]);
  const [filterDomain, setFilterDomain] = useState("");
  const [selectedReaction, setReactionID] = useState("");
  const [selectedPractice, setPractice] = useState([]);
  const [practiceDropDown, setPracticeDropDown] = useState([]);
  const [reactionDropDown, setReactionDropDown] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [advancefilterpopup, setadvancefilterpopup] = useState(false);
  const [showmenu, setShowMenu]=useState(false);
  const [buttonShow, setButtonShow] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [isLoad,setIsLoad] = useState(false)
  // ;
  // PS_AS_11 - In useEffect call getDominData to fetch the domain data
  useEffect(() => {
    getDomainData();
  }, [filterDomain]);


// PS_AS_10 Call getfeedbackData to fetch feedbacks getPracticeandReaction() function with the request body to get Reaction data for filter dropdown.
  useEffect(() => {
    // setIsLoading(true);
    getFeedbackData();
    getReaction();
  }, []);
  const getReaction = async () => {
    try {
      const DropDownData = await fetchPromptEmj({});
      setReactionDropDown(DropDownData.FeedbackReaction);
      setIsLoading(true);
    } catch (error) {
      setIsLoading(true);
      setError("Failed to fetch reaction data.");
    }
  };
  // PS_AS_48 getDomianData function to fetch the Domaindata for dropdown
  const getDomainData = async () => {
    try {
      const reqBody = {
        SearchDomain: filterDomain,
      };
      const DropDownData = await insertGetFeedBack(reqBody);
      // console.log(DropDownData,"helooooooooooooooo")
      setPracticeDropDown(DropDownData.ResponseData);
    } catch (error) {
      setIsLoading(true)
      setError("Failed to fetch domain data.");
      setIsLoading(true);
    }
  };

  function getEmoji(val){
    if(val=='sad'){
      return 'images-new/sad-emoji.svg'
    }
    else if(val=='netrual'){
      return 'images-new/neutral-emoji.svg'
    }
    else{
      return 'images-new/happy-emoji.svg'
    }
  }

// PS_AS-38 Call getFeedback() function with the request body to get feedbacks data
// PS_AS_59 - 78  update FilterDomin state variable ,update FilterReaction state Variable to filter the feedback and based on filtered practice and reaction  
const getFeedbackData = async () => {
    // // ;
    try {
      setIsLoad(true)
      setIsLoading(false);
      // setIsLoad(true)
      const reqBody = {
        ReactionID: selectedReaction,
        Practice: selectedPractice,
      };
      const responseData = await insertGetFeedBack(reqBody);
      setIsLoading(true);
      setIsLoad(false)
      // setIsLoad(false)
      if (responseData.Success === true) {
        setGetFeedback(responseData.ResponseData);
      } else {
        setError(responseData.ErrorMessage);
      }
    } catch (error) {
      setIsLoading(true);
      setError("Failed to fetch feedback data.");
    }
  };


  // PS_AS_62 if user selected reaction update reactionID
  const handleReactionChange = (event) => {
    setReactionID(event.target.value);
    // console.log(selectedReaction, "SR");
  };

  
  // PS_AS_64 while user click filter apply 
  const handleApply = () => {

    getFeedbackData();
    handleCancel();
    setIsFilterOpen(false); // Close filter popup when Apply is clicked
  };
  // PS_AS_81 user to clear the usestate variables
  const handleCancel = () => {
    toggleFilter()
    // setIsFilterOpen(false);
    setFilterDomain("")
    setReactionID("");
    setPractice([]);
    getFeedbackData();
  }; 
  // PS_AS_46  Add the practice to the selectedPractice state
 // Remove the practice from the selectedPractice state
  const handlePracticeChange = (e, practice) => {
    const isChecked = e.target.checked;
    if (isChecked) {
     
      setPractice((prevSelectedPractice) => [
        ...prevSelectedPractice,
        practice,
      ]);
    } else {
     
      setPractice((prevSelectedPractice) =>
        prevSelectedPractice.filter((item) => item !== practice)
      );
    }
  };

  function toggleFilter(){
    setadvancefilterpopup(!advancefilterpopup)
  }
  
  // function toggleMenu(){
  //   console.log("weeeeeeeeeeeeeeeeeeee")
  //   setShowMenu(!showmenu)
  // }
  function getEmoji(val,feedBackCard){
    if(feedBackCard.userName=='prasanth'){
      // console.log(val,"hereeeeeeeeeeeeeeee");
      // console.log(feedBackCard.Profile_url,"trrrr")
    }
    if(val=='sad'){
      return 'images-new/sad-emoji.svg'
    }
    else if(val=='netrual'){
      return 'images-new/neutral-emoji.svg'
    }
    else{
      return 'images-new/happy-emoji.svg'
    }
  }
  function profileImage(url){
    let adjustedUrl = url
    if (url.includes('asami-upload-image')) {
      // Adjust the URL to use 'user-profile-storage'
      adjustedUrl = 'images/Default/default-profile-image.png';
    }
    return adjustedUrl
  }
  useEffect(() => {
    if (buttonShow) {
        const dropdown = document.querySelector('.filter-dropdown-bg');
        if (dropdown) {
            dropdown.style.top = '100px'; // Adjust the value as needed
        }
    }
}, [buttonShow]);
const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [description, setDescription] = useState('');
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [hoverStyles, setHoverStyles] = useState({});
  const toggleDropdown = () => setIsOpen(!isOpen);
  const [bodyClasses, setBodyClasses] = useState([]);
useEffect(() => {
    // Function to update the state with current body classes
    const updateBodyClasses = () => {
      setBodyClasses(Array.from(document.body.classList));
    };

    // Initial update
    updateBodyClasses();

    // Create a MutationObserver to watch for changes in body's class attribute
    const observer = new MutationObserver(updateBodyClasses);

    // Start observing
    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ['class']
    });

    // Cleanup function
    return () => observer.disconnect();
  }, []); // Empty dependency array, we only set this up once

  // useEffect(() => {
  //   console.log(bodyClasses, "say hello");
  // }, [bodyClasses]); 
  return (
    <>
      {
        !isLoading ? (
          <Loader isLoading={isLoading} /> 
        ) : (
          <div className="container-fluid">
          <div className="row justify-content-lg-end ms-lg-3 ms-xl-0 justify-content-center">
          <div className={navOpen.navOpen == "Close" ? `col-11` : `col-lg-9 me-lg-4 col-11`}>
            <div className="row">
              <div className="col-md-12">
                <div className="px-0 py-4 mb-3 border-0 rounded-3">
                  <div className="row">
                    <div className="col-md-12 mb-4">
                      <div className="d-flex justify-content-between align-items-center">
                      <h5 className="font-bold font-20 mb-0 text-nowrap text-color-1">Feedbacks</h5>
                        <div >
                        <button type="button" className={`btn font-16 font-semibold align-items-center inter-btn d-flex transparent-grp search-bg py-2 theme-focus ${buttonShow ? "show" : ""}`} data-bs-toggle="dropdown" aria-expanded={buttonShow ? true : false} onClick={() => {
                          setButtonShow((e) => !e);
                          setShowFilter(!showFilter);
                          }}>
                          <img className="filter-icons" src="images-new/adv-filter.svg" alt="filter-icon" />
                        </button>
                          {/* advanced filter starts here */}
                          {showFilter==true&&(<span className={`dropdown-menu dropdown-menu-end p-3 bg-color-4 filter-dropdown-bg w-filter-dropdown end-0 filter-feedbackdropdown-bg ${buttonShow ? "show" : ""}`} >
                          <span className="d-flex justify-content-between align-items-center mb-3">
                            <span className="font-18  font-semibold text-color-1">Advanced Filter</span>
                              <span className onClick={() => {setShowFilter(false);setButtonShow(false);}}>
                                <img src="images-new/flter-close-icon.svg" alt="flter-close-icon" className="cursor-pointer close-icon-filter" /></span>
                        </span>
                        <span className="row ">
                        <div className="mb-3 d-block col-md-12">
                          <div className="dropdown" style={{width:'100%'}}>
                          <label htmlFor="model-type" className="form-label text-color-9 font-15 font-semibold">Practice</label>

                            <button id="model-type" className="btn input-field font-14 font-regular border-radius-3 bg-color-4 text-color-4 custom-border-3 cust-search-input w-100 d-flex justify-content-between " type="button"

                            style={{width:'100%'}}

                            onClick={()=>{setShowMenu(!showmenu);toggleDropdown()}} >

                              <span className="text-color-1">Select</span>

                              <span className={`bg-color arrow ${isOpen ? 'up' : 'down'} text-color-4`} 
    style={{ ...(bodyClasses[1] === "dark-theme" ? { border: "solid white", borderWidth: "0 2px 2px 0",marginTop:"5px" } : {}) }}>
  </span>

                            </button>



                            {showmenu && (<ul className="dropdown-menu border-0 shadow-sm p-2 bg-color text-color-9 w-100 common-dropdown-scroll-popup" style={{display : showmenu ? "block" : "none"}} >

                              <li className="font-14 font-medium color-black">

                                <div className="input-group transparent-grp w-100 me-3 custom-border-5 bg-transparent">

                                  <input

                                    type="text"

                                    className="form-control custom-search-input bg-transprent border-0 focus-none font-14 font-medium pe-0 ps-3 text-color-2"

                                    placeholder="Search"


                                  value={filterDomain}
                                            onChange={(e) =>
                                              setFilterDomain(e.target.value)
                                            }
                                          />
                                          <button
                                            className="btn focus-none"
                                            type="button"
                                          >
                                            <img
                                              className="filter-icons"
                                              src="images-new/input-search.svg"
                                              alt="input-search-icon"
                                            />
                                          </button>
                                        </div>
                                      </li>
                                      {practiceDropDown &&
                                      practiceDropDown.length !== 0 ? (
                                        practiceDropDown.map(
                                          (practice, index) => (
                                            <li
                                              className="font-14 font-medium text-color-1"
                                              key={index}
                                            >
                                              <div className="me-4">
                                                <input className="form-check-input align-middle text-color-9 m-0 ms-2 mb-1 user-checkbox"
                                                  type="checkbox"
                                                  id={`flexCheckDefault${index}`}
                                                  value={practice}
                                                  checked={selectedPractice.includes(
                                                    practice
                                                  )}
                                                  onChange={(e) =>
                                                    handlePracticeChange(
                                                      e,
                                                      practice
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label p-2"
                                                  htmlFor={`flexCheckDefault${index}`}
                                                >
                                                  {practice}
                                                </label>
                                              </div>
                                            </li>
                                          )
                                        )
                                      ) : (
                                        <p className="text-color-9">No option</p>
                                      )}
                                    </ul>)}
                                  </div>
                                </div>
                                <span className="mb-5">
                                  <label
                                    htmlFor="from"
                                    className="form-label text-color-9 font-15 font-semibold"
                                  >
                                    Reaction
                                  </label>
                                  <select
                                    className="form-select input-field font-14 text-color-9 font-regular border-radius-3 custom-border-3 bg-color-4"
                                    value={selectedReaction} // Set the value of the select to the selectedReaction state
                                    onChange={handleReactionChange}
                                    onClick={(e)=>e.currentTarget.classList.toggle('open')}
                                  >
                                    <option selected>Select</option>
                                    {reactionDropDown &&
                                    reactionDropDown.length !== 0 ? (
                                      reactionDropDown.map(
                                        (reaction, index) => (
                                          <option value={reaction.emoji_id}>
                                            {reaction.emoji_desc}
                                          </option>
                                        )
                                      )
                                    ) : (
                                      <option>No reaction option </option>
                                    )}
                                  </select>
                                </span>
                            <span className="w-100 d-flex justify-content-end">
                              <button
                                className="btn btn-outline-secondary font-14 font-semibold px-3 me-3"
                                // onClick={()=>{handleCancel();setButtonShow(false);}}
                                onClick={() => {setShowFilter(false);setButtonShow(false);}}
                              >
                                Cancel
                              </button>
                              <button
                                className="btn primary-btn font-14 font-semibold border-radius-5 px-3 py-2 color-white "
                                onClick={()=>{handleApply();setShowFilter(false);setButtonShow(false);}}
                              >
                                Apply
                              </button>
                            </span>
                          </span>
                          </span>)}
                          {/* advanced filter ends here */}
                        </div>
                      </div>
                    </div>
                    {/*Cards*/}
                    {getFeedback.length !== 0 ? (
                      getFeedback.map((feedBackCard, index) =>(
                        
                        <div className="col-md-4 mb-4">
                          <div className="card feedback-cards border-0 rounded-3 h-100 p-4">
                            <img
                              className="custom-quotes-width mb-3"
                              src="images-new/quote.svg"
                              alt="quote"
                            />
                            <p className="font-medium font-18 text-color-1">
                              {feedBackCard.PromptName}
                            </p>
                            <p className="font-regular text-color-9 font-14 feedback-para-scroll">
                              {feedBackCard.UserFeedBack}
                            </p>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                              <div>
                                <div className="d-lg-flex d-block align-items-center">
                                  <div className="d-block me-2">
                                    {feedBackCard?.Profile_url?<img
                                      className="profile-img-style"
                                      src={profileImage(feedBackCard?.Profile_url)}
 
                                      alt="user"
                                      // style={{ width: "50px", height: "30px" }}
                                    />:<img
                                      className="profile-img-style"
                                      src='images/Default/default-profile-image.png'
                                      alt="user"
                                      // style={{ width: "50px", height: "30px" }}
                                    />}
                                  </div>
                                  <div className="d-block">
                                    <span className="font-regular d-block w-100 font-14 w-100 text-color-9">
                                      {feedBackCard.userName}
                                    </span>
                                    <span className="font-regular d-block font-12 w-100 text-color-9">
                                      {feedBackCard.Role}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="text-sm-center mt-sm-0 mt-3">
                                <span>
                                  <img
                                   src={getEmoji(feedBackCard.Emoji_Desc,feedBackCard)}
                                    alt="sad-emoji"
                                    style={{ width: "50px", height: "30px" }} // Adjust width and height as needed
                                  />
                                </span>

                                <span className="font-12 font-semibold text-color-15">
                                  {feedBackCard.Emoji_Desc}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : 
                      ( isLoad ?
                        (<div style={{textAlign:'center', flex: 1,
                          display: 'flex',
                          justifyContent: 'center', marginTop:'20%'}}>
                            <ClipLoader
                                      color={'#865BE7'}
                                      loading={isLoad}
                                      size={50}
                                    /></div>):(<span className="text-center py-5 vh-50" style={{marginTop:'15%'}}>
                                      <img
                                        src="images/Default/no-record-img.svg"
                                        alt="no-record-img"
                                        className="no-record-img mb-5"
                                      />
                                      <span className="font-20 font-medium w-100 d-block text-color-1">
                                        Sorry! No history of feedbacks.
                                      </span>
                                    </span>)
                      )}
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          </div>
          
        )
        
      }
    </>
  );
};

export default FeedBackCard;