import React, { useState, useEffect } from "react";

function LoadingIndicator() {
  const [dots, setDots] = useState("...");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots === "...") {
          return ".";
        } else if (prevDots === ".") {
          return "..";
        } else if (prevDots === "..") {
          return "...";
        }
        return "";
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    
    <div className="d-flex flex-row  mb-4 mt-5 loading-indicator-custom-padding">
      <img
        style={{ width: "60px", height: "100%" }}
        src="images\ai-images\Bot_profile.svg"
        alt="bot-happy"
        className="chat-bot"
      />
      <div
        className="p-3 ms-3 font-semibold m-0 "
        style={{
          borderRadius: "15px",
          backgroundColor: "#ffeefca3",
        }}
      >
        <p className="small mb-0">{dots}</p>
      </div>
    </div>
  );
}

export default LoadingIndicator;
