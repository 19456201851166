// PS_DB_00
// All images , hooks and functions are imported here.

// import asamiusage from "./images-new/asami-usage.svg"
// import searchIcon from ""
// import costanalysis from "./images-new/cost-analysis-chart.svg"
// import visitorsChart from "./images-new/visitors-chart.svg"
// import sadEmoji from "./"
// import neutralEmoji from "./"
// import happyEmoji from "./"
// import blackDropDown from "./images-new/black-drpdown.svg"
// import clockIcon from "./"
// import eyeIcon from "./"
import BarGraphComp from "./BarGraph"
import LineBarGraph from "./LineBarGraph"
import LineGraph from "./LineBar"
import { useState, useEffect, useRef } from "react"
import { fetchActiveUsers, fetchFixedResponseData, fetchMostOrLeastUsedPrompt, fetchAsamiUsageData, fetchCostAnalysisData, fetchFeedbackData } from "../../AI Service/Api"
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import UserContext from "../../Auth/useContext"
import { useContext } from "react";
import { fetchPractice } from "../../AI Service/Api"
import { useMsal, useAccount, useIsAuthenticated } from "@azure/msal-react";
import { useMemo } from "react"
import Loader from "../../Other-Components/loader"
import { ClipLoader } from 'react-spinners';
import { useTheme } from '@mui/material/styles';

// PS_DB_02
// The dashboard function is triggered here 
const Dashboard = ({ navOpen }) => {

  // PS_DB_03
  // required state variables are declared here 

  // top bar variables
  const [activeUsers, setActiveUsers] = useState(0)
  const [totalUsers, setTotalUsers] = useState(0)
  const [monthlyCost, setMonthlyCost] = useState(0)
  const [fixedResponsesCount, setfixedResponsesCount] = useState(0)
  const [showMultiSelect, setShowMultiSelect] = useState(false)

  // graph data variables
  const [asamiUsageProjectData, setAsamiUsageProjectData] = useState([])
  const [asamiUsagePracticeData, setAsamiUsagePracticeData] = useState([])
  const [costAnalysisPracticeData, setCostAnalysisPracticeData] = useState([])
  const [costAnalysisProjectData, setCostAnalysisProjectData] = useState([])
  const [costAnalysisIndividualData, setCostAnalysisIndividualData] = useState([])
  // const [asamiUsageYAxis,setAsamiUsageYAxis] = useState([])
  // const [costAnalysisYAxis,setCostAnalysisYAxis] = useState([])
  // const [visitorsYAxis , setVisitorsYAxis] = useState([])
  const [visitorsMonth, setVisitorsMonth] = useState([])
  const [visitorsWeek, setVisitorsWeek] = useState([])
  const [visitorsYear, setVisitorsYear] = useState([])
  const [visitorsData, setVisitorsData] = useState([])
  const [visitorsDropDown, setVisitorsDropDown] = useState("Monthly")

  // feedback data
  const [happyMeter, setHappyMeter] = useState()
  const [sadMeter, setSadMeter] = useState()
  const [neutralMeter, setNeutralMeter] = useState()

  // promptUsage section
  const [promptInfo, setPromptInfo] = useState([])
  const [mostOrLeastUsedPrompt, setMostOrLeastUsedPrompt] = useState("")
  const [promptSearchVal, setPromptSearchVal] = useState()
  const [practiceArray, setPracticeArray] = useState()
  const [tempPracticeArray, setTempPracticeArray] = useState([])

  // graph sort values
  const [asamiUsageDropDown, setAsamiUsageDropDown] = useState("")
  const [costAnalysisDropDown, setCostAnalysisDropDown] = useState("")
  const [selectedPromptsDropDown, setSelectedPromptsDropDown] = useState([])

  // fixed response data
  const [fixedResponseData, setFixedResponseData] = useState([])
  const [fixedResponseExpandData, setFixedResponseExpandData] = useState([])
  // const [popUpKeys, setPopUpKeys] = useState([])
  const [fixedExpand, setFixedExpand] = useState("")


  // Navigate variable should be declared
  const history = useHistory()

  const [asamiGraphData, setAsamiGraphData] = useState([])
  const [options, setOptions] = useState([])
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedPractice, setPractices] = useState([])
  const userData = useContext(UserContext);

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || null);
  const [fixedPromptName, setFixedPromptName] = useState("")
  // const [isLoading, setIsLoading] = useState(false)

  // const [isLoad, setIsLoad] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [isLoad, setIsLoad] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const theme = useTheme();
  const [asamiDropdownOpen, setAsamiDropdownOpen] = useState(false);
  const [costAnalysisDropdownOpen, setCostAnalysisDropdownOpen] = useState(false);
  const [visitorsDropdownOpen, setVisitorsDropdownOpen] = useState(false);
  const [mostOrLeastUsedSortDropdownOpen, setMostOrLeastUsedSortDropdownOpen] = useState(false);
  // Refs for each select element
  const asamiSelectRef = useRef(null);
  const costAnalysisSelectRef = useRef(null);
  const visitorsSelectRef = useRef(null);
  const mostOrLeastUsedSortSelectRef = useRef(null);

  // Function to handle clicks outside of the select elements
  const handleClickOutside = (event) => {
    if (asamiSelectRef.current && !asamiSelectRef.current.contains(event.target)) {
      setAsamiDropdownOpen(false);
    }
    if (costAnalysisSelectRef.current && !costAnalysisSelectRef.current.contains(event.target)) {
      setCostAnalysisDropdownOpen(false);
    }
    if (visitorsSelectRef.current && !visitorsSelectRef.current.contains(event.target)) {
      setVisitorsDropdownOpen(false);
    }
    if (mostOrLeastUsedSortSelectRef.current && !mostOrLeastUsedSortSelectRef.current.contains(event.target)) {
      setMostOrLeastUsedSortDropdownOpen(false);
    }
  };

  // Add and remove event listeners for detecting clicks outside of the dropdowns
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Function to toggle the dropdown state and class
  const toggleDropdown = (setDropdownState, dropdownState, ref) => {
    setDropdownState(!dropdownState);
    if (ref.current) {
      ref.current.classList.toggle('open');
    }
  };
  // PS_DB_06
  // Inside this dependency array, all data that is required for the hub components is loaded 

  useEffect(() => {
    getTopCards()
    getAsamiUsageData()
    getCostAnalysisData()
    getMostOrLeastUsedPrompt()
    getFeedBackData()
  }, [])
  useEffect(() => {
    setOptions(promptInfo?.map(item => ({
      value: item.practiceName,
      label: item.practiceName
    })));
  }, [tempPracticeArray])

  useEffect(() => {
    // // 
    let tempOptions = selectedOptions
    tempOptions = selectedOptions?.map((element) => element.value)
    console.log(tempOptions)
    setPractices(tempOptions.map((element) =>
      element
    ))
  }, [selectedOptions])

  // PS_DB_200
  // This useEffect will change the Visitors graph based on the dropdown value
  useEffect(() => {
    if (visitorsDropDown == "Weekly") {
      setVisitorsData(visitorsWeek)
    }
    else if (visitorsDropDown == "Monthly") {
      setVisitorsData(visitorsMonth)
    }
    else if (visitorsDropDown == "Yearly") {
      setVisitorsData(visitorsYear)
    }
  }, [visitorsDropDown])


  // PS_DB_217
  // This useEffect sets different types of data for asami usage graph based on the drop down value
  useEffect(() => {
    // 
    if (asamiUsageDropDown == "Practice") {
      setAsamiGraphData(asamiUsagePracticeData)
    }
    else if (asamiUsageDropDown == "Project") {
      console.log("entered prj")
      setAsamiGraphData(asamiUsageProjectData)
    }
  }, [asamiUsageDropDown])


  // PS_DB_225
  // This function alters the graph data based on the drop down value
  useEffect(() => {
    if (costAnalysisDropDown == "Practice") {
      setCostAnalysisGraphData(costAnalysisPracticeData)
    }
    else if (costAnalysisDropDown == "Project") {
      setCostAnalysisGraphData(costAnalysisProjectData)
    }
    else if (costAnalysisDropDown == "Individual") {
      setCostAnalysisGraphData(costAnalysisIndividualData)
    }

  }, [costAnalysisDropDown])


  useEffect(() => {
    const heartBeatInterval = 10 * 60 * 1000
    const updateActiveUsers = async () => {
      console.log("hub active users")
      try {
        let reqBody = {
          "onlineStatus": 1,
          "userEmailId": account.username,
          "timerPeriod": "month",
          "VisitorsData": false
        }
        let response = await fetchActiveUsers(reqBody)
        // setIsLoading(true)
        setActiveUsers(response.data.activerUsers)

      }
      catch (error) {
        setIsLoading(true)
        console.log("Active users error")
        setIsLoading(true)
      }
    }
    updateActiveUsers();
    const intervalId = setInterval(updateActiveUsers, heartBeatInterval)

    return () => clearInterval(intervalId)
  }, [])


  const { uniqueOptions, initialSelectedOption } = useMemo(() => {
    const uniqueSet = new Set(options?.map(option => JSON.stringify(option)));
    const uniqueOptions = Array.from(uniqueSet)?.map(item => JSON.parse(item));

    let initialSelectedOption = null;
    if (userData?.department) {
      initialSelectedOption = uniqueOptions.find(
        option => option?.value.toLowerCase() === userData?.department.toLowerCase()
      );
    }

    return { uniqueOptions, initialSelectedOption };
  }, [options, userData?.department]);
  useEffect(() => {
    if (initialSelectedOption) {
      setSelectedOptions([initialSelectedOption]);
    }
  }, [initialSelectedOption]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '38px',
      height: '38px',
      backgroundColor: 'inherit',
    }),
    valueContainer: (provided) => ({
      ...provided,
      whiteSpace: 'nowrap',
      overflow: 'auto',
      flexWrap: 'nowrap',
      minWidth: '80px',
      maxWidth: '80px',
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
    }),
    multiValue: (provided) => ({
      ...provided,
      flexShrink: 0,
      backgroundColor: 'var(--select-pill-bg, #e9ecef)',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'var(--select-pill-color, #495057)',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: 'var(--select-pill-remove-color, #495057)',
      ':hover': {
        backgroundColor: 'var(--select-pill-remove-hover-bg, #ffcccb)',
        color: 'var(--select-pill-remove-hover-color, #495057)',
      },
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transition: 'all 0.2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
      color: 'var(--select-indicator-color, #495057)',
      ':hover': {
        color: 'var(--select-indicator-hover-color, #495057)',
      },
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: 'var(--select-indicator-color, #495057)',
      ':hover': {
        color: 'var(--select-indicator-hover-color, #495057)',
      },
    }),
    input: (provided) => ({
      ...provided,
      position: 'relative',
      margin: '0',
      padding: '0',
      maxWidth: '2px',
      color: 'var(--select-input-color, currentColor)',
    }),
    placeholder: (provided) => ({
      ...provided,
      position: 'absolute',
      left: '8px',
      top: '50%',
      transform: 'translateY(-50%)',
      color: 'var(--select-placeholder-color, #6c757d)',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'var(--select-bg, #fff)',
      color: 'var(--select-color, #333)',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? 'var(--select-option-focus-bg, #f0f0f0)'
        : 'var(--select-bg, #fff)',
      color: 'var(--select-color, #333)',
      ':active': {
        backgroundColor: 'var(--select-option-active-bg, #e0e0e0)',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'var(--select-input-color, currentColor)',
    }),
  };


  const handleSelectChange = (selected) => {
    // // 
    setSelectedOptions(selected);
  };
  // PS_DB_23
  // Fetch the feedback details in the top cards
  const getFeedBackData = async () => {
    try {

      // let response = await fetchFeedbackData()
      // setIsLoading(true)
      // 
      let response = await fetchFeedbackData()
      setIsLoading(true)
      // // 
      console.log(response, "feedback response")
      setHappyMeter(parseFloat(response.feedbackDetails.happy).toFixed(2))
      setSadMeter(parseFloat(response.feedbackDetails.sad).toFixed(2))
      setNeutralMeter(parseFloat(response.feedbackDetails.neutral).toFixed(2))
    }
    catch (error) {
      setIsLoading(true)
      console.log(error)
      setIsLoading(true)
    }
  }


  // PS_DB_07
  // Fetches the details required at the top part of the hub component
  const getTopCards = async () => {
    try {
      // PS_DB_09
      // active users and total users 
      // Fetching active users and total users 
      // monthly
      // console.log("hi).log("second active users")
      let activeUserRes = await fetchActiveUsers({
        "onlineStatus": 1,
        "userEmailId": account.username,
        "timePeriod": "month",
        "VisitorsData": true
      })
      // setIsLoading(true)
      setVisitorsMonth(activeUserRes?.data?.totalMonthlyUsers)
      setVisitorsWeek(activeUserRes?.data?.totalWeeklyUsers)
      setVisitorsYear(activeUserRes?.data?.totalYearlyUsers)
      setVisitorsData(activeUserRes?.data?.totalMonthlyUsers)
      setActiveUsers(activeUserRes?.data?.activerUsers)
      setTotalUsers(activeUserRes?.data?.overallUsers)
      // PS_DB_38
      // To get the data for fixed response section which is at the bottom of the dash board
      let fixedResResponse = await fetchFixedResponseData({
        "expanded": "False",
        "promptId": ""
      })
      setIsLoading(true)
      // 7FE2C6DD-5FF3-4F9D-9840-D971C4B5B4B1
      console.log(fixedResResponse?.data, "ahousfhoauish")
      setfixedResponsesCount(fixedResResponse?.data?.totalFixedResponses)
      setFixedResponseData(fixedResResponse?.data?.mainOutput)
    }
    catch (error) {
      setIsLoading(true)
      console.log(error)
      setIsLoading(true)
    }

  }

  // PS_DB_142
  // This function gets the most used prompts from all practices and displays them
  const getMostOrLeastUsedPrompt = async () => {
    // // 
    try {
      let reqBody = {
        "practices": "",
        "sort": "Desc"
      }
      // PS_DB_145
      // This function fetches the prompt info from backend
      let response = await fetchMostOrLeastUsedPrompt(reqBody)
      // setIsLoading(true)
      setPromptInfo(response?.data?.mainOutput)
      console.log(promptInfo, "promptInfo")

      const practiceNames = response?.data?.mainOutput?.map(ele => ele.practiceName);

      const uniquePracticeNames = practiceNames.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      setPracticeArray(uniquePracticeNames);
      setTempPracticeArray(uniquePracticeNames)



    }
    catch (error) {
      setIsLoading(true)
      console.log(error)
      setIsLoading(true)
    }


  }

  // PS_DB_91
  // The data required for cost graph is displayed over here for all types : practice , project and individual
  const [costAnalysisGraphData, setCostAnalysisGraphData] = useState([])
  const getCostAnalysisData = async () => {
    try {
      let reqBody = {
        "pageValue": "Practice",
        "sortOption": "Practice",
        "sortValue": "DESC",
        "offsetValue": 1,
        "searchValue": "",
        "filterValue": ""
      }
      // PS_DB_95
      // The function which hits the cost analysis api and gets the called
      let response = await fetchCostAnalysisData(reqBody)
      //setIsLoading(true)
      console.log(response, "costanalysisdata");
      setCostAnalysisPracticeData(response?.data?.data1)
      setCostAnalysisGraphData(response?.data?.data1)
      setMonthlyCost(parseFloat(response?.data?.totalCost).toFixed(2))

      reqBody = {
        "pageValue": "Project",
        "sortOption": "Project",
        "sortValue": "DESC",
        "offsetValue": 1,
        "searchValue": "",
        "filterValue": ""
      }

      // PS_DB_102
      // The function which hits the cost analysis api and gets the called
      response = await fetchCostAnalysisData(reqBody)
      setCostAnalysisProjectData(response?.data?.data1)


      reqBody = {
        "pageValue": "Individual",
        "sortOption": "Individual",
        "sortValue": "DESC",
        "offsetValue": 1,
        "searchValue": "",
        "filterValue": ""
      }

      // PS_DB_112
      // The function which hits the cost analysis api and gets the called
      response = await fetchCostAnalysisData(reqBody)
      setCostAnalysisIndividualData(response?.data?.data1)
    }
    catch (error) {
      setIsLoading(true)
      console.log(error)
      setIsLoading(true)
    }

  }
  console.log(costAnalysisGraphData, "costanalysisgraphdata")
  // PS_DB_56
  // Function to get asami usage graph data
  const getAsamiUsageData = async () => {
    try {
      let reqBody = {
        "TypeofData": "Practice",
        "sort": "",
        "Search": "",
        "offsetValue": 1
      }

      // PS_DB_60
      // The function which hits the api and gets the graph data is called here (Practice data)
      let asamiUsageResponse = await fetchAsamiUsageData(reqBody)
      //setIsLoading(true)
      setAsamiUsagePracticeData(asamiUsageResponse?.data?.ResponseData)
      setAsamiGraphData(asamiUsageResponse?.data?.ResponseData)


      reqBody = {
        "TypeofData": "Project",
        "sort": "",
        "Search": "",
        "offsetValue": 1
      }
      // PS_DB_77
      // The function which hits the api and gets the graph data is called here (Project data)
      asamiUsageResponse = await fetchAsamiUsageData(reqBody)
      setAsamiUsageProjectData(asamiUsageResponse?.data?.ResponseData)

    }
    catch (error) {
      setIsLoading(true)
      console.log(error)
      setIsLoading(true)
    }

  }

  console.log(asamiUsagePracticeData, "asamiUsagePracticeData")
  console.log(asamiUsageProjectData, "asamiUsageProjectData")

  // PS_DB_209
  // This function handles the multiselect scenario in prompt usage information section
  const handleMultiSelect = (e, practice) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setPractices(prevSelectedPractice => [...prevSelectedPractice, practice]);
    } else {
      setPractices(prevSelectedPractice =>
        prevSelectedPractice.filter(item => item !== practice)
      );
    }
  };


  console.log(selectedPractice, "selected practice")


  const [isReversed, setIsReversed] = useState(false);

  // PS_DB_239
  // The promptInfo array is reversed 
  const reverseArray = () => {
    promptInfo.reverse()
    setIsReversed(true);
  };


  // PS_DB_235
  // This function to sorts and unsorts the promptUsage table based on the drop down value
  const handleMostOrLeastUsedSort = (e) => {
    if (e == "Most Used Prompt") {
      unReverseArray()
    }
    else if (e == "Less Used Prompt") {
      reverseArray()
    }
  }



  // PS_DB_236
  // This function un-reverses the promptInfo array
  const unReverseArray = () => {
    promptInfo.reverse()
    setIsReversed(false);
  };

  // PS_DB_173
  // This function hits the API for fetching data when the user clicks on the eye icon at the fixed response section
  const handleFixedResExpand = async (promptId) => {
    try {
      // PS_DB_176
      // The function which does the API call to fetch the data onclicking the eye icon is called here
      setIsLoad(true)
      let fixedResResponse = await fetchFixedResponseData({
        "expanded": "True",
        "promptId": promptId
      })

      // setIsLoad(true)
      setFixedResponseExpandData(fixedResResponse?.data?.popUpOutput)
      console.log(fixedResponseExpandData, "oeuahfoahfoia")

    }
    catch (error) {
      // setIsLoad(true)
      console.log(error)
    }
    finally {
      setIsLoad(false)
    }

  }








  // PS_DB_242
  // This function navigates the user to costAnalysisTable component
  const handleNavToCostTable = () => {
    history.push("/costanalysistable")
  }

  // PS_DB_245
  // This function navigates the user to costAnalysisTable component
  const navToAsamiUsageTable = () => {
    history.push("/asamiusagetable")
    // history.push({
    //   pathname: "/AsamiUsageTable",
    //   state: {
    //     PracticeData: asamiUsagePracticeData,
    //     ProjectData: asamiUsageProjectData
    //   }
    // });
  }

  useEffect(() => {
    console.log("printing navopen", navOpen)
  }, [navOpen])


  return (
    <div >
      {!isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <div className={navOpen === "Close" ? "container-fluid second-component-hub-nav-close" : "container-fluid second-component-hub"}>
          <div className="row ">
            <div className="col-md-12 ">
              <div className="p-3 mt-3 mb-3 border-radius-8 ">
                <h1 className="font-bold font-20 mb-4 text-color-1">Hub</h1>
                <div className="row">
                  <div className="col-xl-8 col-lg-12 col-sm-12 col-md-12 mb-2 mt-0 px-0">
                    <div className="h-100 border-0 px-2">
                      <div className="row">
                        <div className="col-md-3 mb-3">
                          <div className="custom-dashboard-section p-3">
                            <p className="font-medium font-16 mb-2 dark-black-color  text-color-1" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Total Users</p>
                            <p className="font-bold font-18 primary-color mb-0">{totalUsers}</p>
                          </div>
                        </div>
                        <div className="col-md-3 mb-3">
                          <div className="custom-dashboard-section p-3">
                            <p className="font-medium font-16 mb-2 dark-black-color text-color-1" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Active Users</p>
                            <p className="font-bold font-18 primary-color mb-0">{activeUsers}</p>
                          </div>
                        </div>
                        <div className="col-md-3 mb-3">
                          <div className="custom-dashboard-section p-3">
                            <p className="font-medium font-16 mb-2 dark-black-color  text-color-1" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Monthly Cost</p>
                            <p className="font-bold font-18 primary-color mb-0">${monthlyCost}</p>
                          </div>
                        </div>
                        <div className="col-md-3 mb-3">
                          <div className="custom-dashboard-section p-3">
                            <p className="font-medium font-16 mb-2 dark-black-color text-color-1" style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>Number of Fixes</p>
                            <p className="font-bold font-18 primary-color mb-0">{fixedResponsesCount}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-12 mb-3">
                          <div className="card h-100 custom-dashboard-section p-3" style={{ minHeight: '100%' }}>
                            <div className="d-md-flex d-block justify-content-between align-items-center mb-3">
                              <h3 className="font-semibold font-16 text-color-1">Asami Usage (Based On Chats)</h3>
                              <div className="align-items-center d-md-flex d-block">
                                <div className="align-items-center d-md-flex d-block">
                                  <div className="d-flex mb-md-0 mb-3">
                                    <select
                                        ref={asamiSelectRef}
                                        className={`form-select dashboard-select bg-color-4 text-color-1 w-auto w-md-100 custom-form py-2 font-12 me-0 me-md-3 font-regular order-2 order-md-0 theme-focus ${asamiDropdownOpen ? 'open' : ''}`}
                                        onClick={(e) =>{setAsamiUsageDropDown(e.target.value); toggleDropdown(setAsamiDropdownOpen, asamiDropdownOpen, asamiSelectRef)}}
                                        onBlur={() => setAsamiDropdownOpen(false)}
                                        defaultValue="Practice"
                                      >
                                        <option value="Practice">Practice</option>
                                        <option value="Project">Project</option>
                                      </select>
                                  </div>
                                  <a
                                    className="text-decoration-none link-color font-regular font-13 cursor-pointer"
                                    onClick={() => navToAsamiUsageTable()}
                                  >
                                    See All
                                  </a>
                                </div> 
                              </div>
                            </div>
                            <div className=" asami-usage-edit">
                              <div>
                                {/* Asami usage graph  */}
                                <BarGraphComp AsamiUsageData={asamiGraphData} themeMode={theme.palette.mode} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-12">
                          <div className="card h-100 custom-dashboard-section p-3" style={{ minHeight: "100%" }}>
                            <div className="d-md-flex d-block justify-content-between align-items-center mb-3">
                              <h3 className="font-semibold font-16 text-color-1">Cost Analysis</h3>
                              <div className="align-items-center d-md-flex d-block">
                                <div className="d-flex mb-md-0 mb-3">
                                <select
                                    ref={costAnalysisSelectRef}
                                    className={`form-select dashboard-select bg-color-4 text-color-1 w-auto w-md-100 custom-form py-2 font-12 me-0 me-md-3 font-regular order-2 order-md-0 theme-focus ${costAnalysisDropdownOpen ? 'open' : ''}`}
                                    onClick={(e) =>{setCostAnalysisDropDown(e.target.value); toggleDropdown(setCostAnalysisDropdownOpen, costAnalysisDropdownOpen, costAnalysisSelectRef)}}
                                    onBlur={() => setCostAnalysisDropdownOpen(false)}
                                    defaultValue="Practice"
                                  >
                                    <option value="Practice">Practice</option>
                                    <option value="Project">Project</option>
                                    <option value="Individual">Individual</option>
                                  </select>      
                                </div>
                                <a href className="text-decoration-none link-color font-regular font-13 cursor-pointer" onClick={() => handleNavToCostTable()}>See All</a>
                              </div>
                            </div>
                            <div className="custom-scroll cust-dashboard-widget-scroll">
                              <div className="asami-usage-scroll ">
                                <LineBarGraph costGraph={costAnalysisGraphData} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-12 col-sm-12 col-md-12 mb-2">
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <div className="p-3 custom-dashboard-section card h-100 ">
                          <p className="font-semibold font-16 text-color-1">Feedback</p>
                          <div className="row">
                            <div className="col-sm-12 col-md-4">
                              <div className="d-flex align-items-center justify-content-between mb-3 mb-md-0 flex-column col-md-0">
                                <span><img src="images-new/happy-emoji.svg" alt="Happy" className="mb-2" /></span>
                                <div><span className="font-regular font-14 color-grey me-3 emoji-status">Happy</span><span className="font-bold font-14 emoji-percentage text-color-1">{happyMeter}%</span></div>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                              <div className="d-flex align-items-center justify-content-between mb-3 mb-md-0 flex-column col-md-0">
                                <span><img src="images-new/neutral-emoji.svg" alt="neutral" className="feedback-img mb-2" /></span>
                                <div>
                                  <span className="font-regular font-14 color-grey me-3 emoji-status">Neutral</span><span className="font-bold font-14 emoji-percentage text-color-1">{neutralMeter}%</span></div>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                              <div className="d-flex align-items-center justify-content-between mb-3 mb-md-0 flex-column col-md-0">
                                <span><img src="images-new/sad-emoji.svg" alt="Sad" className="feedback-img mb-2" /></span>
                                <div>
                                  <span className="font-regular font-14 color-grey me-3 emoji-status">Sad</span><span className="font-bold font-14 emoji-percentage text-color-1">{sadMeter}%</span></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="p-3 custom-dashboard-section card h-100 ">
                          <div className="d-md-flex d-block justify-content-between align-items-center mb-3">
                            <h3 className="font-semibold font-16 text-color-1">Visitors</h3>
                            <div className="align-items-center d-md-flex d-block">
                              <div className="d-flex mb-md-0 mb-3">
                              <select
                                  ref={visitorsSelectRef}
                                  className={`form-select dashboard-select bg-color-4 text-color-1 w-auto w-md-100 custom-form py-2 font-12 me-0 me-md-3 font-regular order-2 order-md-0 theme-focus ${visitorsDropdownOpen ? 'open' : ''}`}
                                  onClick={(e) =>{setVisitorsDropDown(e.target.value); toggleDropdown(setVisitorsDropdownOpen, visitorsDropdownOpen, visitorsSelectRef)}}
                                  onBlur={() => setVisitorsDropdownOpen(false)}
                                >
                                  <option value="Monthly">Monthly</option>
                                  <option value="Weekly">Weekly</option>
                                  <option value="Yearly">Yearly</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="cust-visitor-widget-scroll visitor-height">
                            <LineGraph graphData={visitorsData} timePeriod={visitorsDropDown} />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="custom-dashboard-section card h-100 " style={navOpen === "Close" ? { minHeight: '105%' } : { minHeight: '115%' }}>
                          <div className="p-3 py-2 d-md-flex d-block justify-content-between align-items-center">
                            <div className="dropdown">
                              {/* <button id="model-type" className="btn input-field font-14 font-regular border-radius-3 bg-white w-100 d-flex justify-content-between" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              <span className="dark-black-color font-semibold font-16">Most Used Prompt</span>
                              <span className="ps-2"><img src={blackDropDown} alt="dropdown-icon" /></span>
                            </button> */}
                              <select
                                ref={mostOrLeastUsedSortSelectRef}
                                className={`border-0 form-select p-2 w-100 bg-color-2 text-color-1 prompt-select custom-select small-text theme-focus ${mostOrLeastUsedSortDropdownOpen ? 'open' : ''}`}
                                onChange={(e) => handleMostOrLeastUsedSort(e.target.value)}
                                onClick={() => toggleDropdown(setMostOrLeastUsedSortDropdownOpen, mostOrLeastUsedSortDropdownOpen, mostOrLeastUsedSortSelectRef)}
                                onBlur={() => setMostOrLeastUsedSortDropdownOpen(false)}
                                style={{marginRight:'25px'}}
                              >
                                <option className="font-14 font-medium text-color-1 font-semibold font-16 select-text-limit" value="Most Used Prompt">Most Used Prompt</option>
                                <option className="font-14 font-medium text-color-1 font-semibold font-16 select-text-limit" value="Less Used Prompt">Less Used Prompt</option>
                              </select>
                            </div>
                            <div className="align-items-center d-md-flex d-block theme-focus">
                              <div className="d-flex mb-md-0 mb-3 search-fix-hub-new theme-focus">
                                <span className="row ">
                                  <span className="mb-4 d-block col-md-12">
                                    <span className="mb-5">
                                      <label htmlFor="from" className="form-label label-color font-15 font-semibold"></label>
                                      <Select

                                        isMulti

                                        name="projects"
                                        options={uniqueOptions}
                                        classNamePrefix="select "
                                        onChange={handleSelectChange}
                                        value={selectedOptions}
                                        styles={customStyles}
                                        title={selectedOptions.map(option => option.label).join(', ')}
                                      />

                                    </span>
                                  </span>
                                </span>
                                {/* <select className="form-select dashboard-select w-65 custom-form py-2 font-12 font-regular order-2 order-md-0">
                                <option>All</option>
                                <option>Weekly</option>
                                <option>Yearly</option>
                              </select> */}
                                {/* <div className="input-group transparent-grp w-225 me-3 filter-dropdown-border search-fix-hub" >
                                <input
                                  type="text"
                                  className="form-control custom-search-input bg-transprent border-0 focus-none font-14 font-medium pe-0 ps-3 "
                                  placeholder="Search"
                                  value={promptSearchVal}
                                  onChange={(e) => {
                                    setPromptSearchVal(e.target.value);
                                    (e.target.value)?.length <= 0 ? setTempPracticeArray(practiceArray) : setTempPracticeArray(practiceArray.filter(item => item.toLowerCase().startsWith(e.target.value.toLowerCase())));;
                                    (e.target.value)?.length <= 0 ? setShowMultiSelect(false) : setShowMultiSelect(true);

                                  }}
                                />
                                <button
                                  className="btn focus-none"
                                  type="button"
                                  onClick={(e) => setShowMultiSelect(false)}>
                                  <img
                                    src='images-new/input-search.svg'
                                    alt="input-search-icon"
                                  />
                                </button>
                              </div> */}
                                {/* <ul className="dropdown border-0 shadow-sm p-2 w-100 " style={{ "listStyle": "none", "display": showMultiSelect ? "block" : "none" }} >
                                {/* <li className="font-14 font-medium color-black">
                                  <div className="input-group transparent-grp w-225 me-3 filter-dropdown-border">
                                    <input
                                      type="text"
                                      className="form-control custom-search-input bg-transprent border-0 focus-none font-14 font-medium pe-0 ps-3 "
                                      placeholder="Search"
                                      value={promptSearchVal}
                                      onChange={(e) => setPromptSearchVal(e.target.value)}
                                    />
                                    <button
                                      className="btn focus-none"
                                      type="button"
                                    >
                                      <img
                                        src='images-new/input-search.svg'
                                        alt="input-search-icon"
                                      />
                                    </button>
                                  </div>
                                </li> }
                                {promptInfo?.length !== 0 ? (
                                  practiceArray?.map((practice, index) => (
                                    tempPracticeArray.includes(practice) && (
                                      <li className="font-14 font-medium color-black" key={index}>
                                        <div className="me-4">
                                          <input
                                            className="form-check-input align-middle m-0 ms-2 mb-1 user-checkbox"
                                            type="checkbox"
                                            id={`practiceCheckbox_${index}`}
                                            value={practice}
                                            checked={selectedPractice.includes(practice)} // Check if the practice is included in selectedPractice
                                            onChange={(e) => handleMultiSelect(e, practice)} // Handle onChange event
                                          />
                                          <label
                                            className="form-check-label p-2"
                                            htmlFor={`practiceCheckbox_${index}`}
                                          >
                                            {practice}
                                          </label>
                                        </div>
                                      </li>
                                    )
                                  ))
                                ) : (
                                  <p>No option</p>
                                )}
                              </ul> */}
                              </div>
                            </div>
                          </div>
                          <div className="table-responsive dashboard-table-scroll" style={navOpen === "Close" ? { minHeight: '68%' } : { minHeight: '70%' }}>
                            <table className="table table-borderless dashboard-table mb-0">
                              <thead>
                                <tr>
                                  <th>Prompt Name</th>
                                  <th>Practice</th>
                                  <th>Count</th>
                                </tr>
                              </thead>
                              <tbody>
                                {promptInfo?.map((ele, index) => {
                                  if (selectedPractice?.length == 0) {
                                    return (
                                      <>
                                        <tr>
                                          <td className="text-nowrap" > <span className="text-prompt-width text-truncate d-inline-block" title={ele.promptName}> {ele.promptName}</span></td>
                                          <td className="text-nowrap">{ele.practiceName}</td>
                                          <td className="text-nowrap">{ele.count}</td>
                                        </tr>
                                      </>
                                    )
                                  }
                                  else if (selectedPractice.length > 0) {
                                    if (selectedPractice.includes(ele.practiceName)) {
                                      // 
                                      console.log(ele.promptName, "entered filtered prompt")
                                      return (
                                        <>
                                          <tr>
                                            <td className="text-nowrap" > <span className="text-prompt-width text-truncate d-inline-block" title={ele.promptName}>{ele.promptName}</span></td>
                                            <td className="text-nowrap">{ele.practiceName}</td>
                                            <td className="text-nowrap">{ele.count}</td>
                                          </tr>
                                        </>
                                      )
                                    }
                                  }
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="card h-100 custom-dashboard-section p-3 fixed-reponse">
                      <h3 className="font-semibold font-16 text-color-1">Fixed Response</h3>
                      <p className="mb-0 color-grey-v5 font-semibold font-14 text-color-7 mt-3">Prompt</p>
                      <div className="cust-prompt-scroll" style={{ maxHeight: '300px', overflowY: 'auto' }}>


                        <ul className="list-unstyled">
                          {fixedResponseData?.map((ele, index) => {
                            return (
                              <>
                                <li className="d-flex justify-content-between align-items-center custom-border-bottom-2 p-3 li-width">
                                  <p href className="font-medium font-14 text-color-1 mb-0 text-decoration-none text-nowrap " >
                                    {ele.promptName}
                                  </p>
                                  <div className="d-flex align-items-center">
                                    <span className="cust-prompt-badge d-flex align-items-center p-2 me-5 text-nowrap">
                                      <img src="images-new/clock-icon.svg" className="me-2" />
                                      <span className="font-10 font-medium text-color-1"> Number of time Regenerated: </span>
                                      <span className="font-bold font-12 primary-color ms-1"> {ele.promptCount}</span>
                                    </span>
                                    <span className><a href data-bs-toggle="modal" data-bs-target="#Helpus-popup" onClick={() => { setFixedPromptName(ele.promptName); handleFixedResExpand(ele.promptId); setFixedExpand("1") }}><img src="images-new/eye-img.svg" /></a></span>
                                  </div>
                                </li>
                              </>
                            )
                          })
                          }
                          <div className='modal fade' id="Helpus-popup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered fixed-response-popup-width border-0">
                              <div class="modal-content p-3 border-0 bg-color" style={{ height: '634px' }}>
                                <div class="modal-header border-0 pt-2 pb-0 mb-3">
                                  <h5 class="modal-title text-color-1 font-22 font-bold">Fixed Response</h5>
                                  <button type="button" class="btn-close close-icon-filter" data-bs-dismiss="modal" aria-label="Close" onClick={() => setFixedResponseExpandData([])}></button>
                                </div>

                                <div class="modal-body popup-scrl pt-2">
                                  <div>
                                    {/* <h1>{fixedResponseExpandData}</h1> */}

                                    <div><p class="font-semibold font-20 text-color-4">{fixedPromptName}</p>
                                      {isLoad ? (<div style={{
                                        textAlign: 'center', flex: 1,
                                        display: 'flex',
                                        justifyContent: 'center', marginTop: '20%'
                                      }}>
                                        <ClipLoader
                                          color={'#865BE7'}
                                          loading={isLoad}
                                          size={50}
                                        /></div>) :
                                        (<ul class="list-unstyled fixed-response-popup-scroll">
                                          {
                                            fixedResponseExpandData?.map((ele, index) => {
                                              return (
                                                <>
                                                  <li class="cust-response-list shadow-sm p-3 mb-3">
                                                  <p class="font-semibold font-14 text-clor-1 mb-2" style={{display:'inline-block'}}>Reason</p>
                                                  <p class="font-semibold font-14 text-clor-1 mb-2 fixes_fixed_res">Fixes: {ele.total_case}</p>
                                                    <p class="font-regular font-14 text-color-6">{ele.responses}</p>
                                                    <div class="d-flex justify-content-between align-items-center">
                                                      <div>
                                                        <img src={ele?.photo ? `data:image/jpeg;base64,${ele?.photo}` : "images/Default/default-profile-image.png"} alt="Profile" class="profile-img-sm me-2" />
                                                        <span class="font-semibold font-16 text-color-9">{ele.userName}{/*,{ele.practice}*/}</span>
                                                        {console.log(fixedResponseExpandData, "fixedResponseExpandData")}
                                                      </div>
                                                      <a href={`/chat?chatID=${ele.id}`} target="_blank" class="text-decoration-none link-color font-regular font-13"
                                                        rel="noopener noreferrer"
                                                        onClick={(e) => {
                                                          e.preventDefault();

                                                          localStorage.setItem("ViewChat", "True");

                                                          window.open(`/chat?chatID=${ele.id}`, '_blank');
                                                        }} >View Chat</a>
                                                      {/* <a href="" target="_blank" class="text-decoration-none link-color font-regular font-13" onClick={()=>{
                                                  window.location.assign(`/chat?chatID=${ele.id.toLowerCase()}&share=true`);
                                                }}>View Chat</a> */}
                                                    </div>
                                                  </li>
                                                </>
                                              )
                                            })
                                          }

                                        </ul>)}</div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </ul>


                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>)}
    </div>
  );
}


export default Dashboard