import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

import { msalConfig } from "./Auth/authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

(function() {
  function removeErrorPage() {
      const errorPage = document.querySelector('html > body > iframe[style*="position: fixed"]');
      if (errorPage) {
          errorPage.remove();
          document.body.style.overflow = 'auto';
      }
  }

  // Run immediately
  removeErrorPage();

  // Set up a MutationObserver to watch for the error page being added
  const observer = new MutationObserver((mutations) => {
      for (let mutation of mutations) {
          if (mutation.type === 'childList') {
              removeErrorPage();
          }
      }
  });

  // Start observing the document body for changes
  observer.observe(document.body, { childList: true, subtree: true });
})();


(function() {
    function removeErrorPage() {
        const errorPage = document.querySelector('html > body > iframe[style*="position: fixed"]');
        if (errorPage) {
            errorPage.remove();
            document.body.style.overflow = 'auto';
        }
    }

    // Run immediately
    removeErrorPage();

    // Set up a MutationObserver to watch for the error page being added
    const observer = new MutationObserver((mutations) => {
        for (let mutation of mutations) {
            if (mutation.type === 'childList') {
                removeErrorPage();
            }
        }
    });

    // Start observing the document body for changes
    observer.observe(document.body, { childList: true, subtree: true });
})();

ReactDOM.render(
  <React.StrictMode>
  <DndProvider backend={HTML5Backend}>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </DndProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
