import { useEffect, useState } from "react"
import { getPractice, fetchPromptHistory, restoreManagePrompt, getPromptHistory } from "../AI Service/Api";
import Loader from "../Other-Components/loader";
import { useHistory, useLocation } from "react-router-dom";
import UserContext from "../Auth/useContext";
import { useContext } from "react";
function ManagePromptCards({ promptCards, setShowPage, setPracticeID, setShowPopup, setPromptIndex, setPopUpText, handleCreatePrompt, setHandlePromptHistory, setPracticeName }) {



  const [promptCardData, setPromptCardData] = useState([]);
  const [practiceDetails, setPracticeDetails] = useState([])
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [accordian, setAccordian] = useState(true)
  const [arr, setArr] = useState([])
  const [IsAscendingPromptName, setIsAscendingPromptName] = useState(true)
  const [sortname, setsortname] = useState("promptName")
  const [sortvalue, setsortvalue] = useState("ASC")
  const [promptSort, setPromptSort] = useState(true)
  const [modifiedBy, setModifiedBy] = useState(false)
  const [timeStamp, setTimeStamp] = useState(false)
  const userDetails = useContext(UserContext);

  // const [practiceID,setPracticeID]=useState("")
  //(promptCardData,"fish11")
  // const path = "/edit-prompt"

  const history1 = useHistory();
  function edit() {
    history1.push("/asami-edit-prompt")
  }
  function handleCreate() {
    history1.push("/asami-create-prompt")
  }
  useEffect(() => {
    //   ;

    fetchPractice()
    setPromptCardData(promptCards)
  }, [])


  //(search,"hjkl;");
  const [promptid, setpromptid] = useState("")
  const check = async (promptVal) => {
    try {
      //
      setpromptid(promptVal)
      const data = await fetchPromptHistory({ "promptId": promptVal, "editedPromptID": promptVal, 'sortOption': sortname, 'sortValue': sortvalue })
      setArr(data.data)
      //(data.data,"mmmmmmmmmm")  
    }
    catch (Error) {
      //(Error)
    }
  }
  const promptHistory = async (promptID) => {
    try {
      //
      const data = await getPromptHistory({ "promptId": promptID, "editedPromptID": "", 'sortOption': "promptName", 'sortValue': "DESC" })
      //(data.ResponseData.prompts,"praaaaaaaaaaaaaaaaaaadeeeeeeeeeeeeeeeeeeeep")  
      setPromptIndex(data.ResponseData.prompts[0]); setShowPage("showManagePrompt"); setHandlePromptHistory(false);
    }
    catch (Error) {
      //(Error)
    }
  }
  const retsore = async (promptVal, editedPromptVal) => {
    //
    try {
      setIsLoading(false);
      const obj = { "editedPromptID": promptVal, "promptID": editedPromptVal }
      //(obj,"eeeeeeeeeeeeeee")  
      const result = await restoreManagePrompt(obj)
      window.location.reload()
      setIsLoading(true)

    }
    catch (Error) {
      //(Error)
    }

  }
  useEffect(() => {
    check(promptid);
  }, [sortvalue, promptSort, timeStamp, modifiedBy])
  const sortPromptName = async () => {
    // 
    if (promptSort) { setsortname("promptName"); setsortvalue("DESC"); setPromptSort(false); } else { setsortname("promptName"); setsortvalue("ASC"); setPromptSort(true); }
    // console.log(sortvalue);
  }


  const fetchPractice = async () => {
    //
    try {
      let obj = {
        search: search
      }
      //(obj,"fghj");
      setIsLoading(false);
      const response = await getPractice(obj);
      setIsLoading(true);
      //(response,"OOOOOOOOO");
      setPracticeDetails(response.data.PracticeDetails)
    } catch (error) {
      //(error);
    }
  };

  //(search,"LLLLLLLLLLLL");

  //(practiceDetails, "dfghjkl");

  function bindPractice(practiceDetails) {
    // Check if practiceDetails is not undefined
    if (practiceDetails !== undefined) {
      //(practiceDetails, "Practice Details Log");

      // Map over each practiceDetail to render its corresponding UI
      return practiceDetails.map((practice) => {
        //(practice.practiceName, "Practice Name Log");

        // Filter promptCards for the current practice based on matching practiceID
        //(promptCardData.filter(promptCard => promptCard.practiceID),"LK")
        const filteredPromptCards = promptCardData.filter(promptCard => {

          //(practice.practiceID,"hellothere" ,"Helloawaw",promptCard.practiceID);
          //(promptCard.practiceID === practice.practiceID,"heehaw");
          return (promptCard.practiceID.toUpperCase() === practice.practiceID.toUpperCase())
        });
        //(practice,"hellothere" ,"Helloawaw",promptCardData)
        //(filteredPromptCards,"animal1")
        const length = filteredPromptCards.length
        //(filteredPromptCards,"####")
        //(length,"PPP")

        return (
          // <React.Fragment key={practice.practiceID}>
          <div className="font-20 custom-acc-border border-radius-11 font-bold mt-1">
            <p className="d-flex justify-content-between color-white custom-accordion mb-0 p-2 py-3 align-items-center">
              <button className={`btn custom-accordian focus-none me-3 d-inline-flex w-75 align-items-center focus-none collapse ${accordian == true ? "collapsed" : null}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${practice.practiceID}`}>
                <img className="collapse-accod me-3" src="images/Default/acc-right-arrow.svg" alt="arrow" />
                <span className="cursor-pointer pt-1 text-color-9 font-14 font-medium">{practice.practiceName}</span>
              </button>
              <span className="font-medium text-color-9 font-14 font-regular me-4 text-nowrap">
                <span className="font-bold text-color-9 font-14">#{practice.practiceName}:</span> {length}
              </span>
            </p>
            <div className="row">
              <div className="collapse" id={`collapse${practice.practiceID}`}>
                <div className="custom-accordian-body color-grey-bg py-4 mx-4">
                  <div className="d-flex justify-content-end">
                    <button type="button" className="btn primary-btn font-14 font-semibold border-radius-5 color-white mb-4 py-2" onClick={() => {
                      setShowPage("showManagePrompt");
                      setPracticeID(practice.practiceID)
                      setPracticeName(practice.practiceName)
                      handleCreatePrompt()
                      handleCreate()
                    }} >
                      <img src="images\Default\add-white-icon.svg" alt="Add" className="me-2" onClick={() => {
                        setShowPage("showManagePrompt");
                        setPracticeID(practice.practiceID)
                        setPracticeName(practice.practiceName)
                        handleCreatePrompt()
                      }} />Prompt</button>
                  </div>
                  <div className="row row-cols-1 row-cols-md-3 g-4">
                    {filteredPromptCards.length !== 0 ? filteredPromptCards.map((promptCard, index) => (
                      //  {promptCards.length != 0 ? promptCards?.map((value, index) => {

                      <div className="col-md-4 col-sm-12">
                        <div className="card h-100 shadow-sm custom-card">
                          <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                              <h5 className="font-18 font-semibold text-color-1 mb-0">{promptCard.promptName}</h5>
                              {
                                promptCard.draft === true ? <span className="batch-sty batch-green font-12 font-semibold d-flex align-items-center">
                                  <span className="pointer-green me-2" /> Live
                                </span> :
                                  <span className="batch-sty batch-orange font-12 font-semibold d-flex align-items-center">
                                    <span className="pointer-orange me-2" /> Drafted
                                  </span>
                              }
                            </div>
                            <p className="font-12 text-color-12 font-medium">{promptCard.promptDescription}</p>
                          </div>
                          {/*   */}
                          <div className="custom-border-top py-3 mx-3 justify-content-between d-flex">
                            <button type="button" className="btn font-14 font-medium primary-color custom-primary-hov shadow-none p-0 d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#Helpus-popup" onClick={(e)=>{setTimeStamp(false);setModifiedBy(false);setPromptSort(true);check(promptCard.promptID)}}>
                              <img src="images-new/activity-icon.svg" alt="activity-icon" className="me-2 pb-1" />
                              Activity
                            </button>
                            <div className="d-flex">

                              <button type="button" className="btn me-3 font-14 font-medium custom-blue-hov color-blue shadow-none p-0 d-flex align-items-center" id={index} onClick={(e) => { setPromptIndex(promptCard);  setShowPage("showManagePrompt"); setPracticeName(practice.practiceName); edit() }} >
                                <img src="images-new/edit-icons.svg" alt="edit-icon" className="me-2" />
                                Edit
                              </button>
                              {userDetails.email === promptCard.createdBy && (
                                <button
                                  type="button"
                                  className="btn me-3 font-14 font-medium custom-red-hov color-red shadow-none p-0 d-flex align-items-center"
                                  id={index}
                                  onClick={(e) => {
                                    setShowPopup(true);
                                    setPromptIndex(promptCard.promptID);
                                    setPopUpText(`delete ${promptCard.promptName}`);
                                  }}>
                                  <img src="images-new/delete-icons.svg" alt="delete-icon" className="card-icon me-2" />
                                  Delete
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )) : (
                      <div className="text-center d-flex align-items-center justify-content-center vh-75 w-100" style={{ height: "20%", width: "20%" }}>
                        <div className="w-100">
                          <img src="images/Default/no-record-img.svg" alt="no-record-img" className="no-record-img mb-5" />
                          <p className="font-20 font-medium text-color-1">Sorry! You don’t have any prompts created.
                            <span className="d-block mt-2 text-color-1"> Click on <span className="theme-color cursor-pointer" onClick={() => {

                              setShowPage("showManagePrompt");
                              setPracticeID(practice.practiceID);
                              setPracticeName(practice.practiceName)
                              handleCreatePrompt();

                            }}>“Create New Prompt”</span> to add a new prompt.</span></p>
                        </div>
                      </div>
                    )}
                  </div>




                </div>
              </div>
            </div>
          </div>
          // </React.Fragment>
        );
      });
    } else {
      // Return null if practiceDetails is undefined
      return null;
    }
  }










  return (
    <>
      {!isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        // <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="d-flex justify-content-between mt-4 mb-3 align-items-center manage-prompts-page-top-spacing">
              <div>
                <h3 className="font-24 font-semibold text-color-1 mb-0">Manage Prompts</h3>
              </div>
              <div className="input-group transparent-grp w-225" style={{ width: "255px" }}>
                <input type="text" className="form-control custom-search-input cust-search-input search-bg border-0 text-color-4 focus-none font-14 font-medium pe-0 ps-3" placeholder="Search"
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      fetchPractice();
                    }
                  }}
                  value={search} />
                <button className="btn focus-none search-bg" type="button"><img className="filter-icons" src="images/Default/input-search.svg" alt="input-search-icon" onClick={(e) => {
                  fetchPractice()
                }} /></button>
              </div>
            </div>
            {/* Accordion starts here */}
            <div className="accordion-custom-scroll mt-4 px-1 custom-accordion-scroll-height">
              {practiceDetails.length > 0 ? bindPractice(practiceDetails) :
                <>
                  <div className="text-center py-5 vh-50 acc-child-fix">
                    <img
                      src="images/Default/no-record-img.svg"
                      alt="no-record-img"
                      className="no-record-img mb-5"
                    />
                    <span className="font-20 font-medium w-100 d-block text-color-1">
                      No Match Found
                    </span>
                  </div>
                </>}

            </div>
            {/* Accordion ends here */}

          </div>
        </div>

      )}

      <div className="modal fade" id="Helpus-popup" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg border-0">
          <div className="modal-content p-4 border-0 bg-color">
            <div className="modal-header border-0 pt-2 pb-2 ps-2">
              <h5 className="modal-title text-color-1 font-22 font-bold">Activity</h5>
              <button type="button" className="btn-close close-icon-filter" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body pt-2 px-0 pb-0" >
              <div className="table-responsive rounded-3 transparent-grp manageprompt-activity-scroll" style={{ maxHeight: "70vh", overflowY: "scroll" }}>
                <table className="table theme-table font-regular font-14 mb-0">

                <thead style={{ textAlign: "left", position: "sticky", top: 0, background: "#fff", zIndex: 2 }}>
                    <tr>
                      <th className="text-center pe-5" onClick={() => { sortPromptName() }}>Prompt{promptSort ? (<span className="cursor-pointer"><img src="images/sort-up.svg" alt="sort-up" className="ms-1 filter-icons" /></span>) : ((<span className="cursor-pointer"><img src="images/sort-down.svg" alt="sort-down" className="ms-1 filter-icons" /></span>))}</th>
                      <th className="text-center pe-5" onClick={() => { if (modifiedBy) { setsortname("createdBy"); setsortvalue("DESC"); setModifiedBy(false); } else { setsortname("createdBy"); setsortvalue("ASC"); setModifiedBy(true); } }}>Modified by{modifiedBy ? ((<span className="cursor-pointer"><img src="images/sort-down.svg" alt="sort-down" className="ms-1 filter-icons" /></span>)) : (<span className="cursor-pointer"><img src="images/sort-up.svg" alt="sort-up" className="ms-1 filter-icons" /></span>)}</th>
                      <th className="text-center pe-5" onClick={() => { if (timeStamp) { setsortname("createdDateTime"); setsortvalue("DESC"); setTimeStamp(false); } else { setsortname("createdDateTime"); setsortvalue("ASC"); setTimeStamp(true); } }}>Time Stamp{timeStamp ? (<span className="cursor-pointer"><img src="images/sort-down.svg" alt="sort-down" className="ms-1 filter-icons" /></span>) : ((<span className="cursor-pointer"><img src="images/sort-up.svg" alt="sort-up" className="ms-1 filter-icons" /></span>))}</th>
                      <th className="text-center pe-5">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {arr.length !== 0 ? arr.map((promptCard, index) => (
                      //  {promptCards.length != 0 ? promptCards?.map((value, index) => {
                      <tr>
                        <td className>{promptCard["promptName"]} </td>
                        <td className>{promptCard["ModifiedBy"]}</td>
                        <td className>{promptCard["DateTime"]}</td>
                        <td className="text-center pe-5">
                          <button className="btn me-2 pe-0" data-bs-dismiss="modal" aria-label="Close" onClick={() => { promptHistory(promptCard["promptID"]) }}><img src="images-new/blue-eye-iocn.svg" alt="View" /></button>
                          <button className="btn" data-bs-dismiss="modal" aria-label="Close" ><img src="images-new/watch-history-icon.svg" alt="History" onClick={() => { retsore(promptCard["promptID"], promptCard["EditedTo"]) }} /></button>
                        </td>
                      </tr>

                    )) : (
                      // <div className="text-center d-flex align-items-center justify-content-center vh-75 w-100" style={{ height: "20%", width: "20%" }}>
                      //   <div className="w-100">
                      //     <img src="images/Default/no-record-img.svg" alt="no-record-img" className="no-record-img mb-5" />
                      //     <p className="font-20 font-medium text-color-1">Sorry! You don’t have any records.</p>
                      //   </div>
                      // </div>
                      <tr>
                        <td colSpan="4" className="text-center p-4">
                         <img src="images/Default/no-record-img.svg" alt="no-record-img" className="no-record-img" />
                      </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <p className="font-regular font-14 text-color-1 mt-3 mb-0"># of Records : <span className="font-bold font-14 text-color-9">{arr.length}</span> out of <span className="font-bold font-14 text-color-9">{arr.length}</span></p>
            </div>
          </div>
        </div>
      </div>

    </>


  )

}

export default ManagePromptCards