import { useContext, useEffect, useState, useRef } from "react"; //PS_CGAI_10.1 importt necessary from react
import toggleContext from "./Context";
import MyContext from "./MyContext";

/**
 * PS_CGAI_10.2 to PS_CGAI_10.7 using ternary operator check the condition for record and bind the respective values
 * PS_CGAI_10.8 to PS_CGAI_10.18 in onchange get the values in target and do length validation if place holder is presents means
 * then pass the va;ue to the parent component usng handleFieldValues() function
 * @param {props from propmtFormComponent} param0
 * @returns the code snippett textarea field
 */
export function CodeSnippet({ record }) {
  const [recordData, setRecordData] = useState();
  const toggle = useContext(toggleContext);
  const [error, seterror] = useState("");
  const [hideError, sethideError] = useState(true);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (record) {
      setRecordData(record.record);
    }
  }, [record]);
  /**
   * PS_CGAI_8.6 to check and bind the error message
   * @returns error message if there means
   */
  const bind = () => {
    // if(record.record.inputValueOrder=="input-2")
    // {  console.log(record.record,"heheeeeeeeeeeeeee")}
    // let val=record.record.labelName
    // if (record.record.inputValueOrder=="input-2" || val.toLowerCase().includes('functionality')) {
    //   console.log(record.record,"heheeeeeeeeeeeeee")
    //   isFirstRender.current = false;
    // }
    if (recordData) {
      const err = `${recordData.inputValueOrder}`;

      if (record?.validationErrors) {
        return (
          <>
            {hideError ? (
              <span
                className="mandatory-star font-14 font-medium mb-0"
                style={{ color: "red" }}
              >
                {record.validationErrors[err]}
              </span>
            ) : null}
          </>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  return (
    <>
      {record && recordData?.labelName ? (
        <label
          class="text-color-14 font-15 font-semibold "
          for="Code-similar"
        >
          {recordData.labelName}
          {record && recordData?.required == "true" ? (
            <span class="color-red">*</span>
          ) : null}
        </label>
      ) : (
        ""
      )}

      <textarea
        class="form-control font-14 font-medium textarea-sty theme-focus input-field bg-transparent rounded-3 text-color-4 border-radius-3 custom-border-3"
        rows="3"
        disabled={toggle}
        placeholder={recordData?.placeHolder ? recordData.placeHolder : null}
        value={record?.fieldValue}
        onChange={(e) => {
          
          seterror("")
          if (e.target.value) {
            sethideError(false);
           
            if (!recordData?.placeHolder) {
              seterror("");
              const lines = e.target.value.split(/\r\n|\r|\n/);
            
              if (lines.length > 0 && lines.length < 2000) {
                seterror("");
                record?.handleFieldValues(
                  recordData?.labelName == null ? "" : recordData.labelName,
                  e.target.value,
                  recordData.inputValueOrder,
                  recordData.required
                );
              } else if (
                (lines.length == 0 || lines.length > 2000) &&
                recordData?.required == "true"
              ) {
                seterror(
                  "Please ensure the code meets the required lenght (below 2000 lines)"
                );
              }
            } else {
              record?.handleFieldValues(
                recordData.labelName,
                e.target.value,
                recordData.inputValueOrder,
                recordData.required
              );
            }
            toggle?.functions(e);
          } else {
            record?.handleFieldValues(
              recordData.labelName,
              e.target.value,
              recordData.inputValueOrder,
              recordData.required
            );
            sethideError(true);
          }
        }}
      ></textarea>
      <span
        className="mandatory-star font-14 font-medium mb-0"
        style={{ color: "red" }}
      >
        {error}
      </span>
      {bind()}
    </>
  );
}
