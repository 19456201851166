import { useContext, useEffect, useState } from "react";
import UserContext from "../Auth/useContext";
import PopUpComponent from "./Popup";
/**
 *
 * @returns the user profile
 * PS_CGAI_12.2 to PS_CGAI_12.11 get the user profile data using useContetx and bind the values
 */
export default function UserProfileComponent({ logout }) {
  const userData = useContext(UserContext);
  return (
    <span
      className="dropdown-menu dropdown-menu-end profile-dropdown theme-dropdown p-3 text-center border-0"
      data-bs-popper="none"
    >
      <span className="d-block text-start w-100 mb-4 mt-3">
        <img
          src={userData?.profileImage}
          alt="profile-image"
          className="profile-hamburger-img mb-3"
        />
        <span className="font-16 font-semibold d-block mb-2">
          {userData?.userName}
        </span>
        <span className="font-12 font-medium d-block grey-v2">
          {userData?.role}
        </span>
        <span className="font-12 font-medium d-block grey-v2">
          {userData?.jobTitle}
        </span>
      </span>
      <button
        className="btn primary-gradiant-btn border-0 text-white mb-4"
        data-bs-toggle="modal"
        data-bs-target="#logout-popup"
        onClick={() => {
         logout.logoutFunction()
        }}
      >
        Logout
      </button>
    </span>
  );
}
