import React, { useRef } from "react";
import { useDrag } from "react-dnd";
import { COLUMN } from "../../Form-Builder/Components/form-constants";
import { COMPONENT } from "./filter-constants";

const style = {
  // border: "1px dashed black",
  padding: "0rem 0rem",
  backgroundColor: "white",
  cursor: "move",
};
const Component = ({
  data,
  components,
  path,
  handleSettings,
  handleDelete,
}) => {
  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: COLUMN,
    item: { type: COMPONENT, id: data.id, path },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  const component = components[data.id];

  return (
    // <div class="col-md-11 element-child border mt-3 mb-3 rounded-3">
    //   <div class="row">
    //     <div class="col-md-12 bg-light">
    //       <div class="float-start">
    //         <h4 class="element-head mt-1 ms-2 pt-1 flex-fill">Element name</h4>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div
      ref={ref}
      style={{ ...style, opacity }}
      className="component draggable"
    >
      <div className="element-child">
        <div className="row">
          <div
            className={
              data.validation == undefined || data.validation == true
                ? "col-md-12  bg-theme-color"
                : "col-md-12 bg-danger bg-red"
            }
          >
            <div className="float-start">
              <h4 className="element-head mt-1 ms-2 pt-1 flex-fill">
                {component.content}{" "}
                {component.general.labelName == ""
                  ? null
                  : " (" + component.general.labelName + ")"}
              </h4>
            </div>
            <div className="float-end">
              <button
                type="button"
                className="btn pt-0 ps-2 pe-1 cursor-point"
                onClick={() => handleSettings(component.id, component.type)}
              >
                <img src="images/settings.svg" alt="element-delete" />
              </button>
              <button
                type="button"
                className="btn me-2 pt-0 ps-2 pe-1 cursor-point"
                onClick={() => handleDelete(path, component.id)}
              >
                <img
                  src="images/builder-css/filter-close-icon.svg"
                  alt="element-delete"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Component;
