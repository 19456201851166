import React, { useRef } from "react";
import { useDrag } from "react-dnd";
import { ROW } from "./filter-constants";
import FilterDropZone from "./Filter-Dropzone";
import Column from "./Column";

const style = {};
const Row = ({
  data,
  components,
  handleDrop,
  path,
  handleSettings,
  handleDelete,
}) => {
  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: ROW,
    item: {
      type: ROW,
      id: data.id,
      children: data.children,
      path,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  const renderColumn = (column, currentPath) => {
    return (
      <Column
        key={column.id}
        data={column}
        components={components}
        handleDrop={handleDrop}
        path={currentPath}
        handleSettings={handleSettings}
        handleDelete={handleDelete}
      />
    );
  };

  return (
    // <div className="base draggable row">
    // <div class="row mt-3 mb-3" ref={ref} style={{ ...style, opacity }}>

    //   {data.children.map((column, index) => {
    //     const currentPath = `${path}-${index}`;

    //     return (
    //       <React.Fragment key={column.id}>
    //         <DropZone
    //           data={{
    //             path: currentPath,
    //             childrenCount: data.children.length,
    //           }}
    //           onDrop={handleDrop}
    //           className="horizontalDrag"
    //         />
    //         {renderColumn(column, currentPath)}
    //       </React.Fragment>
    //     );
    //   })}
    //   <DropZone
    //     data={{
    //       path: `${path}-${data.children.length}`,
    //       childrenCount: data.children.length
    //     }}
    //     onDrop={handleDrop}
    //     className="horizontalDrag"
    //     isLast
    //   />
    // </div>
    // </div>
    <div
      ref={ref}
      style={{ ...style, opacity }}
      className="base draggable row drop-zone-padding"
    >
      <div className="row mt-3 mb-3">
        <div className="columns drop-zone-padding">
          {data.children.map((column, index) => {
            const currentPath = `${path}-${index}`;

            return (
              <React.Fragment key={column.id}>
                <FilterDropZone
                  data={{
                    path: currentPath,
                    childrenCount: data.children.length,
                  }}
                  onDrop={handleDrop}
                  className="horizontalDrag"
                />
                {renderColumn(column, currentPath)}
              </React.Fragment>
            );
          })}
          <FilterDropZone
            data={{
              path: `${path}-${data.children.length}`,
              childrenCount: data.children.length,
            }}
            onDrop={handleDrop}
            className="horizontalDrag"
            isLast
          />
        </div>
      </div>
    </div>
  );
};
export default Row;
