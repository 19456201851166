/**
 * PS_CGAI_12.1 to PS_CGAI_12.9 ,Declare all the import statements
 */

import { useState, useEffect, useContext, useRef } from "react";
import { getInputTypeAndTechStack } from "../AI Service/Api";
import { insertPrompt, deletePrompt, promptGen, systemMessageGen } from "../AI Service/Api";
import UserContext from "../Auth/useContext";
import Choices from "./Choices";
import { CodeSnippet } from "./CodeSnippet";
import { MediaUpload } from "./MediaUploadComponent";
import DropdownComponent from "./DropdownComponent";
import { AddFileOrFolder } from "./AddFileOrFolder";
import { Text } from "./Text";
import toggleContext from "./Context";
// import ManageTask from "./manageTask";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ManagePromptCards from "./ManagePromptCards";
import { fetchPrompt } from "../AI Service/Api";
import PopUpComponent from "./Popup";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { AddFileOrFolderSample } from "./AddFileStuctureSample";
import Loader from "../Other-Components/loader";
import { HeaderComponent } from "./HeaderComponent";
import { useHistory, useLocation } from "react-router-dom";
import { isAuth } from "../Auth/authConfig";
import { useMsal, useAccount, useIsAuthenticated } from "@azure/msal-react";
import ChoicesComponent from "./ChoicesComponent";
import TechStack from "./TechStack";
import { AddKnowledgeBase } from "./AddKnowledgeBase";
import { getBindParams } from "../AI Service/Api";
import FewShotExapmle from "./FewShotExamples";
import { ContainerSASPermissions } from "@azure/storage-blob";
/**
 * PS_CGAI_12.10 to PS_CGAI_12.29,Declare all the state variables
 * PS_CGAI_12.30 to PS_CGAI_12.32,Declare the promptObj,promptFieldObj,
 promptMessageObj with necessary key and values
 */

function Manageprompt() {
  const [showPage, setShowPage] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [inputTypeData, setInputTypeData] = useState();
  const [show, setShow] = useState(true);
  const [choices, setChoices] = useState("radio");
  const [promptMessagePage, setPromptMessagePage] = useState("");
  const [flag, setflag] = useState(0);
  const [tasks, setTasks] = useState([]);
  const [submitType, setSubmitType] = useState("");
  const [promptCard, setPromptCard] = useState([]);
  const [promptIndex, setPromptIndex] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popUpText, setPopUpText] = useState("");
  const userDetails = useContext(UserContext);
  const [fieldIndex, setFieldIndex] = useState();
  const [editTaskIndex, setEditTaskIndex] = useState(null);
  const [userData, setuserData] = useState({});
  const [addOption, setAddOption] = useState(2);
  const [checkbox, setCheckbox] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  let [required, setRequired] = useState(false);
  const [suma, setSuma] = useState("")
  const [longAnswer, setLongAnswer] = useState(false);
  const [error, setError] = useState("");
  const [editChoices, setEditChoices] = useState([]);
  const [conditionData, setConditionData] = useState("");
  const [promptLogoData, setPromptLogoData] = useState("");
  const [userEmailId, setUserEmailId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const location = window.location;
  const location = useLocation();
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const history = useHistory();
  let logoObj = { logoPath: "", logoName: "", logoID: "" };
  const [promptLogoValues, setPromptLogoValues] = useState(logoObj);
  const [hoveredInput, setHoveredInput] = useState(null);
  const [hiddenIndices, setHiddenIndices] = useState([]);
  const [modelData, setModelData] = useState("")
  let [isWebSearch, setIsWebSearch] = useState(false)
  const [practiceID, setPracticeID] = useState("")
  const [allKbFiles, setAllKnFiles] = useState([])
  let modelName;
  let modelConfigurationID;
  const [selectedModelConfigurationID, setSelectedModelConfigurationID] = useState('');
  const [collection_name, setcollection_name] = useState('');
  const [indexName, setIndexName] = useState("")
  const [promptName, setPromptName] = useState("")
  const [promptID, setPromptID] = useState("")
  const [editedPromptID, setEditedPromptID] = useState("")
  const [isKbRetriever, setIsKbRetriever] = useState(false)
  const [selectedModelName, setSelectedModelName] = useState("")
  const [kbRetrieverUrl, setKbRetrieverUrl] = useState()
  const [modelError, setModelError] = useState("")
  const [HandlePromptHistory, setHandlePromptHistory] = useState(true)

  const [exampleVal, setExampleVal] = useState([])


  const [fewShotValues, setFewShotValues] = useState([])

  const [outputDescription, setOutputDescription] = useState("")
  const [inputArray, setInputArray] = useState([])
  const [userContent, setUserContent] = useState("")
  const [assistantContent, setAssistantContent] = useState("")
  const [updatedPromt, setUpdatedPrompt] = useState("")
  const userRef = useRef()
  const assistantRef = useRef()

  const [enhancedSystemMessage, setEnhancedSystemMessage] = useState("")
  const [heartBeat, setHeartBeat] = useState(false)

  const [showInputDD, setShowInputDD] = useState(false)

  const [selectedInputs, setSelectedInputs] = useState([])

  const [tempUpdatePrompt, setTempUpdatedPrompt] = useState("")

  const [askAsamiKbRetriever, setAskAsamiKbRetriever] = useState(false);

  const [disableEdit, setDisableEdit] = useState(false)

  const [aiReq, setAiReq] = useState(false)

  const [hitAiVar, sethitAiVar] = useState(0)

  const [disableKBEdit, setDisableKBEdit]=useState(false)
  const[collectionNameFix , setCollectionNameFix] = useState('')

  const[displayQuestion, setDisplayQuestions] = useState(false)







  let promptFieldObj = {
    labelName: "",
    modifiedBy: "",
    inputTypeID: "",
    choiceName: [],
    inputTypeRefCode: "",
    promptFieldOrder: "",
    placeHolder: "Enter the value",
    errorMessage: null,
    required: "false",
    hitAI: hitAiVar,
    inputValueOrder: "",
    uniqueField: 0,
    createdBy: userDetails?.email
  };



  let promptMessageObj = {
    promptMessageOrder: 1,
    modifiedBy: "",
    promptMessage: "",
    outputDesc: "",
    inputArr: inputArray,
    inputKey: "",
    inputValue: "",
    inputArray: [],
    conditionID: "",
    isKbRetriever: "",
    modelConfigurationID: "",
    promptMessageID: "",
    isWebSearch: "",
    webContent: "",
    oldPrompt: "",
    outputDescription: "",
    fewShotExamples: "",
    createdBy: userDetails?.email,
    draft: 0,
  };

  const [promptFieldData, setPromptFieldData] = useState(promptFieldObj);
  const [PF, setPF] = useState([]);
  const [promptMessageData, setPromptMessageData] = useState(promptMessageObj);
  const [inputValue, setInputValue] = useState([]);
  const [knowledgeData, setKnowledgeData] = useState([]);
  const [kbUrl, setKbUrl] = useState("")
  //(collection_name, indexName, knowledgeData, kbUrl, "came index");

  let promptObj = {
    promptID: "",
    promptName: "",
    promptDescription: "",
    promptLogoID: "",
    systemMessage: "",
    enhancedSystemMessage: enhancedSystemMessage,
    editedPromptID: editedPromptID,
    modifiedBy: "",
    kbFiles: [],
    kbUrl: "",
    collectionName: collection_name,
    indexName: "",
    practiceID: practiceID,
    createdBy: userDetails?.email,
    draft: 0,
    promptFields: PF,
    promptMessages: inputValue,

  };

  //(knowledgeData, "ghjkl");

  const [promptData, setPromptData] = useState(promptObj);

  const errorObj = {
    promptNameError: "",
    labelNameError: "",
    choicesError: "",
    promptLogoIDError: "",
  };


  const [errorData, setErrorData] = useState(errorObj);
  const [toggleDropDown, setToggleDropDown] = useState("up")
  //(isWebSearch, "OOOOO")



  useEffect(()=>{
    //(PF , "Printing PFPFPFPFPPF")
  },[])

  useEffect(() => {
    const handlePopState = () => {
      // Reload the page when the back button is clicked
      window.location.reload();
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [location]);

  useEffect(() => {
    if (location.state && location.state.fromBackButton) {
      window.location.push("/Asami-manage-prompt");
      window.location.reload();
    }
  }, [location]);




  const handleBackClick = () => {
    history.goBack();

  };












  useEffect(() => {
    setPromptData({
      ...promptData,
      collectionName: collection_name,
      indexName: indexName,
      kbFiles: knowledgeData,
      kbUrl: kbUrl,
      editedPromptID: editedPromptID,
      enhancedSystemMessage: enhancedSystemMessage
    });
    //("step-1", promptData)
  }, [collection_name, indexName, knowledgeData, kbUrl]);


  useEffect(() => {
     
    if (updatedPromt != "") {
      handleAddTask()
    }
  }, [updatedPromt])

  /**
   * 12.33 Give the useEffet to trigger the function fetchInpuTypeAndTechStack and another useeffect for data push
   */
  useEffect(() => {
    // if (!userData) {
    //  setPromptData(promptObj)
    //(indexName, collection_name, knowledgeData, "IIIIIIIIIIIIIIIIIIIIIIII");

    setUserEmailId(userDetails?.email);
    setuserData(userDetails);
    fetchBindParams()
    if (userDetails) {
      fetchPromptData();
    }

    // }
  }, [userDetails]);


  //(setcollection_name, setIndexName, setKnowledgeData, "UUUUUUUUUUUUUUUUUUUUUUU");

  useEffect(() => {


    setPromptData({ ...promptData, practiceID: practiceID, enhancedSystemMessage: enhancedSystemMessage })
    //("step-2", promptData)
  }, [practiceID]); // This effect depends on practiceID and runs whenever practiceID changes

  useEffect(() => {


    setPromptData({ ...promptData, practiceID: practiceID, enhancedSystemMessage: enhancedSystemMessage })
    //("step-3", promptData)
  }, [practiceID]);
  useEffect(() => {
    //(knowledgeData, "III")

    setPromptData({ ...promptData, kbFiles: knowledgeData, enhancedSystemMessage: enhancedSystemMessage })
    //("step-4", promptData)
  }, [knowledgeData]);

  useEffect(() => {
    fetchInputTypeAndTechStack();
    setPracticeID(practiceID)
    //(promptLogoData, "ghjkl");
  }, []);

  useEffect(() => {
    if (!isAuth(accounts)) {
      history.push("/");
    }
  }, []);

  /**
   * 12.33 Give the useEffet to assign the values from getPrompt
   */

  //(isWebSearch, "fghjk");

  useEffect(() => {
     
    if (showPage === "showManagePrompt" && promptIndex) {
      let editObj = promptIndex;

      //(editObj, "OOOOOOOOOOOOOOOOOOOOOOO");
      //(promptIndex, "mervin is here")

      // Parse the JSON string into an array
      let kbFilesArray = [];
      let kbPairs = [];
      if (editObj.kbFiles) {
        try {
          kbFilesArray = JSON.parse(editObj.kbFiles);
        } catch (error) {
          console.error("Error parsing kbFiles:", error);
        }
      }
      // kbPairs = kbFilesArray.map(file => ({ file, kbUrl: editObj.kbUrl }));
      setKnowledgeData(kbFilesArray);
      setKbRetrieverUrl(editObj.kbUrl)
      let kbUrl = []
      if (editObj.kbUrl) {
        try {
          kbUrl = JSON.parse(editObj.kbUrl);

        }
        catch (error) {
          //("error while parsing files", error)
        }
      }
      setPromptData(editObj);
      //("step-5", promptData)
      setPromptID(editObj.promptID)
      setSubmitType("SD");
      setPromptName(editObj.promptName)
      setPF(editObj.promptFields);
      setCollectionNameFix(editObj.collectionName)
      //("printing_collection", collection_name)
      // setTasks(editObj?.promptMessages);
      try{

        const newPromptMessages = editObj.promptMessages.map(message => ({
          ...message,
          inputArray: JSON.parse(message?.inputArray !== null && message?.inputArray != "None" && message?.inputArray.length > 1 && message.inputArray != undefined ? message.inputArray : null)
        }));
        
        setTasks(newPromptMessages);
      }
      catch(error){
        const newPromptMessages = editObj.promptMessages.map(message => ({
          ...message,
          inputArray: []
        }));
        
        setTasks(newPromptMessages);
        //(error)
      }
      
      setPromptLogoValues({
        ...promptLogoValues,
        logoId: editObj?.promptLogoID,
        logoName: editObj?.promptLogoName,
        logoPath: editObj?.promptLogoPath,
      });
    }
  }, [showPage]);
  //(PF, "hellotethee", tasks)
  useEffect(() => { }, [submitType]);

  // useEffect(() => {
  //   if (modelData && Array.isArray(modelData)) {
  //     const azureGpt3Model = modelData.find(
  //       (model) => model.modelName === "Azure GPT-4 32K"
  //     );
  //     setSelectedModelConfigurationID(azureGpt3Model?.modelConfigurationID || '');
  //     setSelectedModelName(azureGpt3Model?.modelName || '')
  //   }
  // }, [modelData]);

  //(kbRetrieverUrl, "KKKK");

  // prompt gen functionalities


  // const [showInputDD , setShowInputDD] = useState(false)

  const handleInputDD = () => {
    if (showInputDD == true) {
      setShowInputDD(false)
    }
    else {
      setShowInputDD(true)
    }
  }


  // PC_NO_8 - PC_NO_11 : this function creates a new card whenever use clicks on the add button
  const handleFewShotSave = (index) => {
    setFewShotValues([...fewShotValues, {
      "index": Date.now(),
      "inputs": [
        {
          "role": "user",
          "content": userContent
        },
        {
          "role": "assistant",
          "content": assistantContent
        }
      ]
    }])
    userRef.current.value = ''
    assistantRef.current.value = ''
    //(fewShotValues, "LOL")
  }

  // PC_NO_12 - PC_NO_13 : The card deletion scenario will take place in this function
  const handleFewShotDelete = (index) => {
    //("entered fewShotdelete", index)
    //('asfk', fewShotValues)
    setFewShotValues(prevData => prevData.filter(ele => ele.index !== index));
  }

  // PC_NO_14 - PC_NO_18 : The edit scenario will take place in this function. (ie., when the user clicks the edit icon in the card)
  const handleFewShotEdit = (index, updatedUserCont, updatedAssistantContent,) => {

    //(index, updatedUserCont, updatedAssistantContent, "Supermassive")
    let updateElements = [...fewShotValues]
    const elementIndex = fewShotValues.findIndex(item => item.index === index)
    if (elementIndex != -1) {
      //("entered if condition")
      updateElements[elementIndex] = {
        "index": index,
        "inputs": [
          {
            "role": "user",
            "content": updatedUserCont
          },
          {
            "role": "assistant",
            "content": updatedAssistantContent
          }
        ]
      }
      setFewShotValues(updateElements)
      //(index, updatedUserCont, updatedAssistantContent, "\n Supermassive\n", fewShotValues, "\n", updateElements)
    }

  }


  // PC_NO_19 - PC_NO_23 :The fuction which deals with creating a packaged input-placeholder and its description is defined here
  const handleMultiSelect = (e, description) => {
    if (e.target.checked == true) {
      setInputArray([...inputArray, { "inputPlaceholder": e.target.value, "description": description }])
      setSelectedInputs([...selectedInputs, e.target.value])
    }
    else {
      setInputArray(prevData => prevData.filter((ele) => ele.inputPlaceholder !== e.target.value))
      setSelectedInputs(prevData => prevData.filter((ele) => ele != e.target.value))
    }
    //(inputArray, "aipjoifjoisa")
  };



  const [isUpdated, setIsUpdated] = useState(false)

  // PC_NO_24 - PC_NO_41 : This function hits the backend api and generates a enhanced prompt
  const handlePromptGen = async () => {
     
    let obj = {
      modelError: ""
    };

    if (promptMessageData.modelConfigurationID === "") {
      obj.modelError = "Select a Model";
      setModelError(obj);
      return; // Exit the function if modelConfigurationID is empty
    }
    try {
       
      setIsLoading(false)
      let inputDesc = inputArray
      let outputDesc = outputDescription
      let taskDescription = promptMessageData.promptMessage
      let fewShots = fewShotValues.map((ele) => ele.inputs)
      let req_body = {
        "inputs": inputDesc,
        "outputDescription": outputDesc,
        "taskDescription": taskDescription,
        "examples": fewShots

      }
      let updated_prompt = await promptGen(req_body)
      // let updated_prompt = {
      //   "finalPrompt":"test2"
      // }
       

      setIsLoading(true)
      setTempUpdatedPrompt(updated_prompt.finalPrompt)
      setPromptMessageData({
        ...promptMessageData,
        promptMessage: updated_prompt.finalPrompt,
      })
      setUpdatedPrompt(updated_prompt.finalPrompt)
      // setPromptMessageData({
      //   ...promptMessageData,
      //   promptMessage: updatedPromt.finalPrompt,
      //   outputDescription : outputDesc,
      // });
      //(updated_prompt, "fewshotssss")


    }
    catch (error) {
      //(error)
    }

  }


  // const setData = new Promise((resolve, reject) => {
  //   setTimeout(() => {
  //     const result = 'Data fetched';
  //     setUpdatedPrompt(resolve.finalPrompt)
  //     resolve(result);
  //   }, 2000);
  // })

  const [tempFlag, setTempFlag] = useState(false)

  const handleSystemMessageUpdate = async () => {
    try {
       
      // setIsLoading(true)
      setHeartBeat(true)
      let sys_mess = promptData.systemMessage;
      let reqBody = {
        "systemPrompt": promptData.systemMessage
      }
      let response = await systemMessageGen(reqBody)
      setTempFlag(true)
      setPromptData({ ...promptData, enhancedSystemMessage: sys_mess, systemMessage: response.sys_prompt })
      setEnhancedSystemMessage(sys_mess)
      // setIsLoading(false)
      setHeartBeat(false)
    }
    catch (error) {
      //(error);
    }
  }




  // prompt gen ends here








  const handleCreatePrompt = () => {

    let id = "";
    inputTypeData?.map((val, i) => {
      if (val.inputTypeRefCode == "TXT") {
        id = val.inputTypeID;
      }
    });
    let promptFieldDataObj = {
      labelName: "Chat Name",
      inputTypeID: id,
      promptFieldID: "",
      inputTypeRefCode: "TXT",
      promptFieldOrder: "1",
      placeHolder: "Enter the chat name",
      errorMessage: "Please enter the chat name",
      required: "true",
      hitAI: 0,
      inputValueOrder: "input-1",
      uniqueField: 0,
      choiceName: [],
      createdBy: userDetails?.email,
      modifiedBy: "",
    };


    let promptArr = PF;
    promptArr.push(promptFieldDataObj);

    setPF(promptArr);
    // Additional logic or state updates if needed

  };

  //(promptMessageData,"LLLL");
  //(kbRetrieverUrl, "LLLL")

  //(promptData, "KKKKKK")
  const fetchBindParams = async () => {
    try {
      const response = await getBindParams({});
      //(response.data.modelConfiguration, "(((");

      setModelData(response.data.modelConfiguration)
    } catch (error) {
      //(error);
    }
  };
  //(promptData, "PPPP");

  //(PF, "kjhgf");
  //(promptMessageData, "OOOOO");


  const clearOtherFunction = (e) => {
    // if (e.target.checked) {
    //   if (e.target.id === "Convert-to-dropdown")
    //     document.getElementById("Multiple-answers").checked = false
    //   if (e.target.id === "Multiple-answers")
    //     document.getElementById("Convert-to-dropdown").checked = false
    // }
  };

  /**
   * PS_CGAI_12.50 to PPS_CGAI_12.55 onChangeData function will be triggerd when promptField is typed
   * @param {event of thet particular input field should be passed} e
   */
  //(promptFieldData, "afsafohouhuoho", PF)
  const onChangeData = (e, choice = "") => {
     
    let updatedChoices = Array.isArray(promptFieldData.choiceName)
      ? promptFieldData.choiceName
      : [];
    const updatePromptFieldAndEditChoices = (newChoice) => {
      setPromptFieldData((prevData) => ({
        ...prevData,
        placeHolder: e.target?.placeholder,
        choiceName: newChoice,
        hitAI: hitAiVar
      }));
      setEditChoices(newChoice);
    };
    if (e.target?.id == "labelName") {
      setError("");
      setPromptFieldData((pre) => ({
        ...pre,
        [e.target?.id]: e.target?.value,
        hitAI: hitAiVar
      }));
    }
    // if (e.target?.id === "labelName") {
    //   updatePromptFieldAndEditChoices(e.target?.value);
    else if (e.target?.id === "old-values") {
      // Clear existing choices and push the new choice

      if (choice === "") updatedChoices.push(e.target.value);
      else updatedChoices = choice;

      updatePromptFieldAndEditChoices(updatedChoices);

      // Any additional logic for eDITcHOICES can go here
    } else if (
      selectedOption === "RB" ||
      selectedOption === "CB" ||
      selectedOption === "DD"
    ) {
      if (!updatedChoices.includes(e.target.value)) {
        updatedChoices.push(e.target.value);
        updatePromptFieldAndEditChoices(updatedChoices);

        // setEditChoices(updatedChoices);/

        // Any additional logic for eDITcHOICES can go here
      }
    }
  };


  /**
   * PS_CGAI_12.56 to PS_CGAI_12.59 , handlePromptChange is triggerd when promptname is typed
   * @param {event of thet particular input field should be passed} e
   */
  const handlePromptChange = (e) => {
    setErrorData({ ...errorData, promptNameError: "" });
    setPromptData({ ...promptData, [e.target.id]: e.target.value });
    //("step-6", promptData)

  };

  /**
   * PS_CGAI_12.35 to PS_CGAI_12.49 ,Call the function fetchInputypeAndTechstack 
       and fetch the inputTypeData,conditionData,promptLogoData from the backend
       
   */
  const fetchInputTypeAndTechStack = async () => {
    let response = await getInputTypeAndTechStack({});
    //(response, "................");
    if (response.status === 200) {
      setInputTypeData(response?.data?.inputType);
      setConditionData(response?.data?.condition);
      setPromptLogoData(response?.data?.promptLogo);
      // setModelData(response.data.modelConfiguration)
    }
  };




  /**
   * PS_CGAI_12.158 to PS_CGAI_12.172 the respective promptData will be deleted using promptID
   * @param {to delete the prompt using particular promptID} promptID
   */
  const deletePromptData = async (promptID) => {

    try {

      let response = await deletePrompt({ promptID });

      if (response.data.Success) {
        setShowPopup(false);
        setPromptIndex(null);
      }
      fetchPromptData();
      window.location.reload()
    } catch (error) {
      //(error);
    }
  };

  //(isWebSearch, "OOOO")

  /**
   * PS_CGAI_173 to PS_CGAI_PS_CGAI_195 ,getPrompt API will be triggered and whole data or a data for a
   * particular prompt ID will be fetched
   */

  const fetchPromptData = async () => {
    try {
      // 
       
      setIsLoading(false);
      let response = await fetchPrompt({});
      //(response)
      if (response.status === 200) {
        //(isLoading, ".......")
        setIsLoading(true);

        if (response.data != "") {
          let arr = [];
          response?.data?.ResponseData?.prompts?.forEach((prompt) => {
            if (prompt?.draft != "") {
              arr.push(prompt);
            } else {
              arr.push(prompt);
            }
          });
          setPromptCard(arr);
          setShowPage("manage prompt");
        } else {
          setShowPage("no record");
        }
      }
    } catch (error) {
      //(error);
    }
  };

  /**
   * PS_CGAI_12.95,PS_CGAI_12.96 ,When toggle buttons are toggle call the renderInputComponent
   */
  const toggleButtonHandle = () => {
    renderInputComponent();
  };

  /**
   * PS_CSAI_12.71 to PS_CSAI_12.71 Triggers the validate function where submitPrompt function will be called after validation
   */

  async function validate(id) {
    setIsLoading(false);

    let validateObj = {
      promptNameError: "",
      labelNameError: "",
      choicesError: "",

    };
    if (promptData.promptName == "") {
      validateObj.promptName = "Enter the Prompt Name";
      flag++;
    }
    // if (promptData.promptLogoID == "") {
    //   validateObj.promptLogoIDError = "select the promptLogo";
    //   flag++;
    // }

    setErrorData(validateObj);
    await submitPrompt(id);
    // setIsLoading(true);
  }

  useEffect(() => {
    setPromptData({
      ...promptData,
      enhancedSystemMessage: enhancedSystemMessage
    })
    //(promptData, "sakdhsasa")
  }, [enhancedSystemMessage])

  const submitPrompt = async (id) => {
     
    let promptObject = promptData;
    promptObject.createdBy = userDetails?.email;
    if (promptData.promptID === "") {
      promptObject.createdBy = userDetails?.email;

      if (flag === 0) {
        if (id == "SD") {
          promptObject.draft = 0;
        } else {
          promptObject.draft = 1;
        }
        //(promptObject, "--------------------------------->")
        //("errorWillOccurHere")

        const response = await insertPrompt(promptObject);
        // setShowPage("manage prompt");
        fetchPromptData();
      }
      setPromptMessagePage("");
      setPF([]);
      setTasks([]);
      setPromptFieldData(promptFieldObj);
      setPromptMessageData(promptMessageObj);
      setPromptData(promptObj);
      //("step-7", promptData)
      setPromptLogoValues(logoObj);
    } else {
      if (id == "SD") {
        promptObject.draft = 0;
        //(promptObject, "asfasfawsfeagege")
        promptObject.systemMessage = enhancedSystemMessage
        promptObject.promptMessages = promptObject.promptMessages.filter((ele, index) => index !== 0)
        const response = await insertPrompt(promptObject);
        // setShowPage("manage prompt");
        fetchPromptData();
      } else {
        promptObject.draft = 1;
        // promptObject.systemMessage = enhancedSystemMessage
        // promptObject.promptMessages = promptObject.promptMessages.filter((ele, index) => index !== 0)
        const response = await insertPrompt(promptObject);
        // setShowPage("manage prompt");
        fetchPromptData();
      }
      setPromptMessagePage("");
      setPF([]);
      setTasks([]);
      setPromptFieldData(promptFieldObj);
      setPromptMessageData(promptMessageObj);
      setPromptData(promptObj);
      //("step-9", promptData)
      setPromptLogoValues(logoObj);
      setEnhancedSystemMessage()
      setFewShotValues([])
      setOutputDescription("")
      setUpdatedPrompt("")
      setEnhancedSystemMessage("")
      setSelectedInputs([])
    }
  };



  // const submitPrompt = async (id) => {
  //    ;
  //   const promptObject = { ...promptData, createdBy: userDetails?.email };

  //   if (promptData.promptID === "") {
  //     // Creating a new prompt
  //     if (flag === 0) {
  //       promptObject.draft = id === "SD" ? 0 : 1;
  //       await insertPrompt(promptObject);
  //     }
  //   } else {
  //     // Editing an existing prompt
  //     promptObject.draft = id === "SD" ? 0 : 1;
  //     await insertPrompt(promptObject);
  //   }

  //   // Reset state variables
  //   setPromptMessagePage("");
  //   setShowPage("manage prompt");
  //   setPF([]);
  //   setTasks([]);
  //   setPromptFieldData(promptFieldObj);
  //   setPromptMessageData(promptMessageObj);
  //   setPromptData(promptObj);
  //   setPromptLogoValues(logoObj);

  //   // Fetch updated prompt data if needed
  //   // if (promptData.promptID === "") {
  //   fetchPromptData();
  //   // }
  // };

  /**
   * PS_CGAI_12.101 to PS_CGAI_12.109 ,onclick of add or update the data will be setted to the screen
   */


  const handleAddTask = () => {
     ;
    let obj = {
      modelError: ""
    };

    if (promptMessageData.modelConfigurationID === "") {
      obj.modelError = "Select a Model";
      setModelError(obj);
      return; // Exit the function if modelConfigurationID is empty
    }

    setModelError("")

    if (promptMessageData?.promptMessage.trim() !== "") {
      if (typeof editTaskIndex === "number") {
        // Editing an existing task
        const updatedPromptData = promptData.promptMessages.map((task, index) => {
          if (index === editTaskIndex) {
            let messageValue = ""

            // if (tempUpdatePrompt == "") {
            messageValue = promptMessageData.promptMessage.trim()
            // }
            // else {
            //   messageValue = tempUpdatePrompt
            // }

            let outputDes = outputDescription
            if (outputDescription != null) {
              if (outputDescription.length > 1) {
                outputDes = outputDescription.trim()
              }

            }
            else {
              outputDes = ""
            }

            return {
              ...task,
              promptMessage: messageValue,
              oldPrompt: promptMessageData.promptMessage.trim(),
              outputDescription: outputDes,
              fewShotExamples: fewShotValues,
              modifiedBy: promptMessageData.modifiedBy,
              // promptMessage: promptMessageData.promptMessage.trim(),
              inputKey: promptMessageData.inputKey,
              conditionID: promptMessageData.conditionID,
              modelConfigurationID: promptMessageData.modelConfigurationID,
              isWebSearch: promptMessageData.isWebSearch,
              webContent: promptMessageData.webContent,
              isKbRetriever: promptMessageData.isKbRetriever,
              inputValue: promptMessageData.inputValue ? promptMessageData.inputValue.trim() : promptMessageData.inputValue,
              inputArray: inputArray
            };
          }
          return task;
        });
        const newPromptMessages = updatedPromptData.map(message => (typeof (message.inputArray) == "string" ? {
          ...message,
          inputArray: JSON.parse(message.inputArray !== null && message.inputArray != "None" && message.inputArray.length > 2 ? message.inputArray : null)
        } : {
          ...message,
          inputArray: message.inputArray
        }));
        setTasks(newPromptMessages);
        setIsWebSearch(false);
        setIsKbRetriever(false);
        setPromptData((pre) => ({ ...pre, promptMessages: updatedPromptData, enhancedSystemMessage: enhancedSystemMessage }));
        //("step-10", promptData)
        setEditTaskIndex(null);
        setPromptMessageData((pre) => ({ ...pre, promptMessage: "", inputArray: inputArray }));
      } else {
        const newTask = {
          promptMessage: updatedPromt.trim(),
          oldPrompt: promptMessageData.promptMessage.trim(),
          outputDescription: outputDescription.trim(),
          fewShotExamples: fewShotValues,
          inputValue: promptMessageData.inputValue ? promptMessageData.inputValue.trim() : promptMessageData.inputValue,
          inputKey: promptMessageData.inputKey,
          conditionID: promptMessageData.conditionID,
          modelConfigurationID: promptMessageData.modelConfigurationID,
          isWebSearch: promptMessageData.isWebSearch,
          isKbRetriever: promptMessageData.isKbRetriever,
          webContent: promptMessageData.webContent,
          createdBy: userDetails?.email,
          modifiedBy: promptMessageData.modifiedBy,
          promptMessageOrder: promptData.promptMessages.length + 1,
          promptMessageID: promptMessageData.promptMessageID,
          inputArray: inputArray
        };

        setIsKbRetriever(false);
        setTasks([...tasks, newTask]);
        setIsWebSearch(false);
        setPromptData((pre) => ({
          ...pre,
          promptMessages: [...pre.promptMessages, newTask],
        }));
        //("step-11", promptData)
      }

      setPromptMessageData((pre) => ({
        ...pre,
        promptMessage: "",
        fewShotExamples: [],
        outputDesc: "",
        inputArr: [],
        inputValue: "",
        inputKey: "",
        conditionID: "",
        modelConfigurationID: "",
        isWebSearch: "",
        webContent: "",
        isKbRetriever: "",
        inputArray: []
      }));
    }
    setFewShotValues([])
    setInputArray([])
    setSelectedInputs([])
    setOutputDescription("")
    setDisableEdit(false)
  };


  //(promptData, "11111111111111");

  /**
   * PS_CGAI_12.111 to PS_CGAI_12.117,edit function to edit that particular text
   * @param {Id of the specific task which is been clicked to edit} taskId
   * @param {tasks text of the particular row} taskText
   */
  const handleEditTask = (index, task) => {
     
    setDisableEdit(true)
    //(task, "987");
    setIsWebSearch(task.isWebSearch); // Set isWebSearch to the value from the task
    setIsKbRetriever(task.isKbRetriever);
    const jsonString = task.fewShotExamples;

    // Replace single quotes with double quotes to make it a valid JSON string
    let validJsonString = 0
    let array = 0
    if (task.fewShotExamples != null) {
      let type = typeof (task.fewShotExamples)
      if (typeof (task.fewShotExamples) == "string") {

        if (task.fewShotExamples.length > 2) {
          try {

            validJsonString = jsonString
            // validJsonString = validJsonString.replace('/',' ')
            // const cleanedString = validJsonString.replace(/\\"/g, '"');
            array = JSON.parse(jsonString);
            if (typeof (array) !== 'object') {
              array = []
            }
          }
          catch (error) {
            array = []
          }
        }
        else {
          array = []
        }
      }
      else if (typeof (task.fewShotExamples) == "object") {
        array = task.fewShotExamples
      }
    }
    else {
      array = []
    }

    let arr = []
    if (task.inputArray != null) {
      let type = typeof (task.inputArray)
      if (typeof (task.inputArray) == "string") {

        if (task.inputArray.length > 2) {
          validJsonString = jsonString;
          arr = JSON.parse(validJsonString);
        }
        else {
          arr = []
        }
      }
      else if (typeof (task.inputArray) == "object") {
        arr = task.inputArray
      }
    }
    else {
      arr = []
    }




    // Parse the JSON string into a JavaScript object
    let tempArr = []
    if (typeof (task.inputArray) == "object" && task.inputArray != null) {
      task.inputArray.map((ele) => {
        tempArr.push(ele.inputPlaceholder)
      })
      setSelectedInputs(tempArr)
      setInputArray(task.inputArray)
    }
    else {
      setSelectedInputs([])
      setInputArray([])
    }
    setFewShotValues(array)
    setPromptMessageData({
      ...promptMessageData,
      promptMessage: task.promptMessage,
      inputKey: task.inputKey,
      inputValue: task.inputValue,
      conditionID: task.conditionID,
      modelConfigurationID: task.modelConfigurationID,
      isWebSearch: task.isWebSearch,
      webContent: task.webContent,
      isKbRetriever: task.isKbRetriever,
      outputDesc: task.outputDescription,
      inputArray: arr,
      fewShotExamples: array,

    });
    setOutputDescription(task.outputDescription)
    //(task, "safsafas")
    // setOutputDescription(task.outputDescription)
    setEditTaskIndex(index);

  };

  //(editTaskIndex, "sadasdwfa")




  // Define the function to handle knowledge data save
  // const handleKnowledgeSave = (data) => {
  //(data, "Received knowledge data in parent component");
  //     setKnowledgeData(data);
  // };

  //(knowledgeData, "yyyyy");
  //(promptMessageData, "456");

  /**
   * PS_CGAI_12.118 to PS_CGAI_12.122 ,delete the promptMessage of the particular id
   * @param {tasks text of the particular row} taskId
   */
  const handleDeleteTask = (index) => {
    const updatedTasks = tasks.filter((_, i) => i !== index);
    const updatedTasksWithOrder = updatedTasks.map((task, newIndex) => ({
      ...task,
      promptMessageOrder: newIndex + 1,
    }));

    setTasks(updatedTasksWithOrder);
    setPromptData((prevPromptData) => ({
      ...prevPromptData,
      promptMessages: updatedTasksWithOrder,
    }));
    //("step-13", promptData)
  };

  /**
   * PS_CGAI_12.123 to PS_CGAI_12.128,this function is used to drag and drop the prompt message using the package
   * @param {the data which is dragged will be in result param} result
   */

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const reorderedTasks = Array.from(tasks);
    const [removed] = reorderedTasks.splice(result.source.index, 1);
    reorderedTasks.splice(result.destination.index, 0, removed);
    const updatedTasks = reorderedTasks.map((task, index) => ({
      ...task,
      promptMessageOrder: index + 1,
    }));

    setTasks(updatedTasks);
    setPromptData((prevPromptData) => ({
      ...prevPromptData,
      promptMessages: updatedTasks,
    }));
    //("step-15", promptData)
  };

  /**
   * PS_CGAI_12.129 to PS_CGAI_12.134,this function is used to drag and drop the prompt questions using the package
   * @param {the data which is dragged will be in result param} result
   */
  const handleDrag = (result) => {
     
    if (!result.destination) {
      return;
    }

    const updatedPF = Array.from(PF);
    const movedItem = updatedPF[result.source.index];
    updatedPF.splice(result.source.index, 1);
    updatedPF.splice(result.destination.index, 0, movedItem);
    const updatedPFWithOrders = updatedPF.map((item, index) => ({
      ...item,
      promptFieldOrder: index + 1,
      inputValueOrder: `input-${index + 1}`,
    }));

    setPF(updatedPFWithOrders);

    setPromptData((prevPromptData) => ({
      ...prevPromptData,
      promptFields: updatedPFWithOrders,
    }));
    //("step-18", promptData)
  };

  //(PF, "......")

  //(tasks, ",,,,,,")

  /**
   * PS_CGAI_12.135 to PS_CGAI_12.157,this function is used to bind the inputype
   * @returns the value of the inputtype
   */

  function bindInputType() {
    const processedRefCodes = [];
    return inputTypeData?.map((val, i) => {
      if (!processedRefCodes.includes(val.inputTypeRefCode)) {
        processedRefCodes.push(val.inputTypeRefCode);
        switch (val.inputTypeRefCode) {
          case "RB" || "DD" || "CB":
            return (
              <>
                <a
                  class="dropdown-item py-2 custom-dropdown-li border-bottom"
                  disabled={true}
                  onClick={() => {
                    setDisplayQuestions(true)
                    let id = val.inputTypeID;
                    //(id, "i")

                    setPromptFieldData((pre) => ({
                      ...pre,
                      inputTypeID: id,
                      promptFieldID: "",
                      promptFieldOrder: PF?.length + 1,
                      inputTypeRefCode: val.inputTypeRefCode,
                      inputValueOrder: `input-${PF?.length + 1}`,
                      hitAI: hitAiVar
                    }));
                    setSelectedOption(val.inputTypeRefCode);
                    setShow(true);
                  }}
                >
                  Choice
                </a>
              </>
            );

          case "TXT":
            return (
              <>
                <a
                  class="dropdown-item py-2 custom-dropdown-li border-bottom"
                  onClick={() => {
                    let id = val.inputTypeID;
                    setDisplayQuestions(true)
                    setPromptFieldData((pre) => ({
                      ...pre,
                      inputTypeID: id,
                      promptFieldOrder: PF?.length + 1,
                      promptFieldID: "",
                      inputTypeRefCode: val.inputTypeRefCode,
                      hitAI: hitAiVar,
                      inputValueOrder: `input-${PF?.length + 1}`,
                    }));
                    setLongAnswer(false)
                    setSelectedOption(val.inputTypeRefCode);
                    setShow(true);
                  }}
                >
                  Text
                </a>
              </>
            );
          case "CS":
            return (
              <>
                <a
                  class="dropdown-item py-2 custom-dropdown-li border-bottom"
                  onClick={() => {
                    let id = val.inputTypeID;
                    setDisplayQuestions(true)
                    setPromptFieldData((pre) => ({
                      ...pre,
                      inputTypeID: id,
                      promptFieldOrder: PF?.length + 1,
                      promptFieldID: "",
                      inputTypeRefCode: val.inputTypeRefCode,
                      hitAI: hitAiVar,
                      inputType: "CS",
                      inputValueOrder: `input-${PF?.length + 1}`,
                    }));
                    setSelectedOption(val.inputTypeRefCode);
                    setShow(true);
                  }}
                >
                  Code Snippet
                </a>
              </>
            );
          case "TS":
            return (
              <>
                <a
                  class="dropdown-item py-2 custom-dropdown-li border-bottom"
                  onClick={() => {
                    let id = val.inputTypeID;
                    setDisplayQuestions(true)
                    setPromptFieldData((pre) => ({
                      ...pre,
                      inputTypeID: id,
                      promptFieldOrder: PF?.length + 1,
                      promptFieldID: "",
                      inputTypeRefCode: val.inputTypeRefCode,
                      inputValueOrder: `input-${PF?.length + 1}`,
                      hitAI: hitAiVar
                    }));
                    setSelectedOption(val.inputTypeRefCode);
                    setShow(true);
                  }}
                >
                  Tech Stack
                </a>
              </>
            );
          // case "FS":
          //   return (
          //     <>
          //       <a
          //         class="dropdown-item py-2 custom-dropdown-li border-bottom"
          //         onClick={() => {
          //           let id = val.inputTypeID;
          //           setPromptFieldData((pre) => ({
          //             ...pre,
          //             inputTypeID: id,
          //             promptFieldOrder: PF?.length + 1,
          //             promptFieldID: "",
          //             inputTypeRefCode: val.inputTypeRefCode,
          //             inputValueOrder: `input-${PF?.length + 1}`,
          //             hitAI: hitAiVar
          //           }));
          //           setSelectedOption(val.inputTypeRefCode);
          //           setShow(true);
          //         }}
          //       >
          //         File Structure
          //       </a>
          //     </>
          //   );
          case "UF":
            return (
              <>
                <a
                  class="dropdown-item py-2 custom-dropdown-li border-bottom"
                  onClick={() => {
                    let id = val.inputTypeID;
                    setPromptFieldData((pre) => ({
                      ...pre,
                      ["inputTypeID"]: id,
                      ["promptFieldOrder"]: PF?.length + 1,
                      promptFieldID: "",
                      ["inputTypeRefCode"]: val.inputTypeRefCode,
                      ["inputValueOrder"]: `input-${PF?.length + 1}`,
                      
                      hitAI: hitAiVar
                    }));
                    setSelectedOption(val.inputTypeRefCode);
                    setShow(true);
                    setPromptFieldData((pre) => ({ ...pre, ["labelName"]: "Upload image" }))
                  }}
                >
                  Upload
                </a>
              </>
            );
        }
      }
    });
  }

  //(editChoices, "editChoic");

  /**
     * PS_CGAI_12.60 to PS_CGAI_12.70,this function is used to call the 
     particular component for the particular input field
     * @returns 
     */
  const renderInputComponent = () => {
    switch (selectedOption) {
      case "RB":
        return (
          <div>
            <div>
              <toggleContext.Provider
                value={{
                  editChoices: editChoices,
                  chs: choices,
                  options: addOption,
                  functions: onChangeData,
                }}
              >
                <ChoicesComponent />
              </toggleContext.Provider>
            </div>
          </div>
        );
      case "DD":
        return (
          <div>
            <div>
              {/* {//(editChoices)} */}
              <toggleContext.Provider
                value={{
                  chs: choices,
                  editChoices: editChoices,
                  options: addOption,
                  functions: onChangeData,
                }}
              >
                <ChoicesComponent />
              </toggleContext.Provider>
            </div>
          </div>
        );
      case "CB":
        return (
          <div>
            <div>
              <toggleContext.Provider
                value={{
                  chs: choices,
                  editChoices: editChoices,
                  options: addOption,
                  functions: onChangeData,
                }}
              >
                <ChoicesComponent />
              </toggleContext.Provider>
            </div>
          </div>
        );
      case "TS":
        return (
          <toggleContext.Provider value={{ functions: onChangeData }}>
            <DropdownComponent />
          </toggleContext.Provider>
        );
      case "TXT":
        return (
          <div>
            <toggleContext.Provider value={{ functions: onChangeData }}>
              {" "}
              <Text />
            </toggleContext.Provider>
          </div>
        );

      case "CS":
        return (
          <div>
            {/* <div className="w-100">
              <div className="col-md-4 col-sm-12 mb-3 d-flex align-items-center">
                <input
                  type="text"
                  className="form-control theme-focus input-field"
                  placeholder="Add title"
                  disabled={true}
                />
              </div>
            </div> */}
            <div className="col-md-12 col-sm-12 mb-3">
              <toggleContext.Provider value={{ functions: onChangeData }}>
                <CodeSnippet />
              </toggleContext.Provider>
            </div>
          </div>
        );
      case "LA":
        return (
          <div>
            <div className="col-md-12 col-sm-12 mb-3">
              <toggleContext.Provider value={{ functions: onChangeData }}>
                <CodeSnippet />
              </toggleContext.Provider>
            </div>
          </div>
        );
      case "UF":
        return (
          <div>
            <div className="upload-btn-wrapper">
              <toggleContext.Provider value={{ functions: onChangeData }}>
                <MediaUpload />
              </toggleContext.Provider>
            </div>
          </div>
        );

      case "FS":
        return (
          <div>
            <div class="d-flex align-items-center pb-3">
              <toggleContext.Provider value={{ functions: onChangeData }}>
                <AddFileOrFolder />
              </toggleContext.Provider>
            </div>
          </div>
        );

      default:
        return null;
    }
  };
  const handleDelete = (index) => {
    setPF((prevPF) => {
      const updatedPF = [...prevPF];
      const removedItem = updatedPF.splice(index, 1)[0];

      // Update promptFieldOrder and inputValueOrder for the remaining items
      const updatedPFWithOrders = updatedPF.map((item, newIndex) => ({
        ...item,
        promptFieldOrder: newIndex + 1,
        inputValueOrder: `input-${newIndex + 1}`,
      }));

      setPromptData((prevPromptData) => ({
        ...prevPromptData,
        promptFields: updatedPFWithOrders,
      }));
      //("step-19", promptData)

      return updatedPFWithOrders;
    });
  };

  // function bindModel(modelData) {
  //(modelData, "in");
  //   if (modelData) {
  //("innnnnnnnnn");
  //     return modelData?.map((value, index) => (
  //       <li value={modelData.modelConfigurationID} id={value.modelConfiguration} key={index} class="font-14 font-medium color-black" onClick={(e) => {
  //         // Update the selected  when clicked

  //           setPromptMessageData({
  //             ...promptMessageData,
  //             modelConfigurationID: e.target.value,
  //           });

  //       }}><a value={value.modelConfigurationID}
  //         id={value.modelConfigurationID}
  //         key={index} class="dropdown-item py-2 custom-dropdown-li border-bottom" onClick={(e) => {  setPromptMessageData({
  //           ...promptMessageData,
  //           modelConfigurationID: e.target.value,
  //         }); }}>{value.modelConfiguration}</a></li>
  //     ));
  //   }
  // }



  //(modelName,modelConfigurationID,"HHHHHHHHHHHHHHHH");



  function bindModel(modelData) {
    //(modelData, "+++");
    if (modelData && Array.isArray(modelData)) {
      // const azureGpt3Model = modelData.find(model => model.modelName === "Azure gpt 3.5 preview");
      //(azureGpt3Model,"______");
      // // Extract modelName and modelConfigurationID
      //  modelName = azureGpt3Model ? azureGpt3Model.modelName : null;
      //  modelConfigurationID = azureGpt3Model ? azureGpt3Model.modelConfigurationID : null;

      return modelData.map((value, index) => (
        <option value={value.modelConfigurationID} id="modelConfiguaration"
        data-bs-toggle="tooltip"
            data-bs-placement="left"
            data-bs-original-title={value.modelDescription}
            title={value.modelDescription}
        key={index}>
          {value.displayName}
        </option>
      ));
    }
    return null;
  }


  //(modelData,"fgvhbjnkm");

  /**
   * PS_CGAI_12.210 to PS_CGAI_212 ,Bind the input values in PF state variable dynamically
   * @param {The values of question will be in PF in the form of array} PF
   * @returns dynamicaly binded dropdown with respective values
   */

  function bindKeyData(PF) {
    if (PF && Array.isArray(PF)) {
      //(PF, "uuuu");
      return PF.map((value, index) => (
        <option value={value.inputKey} id="inputKey" key={index}>
          {value.inputValueOrder}
        </option>
      ));
    }
  }

  //(practiceID,"OOO")

  /**
   *  PS_CGAI_12.213 to PS_CGAI_215 ,Bind the condition names in conditionData state variable dynamically
   * @param {Conditions will be stored in this state variable} conditionData
   * @returns dynamicaly binded dropdown with respective values
   */
  function bindConditionData(conditionData) {
    if (conditionData && Array.isArray(conditionData)) {
      return conditionData.map((value, index) => (
        <option value={value.conditionID} id={value.conditionID} key={index}>
          {value.inputCondition}
        </option>
      ));
    }
  }

  /**
   * PS_CGAI_12.207 to 209 ,Bind the prompt logo names in promptLogoData state variable dynamically in the dropdown.
   * @param {promptLogo names and path will be stored in the state variable will be stored in this state variable} promptLogoData
   * @returns
   */
  function bindPromptLogo(promptLogoData) {
    if (promptLogoData && Array.isArray(promptLogoData)) {
      return promptLogoData.map((value, index) => (
        <li
          value={value.promptLogoID}
          id={value.promptLogoID}
          key={index}
          className="font-14 font-medium text-color-black align-items-center d-flex"
        >
          <a
            className="dropdown-item py-2 custom-dropdown-li border-bottom"
            value={value.promptLogoID}
            id={`${value.promptLogoID}#${value.promptLogoPath}#${value.promptLogoName}`}
            key={index}
            onClick={(e) => {

              setErrorData({ ...errorData, promptLogoIDError: "" })

              let values = e.target.id.split("#");
              setPromptLogoValues({
                ...promptLogoValues,
                logoPath: values[1],
                logoName: values[2],
                logoID: values[0],
              });
              setPromptData({ ...promptData, promptLogoID: values[0], enhancedSystemMessage: enhancedSystemMessage });
              //("step-20", promptData)
            }}
          >
            <span className="me-2">
              <img
                src={value.promptLogoPath}
                alt="drop-icon"
                className="drop-icon"
              />
            </span>
            {value.promptLogoName}
          </a>
        </li>
      ));
    }
  }

  //(showPage, isLoading)
  return (
    <>
      {!isLoading ?
        <Loader isLoading={isLoading} />
        :
        <div className="container-fluid">
          <div className="row justify-content-center" style={{ marginLeft: "2%" }}>
            {(showPage == "no records" || showPage == "manage prompt") &&
              isLoading && (
                <ManagePromptCards

                  setPracticeID={setPracticeID}
                  setPopUpText={setPopUpText}
                  promptCards={promptCard}
                  setShowPopup={setShowPopup}
                  setShowPage={setShowPage}
                  setPromptIndex={setPromptIndex}
                  handleCreatePrompt={handleCreatePrompt}
                  setHandlePromptHistory={setHandlePromptHistory}
                />
              )}
            {/* {showPage === "manage prompt" && isLoading && <Loader isLoading={isLoading} />} */}
            {showPage === "showManagePrompt" ? (
              <div className="d-flex justify-content-center my-5 position-relative">
                <span
                  className={`wizard-num${promptMessagePage == ""
                    ? `-active`
                    : promptMessagePage != ""
                      ? `-active completed`
                      : ``
                    } font-18 font-semibold d-block d-flex align-items-center justify-content-center me-2`}
                >
                  1
                </span>
                <span
                  className="wizard-text-active wizard-line font-18 font-semibold d-block d-flex align-items-center justify-content-center me-5 position-relative cursor-pointer"
                  onClick={() => {
                    setPromptMessagePage("");
                    setSubmitType("SD");
                  }}
                >
                  {/* {//(showPage, "i")} */}
                  Questions
                </span>
                <span
                  className={`wizard-num${promptMessagePage != "" ? `-active` : ``
                    } font-18 font-semibold d-block d-flex align-items-center justify-content-center me-2 ms-5`}
                >
                  2
                </span>
                <span
                  className={`wizard-text${promptMessagePage != "" ? `-active` : ``
                    } font-18 font-semibold d-block d-flex align-items-center justify-content-center cursor-pointer`}
                  onClick={() => {
                    if (PF.length !== 1 && promptData.promptName !== "" && promptData.promptLogoID !== "") {
                      setPromptMessagePage("show");
                    }
                    else {
                      let obj = {
                        promptNameError: "",
                        promptLogoIDError: "",
                      };

                      // Check if any of the required fields is not provided
                      if (promptData.promptName === "") {
                        obj.promptNameError = "Enter the prompt name!";
                      }
                      if (promptData.promptLogoID === "") {
                        obj.promptLogoIDError = "Select the promptLogo!";
                      }
                      if (PF.length === 1) {
                        setError("Please add at least one prompt field!");
                      }

                      // // If any error is set, display it
                      // if (obj.promptNameError !== "" || obj.promptLogoIDError !== "" || PF.length === 1) {
                      //     setErrorData(obj);
                      // } 
                      setErrorData(obj);
                    }
                    setSubmitType("");
                  }}
                >
                  Prompts
                </span>
              </div>
            ) : (
              <></>
            )}
            {promptMessagePage == "" && showPage === "showManagePrompt" ? (
              <div className="col-md-10">
                <h3 className="font-24 font-semibold black-v1 mt-3 mb-5">
                  {promptIndex ? `Update ` : `Create New`} Prompt
                </h3>
                <div className="row">
                  <div className="col-md-10 col-sm-12 mb-3">
                    <label
                      htmlFor="Prompt-Name"
                      className="form-label label-sty"
                    >
                      Prompt Name <span style={{ "color": "red" }}>*</span>
                    </label>
                    {HandlePromptHistory ? (<input
                      type="text"
                      className="form-control theme-focus input-field"
                      id="promptName"
                      value={promptData.promptName}
                      placeholder="Enter name of the prompt"
                      onChange={(e) => {
                        // handlePromptChange(e);
                        // setPromptName(e.target.value)
                        const value = e.target.value;
                        //(value.trim() === "", "truetrue")
                        setDisableKBEdit(value.trim() === "");
                        handlePromptChange(e);
                        setPromptName(value);
                      }}
                    />) : (<input
                      type="text"
                      className="form-control theme-focus input-field"
                      id="promptName"
                      value={promptData.promptName}
                      placeholder="Enter name of the prompt"
                      disabled
                    />)}

                    {"     "}{" "}
                    <h6>
                      {errorData.promptNameError ? (
                        <div className="span-color">
                          {errorData.promptNameError}
                        </div>
                      ) : null}
                    </h6>
                  </div>
                  <div className="col-md-2 dropdown cursor-pointer">
                    <label
                      htmlFor="Prompt-Name"
                      className="form-label label-sty d-block"
                    >
                      Choose logo <span style={{ "color": "red" }}>*</span>
                    </label>
                    {HandlePromptHistory ? (<><button
                      className="btn  input-field font-14 font-semibold theme-focus col-md-2 w-100 d-flex justify-content-between bg-white"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {promptLogoValues.logoName &&
                        promptLogoValues.logoPath ? (
                        <span className="ps-1">
                          <img
                            style={{ width: "16px", height: "16px" }}
                            src={promptLogoValues.logoPath}
                            alt="dropdown-icon"
                          />{" "}
                          {promptLogoValues.logoName}
                        </span>
                      ) : (
                        "Select"
                      )}
                    </button>
                      <ul
                        className="dropdown-menu border-0 shadow-sm p-2 w-100 stack-li-scroll cust-chooselogo"
                        value={promptData.promptLogoID}
                      >
                        {bindPromptLogo(promptLogoData)}
                      </ul>
                      <h6>
                        {errorData.promptLogoIDError ? (
                          <div className="span-color">
                            {errorData.promptLogoIDError}
                          </div>
                        ) : null}
                      </h6>
                    </>
                    ) : (<button
                      style={{ backgroundColor: 'lightgrey' }}
                      className="btn input-field font-14 font-semibold col-md-2 w-100 d-flex justify-content-between"
                      type="button"
                    >
                      {promptLogoValues.logoName &&
                        promptLogoValues.logoPath ? (
                        <span className="ps-1">
                          <img
                            style={{ width: "16px", height: "16px" }}
                            src={promptLogoValues.logoPath}
                            alt="dropdown-icon"
                          />{" "}
                          {promptLogoValues.logoName}
                        </span>
                      ) : (
                        "Select"
                      )}
                    </button>)}

                  </div>
                </div>
                <div className="col-md-12 col-sm-12 mb-3">
                  <label htmlFor="Description" className="form-label label-sty">
                    Description
                  </label>
                  {HandlePromptHistory ? (
                    <textarea
                      className="form-control theme-focus input-field resize-none"
                      value={promptData.promptDescription}
                      rows={3}
                      defaultValue={""}
                      id="promptDescription"
                      placeholder="Enter description of the prompt"
                      onChange={(e) => {
                        handlePromptChange(e);
                      }}
                    />) : (
                    <textarea
                      className="form-control theme-focus input-field resize-none"
                      value={promptData.promptDescription}
                      rows={3}
                      defaultValue={""}
                      id="promptDescription"
                      placeholder="Enter description of the prompt"
                      disabled
                    />
                  )}
                </div>


                <div className="row border-bottom pb-3 system-message-container">
                  <div className="col-md-12 col-sm-12 mb-3">
                    <label
                      htmlFor="Enter-Question-1"
                      className="form-label label-sty"
                    >
                      System Message
                    </label>
                    {HandlePromptHistory ? (
                      <textarea

                        className="form-control theme-focus input-field mb-3"
                        rows={2}
                        id="systemMessage"
                        placeholder="This prompt explicitly instructs the user to input a system message that describes the role or function of the AI assistant, such as providing information (Ex:You are an AI assistant that helps people find information)"
                        value={promptData.systemMessage}
                        onChange={(e) => {
                          handlePromptChange(e)
                        }}
                      >  </textarea>
                    ) : (

                      <textarea

                        className="form-control theme-focus input-field mb-3"
                        rows={2}
                        id="systemMessage"
                        placeholder="This prompt explicitly instructs the user to input a system message that describes the role or function of the AI assistant, such as providing information (Ex:You are an AI assistant that helps people find information)"
                        onChange={(e) => handlePromptChange(e)}
                      >
                        {promptData.systemMessage}
                      </textarea>

                    )}


                    <button class={`border-0 bg-none system-message`} onClick={(e) => {
                      if(promptData.systemMessage.length>1){
                        handleSystemMessageUpdate()
                      }
                      }}>
                      <img src="images-new/ask-asami-btn.svg" disabled={promptData?.systemMessage?.length > 1 ? false : true} className={heartBeat ? `system-message-rot` : ``} alt="ask-asami" />
                    </button>

                  </div>
                </div>
                {HandlePromptHistory ? (
                  <div className="col-md-12 col-sm-12 mb-3">
                    <h3 className="font-20 color-black-v1 font-bold my-4">Knowledge Base</h3>
                    <p className="font-15 font-medium color-grey-v3">Elevate your prompts with precision – seamlessly integrate and manage knowledge bases for enhanced task execution.</p>
                    <button type="button" disabled={disableKBEdit} className="btn primary-btn font-14 font-semibold border-radius-5 color-white py-2 px-3 mb-3" data-bs-toggle="modal" data-bs-target="#Helpus-popup">Add Knowledge Base</button>
                    <AddKnowledgeBase setAskAsamiKbRetriever={setAskAsamiKbRetriever} editKburl={kbRetrieverUrl} initialData={knowledgeData} knowledgeData_func={setKnowledgeData} knowledgeUrl_func={setKbUrl} promptName={promptName} index_func={setIndexName} collectionName={promptName} indexName={indexName} promptID={promptID} collection_func={setcollection_name} />
                  </div>) : (<></>)}

                <div
                  className="row"
                // hidden={
                //   selectedOption == "RB" ||
                //   selectedOption == "DD" ||
                //   selectedOption == "CB"
                // }
                >
                  <h3 className="font-20 font-semibold black-v1 mt-5 mb-4">
                    Manage Questions
                  </h3>
                  {/* {submitType !== "SD" &&
                  PF?.map((key, index) => (
                    <div key={index} className="col-md-12 col-sm-12 mb-3">
                      <label className="form-label label-sty mb-3">
                        {index + 1}.
                        {key?.labelName ? key?.labelName : key?.inputTypeName}
                      </label>
                      {key?.choiceName?.map((value, choiceIndex) => (
                        <div key={choiceIndex} className="form-check mb-3">
                          <input
                            className="form-check-input custom-check-input 1 theme-focus"
                            type="radio"
                            name="flexRadioDefault"
                            id={`react-js-${index}-${choiceIndex}`}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`react-js-${index}-${choiceIndex}`}
                          >
                            {value}
                          </label>
                        </div>
                      ))}
                    </div>
                  ))} */}
                </div>
                {/* ist */}
                {selectedOption && (
                  <div>
                    <div className="row border-bottom pb-3">
                      <div class="col-md-12 col-sm-12 mb-3">
                        <label
                          htmlFor="Enter-Question-1"
                          className="form-label label-sty"
                        >
                          {fieldIndex ? fieldIndex : PF.length + 1}. Enter
                          Question
                        </label>
                        {errorData.labelNameError ? (
                          <h4>
                            <div className="span-color">
                              {errorData.labelNameError}
                            </div>
                          </h4>
                        ) : null}
                        <input
                          type="text"
                          className="form-control theme-focus input-field mb-3"
                          id="labelName"
                          placeholder="Specify the Question"
                          value={promptFieldData.labelName}
                          //disabled={selectedOption == "UF" ? true : false}
                          onChange={(e) => {
                            onChangeData(e);
                          }}
                        />
                      </div>
                      {renderInputComponent()}
                    </div>

                    <div className="row">
                      <div class="d-flex justify-content-between ">
                        <div className="d-flex align-items-center py-3">
                          {(selectedOption == "RB" ||
                            selectedOption == "DD" ||
                            selectedOption == "CB") && (
                              <button
                                type="checkbox"
                                className="btn grey-v3 me-5 py-0 font-11 font-medium px-0 me-4 shadow-none"
                                onClick={() => {
                                  setAddOption((e) => e + 1);
                                }}
                              >
                                <img
                                  className="me-2 mb-1 icon-width"
                                  src="images/Default/add-grey-icon.svg"
                                  alt="add-grey-icon"
                                  onClick={() => {
                                    setAddOption((e) => e + 1);
                                  }}
                                />
                                Add Option
                              </button>
                            )}
                          {(selectedOption == "TXT" ||
                            selectedOption == "CS" ||
                            selectedOption == "LA") && (
                              <div className="form-check form-switch me-5">
                                <input
                                  className="form-check-input custom-check-input 2 theme-focus"
                                  type="checkbox"
                                  role="switch"
                                  id="Long-answer"
                                  checked={
                                    longAnswer ||
                                    promptFieldData.inputTypeRefCode === "CS" ||
                                    promptFieldData.inputTypeRefCode === "LA"
                                  }
                                  disabled={promptFieldData.inputType == "CS"}
                                  onClick={() => {
                                    if (!longAnswer) {
                                      let id = "";
                                      inputTypeData?.map((val, i) => {
                                        if (val.inputTypeRefCode == "CS") {
                                          id = val.inputTypeID;
                                        }
                                      });
                                      setSelectedOption("LA");
                                      toggleButtonHandle();
                                      setPromptFieldData((pre) => ({
                                        ...pre,
                                        inputTypeID: id,
                                        inputTypeRefCode: "CS",
                                        hitAI: hitAiVar
                                      }));
                                      setLongAnswer(true);
                                    } else {
                                      let id = "";
                                      inputTypeData?.map((val, i) => {
                                        if (val.inputTypeRefCode == "TXT") {
                                          id = val.inputTypeID;
                                        }
                                      });
                                      setSelectedOption("TXT");
                                      setPromptFieldData((pre) => ({
                                        ...pre,
                                        inputTypeID: id,
                                        hitAI: hitAiVar,
                                        inputTypeRefCode: "TXT",
                                      }));
                                      toggleButtonHandle();
                                      setLongAnswer(false);
                                    }
                                    // setLongAnswer((e) => !e);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Long-answer"
                                >
                                  Long answer
                                </label>
                              </div>
                            )}
                          {(selectedOption == "RB" ||
                            selectedOption == "DD" ||
                            selectedOption == "CB") && (
                              <div className="form-check form-switch me-5">
                                <input
                                  className="form-check-input custom-check-input 3 theme-focus"
                                  type="checkbox"
                                  role="switch"
                                  id="Convert-to-dropdown"
                                  checked={dropdown}
                                  onClick={(e) => {
                                    setPromptFieldData((pre) => ({
                                      ...pre,
                                      required: "",
                                      errorMessage: "",
                                      hitAI: hitAiVar,
                                    }));
                                    clearOtherFunction(e);
                                    let id = "";
                                    inputTypeData?.map((val, i) => {
                                      if (val.inputTypeRefCode == "DD") {
                                        id = val.inputTypeID;
                                      }
                                    });

                                    setPromptFieldData((pre) => ({
                                      ...pre,
                                      inputTypeID: id,
                                      hitAI: hitAiVar,
                                      inputTypeRefCode: "DD",
                                    }));

                                    if (!dropdown) {
                                      let id = "";
                                      inputTypeData?.map((val, i) => {
                                        if (val.inputTypeRefCode == "DD") {
                                          id = val.inputTypeID;
                                        }
                                      });
                                      setChoices("dropdown");
                                      setSelectedOption("DD");
                                      toggleButtonHandle();
                                      setPromptFieldData((pre) => ({
                                        ...pre,
                                        inputTypeID: id,
                                        inputTypeRefCode: "DD",
                                        hitAI: hitAiVar
                                      }));
                                      setDropdown(true);
                                    } else {
                                      let id = "";
                                      inputTypeData?.map((val, i) => {
                                        if (val.inputTypeRefCode == "RB") {
                                          id = val.inputTypeID;
                                        }
                                      });
                                      setChoices("radio");
                                      setSelectedOption("RB");
                                      setPromptFieldData((pre) => ({
                                        ...pre,
                                        inputTypeID: id,
                                        inputTypeRefCode: "RB",
                                        hitAI: hitAiVar
                                      }));
                                      toggleButtonHandle();
                                      setDropdown(false);
                                    }
                                    // setDropdown((e) => !e);
                                    //  setDropdown(true);
                                    setCheckbox(false);
                                    // setEditChoices([]);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Convert-to-dropdown"
                                >
                                  Convert to dropdown
                                </label>
                              </div>
                            )}
                          {(selectedOption == "RB" ||
                            selectedOption == "DD" ||
                            selectedOption == "CB") && (
                              <div className="form-check form-switch me-5">
                                <input
                                  className="form-check-input custom-check-input 4 theme-focus"
                                  type="checkbox"
                                  role="switch"
                                  id="Multiple-answers"
                                  checked={checkbox}
                                  onClick={(e) => {
                                    setPromptFieldData((pre) => ({
                                      ...pre,
                                      required: "",
                                      errorMessage: "",
                                      hitAI: hitAiVar
                                    }));
                                    let id = "";
                                    clearOtherFunction(e);
                                    inputTypeData?.map((val, i) => {
                                      if (val.inputTypeRefCode == "CB") {
                                        id = val.inputTypeID;
                                      }
                                    });

                                    if (!checkbox) {
                                      let id = "";
                                      // clearOtherFunction(e);
                                      inputTypeData?.map((val, i) => {
                                        if (val.inputTypeRefCode == "CB") {
                                          id = val.inputTypeID;
                                        }
                                      });
                                      setChoices("checkbox");
                                      setSelectedOption("CB");
                                      setPromptFieldData((pre) => ({
                                        ...pre,
                                        inputTypeID: id,
                                        inputTypeRefCode: "CB",
                                        hitAI: hitAiVar
                                      }));
                                      toggleButtonHandle();
                                    } else {
                                      let id = "";
                                      // clearOtherFunction(e);
                                      inputTypeData?.map((val, i) => {
                                        if (val.inputTypeRefCode == "RB") {
                                          id = val.inputTypeID;
                                        }
                                      });
                                      setChoices("radio");
                                      setSelectedOption("RB");
                                      setPromptFieldData((pre) => ({
                                        ...pre,
                                        inputTypeID: id,
                                        inputTypeRefCode: "RB",
                                        hitAI: hitAiVar
                                      }));
                                      toggleButtonHandle();
                                    }
                                    setCheckbox((e) => !e);
                                    setDropdown(false);
                                    // setEditChoices([]);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Multiple-answers"
                                >
                                  Multiple answers
                                </label>
                              </div>
                            )}
                          <div className="form-check form-switch me-5">
                            <input
                              className="form-check-input custom-check-input 5 theme-focus"
                              type="checkbox"
                              role="switch"
                              id="Required"
                              checked={required}
                              onClick={() => {
                                if (!required) {
                                  setPromptFieldData({
                                    ...promptFieldData,
                                    errorMessage: "This is a Mandatory Field",
                                    required: "true",
                                  });
                                } else {
                                  setPromptFieldData({
                                    ...promptFieldData,
                                    errorMessage: "",
                                    required: "false",
                                  });
                                }
                                setRequired((e) => !e);
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="Required"
                            >
                              Required
                            </label>
                          </div>

                          <div className="form-check form-switch me-5">
                            <input
                              className="form-check-input custom-check-input 5 theme-focus"
                              type="checkbox"
                              role="switch"
                              id="AIHit"
                              checked={aiReq}
                              onClick={() => {
                                if (!aiReq) {
                                  setPromptFieldData({
                                    ...promptFieldData,
                                    errorMessage: "This is a Mandatory Field",
                                    hitAI: 1,
                                  })
                                  sethitAiVar(1);
                                } else {
                                  setPromptFieldData({
                                    ...promptFieldData,
                                    errorMessage: "",
                                    hitAI: 0,
                                  });
                                  sethitAiVar(0)
                                }
                                setAiReq((e) => !e);
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="Required"
                            >
                              Enhance Requirement
                            </label>
                          </div>





                        </div>
                        <div className="d-flex align-items-center">
                          <button
                            type="button"
                            className="btn btn-outline-secondary font-14 font-semibold me-3"
                            onClick={() => {
                              setSelectedOption("");
                              setFieldIndex("");
                              setPromptFieldData(promptFieldObj);
                              setEditChoices([]);
                              setHiddenIndices([]);
                              setRequired(false);
                              setAiReq(false)
                              setKnowledgeData([])
                              setDisplayQuestions(false)
                              // handleBackClick()
                              // setErrorData(errorObj)
                              // setCheckbox(false);
                            }}
                          >
                            Cancel
                          </button>

                          <button
                            type="button"
                            id="add-field-button"
                            className="btn theme-outline-btn font-14 font-semibold theme-focus me-3"
                            style={{ backgroundColor: "#f0e9fd", color: "#7a44e3", border: "1px solid #7a44e3" }}
                            disabled={promptFieldData.labelName == ""}
                            onClick={() => {
                              if (promptFieldData.labelName) {
                                let promptArr = PF;

                                //(promptArr, PF, promptFieldData);
                                if (fieldIndex) {
                                  promptArr[fieldIndex - 1] = promptFieldData;
                                } else {
                                  let promptFieldDataObj = promptFieldData;
                                  promptFieldDataObj.createdBy =
                                    userDetails?.email;
                                  promptArr.push(promptFieldDataObj);
                                }
                                setPF(promptArr);
                              }
                              // if(hiddenIndices.length!=0){
                              //   setHiddenIndices([])
                              // }
                              setSelectedOption("");
                              setFieldIndex("");
                              setCheckbox(false);
                              setAddOption(2);
                              setDropdown(false);
                              setChoices("radio");
                              setHiddenIndices([]);
                              setPromptFieldData((pre) => ({
                                ...pre,
                                labelName: "",
                                choiceName: [],
                                required: "",
                                hitAI: 0,
                                errorMessage: "",
                              }));
                              setEditChoices([]);
                              setRequired(false);
                              setAiReq(false)
                              sethitAiVar(0)
                              setDisplayQuestions(false)

                            }}
                          >{`${promptIndex ? `UPDATE` : `ADD`}`}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <DragDropContext onDragEnd={handleDrag}>
                  <Droppable droppableId="droppable" >
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {(submitType !== "SD" || submitType === "SD") &&
                          PF.map((key, index) => (
                            

                            <Draggable
                              disabled={index == 0 ? true : false}
                              key={index}
                              draggableId={`item-${index}`}
                              index={index}
                              isDragDisabled={index === 0}
                            >

                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {/* {//(index)} */}
                                  <div className="row">
                                    <div className="col-md-12 col-sm-12 mb-3">
                                      <div
                                        className="bg-grey p-3"
                                        hidden={hiddenIndices.includes(index)}

                                      >
                                        <div className="text-center pb-3">
                                          <button
                                            type="button"
                                            disabled={index == 0 ? true : false}
                                            className="btn p-0 shadow-none cursor-grab"
                                          >
                                            {index === 0 ? (<></>) : (<img
                                              src="images/Default/drag-icon.svg"
                                              alt="drag-icon"
                                              className="me-3"
                                              disabled={index == 0 ? true : false}
                                            />)}
                                          </button>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <div>
                                            {!key?.choiceName?.some(
                                              (choice) => choice !== ""
                                            ) && (
                                                <>
                                                  <div className="form-check mb-3">
                                                    <label className="form-label label-sty mb-3 2">
                                                      {index + 1}.
                                                      {key?.labelName
                                                        ? key.labelName
                                                        : "Code"}
                                                    </label>
                                                  </div>
                                                </>
                                              )}
                                            <div className="form-check mb-3">
                                              {key?.choiceName?.some(
                                                (choice) => choice !== ""
                                              ) && (
                                                  // Check if there are non-empty choices
                                                  <>
                                                    <label className="form-label label-sty mb-3 3">
                                                      {index + 1}.
                                                      {key?.labelName
                                                        ? key.labelName
                                                        : "Code snippet"}
                                                    </label>
                                                    <div className="form-check mb-3">
                                                      {key.choiceName &&
                                                        key.choiceName.some(
                                                          (value) =>
                                                            value !== null &&
                                                            value !== ""
                                                        ) ? (
                                                        <>
                                                          {key.choiceName
                                                            .filter(
                                                              (value) =>
                                                                value !== null &&
                                                                value !== ""
                                                            ) // Filter out null and empty strings
                                                            .map(
                                                              (
                                                                trimmedValue,
                                                                index
                                                              ) => (
                                                                <div
                                                                  key={index}
                                                                  className="form-check mb-3"
                                                                >
                                                                  <input
                                                                    className="form-check-input custom-check-input 6 theme-focus"
                                                                    disabled
                                                                    type={
                                                                      key?.inputTypeRefCode ==
                                                                        "RB"
                                                                        ? "radio"
                                                                        : key?.inputTypeRefCode ==
                                                                          "CB"
                                                                          ? "checkbox"
                                                                          : "hidden"
                                                                    }
                                                                    name="flexRadioDefault"
                                                                    id={`react-js-${index}`}
                                                                  />
                                                                  <label
                                                                    className="form-check-label"
                                                                    htmlFor={`react-js-${index}`}
                                                                  >
                                                                    {trimmedValue}
                                                                  </label>
                                                                </div>
                                                              )
                                                            )}
                                                        </>
                                                      ) : null}
                                                    </div>
                                                  </>
                                                )}
                                            </div>
                                          </div>
                                          <div hidden={selectedOption}>
                                            {HandlePromptHistory && index !== 0 ? (
                                              <button
                                                type="button"
                                                className="btn p-0 shadow-none"
                                                disabled={index == 0 ? true : false}
                                                onClick={() => {
                                                  //(key);
                                                  setRequired(
                                                    key?.required == "true"
                                                  );
                                                  setAiReq(
                                                    key?.hitAI == 1
                                                  );
                                                  // required = key?.required;
                                                  setHiddenIndices([
                                                    ...hiddenIndices,
                                                    index,
                                                  ]);
                                                  if (
                                                    key.inputTypeRefCode ===
                                                    "LA" ||
                                                    key.inputTypeRefCode === "CS"
                                                  ) {
                                                    setLongAnswer(true);
                                                  } else {
                                                    setLongAnswer(false);
                                                  }
                                                  if (
                                                    key.promptFieldID &&
                                                    key.choiceName.length != 0
                                                  ) {
                                                    if (
                                                      key.inputTypeRefCode ===
                                                      "RB"
                                                    ) {
                                                      setChoices("radio");
                                                      setCheckbox(false);
                                                      setDropdown(false);
                                                    } else if (
                                                      key.inputTypeRefCode ===
                                                      "CB"
                                                    ) {
                                                      setChoices("checkbox");
                                                      setCheckbox(true);
                                                      setDropdown(false);
                                                    } else if (
                                                      key.inputTypeRefCode ===
                                                      "DD"
                                                    ) {
                                                      setChoices("dropdown");
                                                      setDropdown(true);
                                                      setCheckbox(false);
                                                    }

                                                    if (
                                                      key.inputTypeRefCode ===
                                                      "LA" ||
                                                      key.inputTypeRefCode ===
                                                      "CS"
                                                    ) {
                                                      setLongAnswer(true);
                                                    } else {
                                                      setLongAnswer(false);
                                                    }
                                                  }

                                                  setPromptFieldData(key);
                                                  setFieldIndex(index + 1);
                                                  setSelectedOption(
                                                    key?.inputTypeRefCode
                                                  );
                                                  document
                                                    .getElementById("labelName")
                                                    ?.focus();
                                                  setEditChoices(key?.choiceName);
                                                  //(key?.choiceName);
                                                }}
                                              >
                                                <img
                                                  src="images-new/edit-icons.svg"
                                                  alt="edit-icon"
                                                  className="me-3 cp-icon-sty"

                                                />
                                              </button>) : (<></>)}

                                            {HandlePromptHistory ? (
                                              <CopyToClipboard
                                                text={`${key?.labelName
                                                  ? key.labelName
                                                  : key.inputTypeName
                                                  }`}
                                              >
                                                <button
                                                  disabled={index == 0 ? true : false}
                                                  style={{ display: index == 0 ? 'none' : null }}
                                                  type="button"
                                                  className="btn p-0 shadow-none"
                                                >
                                                  <img
                                                    src="images/Default/copy-icon.svg"
                                                    alt="copy-icon"
                                                    className="me-3 cp-icon-sty"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="right"
                                                    title="copied"
                                                  />
                                                </button>
                                              </CopyToClipboard>
                                            ) : (<></>)}
                                            {HandlePromptHistory ? (
                                              <button
                                                type="button"
                                                disabled={index == 0 ? true : false}
                                                style={{ display: index == 0 ? 'none' : null }}
                                                className="btn p-0 shadow-none"
                                                onClick={() => {
                                                  handleDelete(index);
                                                }}
                                              >
                                                <img
                                                  src="images/Default/delete-icon.svg"
                                                  alt="delete-icon"
                                                  className=" cp-icon-sty"
                                                />
                                              </button>
                                            ) : (<></>)}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                {HandlePromptHistory ? (
                  <div className="pb-5">
                    <div className="dropdown mt-3 mb-5">

                      <button
                        className="btn theme-outline-btn font-14 font-semibold theme-focus"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{display : displayQuestion ? 'none' : 'block'}}
                        
                      >
                        <span className="pe-1">
                          <img src="images/Default/add-icon.svg" alt="add-icon" />
                        </span>
                        Add a Question
                        <span className="ps-1">
                        {toggleDropDown ==="down" ? <img
                            src="images/Default/dropdown-icon.svg"
                            alt="dropdown-icon"
                          />:<img
                          src="images/Default/dropdown-icon.svg"
                          alt="dropdown-icon"
                        />}
                        </span>
                      </button>
                      {
                        <h6>
                          {error ? (
                            <div className="span-color">{error}</div>
                          ) : null}
                        </h6>
                      }
                      {/* <div class="dropdown-menu border-0 shadow-sm p-2 show" aria-labelledby="dropdownMenuButton" data-popper-placement="bottom-start" style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate(0px, 37px);"> */}
                      <div
                        className="dropdown-menu border-0 shadow-sm p-2 mb-5 position-absolute"
                        // style={{ top: "-218px" }}
                        
                        aria-labelledby="dropdownMenuButton"
                      >
                        {bindInputType()}
                      </div>
                    </div>
                  </div>) : (<></>)}
              </div>
            ) : promptMessagePage != "" && showPage === "showManagePrompt" ? (


              <div classname="col-md-12">
                <h4 classname="black-v1 font-20 font-semibold">
                  Manage Task and Sequence
                </h4>
                {HandlePromptHistory ? (<>
                  <p className="grey-v2 font-14 font-medium">
                    Follow the sequence for prompt execution to get the expected
                    results with proper syntax.Please provide as in the
                    placeholder
                  </p>

                  <div class="row mb-4">
                    <div class="col-md-4 col-sm-12 mb-3 pe-4">
                      <label class="form-label label-color font-15 font-semibold color-black-v1 mb-2">Input fields </label>
                      <div className="input-group transparent-grp w-225 me-3 filter-dropdown-border search-fix-hub bg-white">
                        <button
                          className="btn focus-none w-100 t-align-left-input d-flex justify-content-between align-items-center"
                          type="button"
                          onClick={handleInputDD}
                        >
                          <span>
                            {selectedInputs.length > 0
                              ? selectedInputs.join(", ")
                              : "Select"}
                          </span>
                          <span className="ps-1">
                            <img src="images-new/dropdown-grey-icon.svg" style={{ transform: showInputDD ? "" : "rotate(180deg)" }} alt="dropdown-icon" />
                          </span>
                        </button>
                      </div>
                      <ul className="dropdown border-0 shadow-sm p-2 w-100 bg-white" style={{ "listStyle": "none", "display": showInputDD ? "block" : "none" }}>
                        {
                          PF.map((item, index) => (
                            <li className="font-14 font-medium color-black input-hover" key={index}>
                              <div className="me-4">
                                <input
                                  className="form-check-input align-middle m-0 ms-2 mb-1 user-checkbox"
                                  type="checkbox"
                                  checked={selectedInputs.includes(item.inputValueOrder)}
                                  value={item.inputValueOrder}
                                  // Check if the practice is included in selectedPractice
                                  onChange={(e) => handleMultiSelect(e, item.labelName)} // Handle onChange event
                                />
                                <label
                                  className="form-check-label p-2"
                                  htmlFor={`practiceCheckbox_${index}`}
                                >
                                  {item.inputValueOrder}
                                </label>
                              </div>
                            </li>

                          ))
                        }
                      </ul>
                    </div>
                    <div class="col-md-4 col-sm-12 mb-3 pe-4">
                      <label for="task" class="form-label label-color font-15 font-semibold color-black-v1 mb-2">Output Description</label>
                      <textarea class="form-control input-field font-14 font-regular border-radius-3 bg-white resize-none place-holder-fix-mp" id="task" rows="4" placeholder="Example: A reactJs code that is efficient and has no error"
                        value={promptMessageData.outputDesc}
                        onChange={(e) => {
                          setPromptMessageData({
                            ...promptMessageData,
                            outputDesc: e.target.value,
                          });
                          setOutputDescription(e.target.value)
                          setShowInputDD(false)
                        }}></textarea>
                    </div>
                    <div class="col-md-4 col-sm-12 mb-3 pe-4">
                      <label for="task" class="form-label label-color font-15 font-semibold color-black-v1 mb-2">Task Description </label>
                      <textarea class="form-control input-field font-14 font-regular border-radius-3 bg-white resize-none" id="task" rows="4" placeholder="Example: You will be getting a functional description. Based on that description, generate a reactJs hooks based code."
                        value={promptMessageData.promptMessage}
                        onClick={() => { setShowInputDD(false) }}
                        onChange={(e) => {
                          setPromptMessageData({
                            ...promptMessageData,
                            promptMessage: e.target.value,
                          });
                          setShowInputDD(false);
                          if (editTaskIndex != null) {
                            setTempUpdatedPrompt(e.target.value);

                          }


                        }}></textarea>
                    </div>

                    <div class="col-md-12">
                      <div class="row row-gap-32px">
                        <div class="col-md-12">
                          <h4 class="mb-3 font-semibold font-20">Example</h4>
                          <p class="mb-0 color-grey-v7 font-15 font-medium">Training data for fine-tuning the AI</p>
                        </div>
                        {/* <div class="container">
                      <div class="row"> */}
                        {
                          fewShotValues.map((component, index) => (
                            <div className="col-md-4">
                              <FewShotExapmle
                                index={component.index}
                                userContent={component.inputs[0].content}
                                assistantContent={component.inputs[1].content}
                                onEdit={handleFewShotEdit}
                                onDelete={handleFewShotDelete} />
                            </div>
                          ))
                        }
                        <div class="col-md-4">
                          <div class="dashed-container flex-column d-flex align-items-center justify-content-center p-4">
                            <button class="flex-column d-flex gap-3 font-semibold font-14 color-violet align-items-center shadow-none border-0 bg-none" type="button" data-bs-toggle="modal" data-bs-target="#examplePopup" >
                              <span class="add-icon d-flex align-items-center justify-content-center"><img src="images-new/add-purple-icon.svg" width="24" alt="add" /></span>
                              <span>Add Example</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>





                  <div class="row pb-3 mb-2">
                    <div class="col-md-4 col-sm-12 mb-3">
                      <label for="key" class="form-label label-color font-15 font-semibold color-black-v1 mb-2">Key</label>
                      <select class="form-select input-field font-14 font-regular border-radius-3 bg-white" id="key" aria-label="Default select example" onClick={(e)=>{e.currentTarget.classList.toggle('open')}} value={promptMessageData.inputKey}
                        onChange={(e) => {
                          setPromptMessageData({
                            ...promptMessageData,
                            inputKey: e.target.value,
                          });
                        }}>
                        <option selected="">select</option>
                        {bindKeyData(PF)}
                      </select>
                    </div>
                    <div class="col-md-4 col-sm-12 mb-3">
                      <label for="condition" class="form-label label-color font-15 font-semibold color-black-v1 mb-2">Condition</label>
                      <select class="form-select input-field font-14 font-regular border-radius-3 bg-white" id="condition" onClick={(e)=>e.currentTarget.classList.toggle('open')} aria-label="Default select example" value={promptMessageData.conditionID}
                        onChange={(e) => {
                          // let id = "";
                          // conditionData?.map((val, i) => {
                          //   if (val.conditionName == e.target.value) {
                          //("++++")
                          //     id = val.conditionID;

                          //   }});
                          setPromptMessageData({
                            ...promptMessageData,
                            conditionID: e.target.value,
                          });
                        }}>
                        <option selected>Select</option>
                        {bindConditionData(conditionData)}
                      </select>
                    </div>
                    <div class="col-md-4 col-sm-12 mb-3">
                      <label for="value" class="form-label label-color font-15 font-semibold color-black-v1 mb-2">Value</label>
                      <input type="text" class="form-control input-field font-14 font-regular border-radius-3 bg-white" id="inputValue"
                        placeholder="Enter the value"
                        value={promptMessageData.inputValue}
                        onChange={(e) => {
                          setPromptMessageData({
                            ...promptMessageData,
                            inputValue: e.target.value,
                          });
                        }} />
                    </div>

                    <div class="col-md-4 col-sm-12 mb-3">
                      <label for="model" class="form-label label-color font-15 font-semibold color-black-v1 mb-2">Choose Model <span style={{ "color": "red" }}>*</span></label>
                      <select class="form-select input-field font-14 font-regular border-radius-3 bg-white" id="modelConfigurationID" onClick={(e)=>e.currentTarget.classList.toggle('open')} aria-label="Default select example" value={
                        promptMessageData.modelConfigurationID
                      }
                        onChange={(e) => {
                          setPromptMessageData({
                            ...promptMessageData,
                            modelConfigurationID: e.target.value,
                          });
                        }}
                      >
                        <option selected >Select</option> {/* Disable this option */}
                        {bindModel(modelData)}
                      </select>
                      <h6>
                        {modelError.modelError ? (
                          <div className="span-color">
                            {modelError.modelError}
                          </div>
                        ) : null}
                      </h6>
                    </div>

                    <div class="col-md-4 col-sm-12 mt-3">
                      <p class="font-15 font-semibold">Need KB?</p>
                      <div class="form-check form-switch me-5 d-inline-block">
                        <input
                          class="form-check-input custom-toggle-input"
                          type="checkbox"
                          role="switch"
                          id="webSearch"
                          checked={isKbRetriever}
                          onClick={() => {
                            setPromptMessageData({
                              ...promptMessageData,
                              isKbRetriever: !isKbRetriever
                            });
                            setIsKbRetriever(prevState => !prevState); // Toggle the state
                          }}
                        />
                        <label class="form-check-label font-14 font-medium light-black-color ms-2" for="webSearch">
                          {isKbRetriever ? "Yes" : "No"} {/* Corrected the label */}
                        </label>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-12 mt-3 ">
                      <p class="font-15 font-semibold text-color-1">Web Search</p>
                      <div class="form-check form-switch me-5 d-inline-block">
                        <input
                          class="form-check-input custom-toggle-input"
                          type="checkbox"
                          role="switch"
                          id="webSearch"
                          checked={isWebSearch}
                          onClick={() => {
                            setPromptMessageData({
                              ...promptMessageData,
                              isWebSearch: !isWebSearch // Toggle the value directly
                            });
                            setIsWebSearch(prevState => !prevState); // Toggle the state
                          }}
                        />
                        <label class="form-check-label font-14 font-medium light-black-color ms-2" for="webSearch">
                          {isWebSearch ? "Yes" : "No"}
                        </label>
                      </div>
                    </div>

                    {isWebSearch == true ?
                      <div class="col-md-4 col-sm-12 mb-3">
                        <label for="value" class="form-label label-color font-15 font-semibold color-black-v1 mb-2">Web content</label>
                        <input type="text" class="form-control input-field font-14 font-regular border-radius-3 bg-white" id="inputValue" value={promptMessageData.webContent}
                          placeholder="Enter the value"
                          // value={promptMessageData.webContent}
                          onChange={(e) => {
                            setPromptMessageData({
                              ...promptMessageData,
                              webContent: e.target.value,
                            });
                          }} />
                      </div> : null}
                  </div>
                  <div class="row pb-3 mb-3">

                    {/* add task nav */}
                    <div class="custom-btn-parent-mp">
                      <div className="custom-btn-child-mp">
                        <button type="button" className="btn primary-btn font-14 font-semibold border-radius-5 color-white px-4" onClick={(e) => { editTaskIndex != null ? handleAddTask() : handlePromptGen() }}
                          disabled={
                            promptMessageData.promptMessage == ""
                          }>   {editTaskIndex !== null ? "Update" : "Add"}</button>
                        <button className="btn primary-btn font-14 font-semibold border-radius-5 color-white px-4" onClick={(e) => handlePromptGen()} style={{ "display": editTaskIndex !== null ? "block" : "none" }}>Re-Build</button>
                      </div>
                    </div>
                  </div>
                </>) : (<>
                  <p className="grey-v2 font-14 font-medium">
                    Follow the sequence for prompt execution to get the expected
                    results with proper syntax.Please provide as in the
                    placeholder
                  </p>

                  <div class="row mb-4">
                    <div class="col-md-9 col-sm-12 mb-3 pe-4">
                      <label for="task" class="form-label label-color font-15 font-semibold color-black-v1 mb-2">Task</label>
                      <textarea class="form-control input-field font-14 font-regular border-radius-3 bg-white resize-none" id="task" rows="4" placeholder="Example:Based on the below HTML, I need as detailed as possible requirements created based on the functionalities mentioned in the code. Please find the below HTML here: (input-2). Here is the context on this HTML as well: (input-3)."
                        value={promptMessageData.promptMessage}
                        disabled></textarea>
                    </div>

                    <div class="col-md-3 col-sm-12 mb-3 ">
                      <label for="key" class="form-label label-color font-15 font-semibold color-black-v1 mb-2">Key</label>
                      <select class="form-select input-field font-14 font-regular border-radius-3 bg-white" id="key" aria-label="Default select example" onClick={(e)=>e.currentTarget.classList.toggle('open')} value={promptMessageData.inputKey}
                        disabled>
                        <option selected="">selected Value</option>
                        {bindKeyData(PF)}
                      </select>
                    </div>
                    <div class="col-md-9 col-sm-12 mb-3 pe-4">
                      <label for="task" class="form-label label-color font-15 font-semibold color-black-v1 mb-2">Task</label>
                      <textarea class="form-control input-field font-14 font-regular border-radius-3 bg-white resize-none" id="task" rows="4" placeholder="Example:Based on the below HTML, I need as detailed as possible requirements created based on the functionalities mentioned in the code. Please find the below HTML here: (input-2). Here is the context on this HTML as well: (input-3)."
                        value={promptMessageData.promptMessage}
                        disabled></textarea>
                    </div>
                  </div>

                  <div class="row pb-3 mb-2">
                    <div class="col-md-4 col-sm-12 mb-3">
                      <label for="condition" class="form-label label-color font-15 font-semibold color-black-v1 mb-2">Condition</label>
                      <select class="form-select input-field font-14 font-regular border-radius-3 bg-white" id="condition" aria-label="Default select example" onClick={(e)=>e.currentTarget.classList.toggle('open')} value={promptMessageData.conditionID}
                        disabled>
                        <option selected>Selected Value</option>
                        {bindConditionData(conditionData)}
                      </select>
                    </div>
                    <div class="col-md-4 col-sm-12 mb-3">
                      <label for="value" class="form-label label-color font-15 font-semibold color-black-v1 mb-2">Value</label>
                      <input type="text" class="form-control input-field font-14 font-regular border-radius-3 bg-white" id="inputValue"
                        placeholder="Enter the value"
                        value={promptMessageData.inputValue}
                        disabled />
                    </div>

                    <div class="col-md-4 col-sm-12 mb-3">
                      <label for="model" class="form-label label-color font-15 font-semibold color-black-v1 mb-2">Choose Model</label>
                      <select class="form-select input-field font-14 font-regular border-radius-3 bg-white" id="modelConfigurationID" aria-label="Default select example" onClick={(e)=>e.currentTarget.classList.toggle('open')} value={
                        promptMessageData.modelConfigurationID
                      }
                        disabled
                      >
                        <option selected >Selected Value</option> {/* Disable this option */}
                        {bindModel(modelData)}
                      </select>
                      <h6>
                        {modelError.modelError ? (
                          <div className="span-color">
                            {modelError.modelError}
                          </div>
                        ) : null}
                      </h6>
                    </div>

                  </div>
                  <div class="row pb-3 mb-3">
                    <div class="col-md-4 col-sm-12 mb-3">
                      <p class="font-15 font-semibold">Need KB?</p>
                      <div class="form-check form-switch me-5 d-inline-block">
                        <input
                          class="form-check-input custom-toggle-input"
                          type="checkbox"
                          role="switch"
                          id="webSearch"
                          checked={isKbRetriever}
                          disabled
                        />
                        <label class="form-check-label font-14 font-medium light-black-color ms-2" for="webSearch">
                          {isKbRetriever ? "Yes" : "No"} {/* Corrected the label */}
                        </label>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-12 mb-3">
                      <p class="font-15 font-semibold text-color-1">Web Search</p>
                      <div class="form-check form-switch me-5 d-inline-block">
                        <input
                          class="form-check-input custom-toggle-input"
                          type="checkbox"
                          role="switch"
                          id="webSearch"
                          checked={isWebSearch}
                          disabled
                        />
                        <label class="form-check-label font-14 font-medium light-black-color ms-2" for="webSearch">
                          {isWebSearch ? "Yes" : "No"}
                        </label>
                      </div>
                    </div>

                    {isWebSearch == true ? <div class="col-md-4 col-sm-12 mb-3">
                      <label for="value" class="form-label label-color font-15 font-semibold color-black-v1 mb-2">Web content</label>
                      <input type="text" class="form-control input-field font-14 font-regular border-radius-3 bg-white" id="inputValue" value={promptMessageData.webContent}
                        placeholder="Enter the value"
                        // value={promptMessageData.webContent}
                        disabled />
                    </div> : null}
                  </div>
                </>)}

                {/* <div className="col-md-4few-shot-container"> */}

                {/* </div>
                </div> */}

                <div class="modal fade" id="examplePopup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered modal-lg example-popup">
                    <div class="modal-content">
                      <div class="modal-header border-0">
                        <h1 class="modal-title fs-5" id="staticBackdropLabel">Example</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                        <div class="row row-gap-32px">
                          <div class="col-md-12">
                            <label for="FewShotUserInput" class="form-label label-color font-15 font-semibold color-black-v1 mb-2">Few Shot User Input</label>
                            <textarea class="form-control input-field font-14 font-regular border-radius-3 bg-white resize-none shadow-none" id="FewShotUserInput" rows="4" placeholder="Enter Input" label="user" ref={userRef} onChange={(e) => setUserContent(e.target.value)}></textarea>
                          </div>
                          <div class="col-md-12">
                            <label for="ExpectedAIResponse" class="form-label label-color font-15 font-semibold color-black-v1 mb-2">Expected AI Response</label>
                            <textarea class="form-control input-field font-14 font-regular border-radius-3 bg-white resize-none shadow-none" id="ExpectedAIResponse" rows="4" placeholder="Enter Input" label="assistant" ref={assistantRef} onChange={(e) => setAssistantContent(e.target.value)}></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer border-0">
                        <button type="button" class="btn btn-outline-secondary font-14 px-4 py-2 me-3 font-bold" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" class="btn primary-btn font-14 font-bold border-radius-5 color-white px-4 py-2" data-bs-dismiss="modal" onClick={() => handleFewShotSave()}>Save</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12  mb-5 pb-5">
                  <div class="table-responsive rounded-3 transparent-grp">

                    <DragDropContext onDragEnd={handleDragEnd}>
                      <table className="table theme-table font-regular font-14 mb-0">
                        <thead>
                          <tr>
                            <th>Inputs</th>
                            <th>Output Description</th>
                            <th className="ps-4">Task</th>
                            <th className="text-center">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tasks?.map((task, index) => (
                            index === 0 && task.promptMessage ? (
                              <tr key={index}>
                                <td className="ps-4 position-relative" style={{ whiteSpace: "normal" }}>
                                  {task?.inputArray != null && typeof(task?.inputArray) != 'string'? (
                                    <div>
                                      {task?.inputArray.map((ele) => <span>{ele.inputPlaceholder} ,</span>)}
                                    </div>
                                  ) : (null)

                                  }
                                </td>
                                <td className="ps-4 position-relative" style={{ whiteSpace: "normal" }}>
                                  {task?.outputDescription ? (
                                    <span>
                                      {task.outputDescription}
                                    </span>
                                  ) : (null)}
                                </td>
                                <td className="ps-4 position-relative" style={{ whiteSpace: "normal" }}>
                                  {task?.promptMessageID ? (
                                    task?.promptMessage
                                  ) : (
                                    task?.promptMessage
                                  )}
                                </td>
                                <td className="text-center" style={{ whiteSpace: "nowrap" }} >
                                  <button
                                    disabled={disableEdit}
                                    className="btn text-nowrap font-14 font-medium ms-3 btn-outline-warning custom-warn-btn px-4"
                                    onClick={() => handleEditTask(index, task)}
                                  >
                                    Edit
                                  </button>
                                  {HandlePromptHistory ? (
                                    <button
                                      disabled={disableEdit}
                                      className="btn text-nowrap font-14 font-medium ms-3 btn-outline-danger custom-danger-btn px-3"
                                      onClick={() => handleDeleteTask(index)}
                                    >
                                      Delete
                                    </button>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              </tr>
                            ) : null
                          ))}
                        </tbody>
                        <Droppable droppableId="tasks">
                          {(provided) => (
                            <tbody ref={provided.innerRef} {...provided.droppableProps}>
                              {tasks?.map((task, index) => (
                                index !== 0 && task.promptMessage ? (
                                  <Draggable
                                    key={index}
                                    draggableId={index.toString()}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <tr


                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={provided.draggableProps.style}
                                      >
                                        <td className="ps-4 position-relative" style={{ whiteSpace: "normal" }}>
                                          {task?.inputArray != null && typeof(task?.inputArray) != 'string'?  (
                                            <div>
                                              {task?.inputArray?.map((ele) => <span>{ele.inputPlaceholder} ,</span>)}
                                            </div>
                                          ) : (null)

                                          }
                                        </td>
                                        <td className="ps-4 position-relative" style={{ whiteSpace: "normal" }}>
                                          {task?.outputDescription ? (
                                            <span>
                                              {task.outputDescription}
                                            </span>
                                          ) : (null)}
                                        </td>
                                        <td
                                          className="ps-4 position-relative"
                                          style={{ whiteSpace: "normal" }}
                                        >
                                          {task?.promptMessageID ? (
                                            <span>
                                              {task?.promptMessage
                                                .split(/(\(input-\d+\))/)
                                                .map((part, partIndex) => {
                                                  const match = part.match(/\((input-\d+)\)/);
                                                  if (match) {
                                                    const inputValueOrder = match[1];
                                                    const label = PF.find(field => field.inputValueOrder === inputValueOrder)?.labelName;

                                                    return (
                                                      <span
                                                        key={partIndex}
                                                        onMouseEnter={() =>
                                                          setHoveredInput(
                                                            inputValueOrder
                                                          )
                                                        }
                                                        onMouseLeave={() =>
                                                          setHoveredInput(null)
                                                        }
                                                        className={`cursor-pointer ${hoveredInput ===
                                                          inputValueOrder
                                                          ? "bold-text"
                                                          : ""
                                                          }`}
                                                      >
                                                        {hoveredInput === inputValueOrder ? (label ? label : "Code snippet") : part}
                                                      </span>
                                                    );
                                                  }
                                                  return part;
                                                })}
                                            </span>
                                          ) : (
                                            task?.promptMessage
                                          )}
                                        </td>
                                        <td className="text-center" style={{ whiteSpace: "nowrap" }}>
                                          <button
                                            disabled={disableEdit}
                                            className="btn text-nowrap font-14 font-medium ms-3 btn-outline-warning custom-warn-btn px-4"
                                            onClick={() => handleEditTask(index, task)}
                                          >
                                            Edit
                                          </button>
                                          {HandlePromptHistory ? (
                                            <button
                                              disabled={disableEdit}
                                              className="btn text-nowrap font-14 font-medium ms-3 btn-outline-danger custom-danger-btn px-3"
                                              onClick={() => handleDeleteTask(index)}
                                            >
                                              Delete
                                            </button>
                                          ) : (
                                            <></>
                                          )}
                                        </td>
                                      </tr>
                                    )}
                                  </Draggable>
                                ) : null
                              ))}
                              {provided.placeholder}
                            </tbody>
                          )}
                        </Droppable>
                      </table>
                    </DragDropContext>

                  </div>
                </div>



              </div>
              // </div>
            ) : (
              <></>
            )}
          </div>
          {promptIndex && showPopup && (
            <PopUpComponent

              text={popUpText}
              promptID={promptIndex}
              yesFunction={deletePromptData}
              noFunction={{ setShowPopup, setPromptIndex }}
            />
          )}

          {showPage === "showManagePrompt" && (
            <div className="row d-flex  d-flex justify-content-end" style={{ marginLeft: "2%" }}>
              <div className="col-md-12  py-3">
                <div className="d-flex justify-content-between">
                  <div className="ms-3">
                    <button
                      type="button"
                      className="btn theme-outline-btn font-14 font-semibold theme-focus me-3"
                      hidden={promptMessagePage === ""}
                      onClick={() => {
                        setPromptMessagePage("");
                        setSubmitType("SD");
                      }}
                    >
                      Previous
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-secondary font-14 font-semibold"
                      onClick={() => {
                        setShowPage("manage prompt");
                        setPromptData(promptObj);
                        //("step-20", promptData)
                        setPromptFieldData(promptFieldObj);
                        setSubmitType("");
                        setPromptIndex(null);
                        setHiddenIndices([]);
                        setPromptMessageData(promptMessageObj);
                        setPF([]);
                        setTasks([]);
                        setPromptMessagePage("");
                        setPromptLogoValues(logoObj);
                        setKnowledgeData([])
                        setErrorData(errorObj)
                        setError("")
                        setHandlePromptHistory(true)
                        setInputArray([])
                        setFewShotValues([])
                        // handleBackClick()
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="me-3">
                    {HandlePromptHistory ? (
                      <button
                        type="button"
                        className="btn theme-outline-btn font-14 font-semibold theme-focus me-3"
                        id="SD"
                        onClick={async (e) => {
                           
                          if (PF.length !== 1 && promptData.promptName !== "" && promptData.promptLogoID !== "") {
                            setIsLoading(false);

                            await validate(e.target.id);
                            setSubmitType("SD");
                            setPromptData(promptObj);
                            //("step-21", promptData)
                            //(promptMessageObj)
                            setPromptMessageData(promptMessageObj);
                            //(promptMessageObj)
                            setPF([]);
                            setTasks([]);
                            setPromptFieldData(promptFieldObj);
                            setPromptLogoValues(logoObj);
                            setPromptMessagePage("");
                            setKnowledgeData([])
                            window.location.href = "/Asami-manage-prompt";
                          }
                          else {
                            let obj = {
                              promptNameError: "",
                              promptLogoIDError: "",
                            };

                            // Check if any of the required fields is not provided
                            if (promptData.promptName === "") {
                              obj.promptNameError = "Enter the prompt name!";
                            }
                            if (promptData.promptLogoID === "") {
                              obj.promptLogoIDError = "Select the promptLogo!";
                            }
                            if (PF.length === 1) {
                              setError("Please add at least one prompt field!");
                            }

                            // // If any error is set, display it
                            // if (obj.promptNameError !== "" || obj.promptLogoIDError !== "" || PF.length === 1) {
                            //     setErrorData(obj);
                            // } 
                            setErrorData(obj);
                          }

                          // setShowPage("manage prompt");
                        }}
                      >
                        Save as Draft
                      </button>) : (<>
                      </>)}
                    {promptMessagePage !== "" && HandlePromptHistory ? (
                      <button
                        type="button"
                        className="btn theme-focus theme-color-bg font-14 font-semibold text-white"
                        onClick={async () => {
                          setIsLoading(false);

                          await validate();
                          setPromptData(promptObj);
                          //("step-23", promptData)
                          setPromptFieldData(promptFieldObj);
                          setSubmitType("");
                          setPromptIndex(null);
                          setPromptMessageData(promptMessageObj);
                          setPF([]);
                          setTasks([]);
                          setPromptMessagePage("");
                          setPromptLogoValues(logoObj);
                          setKnowledgeData([])
                          // window.location.href = "/Asami-manage-prompt";
                          // setShowPage("manage prompt");
                        }}
                      >
                        Submit
                      </button>
                    ) : promptMessagePage !== "" ? (<></>) : (

                      <button
                        type="button"
                        className="btn theme-focus theme-color-bg font-14 font-semibold text-white"
                        onClick={() => {
                           
                          setSubmitType("");
                          if (PF.length !== 1 && promptData.promptName !== "" && promptData.promptLogoID !== "") {
                            setPromptMessagePage("show");
                          }
                          else {
                            let obj = {
                              promptNameError: "",
                              promptLogoIDError: "",
                            };

                            // Check if any of the required fields is not provided
                            if (promptData.promptName === "") {
                              obj.promptNameError = "Enter the prompt name!";
                            }
                            if (promptData.promptLogoID === "") {
                              obj.promptLogoIDError = "Select the promptLogo!";
                            }
                            if (PF.length === 1) {
                              setError("Please add at least one prompt field!");
                            }

                            // // If any error is set, display it
                            // if (obj.promptNameError !== "" || obj.promptLogoIDError !== "" || PF.length === 1) {
                            //     setErrorData(obj);
                            // } 
                            setErrorData(obj);
                          }
                        }}

                      >
                        Continue
                      </button>

                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      }
    </>
  );
}

export default Manageprompt;
