// PS_AS_1.0 import required packages
import React, { useState, useEffect } from "react";
import { ExistData, fetchReposAndBranch, saveConfiguration } from "../../AI Service/Api";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";


// PS_AS_1.3  - PS_AS_1.10 Create a component and create required useState
export const ConfigPopup = ({projectIdFromMP , TypeOfData , setShowPopUp , typeOfGrid , uniqueIdFromMP , url , PAT}) => {
    // { url = "https://dev.azure.com/Avasoftproducts/AVA-ASAMI-PRODUCT", PAT = "qj2z5yzu577jexjueytys73ax723ckvmodu7vkmavgnziw64d6ha" }
    const location = useLocation();
    const history = useHistory()
    //(projectIdFromMP ,  TypeOfData   , "bafauoshg")
    // const[url, setUrl] = useState()
    // const[PAT,setPAT] = useState()



    const [repoDropDown, setrepoDropDown] = useState([]);
    const [selectedRepo, setSelectedRepo] = useState([]);
    const [branchDropDown, setbranchDropDown] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState([]);
    const [alertMessage, setAlertMessage] = useState('');
    const [previousRepo, setPreviousRepo] = useState([])


    useEffect(() => {
         
        if (TypeOfData == "config") {
            fetchrepoDropDown();
        }
        else {
            getExistConfig()
            fetchrepoDropDown()
        }
    }, []);





    const [tfsFormData, setTfsFormData] = useState({
        userName: "",
        personalAT: "",
        projectURL: "",
    });

    // useEffect(()=>{
    //     fetchInitialData()
    // },[])


    // const fetchInitialData = async () => {
    //      
    //     try {
    //         const reqBody = {
    //             projectID: projectIdFromMP
    //         }
    //         let response = await ExistData(reqBody)
    //         //(response.Responsedata[0],"125")
    //         setUrl(response.Responsedata[0].project_url)
    //         setPAT(response.Responsedata[0].PAT)
    //         //(url , PAT)

    //     }
    //     catch (error) {
    //         //(error)
    //     }
    // }
    //(url , PAT,"1235")
    // PS_AS_1.10 to PS_AS_1.23 ,PS_AS_1.69 to PS_AS_1.75  is fetch the selected repos call api to fetch data form database
    const fetchrepoDropDown = async () => {
        // 
        try {
            const req = {
                "ProjectID": projectIdFromMP
            }
            const response = await fetchReposAndBranch(req);

            //(response.ReposData)
            setrepoDropDown(response.ReposData);

        } catch (error) {
            console.error('Error fetching repo options:', error);
        }
    };
    // PS_AS_1.79 based on repo change useffect will trigger
    useEffect(() => {
         
        fetchbranchDropDown()
    }, [selectedRepo]);
    // PS_AS_1.24 to PS_AS_1.36,PS_AS_1.80 to PS_AS_1.94 fetchbranchDropDown function is fetch the repos form tfs and store in branchDropDown variable
    const fetchbranchDropDown = async () => {
        try {
            // 
            //(url , "  afafewfa    ", PAT )
            const req = {
                'url': url,
                'PAT': PAT,
                'RepoName': selectedRepo.Repo_name
            }
            // const req = {
            //     'url': 'https://dev.azure.com/Avasoftproducts/AVA-ASAMI-PRODUCT',
            //     'PAT': 'qj2z5yzu577jexjueytys73ax723ckvmodu7vkmavgnziw64d6ha',
            //     'RepoName': selectedRepo.Repo_name
            // }
            const response = await fetchReposAndBranch(req)
            //(response.data)
            const filteredBranchData = response.Repos_branch.filter(branch => branch.branch_name === "dev" || branch.branch_name === "master" || branch.branch_name === "main");
            //(filteredBranchData,)
            setbranchDropDown(filteredBranchData);

            // setbranchDropDown(response.Repos_branch || '');
        } catch (error) {
            console.error('Error fetching branch options:', error);
        }
    };
    // if (selectedRepo) {
    //   fetchbranchDropDown();
    // } else {
    //   setbranchDropDown([]);
    // }
    // PS_AS_1.61 -PS_AS_1.67 getExixtConfig() function is going fetch previously selected repo data



    const getExistConfig = async () => {
        // await fetchInitialData()
        // 
        try {
            let req = {

            }
            if(typeOfGrid == "issue"){
                req = {
                    "issueID": uniqueIdFromMP// (Prop)
                }
            }
            else if (typeOfGrid == "task"){
                req = {
                    "taskID": uniqueIdFromMP // (Prop)
                }

            }
            else {
                req = {
                    "storyID": uniqueIdFromMP // (Prop)
                }
            }
            const response = await ExistData(req);
            //(response.Responsedata[0].Repo_data)
            setSelectedRepo(response.Responsedata[0].Repo_data)




        } catch (error) {
            console.error('Error fetching repo options:', error);
        }
    };
    // PS_AS_1.90 This function going to update selected Repo
    const handleRepoChange = (event) => {
        const selectedRepoString = event.target.value;
        const selectedRepoObject = JSON.parse(selectedRepoString);
        setSelectedRepo(selectedRepoObject);

        setAlertMessage('');
    };
    //(selectedRepo)
    // PS_AS_1.108 This function going to update selected Branch
    const handleBranchChange = (event) => {
        const selectedBranchString = event.target.value;
        const selectedBranchObject = JSON.parse(selectedBranchString);
        setSelectedBranch(selectedBranchObject);
        //(selectedBranch.branch_name, "branch0")
        setAlertMessage('');
    };
    //(selectedBranch)
    // PS_AS_1.39 to PS_AS_1.53,PS_AS_1.98 - PS_AS_1.113 ConfigData function going to insert seletedRepo and SelectedBranch in database
    const ConfigData = async () => {
        // 
        //(selectedBranch, selectedRepo, "test")
        if (selectedBranch) {
            try {
                const reqBody = {
                    "selectedRepo": selectedRepo.Repo_name,
                    "selectedBranch": selectedBranch.branch_name,
                    "userEmailID": "",
                    "repoUrl": selectedRepo.Repo_url,
                    "branchUrl": selectedBranch.branch_url,
                    "selectedGrid": typeOfGrid,
                    "uniqueId": uniqueIdFromMP,
                    "configOrUpdate": TypeOfData,
                    // "uniqueConfigID":"FB0CFBDD-FC58-49F8-9653-9872013C2179",
                    "projectID": projectIdFromMP


                };

                const response = await saveConfiguration(reqBody); // preedeeep endppoint api
                if (response.statusCode === 200) {
                    handleCancel()
                    localStorage.setItem("collection_name_avalon", selectedRepo.Repo_name)
                    //('Configuration saved successfully');
                }
            } catch (error) {
                console.error('Error saving configuration:', error);
            }
        } else {
            setAlertMessage('Please select a Repo and branch');
        }
    };
    // PS_AS_1.112- 113 handleCancel function clear the selectedRepo and selectedBranch
    const handleCancel = () => {
        setSelectedBranch([]);
        setSelectedRepo([]);
        setAlertMessage('');
        setShowPopUp(false)
        // setbranchDropDown([])
        setrepoDropDown([])

    }
    return (
        <div className="container-fluid">
            {/* <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#configurerepos"
            >
                Racpad - Configurations-Repo
            </button> */}

            <div
                className="modal show"
                style={{ display: "block", background: "#000000cc" }}
                // id="configurerepos"
                // data-bs-backdrop="static"
                // data-bs-keyboard="false"
                // tabIndex={-1}
                // aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered  custom-popup-repos border-1 ">
                    <div className="modal-content p-3 border-0 bg-color-4">
                        <div className="modal-header border-0 pt-2 p-0 px-2">
                            <h5 className="modal-title text-color-1 font-22 font-bold">
                                Configure Repos URL and Branch
                            </h5>
                            <button
                                type="button"
                                className="btn-close close-icon-filter"
                                // data-bs-dismiss="modal"
                                // aria-label="Close"
                                onClick={handleCancel}
                            >
                                {/* <img src="images-new/close-popup.svg" alt="close-icon" /> */}
                            </button>
                        </div>
                        <div className="modal-body popup-scrl pt-2">
                            <div className="row">
                                {/* Repos URL dropdown */}
                                <div className="col-md-6 mt-4">
                                    <span className="mb-3">
                                        <label
                                            htmlFor="from"
                                            className="form-label font-14 font-bold text-color-2"
                                        >
                                            Repos URL
                                        </label>
                                        <select
                                            id="repos-url-dropdown"
                                            className="font-14 font-regular border-radius-3 w-100 text-start d-flex justify-content-between bg-color-4 text-color-4 custom-border-3 cust-search-input p-2"
                                            value={selectedRepo?.RepoName}
                                            onChange={handleRepoChange}
                                            onClick={(e)=>e.currentTarget.classList.toggle('open')}
                                        >
                                            <option value="" >Select Repos URL</option>
                                            {repoDropDown?.length != 0 ? repoDropDown?.map((repo, index) => (
                                                <option key={index} value={JSON.stringify(repo)}>
                                                    {repo?.Repo_name}
                                                </option>
                                            )) : (<option>No option</option>)}
                                        </select>
                                        <span style={{ color: 'red' }}>{alertMessage}</span>
                                    </span>
                                </div>

                                {/* Branch dropdown */}
                                <div className="col-md-6 mt-4">
                                    <span className="mb-3">
                                        <label
                                            htmlFor="from"
                                            className="form-label text-color-2 font-14 font-bold"
                                        >
                                            Branch
                                        </label>
                                        <select
                                            id="branch-dropdown"
                                            className="btn font-14 font-regular border-radius-3 text-start w-100 d-flex justify-content-between bg-color-4 text-color-4 custom-border-3 cust-search-input p-2"
                                            value={selectedRepo?.RepoName}
                                            onChange={handleBranchChange}
                                            onClick={(e)=>e.currentTarget.classList.toggle('open')}
                                        //   disabled={!selectedRepo}
                                        >
                                            <option value="">Select Branch</option>
                                            {branchDropDown?.length != 0 ? branchDropDown?.map((branch, index) => (
                                                <option key={index} value={JSON.stringify(branch)}>
                                                    {branch.branch_name}
                                                </option>
                                            )) : (<option>No option</option>)}
                                        </select>

                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer border-0 gap-3">
                            <button
                                type="button"
                                className="btn btn-outline-secondary font-14 px-4 pb-2 m-0 font-medium"
                                data-bs-dismiss="modal"
                                onClick={handleCancel}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="btn primary-btn font-14 font-semibold border-radius-5 color-white pb-2 px-4 m-0"
                                onClick={ConfigData}
                                data-bs-dismiss="modal"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};