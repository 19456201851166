import { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AddFileOrFolderSample } from "./AddFileStuctureSample";
import {
  getTeamHistory,
  updateApproval,
  getPromptFieldJson,
} from "../AI Service/Api";
import UserContext from "../Auth/useContext";
import Loader from "../Other-Components/loader";
import { isAuth } from "../Auth/authConfig";
import { useMsal, useAccount, useIsAuthenticated } from "@azure/msal-react";

/**
 * PS_CGAI_14.4 to PS_CGAI_14.21 ,Declare All the state variables needed for the functions.
 * PS_CGAI_14.51 to PS_CGAI_14.55, Onclick of uparrow button desc of the data's will take place.
 * PS_CGAI_14.56 to PS_CGAI_14.60, onclick of downarrow button asc of the data will take place.
 * PS_CGAI_14.61 to PS_CGAI_14.64, Fetch the data according to the field searched
 * PS_CGAI_14.85 to PS_CGAI_14.87 ,Onclick of Ok button set the isPromptopenPopup to false
 * PS_CGAI_14.117 to PS_CGAI_14.119 ,onclick of userActivityLogs should navigate to the user activity logs page.
 */
function ManageRequest({props,navOpen}) {
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("promptName Desc");
  const [count, setCount] = useState(10);
  const [chatID, setChatID] = useState([]);
  const [shownRecords, setShownRecords] = useState(count);
  const [totalRecords, setTotalRecords] = useState(Number);
  const [promptMessageJson, setPromptMessageJson] = useState("");
  const [teamHistoryData, setTeamHistoryData] = useState();
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState(false);
  const [isAscendingPrompt, setIsAscendingPrompt] = useState(true);
  const [isAscendingEmailID, setIsAscendingEmailID] = useState(true);
  const [isAscendingUserName, setIsAscendingUserName] = useState(true);
  const [isTitleChecked, setIsTitleChecked] = useState(false);
  const [isButtonsDisabled, setIsButtonsDisabled] = useState(false);
  const [isPromptFieldPopupOpen, setIsPromptFieldPopupOpen] = useState(true);
  const userDetails = useContext(UserContext);
  const [userData, setuserData] = useState({});
  const [userEmailID, setUserEmailID] = useState("");
  const [chat, setChat] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { instance, accounts } = useMsal();
  const [flag, setFlag] = useState("")
  // const [response,setResponse]=(props)

  /**
   * PS_CGAI_14.22 Declare an variable history and assign useHistory Hook to it.
   */
  const history = useHistory();

  /**
 *PS_CGAI_14.23 Give the useEffect and call the function fetchChatHistory  
 with dependencies search,sort and count.
 */

  useEffect(() => {
    fetchTeamHistory();
  }, [ sort, count]);

  useEffect(() => {
    if (userDetails) {
      setuserData(userDetails);
      fetchTeamHistory();
    }
  }, [userDetails, userData]);

  useEffect(() => {
    //(accounts, "account");
    if (!isAuth(accounts)) {
      history.push("/");
    }
  }, []);

  /**
   * PS_CGAI_14.24 to PS_CGAI_14.38 Call the fetchChatHistory Function where getChatHistory 
 function will be triggerd and data will get from the backend
   */

  const fetchTeamHistory = async () => {
    try {
      let teamHistoryObject = {
        search: search,
        sort: sort,
        count: count,
        approverEmailID: userDetails?.email,
      };
      setIsLoading(false);

      let response = await getTeamHistory(teamHistoryObject);

      if (response.status === 200) {
        setIsLoading(true);
        let record = response.data.ResponseData[0].length - shownRecords;

        setShownRecords(record + shownRecords);
        setTeamHistoryData(response.data.ResponseData[0]);
        setTotalRecords(response.data.ResponseData[1][0].promptCount);
      }
    } catch (error) {
      //(error);
    }
  };

  //(totalRecords,"totalRecords");

  /**
   * PS_CGAI_14.88 to PS_CGAI_14.104, onClick of reject or approve call the function updateApproval
   * @param {chatID of the checked rows} id
   * @param {whether approved or rejected} status
   * @returns the success response as approved or rejected.
   */
  const updateApprovalRequest = async (
    chat,
    status,
    approverEmailID,
    codegenStatus
  ) => {
    try {
      const response = await updateApproval({
        chat,
        status,
        approverEmailID,
        codegenStatus: codegenStatus,
      });
      if (response.Success === true) {
        props.props.requestApproved(
          response.data.ResponseData[0].promptName,
          response.data.ResponseData[0].statusMessage,
          response.data.ResponseData[0].approvalStatus,
          response.data.ResponseData[0].chatID
        );
      }
      fetchTeamHistory();
    } catch (error) {
      //(error);
    }
  };

  /**
   * PS_CGAI_14.105 to PS_CGAI_14.108,onclick of the title checkbox collect all the chatID and update in the state variable chatID
   */
  const handleTitleCheckboxChange = () => {
    const updatedData = teamHistoryData.map((item) => ({
      ...item,
      isChecked: !isTitleChecked,
    }));

    const selectedChats = updatedData.filter((item) => item.isChecked);
    const chatIDToEmailIDMap = selectedChats.reduce((acc, item) => {
      acc[item.chatID] = item.userEmailID;
      return acc;
    }, {});

    const chat = Object.entries(chatIDToEmailIDMap).map(
      ([chatID, userEmailID]) => ({
        chatID,
        userEmailID,
      })
    );

    setTeamHistoryData(updatedData);
    setIsTitleChecked(!isTitleChecked);
    setIsButtonsDisabled(!isTitleChecked);
    setChatID(selectedChats.map((item) => item.chatID));
    setUserEmailID(chatIDToEmailIDMap);
    setChat(chat);
  };

  /**
 * PS_CGAI_14.109 to PS_CGAI_14.116, onclick of the row checkbox collect particular 
 chatID of the checked checkboxes and update in the state variable chatID
 * @param {Id of the particular prompt} id 
 */
  const handleItemCheckboxChange = (id) => {
    const updatedData = teamHistoryData.map((item) => {
      if (item.chatID === id) {
        return {
          ...item,
          isChecked: !item.isChecked,
        };
      }
      return item;
    });

    const isAnyItemChecked = updatedData.some((item) => item.isChecked);
    const chatIDToEmailIDMap = updatedData.reduce((acc, item) => {
      if (item.isChecked) {
        acc[item.chatID] = item.userEmailID;
      }
      return acc;
    }, {});

    const chat = Object.entries(chatIDToEmailIDMap).map(
      ([chatID, userEmailID]) => ({
        chatID,
        userEmailID,
      })
    );

    setTeamHistoryData(updatedData);
    setIsButtonsDisabled(isAnyItemChecked);
    setChatID(chat.map((item) => item.chatID));
    setUserEmailID(chatIDToEmailIDMap);
    setChat(chat);
  };

  /**
   * PS_CGAI_14.45 to PS_CGAI_14.50, Trigger the loadmore functionality and add the 10 more content to the grid.
   */

  const loadmoreClick = () => {
    let countValue = count + 10;
    setCount(countValue);
    if (shownRecords >= totalRecords) {
      setIsLoadMoreDisabled(true);
    }
  };

  /**
   * PS_CGAI_14.65 to PS_CGAI_14.84,onclick of the promptName bind the json information of the particular prompt in the popup
   * @param {Id of the particular prompt} id
   */
  const fetchPromptFieldJson = async (id) => {
    try {
      const response = await getPromptFieldJson({ chatID: id });
      const parsedJson = JSON.parse(response.data.ResponseData.content);
      setPromptMessageJson(parsedJson);
      setIsPromptFieldPopupOpen(false);
    } catch (error) {
      //(error);
    }
  };

  /**
   * PS_CGAI_14.39 to PS_CGAI_14.44,set the state variables with the data's and bind it to the grid
   * @param {teamHistoryData state variable which has the grid data's} teamHistoryData
   * @returns the binded data in the grid to the user
   */
  function bindTeamHistoryData(teamHistoryData) {
    if (teamHistoryData != undefined) {
      if (teamHistoryData && Array.isArray(teamHistoryData)) {
        return teamHistoryData?.map((value) => {
          return (
            <tr key={value.chatID}>
              <td>
                <span className="px-3">
                  {" "}
                  <input
                    className="form-check-input custom-check theme-focus"
                    type="checkbox"
                    defaultValue
                    checked={value.isChecked}
                    readOnly
                    onChange={() => handleItemCheckboxChange(value.chatID)}
                  />
                </span>
                {value.userName}
              </td>
              <td>{value.userEmailID}</td>
              <td>
                <a
                  className="text-decoration-none link-color"
                  data-bs-toggle="modal"
                  data-bs-target="#specifiedprompts-popup"
                  onClick={(e) => {
                    let chatID = `${value.chatID}`;
                    fetchPromptFieldJson(chatID);
                    setIsPromptFieldPopupOpen(false);
                  }}
                >
                  {value.promptName}
                </a>
              </td>
              <td className="text-center">
                <button
                  className="btn text-nowrap font-14 font-medium ms-3 btn-outline-success custom-success-btn "
                  value="Approved"
                  disabled={isButtonsDisabled}
                  onClick={(e) => {
                    updateApprovalRequest(
                      [
                        {
                          chatID: value.chatID,
                          userEmailID: value.userEmailID,
                        },
                      ],
                      e.target.value,
                      userDetails.email,
                      "In Progress"
                    );
                  }}
                >
                  Approve
                </button>
                <button
                  className="btn text-nowrap font-14 font-medium ms-3 btn-outline-danger custom-danger-btn px-3"
                  value={"Rejected"}
                  disabled={isButtonsDisabled}
                  onClick={(e) => {
                    updateApprovalRequest(
                      [
                        {
                          chatID: value.chatID,
                          userEmailID: value.userEmailID,
                        },
                      ],
                      e.target.value,
                      userDetails.email,
                      "Abort"
                    );
                  }}
                >
                  Reject
                </button>
              </td>
            </tr>
          );
        });
      }
    } else {
      return null;
    }
  }

  return (
    <>
      {!isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <div className={navOpen === "Close" ? "col-md-11":"col-md-9"} id="manage-req-center" >
          <div>
          {/* request log grid  starts  */}
        
          <div className="container-fluid">
            <div className="row" style={{marginLeft:"2%"}}>
            
              <div className="mt-5 mb-3 d-flex justify-content-between align-items-center">
                {/* tab structure starts here */}
                <ul
                  className="nav nav-pills mb-3 "
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link custom-tabs active font-16 font-medium"
                      id="pills-managerequest-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-managerequest"
                      type="button"
                      role="tab"
                      aria-controls="pills-managerequest"
                      aria-selected="true"
                    >
                      Manage Request
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link  custom-tabs font-16 font-medium"
                      id="pills-useractivity-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-useractivity"
                      type="button"
                      role="tab"
                      aria-controls="pills-useractivity"
                      aria-selected="false"
                      onClick={() => {
                        history.push("/asami-user-activity");
                      }}
                    >
                      User Activity Logs
                    </button>
                  </li>
                </ul>
                <div className="table-header d-flex align-items-center">
                  <div className="input-group transparent-grp">
                    <input
                      type="text"
                      className="form-control bg-transprent border-0 focus-none font-14 font-medium"
                      placeholder="Search"
                        onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          fetchTeamHistory()
                        }
                      }}
                      value={search}
                    />
                    <button className="btn focus-none" type="button">
                      <img
                        src="images/Default/input-search.svg"
                        alt="input-search-icon"
                        onClick={(e) => {
                          setSearch(e.target.value);
                        }}
                      />
                    </button>
                  </div>
                  <button
                    className="btn text-nowrap ms-3 btn-outline-danger custom-danger-btn font-semibold font-14 py-2"
                    value="Rejected"
                    hidden={!isTitleChecked && !isButtonsDisabled}
                    onClick={(e) => {
                      updateApprovalRequest(
                        chat,
                        e.target.value,
                        userDetails.email,
                        "Abort"
                      );
                    }}
                  >
                    Reject Request
                  </button>
                  <button
                    className="btn text-nowrap ms-3 theme-color-bg text-white theme-focus font-semibold font-14 py-2"
                    value="Approved"
                    hidden={!isTitleChecked && !isButtonsDisabled}
                    onClick={(e) => {
                      updateApprovalRequest(
                        chat,
                        e.target.value,
                        userDetails.email,
                        "In Progress"
                      );
                    }}
                  >
                    Approve Request
                  </button>
                </div>
              </div>
              </div>
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="pills-managerequest"
                  role="tabpanel"
                  aria-labelledby="pills-managerequest-tab"
                >
                  <div className="table-responsive rounded-3 transparent-grp" style={{marginLeft: "2%"}}>
                    <table className="table theme-table font-regular font-14 mb-0">
                      <thead>
                        <tr>
                          <th>
                            <span className="px-3" hidden={totalRecords==0}>
                              {" "}
                              <input
                                className="form-check-input custom-check theme-focus"
                                type="checkbox"
                                defaultValue
                                checked={isTitleChecked}
                                onChange={handleTitleCheckboxChange}
                              />
                            </span>
                            User Name{" "}
                            <span
                              className="cursor-pointer"
                              onClick={() => {
                                setIsAscendingUserName(!isAscendingUserName);
                                setSort(
                                  isAscendingUserName
                                    ? "userName Desc"
                                    : "userName Asc"
                                );
                              }}
                            >
                              <img
                                src={
                                  isAscendingUserName
                                    ? "images/Default/sort-up.svg"
                                    : "images/Default/sort-down.svg"
                                }
                                alt={
                                  isAscendingUserName ? "sort-up" : "sort-down"
                                }
                              />
                            </span>
                          </th>
                          <th
                            onClick={() => {
                              setIsAscendingEmailID(!isAscendingEmailID);
                              setSort(
                                isAscendingEmailID
                                  ? "userEmailID Desc"
                                  : "userEmailID Asc"
                              );
                            }}
                          >
                            Email Address{" "}
                            <span className="cursor-pointer">
                              {" "}
                              <img
                                src={
                                  isAscendingEmailID
                                    ? "images/Default/sort-up.svg"
                                    : "images/Default/sort-down.svg"
                                }
                                alt={
                                  isAscendingEmailID ? "sort-up" : "sort-down"
                                }
                              />
                            </span>
                          </th>
                          <th
                            onClick={() => {
                              setIsAscendingPrompt(!isAscendingPrompt);
                              setSort(
                                isAscendingPrompt
                                  ? "promptName Desc"
                                  : "promptName Asc"
                              );
                            }}
                          >
                            Prompt
                            <span className="cursor-pointer">
                              {" "}
                              <img
                                src={
                                  isAscendingPrompt
                                    ? "images/Default/sort-up.svg"
                                    : "images/Default/sort-down.svg"
                                }
                                alt={
                                  isAscendingPrompt ? "sort-up" : "sort-down"
                                }
                              />
                            </span>
                          </th>
                          <th className="text-center">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {totalRecords !== 0 ? (
                          bindTeamHistoryData(teamHistoryData)
                        ) : (
                          <tr>
                            <td colSpan={4} className="text-center py-5 vh-50">
                              <img
                                src="images/Default/no-record-img.svg"
                                alt="no-record-img"
                                className="no-record-img mb-5"
                              />
                              <span className="font-20 font-medium w-100 d-block">
                                Sorry! You don’t have any recent request
                              </span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <p className="font-regular font-14 grey-blue my-3">
                    # of Records :{" "}
                    <span className="font-bold font-14 grey-v1">
                      {totalRecords > shownRecords
                        ? shownRecords
                        : totalRecords}
                    </span>{" "}
                    out of{" "}
                    <span className="font-bold font-14 grey-v1">
                      {totalRecords}
                    </span>
                  </p>
                  <div className="text-center">
                    <button
                      className="btn transparent-grp font-semibold font-14 py-2 px-4 grey-v1 theme-focus"
                      onClick={loadmoreClick}
                      disabled={isLoadMoreDisabled}
                      hidden={totalRecords <= 10}
                    >
                      {" "}
                      Load More
                    </button>
                  </div>
                </div>
              </div>
              {/* tab structure ends here */}
        
          </div>
          </div>
          {/* request log grid  ends  */}
        </div>
      )}
      <div hidden={isPromptFieldPopupOpen}>
        <div
          className="modal fade"
          id="specifiedprompts-popup"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-hidden="true"
        >
          {/* Modal content */}
          <div className="modal-dialog modal-dialog-centered custom-specifiedprompts-width border-0">
            <div className="modal-content p-3 border-0 bg-color-4">
              <div className="modal-header border-0 pt-2 pb-0">
                <h5 className="modal-title font-22 font-bold text-color-1">
                  Specified Prompts
                </h5>
                <button
                  type="button"
                  className="btn-close close-icon-filter"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setIsPromptFieldPopupOpen(true);
                  }}
                />
              </div>
              <div className="modal-body popup-scrl">
                <div className="row">
                  {Object.entries(promptMessageJson).map(([key, value]) => (
                    <>
                      {key.trim() === "Any existing file structure" ? (
                        <div className="col-md-12 mb-2" key={key}>
                          <label className="font-14 font-semibold text-color-9">
                            {key !== "" ? key : "User Request"}:
                          </label>
                          <span className="font-14 font-semibold text-color-15">
                            <AddFileOrFolderSample props={value} />
                          </span>
                        </div>
                      ) : key.trim() === "Tech Stack" ||
                        key.trim() === "Type Of Code" ? (
                        <div className="col-md-6 mb-3" key={key}>
                          <label className="font-14 font-semibold text-color-9">
                            {key}:
                          </label>
                          <span className="font-14 font-semibold text-color-15">
                            {value}
                          </span>
                        </div>
                      ) : (
                        <div className="col-md-12 mb-2" key={key}>
                          <label className="font-14 font-semibold text-color-9">
                            {key !== "" ? key : "User Request"}:
                          </label>
                          <span className="font-14 font-semibold text-color-15">
                            {value}
                          </span>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>
              <div className="modal-footer border-0 d-flex justify-content-center pt-0">
                <button
                  type="button"
                  className="btn primary-btn text-white font-14 px-4 py-2 font-medium theme-focus"
                  data-bs-dismiss="modal"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageRequest;
