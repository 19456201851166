import config from "./config/env";

const nodeBaseURL =
  "https://ava-eus-cdgen-prod-nde-fnapp.azurewebsites.net/api";

const goBaseURL = "https://ava-eus-cdgen-prod-go-fnapp.azurewebsites.net/api";

const prodConfig = {
  URL: {
    formLoadURL: `https://ava-eus-cdgen-prod-nde-fnapp.azurewebsites.net/api/fetchFormData?code=2hyZt91kTLFnjB9v8i6nyogtGmhjh8PGjoQOPETPq2jYThY5Onxf3w==`,
    formGetDataURL: `https://ava-eus-cdgen-prod-nde-fnapp.azurewebsites.net/api/loadFormData?code=N96wxTD5macP7EV30Ypem4LTgeW5iv/XWOD5bRf3kyEAna6fdD99gA==`,
    formPostDataURL: `https://ava-eus-cdgen-prod-nde-fnapp.azurewebsites.net/api/SubmitInfo?code=5RmgNHQyG75sBZiyMz4be60O/KUqstoaL2Pnz88jt88ogkrXeTyfvw==`,
    outputFileNameUpdate: `https://ava-eus-cdgen-prod-nde-fnapp.azurewebsites.net/api/outputFileUpdate?code=ecgMiBGvYWcMjlr47RswLDR5l1a1ARRS5APklpB5JPPypvkrYnmpcA==`,

    gridLoadURL: `https://ava-eus-cdgen-prod-nde-fnapp.azurewebsites.net/api/loadGridData?code=qgB8t9PcK8a2lG09igiJrell1tfkrmnABpySy2xGpT2fSU1ub81Piw==`,
    // deleteDataURL : Domain + 'grid/deleteStudent',
    filterLoadURL:
      "https://ava-eus-cdgen-prod-nde-fnapp.azurewebsites.net/api/fetchDynamicData?code=OjNTyldFzDdSA8wlK45rT06NkVtKFkzsWMc2nAyStNpCRo2yO0EhsQ==",
  },
  GO: {
    CodeGenURL:
      "https://ava-eus-cdgen-prod-go-fnapp.azurewebsites.net/api/codegengoapi?",
  },
  nodeBaseURL: "https://ava-eus-cdgen-prod-nde-fnapp.azurewebsites.net/api",
  goBaseURL: "https://ava-eus-cdgen-prod-go-fnapp.azurewebsites.net/api",
  URLs: {
    frontEnd:
      nodeBaseURL +
      "/frontEnd?code=M_e4LKOsUsABPNPRdrQrNJf-DAIVxvPUZ3w9Bm5WALYXAzFuiVX4kw==",

    service:
      nodeBaseURL +
      "/service?code=m46seDo8m5lDaSuJwXfjLJKCjtyUBRJ0ua5nUrruHXQYAzFuXzU5SQ==",
    DataBase:
      nodeBaseURL +
      "/DataBase?code=nq0g9JNnAS8NZ4nkuUEfO_eGCaFC9w5yegvbROAWIv9PAzFuLZ1EgA==",
    GenerateCode:
      nodeBaseURL +
      "/GenerateCode?code=YwQOE70v_zXOYobRnRtt4N01XSiNJ3pld9L6RdQ3xAXUAzFuO8mNwA==",
    viewGenerateCode:
      nodeBaseURL +
      "/viewGenerateCode?code=CRxATks-S1g15UeoavB3zppnFPJ-DdCTal90cs9zFMpPAzFuKPTZyQ==",
    getAllProjectDetails:
      nodeBaseURL +
      "/getAllProjectDetails?code=cFeY7fp5kAaewGKqE4t6cXhSvIggn5ZAti72BGCQgfRkAzFul3Zbug==",
    postFileUpload:
      nodeBaseURL +
      "/postFileUpload?code=4uQzBCNXODHaBm8eF0beeN5GT9tF4M7Rm7HG02GIQY8_AzFupLY1zg==",
    EntityName:
      nodeBaseURL +
      "/EntityName?code=Js-dQtlmJ8j1GhfF6x3YYgEoFowMPDsBJQM3izR7NR5nAzFuLNw5vQ==",
    EntityInfo:
      nodeBaseURL +
      "/EntityInfo?code=zpfd2ge_G2HzKai0mO7Ym6CSCilwfFEnNNhs6K16huEHAzFu2YBEzA==",
    organiseMethods:
      nodeBaseURL +
      "/organiseMethods?code=pesApBmAJwAkuWUAdlI__4elKrhvjQxFCCbZv2g6soTOAzFuHBiqaw==",
    apiDetails:
      nodeBaseURL +
      "/apiDetails?code=9GXcyS_6Da_SRZzNH0GUZSwnwqXZsk2g8xfGqJh_7AQuAzFuVpG-EQ==",
    gridProperties:
      nodeBaseURL +
      "/gridProperties?code=IzGuBBj59KuGOFOqdRTHC6KmCG7PbU3GcgoLVH1FdYZ2AzFuy4vnvA==",
    gridColumn:
      nodeBaseURL +
      "/gridColumn?code=qKBgR5D48YoGN7S4ohFf0485ys1XzQM-FyljPZ1R4SP2AzFu1jefPA==",
    formProps:
      nodeBaseURL +
      "/formProps?code=tB9EsIeIMyfaKePxT0cJaSc_-bR4485D4T96QRhNNvYeAzFuwpz56Q==",
    FormFilter:
      nodeBaseURL +
      "/FormFilter?code=MrDDyYcr2U4ZCWJxYMBseZ3PDj_VrznFEv8Q0mTlbhYkAzFuufU4ow==",
    setStatus:
      nodeBaseURL +
      "/setStatus?code=k_E99nlRbeEa-Y3PVFvQKXYzuU1e9-UGFIu_ZAn7qCeSAzFuJGYNLg==",
    getFiledetails:
      nodeBaseURL +
      "/getFileDetails?code=MLdMWlwinCSy7ZT-cSSsLpstENGdGB_pkKPaczLbzVimAzFuL17gYw==",
    GenerateFEGridCode: goBaseURL + "/generatefegridcode",
    GenerateFEFormCode: goBaseURL + "/generatefeformcode",
    GenerateBECode: goBaseURL + "/generatebecode",
  },
  phaseAi: {
    deletePrompt:
      "https://ava-eus-cdgen-prod-nde-fnapp.azurewebsites.net/api/deletePrompt?code=0XGWA0NnLbJJSkK2RkLfchN0dgfUxEqZMPOKQmcm5ZEi4z5y1DrX3A==&clientId=default",
    getApprovalRequestData:
      "https://ava-eus-cdgen-prod-nde-fnapp.azurewebsites.net/api/getApprovalRequestData?code=Da6x8o-oYHBbgsUnizYo_BhJ6e2IhpnXAyPA6jntAayoAzFugwoJ-g==",
    getChat:
      "https://ava-eus-cdgen-prod-nde-fnapp.azurewebsites.net/api/getChat?code=Ursd0XWleddH7MVZuo6rEm4m0wF5ZkmL9GwyfiKVn9TYAzFu7U4LjQ==",
    getChatHistory:
      "https://ava-eus-cdgen-prod-nde-fnapp.azurewebsites.net/api/getChatHistory?code=iPFdN883Z_UFOGWvSO9oHg4NocIrnxIFxXQVpbC74ukaAzFuiuNaoQ==",
    getInputTypeAndTechStack:
      "https://ava-eus-cdgen-prod-nde-fnapp.azurewebsites.net/api/getInputTypeAndTechStack?code=O8yqQt6X3WtmcMxGxq0t3VzJu_BN91exrHUee1Qbs_OiAzFust82rQ==",
    getNotifications:
      "https://ava-eus-cdgen-prod-nde-fnapp.azurewebsites.net/api/getNotifications?code=4y8Fu0TkxViALh_a2j0lJ_NID1fZ1-TZPH0Y4lCCJ3RwAzFuYdynZg==",
    getPrompt:
      "https://ava-eus-cdgen-prod-nde-fnapp.azurewebsites.net/api/getPrompt?code=_y7a8xgu3xWxV_QNoj5yAINi7WPTqR2Rt5NP2siOTpIhAzFuvczFxA==",
    getPromptFieldJson:
      "https://ava-eus-cdgen-prod-nde-fnapp.azurewebsites.net/api/getPromptFieldJson?code=ApsaeI-X017o3Pg1-vmK18J-paLl93FPEQe86HLIDGjHAzFux_MAnQ==",
    getTeamHistory:
      "https://ava-eus-cdgen-prod-nde-fnapp.azurewebsites.net/api/getTeamHistory?code=6H94jUFDsM_wxteFI8z9Am3fSIokgloaegiWxi1MpcBtAzFu3DVxLw==",
    insertConnectionID:
      "https://ava-eus-cdgen-prod-nde-fnapp.azurewebsites.net/api/insertConnectionID?code=UpNibROUxAHZIOumAdTsrtMbctDHrqKpzUvB1o6ogMpwAzFuMV-V7w==",
    insertPrompt:
      "https://ava-eus-cdgen-prod-nde-fnapp.azurewebsites.net/api/insertPrompt?code=cQH9gA9AjadZiXlkJj0swAkVgQsI8t6kJpVv2f1UnrhVAzFuflArZw==",
    postMessage:
      "https://ava-eus-cdgen-prod-nde-fnapp.azurewebsites.net/api/postMessage?code=-brnJuY-09Rsn21meiO6IH8tBkVUj_DfUXOGYcWc2ardAzFu-yHuFA==",
    securityKeyCheck:
      "https://ava-eus-cdgen-prod-nde-fnapp.azurewebsites.net/api/securityKeyCheck?code=Hce7vh3d0ZsNPPB7QV3Q7Nj8W_GD9wTuNYvTudaXgXPHAzFu4_KXJA==",
    talkToAI:
      "https://ava-eus-cdgen-prod-nde-fnapp.azurewebsites.net/api/talkToAI?code=_F1CF8CXUKBdgOF2dY7l_QEsEbzDm044XJPllMkp4wEsAzFuDXg9xA==",
    updateApproval:
      "https://ava-eus-cdgen-prod-nde-fnapp.azurewebsites.net/api/updateApproval?",
    updateNotification:
      "https://ava-eus-cdgen-prod-nde-fnapp.azurewebsites.net/api/updateNotification?",
    deleteConnectionID:
      "https://ava-eus-cdgen-prod-nde-fnapp.azurewebsites.net/api/deleteConnectionID?code=RE3Au_6-JbdwsO-gLZbA0R6BsgM5NQcvhROtsWhTY02xAzFusiblqA==",
  },
  blobBasePath:
    "https://avaeuscdgenprodstgacc.blob.core.windows.net/ava-cdegen-zip-prod-con/phase2-zip/",
  blobInputFilePath:
    "https://avaeuscdgenprodstgacc.blob.core.windows.net/ava-cdegen-inpel-prod-con/",
  blobDownloadFilePath:
    "https://avaeuscdgenprodstgacc.blob.core.windows.net/ava-cdegen-zip-prod-con",
};

// const configuration = config;

export default config;
