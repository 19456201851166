import React, { useState, useEffect } from "react";
import { CopyBlock, dracula, irBlack, shadesOfPurple, tomorrowNightBlue } from "react-code-blocks";


export const EditorComponent = (props) => {
  const [code, setCode] = useState("jsx");
  const [language, setLanguage] = useState(""); 
  const [lineNumbers, toggleLineNumbers] = useState(true);  
  

  const detectLanguage = (code) => {
    const patterns = [
      { type: 'javascript', extension: 'js', keywords: ['function(', '=>', 'const ', 'let ', 'var ', '//(', 'import ', 'export ', 'document.', 'React', 'export default', 'function'] },
      { type: 'php', extension: 'php', keywords: ['<?php', 'echo ', 'array(', 'function ', 'class ', 'public ', 'private ', 'protected ', 'namespace ', 'use ', '$', '->', '::', 'header'] },
      { type: 'pyspark', extension: 'py', keywords: ['from pyspark', 'from pyspark.sql', 'from pyspark.streaming', 'from pyspark.mllib', 'SparkContext', 'SQLContext'] },
      { type: 'java', extension: 'java', keywords: ['class ', 'public ', 'private ', ' System.out', 'void ', 'int ', 'string ', 'new ','assert', 'const', 'default', 'enum', 'exports', 'extends', 'finally', 'goto', 'implements', 'import', 'instanceof', 'interface', 'module', 'native', 'package', 'strictfp', 'super', 'synchronized', 'throws', 'transient', 'volatile']},
      { type: 'python', extension: 'py', keywords: ['def ', ':', 'import ', 'print(', 'class ', 'self', 'from ', 'as ', 'if ', 'elif ', 'else:', 'try:', 'except ', 'with ', 'lambda '] },
      { type: 'ruby', extension: 'rb', keywords: ['def ', 'class ', 'module ', 'end', 'do ', 'if ', 'elsif ', 'else', 'begin', 'rescue', 'require ', 'include ', '@', 'puts '] },
      { type: 'angular', extension: 'ts', keywords: ['@Component', '@NgModule', '@Injectable', 'import { ', 'from \'@angular', 'export', 'string'] },
      { type: 'flutter', extension: 'dart', keywords: ['import \'package:flutter/', 'MaterialApp', 'Scaffold', 'Column', 'Row', 'Container', 'Text', 'Center', 'build', 'Widget', 'StatelessWidget', 'StatefulWidget'] },
      { type: 'react_ts', extension: 'tsx', keywords: ['import React', 'useState', 'useEffect', 'useContext', 'useReducer', 'useCallback', 'useMemo', 'useRef', 'useImperativeHandle', 'useLayoutEffect', 'useDebugValue', 'ReactDOM.render', 'React.createElement', 'TSX'] },
      { type: 'vue_js', extension: 'vue', keywords: ['<template>', '<script>', '<style>', 'export default {', 'import Vue', 'new Vue(', 'Vue.component', 'v-bind:', 'v-model', 'v-if', 'v-for', 'v-on'] },
      { type: 'power_bi', extension: 'pbix', keywords: ['Power BI'] },
      { type: 'golang', extension: 'go', keywords: ['package ', 'import ', 'func ', 'defer ', 'go ', 'select ', 'struct ', 'interface ', 'map[', 'for_', 'Println'], },
      { type: 'swift', extension: 'swift', keywords: ['import Foundation', 'import UIKit', 'import SwiftUI', 'class ', 'func ', 'let ', 'var ', 'if ', 'else ', 'guard ', 'enum ', 'struct ', 'protocol ', 'extension ', 'init(', 'self.', 'DispatchQueue', 'autoreleasepool', 'defer ', 'switch ', 'case ', 'default:', 'break', 'continue', 'fallthrough', 'return ', 'throw ', 'catch ', 'do {', 'try ', 'throws ', 'rethrows ', 'async ', 'await ', 'Task ', 'Actor ', '@objc ', '@IBOutlet', '@IBAction', '@UIApplicationMain', '@available', '@discardableResult', '@escaping ', '@frozen', '@inlinable', '@nonobjc', '@NSCopying', '@NSManaged', '@propertyWrapper', '@resultBuilder', '@UIApplicationDelegateAdaptor', '@ViewBuilder'] },
      { type: 'kotlin', extension: 'java', keywords: ['fun ', 'val ', 'var ', 'class ', 'object ', 'interface ', 'import ', 'package ', 'override ', 'when ', 'data class', '@Component', 'import {'] },
      { type: 'tableau', extension: 'twb', keywords: ['tableau', 'Tableau Workbook'] },
      { type: 'dotnet', extension: 'cs', keywords: ['using System;', 'namespace ', 'class ', 'public ', 'private ', 'protected ', 'static ', 'void ', 'int ', 'string ', 'new '] },
      { type: 'node_ts', extension: 'ts', keywords: ['import ', 'from \'express', 'const express', 'const app', 'app.listen(', 'app.get(', 'app.post(', 'app.put(', 'app.delete(', 'app.use(', 'import * as express', 'import express from'] },
      { type: 'node_js', extension: 'js', keywords: ['const express', 'const app', 'app.listen(', 'app.get(', 'app.post(', 'app.put(', 'app.delete(', 'app.use(', 'require(\'express\')'] },
      { type: 'salesforce_apex', extension: 'cls', keywords: ['public class ', 'private class ', 'protected class ', 'global class ', '@AuraEnabled', 'trigger ', 'SObject', 'DML', 'SOQL'] },
      { type: 'react_js', extension: 'jsx', keywords: ['import React', 'useState', 'useEffect', 'useContext', 'useReducer', 'useCallback', 'useMemo', 'useRef', 'useImperativeHandle', 'useLayoutEffect', 'useDebugValue', 'ReactDOM.render', 'React.createElement', '<>', '</>', '<Fragment>', '<Suspense>', '<StrictMode>', '<Profiler>', 'Component', 'PureComponent', 'createContext', 'forwardRef', 'lazy', 'memo', 'Fragment', 'Suspense', 'StrictMode', 'Profiler', 'ReactDOM.createPortal'] },
      {type: 'html', extension: 'php', keywords: ['<!DOCTYPE>', '<html>', '<head>', '<title>', '<meta>', '<link>', '<style>', '<script>', '<body>', '<div>', '<span>', '<p>', '<h1>', '<h2>', '<h3>', '<h4>', '<h5>', '<h6>', '<a>', '<img>', '<ul>', '<ol>', '<li>', '<table>', '<tr>', '<th>', '<td>', '<form>', '<input>', '<textarea>', '<button>', '<select>', '<option>', '<label>', '<fieldset>', '<legend>', '<iframe>', '<audio>', '<video>', '<canvas>', '<svg>', '<footer>', '<header>', '<nav>', '<section>', '<aside>', '<article>', '<main>']}
    ];

    let bestMatch = null;
    let highestScore = -1;
  
    for (const pattern of patterns) {
      let score = 0;
      for (const keyword of pattern.keywords) {
        if (code.includes(keyword)) {
          score++;
        }
      }

      if (score > highestScore && score >=2) { // check if matched keywords are greater than or equal to two
        highestScore = score;
        bestMatch = pattern.extension;
      }
    }
    
    return bestMatch || "text";
  };
  

  useEffect(() => {
    if (props.props) {
      setCode(props.props);
      let data = detectLanguage(code)
      setLanguage(data)
      //("detectedLanguage :", data)
      
      // Assume the AI response contains the stack information in the "stack" property
      const aiResponse = props.aiResponse;
      if (aiResponse && aiResponse.stack) {
        setLanguage(aiResponse.stack);
      }
    }
    
  }, [props]);

  return (
    <div>
       {/* <div style={{ marginBottom: "10px" }}>
        <span>{language}</span>
      </div> */}

      <CopyBlock
        language={language}
        text={code}
        showLineNumbers={lineNumbers}
        theme={tomorrowNightBlue}
        wrapLines={true}
        codeBlock
      />
    </div>
  );
};