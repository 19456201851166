import React, { useRef } from "react";
import { useDrag } from "react-dnd";
import { COLUMN } from "./filter-constants";
import FilterDropZone from "./Filter-Dropzone";
import Component from "./Component";

const style = {};
const Column = ({
  data,
  components,
  handleDrop,
  path,
  handleSettings,
  handleDelete,
}) => {
  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: COLUMN,
    item: {
      type: COLUMN,
      id: data.id,
      children: data.children,
      path,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  const renderComponent = (component, currentPath) => {
    return (
      <Component
        key={component.id}
        data={component}
        components={components}
        path={currentPath}
        handleSettings={handleSettings}
        handleDelete={handleDelete}
      />
    );
  };

  return (
    <div
      className="base draggable column"
      ref={ref}
      style={{ ...style, opacity }}
    >
      <div className="element-container">
        <div className="row mx-0 justify-content-center border rounded-3">
          <div
            className={
              data.validation == undefined || data.validation == true
                ? "col-md-12 bg-theme-color"
                : "col-md-12 bg-danger bg-red"
            }
          >
            <div className="float-start">
              <h4 className="element-head mt-1 ms-2 pt-1" id="element-widget">
                Column
              </h4>
            </div>
            <div className="float-end cursor-point">
              <button
                type="button"
                className="btn me-2 pt-0 ps-2 pe-1 cursor-point"
                onClick={() => handleDelete(path)}
              >
                <img
                  src="images/builder-css/filter-close-icon.svg"
                  alt="element-delete"
                />
              </button>
            </div>
          </div>
          <div
            className="element-child border mt-3 mb-3 rounded-3"
            style={{ width: "98%" }}
          >
            <div className="row">
              <div>
                {data.children.map((component, index) => {
                  const currentPath = `${path}-${index}`;

                  return (
                    <React.Fragment key={component.id}>
                      {renderComponent(component, currentPath)}
                    </React.Fragment>
                  );
                })}
                <FilterDropZone
                  data={{
                    path: `${path}-${data.children.length}`,
                    childrenCount: data.children.length,
                  }}
                  onDrop={handleDrop}
                  isLast
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Column;
