import { mdata } from "../config/prod.env";

const TENANT_ID = mdata.tenant_id

export const msalConfig = {
  auth: {
    clientId:mdata.clientId /*To be changed */,
    authority: `https://login.microsoftonline.com/${TENANT_ID}`,
    redirectUri: window.location.origin + "/chat",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

export const isAuth = (accounts) => {
  //(accounts,"KKKK");
  return accounts.find((_account) => _account?.tenantId === TENANT_ID);
};

export const loginRequest = {
  scopes: ["openid", "profile", "User.Read"],
};
