import { useEffect, useState } from "react"
import { fetchAsamiUsageData } from "../../AI Service/Api";
import Loader from '../../Other-Components/loader';
import { ClipLoader } from 'react-spinners';
import { flip } from "@popperjs/core";

// PS_DB_249
// The table functionalities are loaded here
const AsamiUsageTable = ({navOpen}) => {
    const [page, setPage] = useState("Project")
    const [search, setSearch] = useState()
    const [PracticeData, setPracticeData] = useState([])
    const [ProjectData, setProjectData] = useState([])
    const [recordCount, setRecordCount] = useState()
    const [projectNameOrder, setProjectNameOrder] = useState("asc")
    const [noOfProjectPeople, setNoOfProjectPeople] = useState("asc")
    const [projectAsamiUsage, setProjectAsamiUsage] = useState("asc")
    const [projectLastAccessed, setProjectLastAccessed] = useState("asc")
    const [practiceNameOrder, setPracticeNameOrder] = useState("asc")
    const [noOfPracticePeople, setNoOfPracticePeople] = useState("asc")
    const [practiceAsamiUsage, setPracticeAsamiUsage] = useState("asc")
    const [practiceLastAccessed, setPracticeLastAccessed] = useState("asc")
    const [practiceOffsetValue, setPracticeOffsetValue] = useState(1)
    const [practicecount, setpracticeCount] = useState(1)
    const [projectcount, setprojectCount] = useState(1)
    const [projectShownRecords, setprojectShownRecords] = useState(0);
    const [projectTotalRecords, setprojectTotalRecords] = useState(Number);
    const [practiceShownRecords, setpracticeShownRecords] = useState(0);
    const [practiceTotalRecords, setpracticeTotalRecords] = useState(Number);
    const [isLoading, setIsLoading] = useState(false);
    const [sort, setSort] = useState({
        column: page == "Practice" ? "Practice" : "Project",
        order: "asc"
    })
    const [dupSearch, setDupSearch] = useState("")
    const [isLoad,setIsLoad] = useState(false)
    const [projectSearch, setProjectSearch] = useState('');
    const [practicedupSearch, setPracticeDupSearch] = useState("")
    const [practiceSearch,setPractieSearch]=useState("")


    // PS_DB_255
    // The function which handles the search 
    const handleSearch = async () => {
        // 
        setIsLoad(true)
        // console.log(sort)
        if (page === "Practice") {
            let reqBody = {
                "TypeofData": "Practice",
                "sort": sort,
                "Search": practiceSearch,
                "offsetValue":practicecount
            }
            let response = await fetchAsamiUsageData(reqBody)
            setIsLoading(true)
            setIsLoad(false)
            console.log(response, "etuehueohtiue")
            let record = response?.data?.ResponseData?.length;
            setpracticeShownRecords(record);
            setPracticeData(response?.data?.ResponseData)
            setRecordCount(response?.data?.RecordCount)
        }
        if (page === "Project") {
            
            let reqBody = {
                "TypeofData": "Project",
                "sort": sort,
                "Search": search,
                "offsetValue":projectcount
            }
            let response = await fetchAsamiUsageData(reqBody)
            setIsLoad(false)
            setIsLoading(true)
            let record = response?.data?.ResponseData?.length;
            setprojectShownRecords(record);
            console.log(response?.data?.ResponseData, "awfwasfeagerher")
            setProjectData(response?.data?.ResponseData)
            setRecordCount(response?.data?.RecordCount)
        }
    }

    const convertDate = (inputDate) => {
        // Parse the input date string
        const [datePart, timePart] = inputDate.split(' ');
        const [year, month, day] = datePart.split('-');
        const [time, meridiem] = timePart.split(' ');
        const [hour, minute] = time.split(':');
   
        // Create a Date object
        const date = new Date(year, month - 1, day, hour, minute);
   
        // Format the date
        const formattedDate = date.toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        });
   
        // Set the time to 04:20 PM
        const formattedTime = '04:20 PM';
   
        // Combine the formatted date and time672
        const result = `${formattedDate} ${formattedTime}`;
   
        return result
      };

    async function handleOrderChange() {
        // 
        if (page == "Practice") {
            let reqBody = {
                "TypeofData": "Practice",
                "sort": {
                    column: "Practice",
                    order: "asc"
                },
                "Search": practiceSearch,
                "offsetValue":practicecount
            }
            setIsLoad(true)
            let response = await fetchAsamiUsageData(reqBody)
            setIsLoading(true)
            setIsLoad(false)
            // console.log(response, "etuehueohtiue")
            let record = response?.data?.ResponseData?.length;
            setpracticeShownRecords(record + practiceShownRecords);
            setPracticeData(response?.data?.ResponseData)
            setRecordCount(response?.data?.RecordCount)
            setSort({
                column: "Practice",
                order: "asc"
            })

        } else {
            let reqBody = {
                "TypeofData": "Project",
                "sort": {
                    column: "Project",
                    order: "asc"
                },
                "Search": search,
                "offsetValue":projectcount
            }
            let response = await fetchAsamiUsageData(reqBody)
            setIsLoading(true)
            setIsLoad(false)
            // console.log(response, "etuehueohtiue")
            let record = response?.data?.ResponseData?.length;
            setprojectShownRecords(record);
            setProjectData(response?.data?.ResponseData)
            setRecordCount(response?.data?.RecordCount)
            setSort({
                column: "Project",
                order: "asc"
            })
        }

    }
    function handleSort(event) {
        // 
        if(event.target.dataset.name === 'projectNameOrder'){
            setSort(() => ({

                column: event.target.name,
                order: projectNameOrder === "asc" ? "desc" : "asc"
    
            }))
        }
        else if(event.target.dataset.name === 'noOfProjectPeople'){
            setSort(() => ({

                column: event.target.name,
                order: noOfProjectPeople === "asc" ? "desc" : "asc"
    
            }))
        }
        else if(event.target.dataset.name === 'projectAsamiUsage'){
            setSort(() => ({

                column: event.target.name,
                order: projectAsamiUsage === "asc" ? "desc" : "asc"
    
            }))
        }
        else if(event.target.dataset.name === 'projectLastAccessed'){
            setSort(() => ({

                column: event.target.name,
                order: projectLastAccessed === "asc" ? "desc" : "asc"
    
            }))
        }
        else if(event.target.dataset.name === 'practiceNameOrder'){
            setSort(() => ({

                column: event.target.name,
                order: practiceNameOrder === "asc" ? "desc" : "asc"
    
            }))
        }
        else if(event.target.dataset.name === 'noOfPracticePeople'){
            setSort(() => ({

                column: event.target.name,
                order: noOfPracticePeople === "asc" ? "desc" : "asc"
    
            }))
        }
        else if(event.target.dataset.name === 'practiceAsamiUsage'){
            setSort(() => ({

                column: event.target.name,
                order: practiceAsamiUsage === "asc" ? "desc" : "asc"
    
            }))
        }
        else{
            setSort(() => ({

                column: event.target.name,
                order: practiceLastAccessed === "asc" ? "desc" : "asc"
    
            }))
        }
        

        
        
    }
    function toggleOrder(value) {
        if (value == 'asc') {
            return 'desc'
        }
        else {
            return 'asc'
        }
    }
    const loadmoreClick = () => {

        if (page == "Practice") {
            let countValue = practicecount + 1;
            setpracticeCount(countValue);
        }
        else if (page == "Project") {
            let countValue = projectcount + 1;
            setprojectCount(countValue);
        }
    };

    // PS_DB_267
    // The function is called when the search value or page value is changes
    useEffect(() => {
        // 
        handleOrderChange()
    }, [page])
    useEffect(() => {
        // 
        handleSearch()
    }, [search, sort, practicecount, projectcount,practiceSearch])



    return (
        <>
        {!isLoading ? (<div >
     
     <Loader isLoading={isLoading} />
   </div>): (
            // <div className={navOpen === "Close" ? "container-fluid second-component-asamiusage-nav-close" : "container-fluid second-component-asamiusage"}>
            <div className="container-fluid">
            <div className="row justify-content-lg-end ms-lg-3 ms-xl-0 justify-content-center">
            <div className={navOpen == "Close" ? `col-11 me-xl-4 me-lg-3` : `col-lg-9 me-md-2 cust-me-lg-4 col-11`}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="mt-4 mb-3 d-grid d-md-flex justify-content-md-between align-items-center">
                            <h2 className="font-bold font-20 mb-0 text-color-1">
                                <a href="/dashboard">
                                    <img 
                                        src="images-new/back-black-arrow.svg"
                                        alt="Back"
                                        className="me-2 icon-filter"
                                    />
                                </a>
                                Asami Usage
                            </h2>
                            {/*search*/}
                            <div className="mt-3 mt-md-0 d-flex align-items-center" >       
                                <div className="input-group transparent-grp w-100" style={{top:"50px"}}>
                                <input
                                        type="text" 
                                        className="form-control custom-search-input cust-search-input search-bg border-0 text-color-4 focus-none font-14 font-medium pe-0 ps-3"
                                        placeholder="Search"
                                        onChange={(e) =>
                                            { 
                                            page !== "Practice" ? setDupSearch(e.target.value): setPracticeDupSearch(e.target.value)}
                                        }
                                        onKeyUp={(e) => {
                                            if (e.key == "Enter" && page=="Practice") {
                                           
                                                setPractieSearch(e.target.value);
                                            
                                                
                                                
                                            }
                                            else if(e.key == "Enter" && page=="Project"){
                                           
                                                setSearch(e.target.value);
                                                
                                                
                                            }
                                        }
 
                                        }
                                        value={page=="Practice"?practicedupSearch:dupSearch}
                                    />
                                    <button className="btn focus-none search-bg " type="button" onClick={(e) => {
                                        if(page=="Practice"){
                                        setPractieSearch(practicedupSearch);
                                        }
                                        else{
 
                                            setSearch(dupSearch);
                                        }
                                    }}>
                                        <img src="images-new/input-search.svg" alt="input-search-icon" />
                                    </button>
                                </div>
                            </div>
                            {/*search*/}
                        </div>
                        {/* tabs starts here */}
                        <div className="custom-border-bottom mb-5">
                            <ul
                                className="nav nav-pills font-14 font-semibold custom-pills color-white-bg border-radius-5 cust-respt"
                                id="newtab"
                                role="tablist"
                            >
                                <li className="  nav-item  mb-2" role="presentation">
                                    <button
                                        className="custom-new-tab-btn nav-link  active d-flex px-3 font-16 font-semibold"
                                        id="manage-request-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#manage-request"
                                        type="button"
                                        role="tab"
                                        aria-controls="manage-request"
                                        aria-selected="true"
                                        onClick={() => {setPage("Project");}}
                                    >
                                        Project
                                    </button>
                                </li>
                                <li className="  nav-item  mb-2" role="presentation">
                                    <button
                                        className="custom-new-tab-btn nav-link d-flex px-3 font-16 font-semibold"
                                        id="user-activity-logs-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#user-activity-logs"
                                        type="button"
                                        role="tab"
                                        aria-controls="user-activity-logs"
                                        aria-selected="false"
                                        onClick={() => {setPage("Practice");}}
                                    >
                                        Practice
                                    </button>
                                </li>
                            </ul>
                        </div>
                        {/* tabs ends here */}
                        {/*Tab inner content starts here*/}
                        <div className="tab-content mt-2">
                            {/*manage request tab*/}
                            <div
                                className="tab-pane fade show active"
                                id="manage-request"
                                role="tabpanel"
                                aria-labelledby="manage-request-tab"
                            >
                                {/*grid starts here*/}
                                {isLoad ? ( <div
                                        style={{
                                          textAlign: 'center',
                                          flex: 1,
                                          display: 'flex',
                                          justifyContent: 'center',
                                          marginTop: '20%'
                                        }}
                                      >
                                        <ClipLoader color={'#865BE7'} loading={isLoad} size={50} />
                                      </div>):(
                                <div className="col-md-12">
                                    <div className="table-responsive rounded-3 transparent-grp">
                                        <table className="table theme-table font-regular font-14 mb-0">

                                            <thead>
                                                <tr>
                                                    <th className="ps-5 py-3">
                                                        Project Name{" "}
                                                        <span className="cursor-pointer">
                                                            {projectNameOrder == 'asc' ? <img className="filter-icons" name="Project" src="images/sort-up.svg" data-name = 'projectNameOrder' alt="sort-up" onClick={(event) => { 
                                                                // 
                                                                // console.log(event.target.dataset.name)
                                                                handleSort(event); setProjectNameOrder(toggleOrder(projectNameOrder))
                                                            }} /> : <img className="filter-icons" name="Project" src="images/Sort-Down.svg" alt="sort-up" data-name = 'projectNameOrder' onClick={(event) => {
                                                                handleSort(event); setProjectNameOrder(toggleOrder(projectNameOrder))
                                                            }} />}
                                                            {/* <button>

                                                            </button> */}
                                                        </span>
                                                    </th>
                                                    <th className="text-end py-3">
                                                        # of people
                                                        <span className="cursor-pointer ps-1">
                                                            {noOfProjectPeople == 'asc' ? <img className="filter-icons" name="NoOfPeople" src="images/sort-up.svg" alt="sort-up" data-name = 'noOfProjectPeople' onClick={(event) => {
                                                                handleSort(event); setNoOfProjectPeople(toggleOrder(noOfProjectPeople))
                                                            }} /> : <img className="filter-icons" name="NoOfPeople" src="images/Sort-Down.svg" alt="sort-up" data-name = 'noOfProjectPeople' onClick={(event) => {
                                                                handleSort(event); setNoOfProjectPeople(toggleOrder(noOfProjectPeople))
                                                            }} />}
                                                        </span>
                                                    </th>
                                                    <th className="text-end py-3">
                                                        Asami Usage
                                                        <span className="cursor-pointer ps-1">
                                                            {projectAsamiUsage == 'asc' ? <img className="filter-icons" name="AsamiUsage" src="images/sort-up.svg" alt="sort-up" data-name = 'projectAsamiUsage' onClick={(event) => {
                                                                handleSort(event); setProjectAsamiUsage(toggleOrder(projectAsamiUsage))
                                                            }} /> : <img className="filter-icons" name="AsamiUsage" src="images/Sort-Down.svg" alt="sort-up" data-name = 'projectAsamiUsage' onClick={(event) => {
                                                                handleSort(event); setProjectAsamiUsage(toggleOrder(projectAsamiUsage))
                                                            }} />}
                                                        </span>
                                                    </th>
                                                    <th className="text-center pe-5 py-4"  style={{paddingLeft:"76px"}}>
                                                        Last Accessed
                                                        <span className="cursor-pointer ps-1">
                                                            {projectLastAccessed == 'asc' ? <img className="filter-icons" name="LastAccessed" src="images/sort-up.svg" alt="sort-up" data-name = 'projectLastAccessed'  onClick={(event) => {
                                                                handleSort(event); setProjectLastAccessed(toggleOrder(projectLastAccessed))
                                                            }} /> : <img className="filter-icons" name="LastAccessed" src="images/Sort-Down.svg" alt="sort-up" data-name = 'projectLastAccessed' onClick={(event) => {
                                                                handleSort(event); setProjectLastAccessed(toggleOrder(projectLastAccessed))
                                                            }} />}
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                   ProjectData && projectShownRecords>0 ?( ProjectData?.map((ele, index) => {
                                                        let date=convertDate(ele.LastAccessed)
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td className="ps-5 py-3">
                                                                        {/* <img
                                                                            src="images/racpad-proj.svg"
                                                                            alt="Project"
                                                                            className="me-2"
                                                                        /> */}
                                                                        {ele.Project==""?"Previous Prompt":ele.Project}
                                                                    </td>
                                                                    <td className="text-end py-3">{ele.NoOfPeople}</td>
                                                                    <td className="text-end py-3">{ele.AsamiUsage}</td>
                                                                    <td className="text-center pe-5 py-3" style={{paddingLeft:"115px"}}>{date}</td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })) : <tr>
                                                    <td colSpan={4} className="text-center py-5 vh-50">
                                                      <img
                                                        src="images/Default/no-record-img.svg"
                                                        alt="no-record-img"
                                                        className="no-record-img mb-5"
                                                      />
                                                      <span className="font-20 font-medium w-100 d-block text-color-1">
                                                        No Records Found !
                                                      </span>
                                                    </td>
                                                  </tr>
                                                }
                                                {/* <tr>
                                                    <td className="ps-5 py-3">
                                                        <img
                                                            src="images/avalon-proj.svg"
                                                            alt="Project"
                                                            className="me-2"
                                                        />
                                                        Avalon
                                                    </td>
                                                    <td className="text-end py-4">20</td>
                                                    <td className="text-end py-4">312</td>
                                                    <td className="text-end pe-5 py-4">05/15/2023 02.30 PM</td>
                                                </tr>
                                                <tr>
                                                    <td className="ps-5 py-3">
                                                        <img
                                                            src="images/GenELT.svg"
                                                            alt="Project"
                                                            className="me-2"
                                                        />
                                                        GEN ETL
                                                    </td>
                                                    <td className="text-end py-4">20</td>
                                                    <td className="text-end py-4">312</td>
                                                    <td className="text-end pe-5 py-4">05/15/2023 02.30 PM</td>
                                                </tr>
                                                <tr>
                                                    <td className="ps-5 py-4">
                                                        <img
                                                            src="images/avalon-v2.svg"
                                                            alt="Project"
                                                            className="me-2"
                                                        />
                                                        Avalon
                                                    </td>
                                                    <td className="text-end py-4">20</td>
                                                    <td className="text-end py-4">312</td>
                                                    <td className="text-end pe-5 py-4">05/15/2023 02.30 PM</td>
                                                </tr>
                                                <tr>
                                                    <td className="ps-5 py-4">
                                                        <img
                                                            src="images/NFI_ETM.svg"
                                                            alt="Project"
                                                            className="me-2"
                                                        />
                                                        NFI ETM
                                                    </td>
                                                    <td className="text-end py-4">20</td>
                                                    <td className="text-end py-4">312</td>
                                                    <td className="text-end pe-5 py-4">05/15/2023 02.30 PM</td>
                                                </tr> */}
                                            </tbody>
                                        </table>
                                    </div>
                                    <p className="font-regular font-14 text-color-9 mt-3 mb-4">
                                        # of Records :{" "}
                                        <span className="font-bold font-14 text-color-1">{projectShownRecords}</span>{" "}
                                        out of{" "}
                                        <span className="font-bold font-14 text-color-1">{recordCount}</span>
                                    </p>
                                    {(recordCount > projectShownRecords) && recordCount > 10 ? (<div className="text-center mb-5">
                                        <button className="btn gradient-btn border-radius-4 font-14 font-semibold py-2 px-3" type="button" onClick={() => { loadmoreClick() }}><span className="color-gradient">Load More</span></button>
                                    </div>) :
                                        (<div className="text-center mb-5">
                                        </div>)}
                                </div>)}
                                {/*grid starts here*/}
                            </div>
                            {/*manage request tab*/}
                            {/*user activity logs tab*/}
                            <div
                                className="tab-pane fade"
                                id="user-activity-logs"
                                role="tabpanel"
                                aria-labelledby="user-activity-logs-tab"
                            >
                                {/*grid starts here*/}
                                <div className="col-md-12">
                                    <div className="table-responsive rounded-3 transparent-grp">
                                        <table className="table theme-table font-regular font-14 mb-0">
                                            {() => handleOrderChange("Practice")}
                                            <thead>
                                                <tr>
                                                    <th className="ps-5 py-3">
                                                        Practice Name{" "}
                                                        <span className="cursor-pointer">
                                                            {practiceNameOrder == 'asc' ? <img className="filter-icons" name="Practice" src="images/sort-up.svg" alt="sort-up" data-name = 'practiceNameOrder' onClick={(event) => {
                                                                handleSort(event); setPracticeNameOrder(toggleOrder(practiceNameOrder))
                                                            }} /> : <img name="Practice" className="filter-icons" src="images/Sort-Down.svg" alt="sort-up" data-name = 'practiceNameOrder' onClick={(event) => {
                                                                handleSort(event); setPracticeNameOrder(toggleOrder(practiceNameOrder))
                                                            }} />}
                                                        </span>
                                                    </th>
                                                    <th className="text-end py-4">
                                                        # of people
                                                        <span className="cursor-pointer ps-1">

                                                            {noOfPracticePeople == 'asc' ? <img className="filter-icons" name="NoOfPeople" src="images/sort-up.svg" alt="sort-up" data-name = 'noOfPracticePeople' onClick={(event) => {
                                                                handleSort(event); setNoOfPracticePeople(toggleOrder(noOfPracticePeople))
                                                            }} /> : <img name="NoOfPeople" className="filter-icons" src="images/Sort-Down.svg" alt="sort-up" data-name = 'noOfPracticePeople' onClick={(event) => {
                                                                handleSort(event); setNoOfPracticePeople(toggleOrder(noOfPracticePeople))
                                                            }} />}
                                                        </span>
                                                    </th>
                                                    <th className="text-end py-4">
                                                        Asami Usage
                                                        <span className="cursor-pointer ps-1">
                                                            {practiceAsamiUsage == 'asc' ? <img className="filter-icons" name="AsamiUsage" src="images/sort-up.svg" alt="sort-up" data-name = 'practiceAsamiUsage'  onClick={(event) => {
                                                                handleSort(event); setPracticeAsamiUsage(toggleOrder(practiceAsamiUsage))
                                                            }} /> : <img className="filter-icons" name="AsamiUsage" src="images/Sort-Down.svg" alt="sort-up" data-name = 'practiceAsamiUsage' onClick={(event) => {
                                                                handleSort(event); setPracticeAsamiUsage(toggleOrder(practiceAsamiUsage))
                                                            }} />}
                                                        </span>
                                                    </th>
                                                    <th className="text-center pe-5 py-4" style={{paddingLeft:"76px"}}>
                                                        Last Accessed
                                                        <span className="cursor-pointer ps-1">

                                                            {practiceLastAccessed == 'asc' ? <img className="filter-icons" name="LastAccessed" src="images/sort-up.svg" alt="sort-up" data-name = 'practiceLastAccessed' onClick={(event) => {
                                                                handleSort(event); setPracticeLastAccessed(toggleOrder(practiceLastAccessed))
                                                            }} /> : <img className="filter-icons" name="LastAccessed" src="images/Sort-Down.svg" alt="sort-up" data-name = 'practiceLastAccessed' onClick={(event) => {
                                                                handleSort(event); setPracticeLastAccessed(toggleOrder(practiceLastAccessed))
                                                            }} />}
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    PracticeData && practiceShownRecords>0 ? (PracticeData?.map((ele, index) => {
                                                        let date=convertDate(ele.LastAccessed)
                                                        return (
                                                            <>
                                                                <tr>
                                                                <td className="ps-5 py-3">{ele.Practice==""?"Previous Practice":ele.Practice}</td>
                                                                    <td className="text-end py-3">{ele.NoOfPeople}</td>
                                                                    <td className="text-end py-3">{ele.AsamiUsage}</td>
                                                                    <td className="text-center pe-5 py-3" style={{paddingLeft:"115px"}}>{date}</td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })) :  <tr>
                                                    <td colSpan={4} className="text-center py-5 vh-50">
                                                      <img
                                                        src="images/Default/no-record-img.svg"
                                                        alt="no-record-img"
                                                        className="no-record-img mb-5"
                                                      />
                                                      <span className="font-20 font-medium w-100 d-block text-color-1">
                                                        No Records Found !
                                                      </span>
                                                    </td>
                                                  </tr>
                                                }
                                                {/* <tr>
                                                    <td className="ps-5 py-4">UXD</td>
                                                    <td className="text-end py-4">20</td>
                                                    <td className="text-end py-4">312</td>
                                                    <td className="text-end pe-5 py-4">$ 2,324</td>
                                                </tr>
                                                <tr>
                                                    <td className="ps-5 py-4">UXD</td>
                                                    <td className="text-end py-4">20</td>
                                                    <td className="text-end py-4">312</td>
                                                    <td className="text-end pe-5 py-4">$ 2,324</td>
                                                </tr>
                                                <tr>
                                                    <td className="ps-5 py-4">UXD</td>
                                                    <td className="text-end py-4">20</td>
                                                    <td className="text-end py-4">312</td>
                                                    <td className="text-end pe-5 py-4">$ 2,324</td>
                                                </tr>
                                                <tr>
                                                    <td className="ps-5 py-4">UXD</td>
                                                    <td className="text-end py-4">20</td>
                                                    <td className="text-end py-4">312</td>
                                                    <td className="text-end pe-5 py-4">$ 2,324</td>
                                                </tr> */}
                                            </tbody>
                                        </table>
                                    </div>
                                    <p className="font-regular font-14 text-color-9 mt-3 mb-4">
                                        # of Records :{" "}
                                        <span className="font-bold font-14 text-color-1">{practiceShownRecords}</span>{" "}
                                        out of{" "}
                                        <span className="font-bold font-14 text-color-1">{recordCount}</span>
                                    </p>
                                    {(recordCount > practiceShownRecords) && recordCount > 10 ? (<div className="text-center mb-5">
                                        <button className="btn gradient-btn border-radius-4 font-14 font-semibold py-2 px-3" type="button" onClick={() => { loadmoreClick() }}><span className="color-gradient">Load More</span></button>
                                    </div>) :
                                        (<div className="text-center mb-5">
                                        </div>)}
                                </div>
                                {/*grid ends here*/}
                            </div>
                            {/*user activity logs tab*/}
                        </div>
                        {/*Tab inner content end here*/}
                    </div>
                </div>
            </div>
            </div>
            </div>
            
        )}
        </>
    )
}


export default AsamiUsageTable