import React, { useState, useEffect, useRef } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Editor from "@monaco-editor/react";
import { useLocation, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import plantumlEncoder from 'plantuml-encoder';
import { fetchPlantUMLDiagram } from "../AI Service/Api";
const PlantUMLPreview = ({ navOpen }) => {
  const location = useLocation();
  const plantUMLCode1 = sessionStorage.getItem("plantUMLCode") || '';
  const lines = plantUMLCode1.split("\n");
  let plantUMLCode = lines[0].startsWith("@startuml")
    ? lines.join("\n")
    : lines.slice(1).join("\n");

  const [code, setCode] = useState(plantUMLCode);
  const [diagramSrc, setDiagramSrc] = useState("");
  const [zoom, setZoom] = useState(1);
  const [copiedText, setCopiedText] = useState(false);
  const [copiedImage, setCopiedImage] = useState(false);
  const [chatId, setChatId] = useState(null);
  const [isDiagramFullscreen, setIsDiagramFullscreen] = useState(false);
  const [isEditorFullscreen, setIsEditorFullscreen] = useState(false);
  const [currentService, setCurrentService] = useState("PlantUML");
  const svgRef = useRef(null);

  useEffect(() => {
    const storedData = sessionStorage.getItem("plantUMLData");
    if (storedData) {
      const { chatId } = JSON.parse(storedData);
      setChatId(chatId);
    }
  }, []);

  useEffect(() => {
    const fetchDiagram = async () => {
      // try {
      //   const encoded = plantumlEncoder.encode(code);
      //   const response = await axios.get(`https://www.plantuml.com/plantuml/svg/${encoded}`, {
      //     responseType: 'text',
      //     validateStatus: (status) => status < 500
      //   });
      //   console.log(response.data,"response plantuml")
      //   if (response.status === 503) {
      //     throw new Error("PlantUML service unavailable, switching to Kroki");
      //   }
  
      //   setDiagramSrc(response.data);
      //   setCurrentService("PlantUML");
      //   console.log("Utilizing PlantUML service");
      // } catch (error) {
      //   console.error(error.message);
        try {
          const { diagramSvg, error } = await fetchPlantUMLDiagram(code);
          setDiagramSrc(diagramSvg);
          setCurrentService("Kroki");
          console.log("Utilizing Kroki service");
        } catch (krokiError) {
          console.error("Kroki service also failed:", krokiError);
          setCurrentService("None");
          setDiagramSrc("<div>Error displaying diagram</div>");
        }
      
    };
    fetchDiagram();
  }, [code]);

  const handleZoomIn = () => setZoom(zoom + 0.1);

  const handleZoomOut = () => zoom > 0.2 && setZoom(zoom - 0.1);

  const handleCopyText = () => {
    setCopiedText(true);
    setTimeout(() => setCopiedText(false), 2000);
  };

  const handleCopyDiagram = async () => {
    if (svgRef.current) {
      const svgElement = svgRef.current.querySelector('svg');
      if (svgElement) {
        const svgData = new XMLSerializer().serializeToString(svgElement);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = async () => {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          try {
            const blob = await new Promise((resolve) => canvas.toBlob(resolve));
            await navigator.clipboard.write([
              new ClipboardItem({ "image/png": blob }),
            ]);
            setCopiedImage(true);
            setTimeout(() => setCopiedImage(false), 2000);
          } catch (err) {
            console.error("Failed to copy image: ", err);
          }
        };
        img.src = "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svgData)));
      }
    }
  };

  const history = useHistory();

  const handleGoBack = () => history.push(`/chat?chatID=${chatId}`);

  const toggleDiagramFullscreen = () =>
    setIsDiagramFullscreen(!isDiagramFullscreen);

  const toggleEditorFullscreen = () =>
    setIsEditorFullscreen(!isEditorFullscreen);

  return (
    <div className="container-fluid">
      <div className="row justify-content-lg-end ms-lg-3 ms-xl-0 justify-content-center">
        <div className={navOpen === "Close" ? `col-11 me-xl-4 me-lg-3` : `col-lg-9 me-md-2 cust-me-lg-4 col-11`}>
          <div className="row">
            <div className="col-md-12">
              <h3 className="font-24 font-semibold text-color-1 mt-3 mb-4">
                <a href="#" onClick={handleGoBack}>
                  <img
                    src="images/back-black-arrow.svg"
                    alt="Back"
                    className="me-3 icon-filter"
                  />
                </a>
                Code Snippet & UML Diagram
              </h3>

              <div className="accordion mb-3 rounded-3 overflow-hidden" id="accordionExample">
                <div className="accordion-item cust-code-accordion-item bg-transparent rounded-3">
                  <h2 className="accordion-header p-3 rounded-top" id="headingOne">
                    <button
                      className="accordion-button cust-code-accordion-btn rounded-3 text-color-2 font-medium font-16"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      View Instructions
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body p-2 rounded-bottom">
                      <ul className="ms-4">
                        <li className="font-regular font-14 mb-2 text-color-9">
                          Please validate whether the data's are correct
                        </li>
                        <li className="font-regular font-14 text-color-9">
                          If things are fine just click{" "}
                          <span className="font-bold">'back'</span> then on{" "}
                          <span className="font-bold">'Looks good'</span> and
                          proceed to next steps
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {!isDiagramFullscreen && (
                  <div className={isEditorFullscreen ? "col-md-12" : "col-md-6"}>
                    <div className="position-relative">
                      <Editor
                        defaultLanguage="markdown"
                        className="px-2 py-2 er-snippet code-snippet-area"
                        value={code}
                        onChange={setCode}
                        options={{
                          minimap: { enabled: false },
                        }}
                        height={isEditorFullscreen ? "80vh" : "400px"}
                      />

                      <div className="code-btns">
                        <CopyToClipboard text={code} onCopy={handleCopyText}>
                          <button
                            type="button"
                            className={
                              !copiedText
                                ? "btn textarea-btn me-3"
                                : "btn textarea-btn copied postion-retlative me-3"
                            }
                          >
                            {copiedText ? (
                              <div>
                                <img src="images/copy-icon-btn.svg" alt="Copy" />
                                <img
                                  src="images/tick-icon.svg"
                                  alt="copied"
                                  className="emoji-active-tick"
                                ></img>
                              </div>
                            ) : (
                              <img src="images/copy-icon-btn.svg" alt="Copy" />
                            )}
                          </button>
                        </CopyToClipboard>
                        <button
                          type="button"
                          className={`btn textarea-btn me-3`}
                          onClick={toggleEditorFullscreen}
                        >
                          {isEditorFullscreen ? (
                            <img src="images/minimize-icon.svg" alt="Minimize" />
                          ) : (
                            <img src="images/maximize-icon.svg" alt="Maximize" />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <div className={isDiagramFullscreen ? "col-md-12" : isEditorFullscreen ? "d-none" : "col-md-6"}>
                  <div
                    className="position-relative mb-200 "
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      overflow: "visible",
                    }}
                  >
                    <div
                      className="px-2 py-2 er-snippet code-snippet-area div12 position-relative uml-scroll"
                      style={{
                        height: isDiagramFullscreen ? "80vh" : "400px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        overflow: "auto",
                      }}
                    >
                      <div
                        ref={svgRef}
                        className="px-2 py-2 er-snippet code-snippet-area div2"
                        style={{
                          transform: `scale(${zoom})`,
                          transformOrigin: "top left",
                          width: "fit-content",
                          height: "fit-content",
                          flexGrow: 1,
                          minWidth: "100%",
                          marginLeft: "-1rem",
                        }}
                      >
                        <div dangerouslySetInnerHTML={{ __html: diagramSrc }} />
                      </div>

                      <div
                        className="btn-group cust-btngrp p-1 mt-2 position-fixed"
                        role="group"
                        aria-label="Basic example"
                      >
                        <div>
                          <button
                            type="button"
                            className="d-block btn zoom-btn px-1 border-bottom rounded-0"
                            onClick={handleZoomIn}
                          >
                            <img src="images/plus-icons.svg" alt="Zoom In" />
                          </button>
                        </div>
                        <div className="d-flex justify-content-center">
                          <button
                            type="button"
                            className="btn zoom-btn px-2 rounded-0"
                            onClick={handleZoomOut}
                          >
                            <img src="images/minus-icon.svg" alt="Zoom Out" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="code-btns">
                      <button
                        type="button"
                        className={
                          !copiedImage
                            ? "btn textarea-btn me-3"
                            : "btn textarea-btn copied postion-retlative me-3"
                        }
                        onClick={handleCopyDiagram}
                      >
                        {copiedImage ? (
                          <div>
                            <img src="images/copy-icon-btn.svg" alt="Copy" />
                            <img
                              src="images/tick-icon.svg"
                              alt="copied"
                              className="emoji-active-tick"
                            ></img>
                          </div>
                        ) : (
                          <img src="images/copy-icon-btn.svg" alt="Copy" />
                        )}
                      </button>
                      <button
                        type="button"
                        className={`btn textarea-btn me-3`}
                        onClick={toggleDiagramFullscreen}
                      >
                        {isDiagramFullscreen ? (
                          <img src="images/minimize-icon.svg" alt="Minimize" />
                        ) : (
                          <img src="images/maximize-icon.svg" alt="Maximize" />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlantUMLPreview;