import { EncryptStorage } from "encrypt-storage";

const encryptStorage1 = new EncryptStorage("vdoaks-d--21jnaskxo212okdasdo-", {
  prefix: "jgfuygh389u9okdoadlkmzxlcqoweqow",
  stateManagementUse: true,
  storageType: "localStorage",
});

export const setSessionItem = (key, value) => {
  return encryptStorage1.setItem(key, JSON.stringify(value));
};

export const getSessionItem = (key) => {
  return JSON.parse(encryptStorage1.getItem(key) ?? null);
};
