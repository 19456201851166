import shortid from "shortid";

export const SIDEBAR_ITEM = "sidebarItem";
export const ROW = "row";
export const COLUMN = "column";
export const COMPONENT = "component";

export const SIDEBAR_ITEMS = [
  [
    {
      id: shortid.generate(),
      type: SIDEBAR_ITEM,
      component: {
        type: "1-Column",
        content: "",
        image: "images/one-column.svg",
      },
    },
    {
      id: shortid.generate(),
      type: SIDEBAR_ITEM,
      component: {
        type: "2-Column",
        content: "",
        image: "images/two-column.svg",
      },
    },
    {
      id: shortid.generate(),
      type: SIDEBAR_ITEM,
      component: {
        type: "3-Column",
        content: "",
        image: "images/Three-column.svg",
      },
    },
    {
      id: shortid.generate(),
      type: SIDEBAR_ITEM,
      component: {
        type: "4-Column",
        content: "",
        image: "images/Four-column.svg",
      },
    },
  ],
  [
    {
      id: shortid.generate(),
      type: SIDEBAR_ITEM,
      component: {
        type: "Text Field",
        content: "Text Field",
        image: "images/Text-Field.svg",
        general: {
          labelName: "",
          placeHolder: "",
          inputType: "",
          enableHelpText: false,
          helpText: "",
          isMandatory: true,
          otherValidations: "",
        },
        dataSource: {
          propertyToBind: {
            textProperty: "",
          },
        },
        validation: {
          labelName: false,
          inputType: false,
          textProperty: false,
          helpText: false,
        },
      },
    },
    {
      id: shortid.generate(),
      type: SIDEBAR_ITEM,
      component: {
        type: "Text Area",
        content: "Text Area",
        image: "images/Text-Area.svg",
        general: {
          labelName: "",
          placeHolder: "",
          enableHelpText: false,
          helpText: "",
          isMandatory: true,
        },
        dataSource: {
          propertyToBind: {
            textProperty: "",
          },
        },
        validation: {
          labelName: false,
          textProperty: false,
          helpText: false,
        },
      },
    },
  ],
  [
    {
      id: shortid.generate(),
      type: SIDEBAR_ITEM,
      component: {
        type: "Drop Down",
        content: "Drop Down",
        image: "images/Dropdown.svg",
        general: {
          labelName: "",
          placeHolder: "",
          multiple: false,
          dataSource: 1,
          options: [],
          enableHelpText: false,
          helpText: "",
          isMandatory: true,
        },
        dataSource: {
          apiLink: "",
          responseJson: "",
          propertyToBind: {
            valueProperty: "",
            textProperty: "",
          },
        },
        validation: {
          labelName: false,
          apiLink: false,
          valueProperty: false,
          textProperty: false,
          helpText: false,
          options: false,
        },
      },
    },
    {
      id: shortid.generate(),
      type: SIDEBAR_ITEM,
      component: {
        type: "Checkbox",
        content: "Checkbox",
        image: "images/Checkbox.svg",
        general: {
          labelName: "",
          alignment: 1,
          dataSource: 1,
          options: [],
          enableHelpText: false,
          helpText: "",
          isMandatory: true,
        },
        dataSource: {
          apiLink: "",
          responseJson: "",
          propertyToBind: {
            valueProperty: "",
            textProperty: "",
          },
        },
        validation: {
          labelName: false,
          alignment: false,
          apiLink: false,
          valueProperty: false,
          textProperty: false,
          helpText: false,
          options: false,
        },
      },
    },
    {
      id: shortid.generate(),
      type: SIDEBAR_ITEM,
      component: {
        type: "Radio",
        content: "Radio",
        image: "images/Radio.svg",
        general: {
          labelName: "",
          alignment: 1,
          dataSource: 1,
          options: [],
          enableHelpText: false,
          helpText: "",
          isMandatory: true,
        },
        dataSource: {
          apiLink: "",
          responseJson: "",
          propertyToBind: {
            valueProperty: "",
            textProperty: "",
          },
        },
        validation: {
          labelName: false,
          alignment: false,
          apiLink: false,
          valueProperty: false,
          textProperty: false,
          helpText: false,
          options: false,
        },
      },
    },
    {
      id: shortid.generate(),
      type: SIDEBAR_ITEM,
      component: {
        type: "Switch",
        content: "Switch",
        image: "images/Switch.svg",
        general: {
          labelName: "",
          defaultState: "Off",
          enableHelpText: false,
          helpText: "",
        },
        dataSource: {
          propertyToBind: {
            textProperty: "",
          },
        },
        validation: {
          labelName: false,
          textProperty: false,
          helpText: false,
        },
      },
    },
    {
      id: shortid.generate(),
      type: SIDEBAR_ITEM,
      component: {
        type: "Type Ahead",
        content: "Type Ahead",
        image: "images/Typeahead.svg",
        general: {
          labelName: "",
          placeHolder: "",
          multiple: false,
          dataSource: 1,
          options: [],
          enableHelpText: false,
          helpText: "",
          isMandatory: true,
        },
        dataSource: {
          apiLink: "",
          responseJson: "",
          propertyToBind: {
            valueProperty: "",
            textProperty: "",
          },
        },
        validation: {
          labelName: false,
          apiLink: false,
          textProperty: false,
          valueProperty: false,
          helpText: false,
          options: false,
        },
      },
    },
    {
      id: shortid.generate(),
      type: SIDEBAR_ITEM,
      component: {
        type: "File Upload",
        content: "File Upload",
        image: "images/File-Upload.svg",
        general: {
          labelName: "",
          // className: "",
          fileTypes: [],
          multiple: false,
          enableHelpText: false,
          helpText: "",
          isMandatory: true,
        },
        validation: {
          labelName: false,
          helpText: false,
        },
      },
    },
  ],
  [
    {
      id: shortid.generate(),
      type: SIDEBAR_ITEM,
      component: {
        type: "Date Picker",
        content: "Date Picker",
        image: "images/Date-picker.svg",
        general: {
          labelName: "",
          enableHelpText: false,
          helpText: "",
          isMandatory: true,
        },
        dataSource: {
          propertyToBind: {
            textProperty: "",
          },
        },
        validation: {
          labelName: false,
          textProperty: false,
          helpText: false,
        },
      },
    },
    {
      id: shortid.generate(),
      type: SIDEBAR_ITEM,
      component: {
        type: "Time Picker",
        content: "Time Picker",
        image: "images/Time-Picker.svg",
        general: {
          labelName: "",
          enableHelpText: false,
          helpText: "",
          isMandatory: true,
        },
        dataSource: {
          propertyToBind: {
            textProperty: "",
          },
        },
        validation: {
          labelName: false,
          textProperty: false,
          helpText: false,
        },
      },
    },
  ],
  [
    {
      id: shortid.generate(),
      type: SIDEBAR_ITEM,
      component: {
        type: "Link",
        content: "Link",
        image: "images/Link.svg",
        general: {
          labelName: "",
          // className: "",
          url: "",
          openNewTab: "",
          enableHelpText: false,
          helpText: "",
        },
        validation: {
          labelName: false,
          url: false,
          helpText: false,
        },
      },
    },
    {
      id: shortid.generate(),
      type: SIDEBAR_ITEM,
      component: {
        type: "Text Block",
        content: "Text Block",
        image: "images/Text-Block.svg",
        general: {
          labelName: "",
          // className: "",
          content: "",
        },
        validation: {
          content: false,
        },
      },
    },
  ],
  // {
  //   id: shortid.generate(),
  //   type: SIDEBAR_ITEM,
  //   component: {
  //     type: "input",
  //     content: "Some input"
  //   }
  // },
  // {
  //   id: shortid.generate(),
  //   type: SIDEBAR_ITEM,
  //   component: {
  //     type: "name",
  //     content: "Some name"
  //   }
  // },
  // {
  //   id: shortid.generate(),
  //   type: SIDEBAR_ITEM,
  //   component: {
  //     type: "email",
  //     content: "Some email"
  //   }
  // },
  // {
  //   id: shortid.generate(),
  //   type: SIDEBAR_ITEM,
  //   component: {
  //     type: "phone",
  //     content: "Some phone"
  //   }
  // },
  // {
  //   id: shortid.generate(),
  //   type: SIDEBAR_ITEM,
  //   component: {
  //     type: "image",
  //     content: "Some image"
  //   }
  // }
];
