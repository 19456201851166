import React, { useEffect, useState } from "react";
import { ExistData, tfsconfigForm, kgBuildService, kgCheck } from "../../AI Service/Api";
// PS_MP_01 - 03 import required packages and creaction component function
export default function ManageProjectForm({ projectName, setShowTfsPopUp, configureOrEdit, projectID }) {  // key as props
    //(configureOrEdit, "awfeafwagrsyhrj", projectID)
    // //
    // PS_MP_04 create required useState variables 
    const [tfsFormData, setTfsFormData] = useState({
        userName: "",
        personalAT: "",
        projectURL: "",
    });
    const [key, setKey] = useState(configureOrEdit);
    const [configDB, setconfigDB] = useState(false);
    const [DBschema, setDBschema] = useState(false);
    const [tfsConfigID, settfsConfigID] = useState("");
    const [serverName, setServerName] = useState("");
    const [databaseName, setDatabaseName] = useState("");
    const [DBuserName, setDBuserName] = useState("");
    const [password, setPassword] = useState("");
    const [selectedRepo, setSelectedRepo] = useState([]);
    const [DBschemaName, setDBschemaName] = useState("");
    const [repositories, setRepositories] = useState([]);
    const [errors, setErrors] = useState("");
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState("TFS");
    const [kgRepoName, setKgRepoName] = useState()
    const [PAT, setPAT] = useState()
    const [selectedOption, setSelectedOption] = useState(false);
    // PS_MP_05 - 06 if key value is equal to edit call previousData
    useEffect(() => {
        if (key === "EDIT") {
            previousData()
        }

    }, [])

    const KGBuild = async () => {
        // requirements :  req_body["PAT"] , req_body["branchName"] , req_body["repos"]
        setLoading(true)
        //("Entered KG")
        let req_body = {
            "PAT": tfsFormData.personalAT,
            "ProjectName": projectName,
            "Repos": selectedRepo,
            "ProjectId": projectID
        }
        let response = await kgBuildService(req_body)
        //("Finished KG", response)
        setLoading(false)

    }




    // PS_MP_07 - 19 previousData function to fetch existing data form database
    const previousData = async () => {
        const reqBody = {
            projectID: projectID
        }
        try {
            const response = await ExistData(reqBody)
            //(response, "REPOs1214")
            if (response.success) {
                //("hello there214215315")
                setTfsFormData({ projectURL: response.Responsedata[0].project_url, personalAT: response.Responsedata[0].PAT, userName: response.Responsedata[0].user_name })
                setSelectedRepo(response.Responsedata[0].Repo_data)
                setDBschemaName(response.Responsedata[0].db_schema)
                setDatabaseName(response.Responsedata[0].database_name)
                setDBuserName(response.Responsedata[0].DBusername)
                setServerName(response.Responsedata[0].server_name)
                setPassword(response.Responsedata[0].server_name)
            }
            
        } catch {
            setErrors("Somthing went wrong while fetching Previous data")
        }
    }

    //(selectedRepo,"fafafbbbb")


    //  PS_MP_20 to PS_MP_34 insertUserCred to call the api to insert form1 data in database
    const insertUserCred = async () => {
        setLoading(true);
        const req = {
            key: key,
            next: "TFS",
            username: tfsFormData.userName,
            PAT: tfsFormData.personalAT,
            projectUrl: tfsFormData.projectURL,
            project_id: projectID
        };
        //(req, 'fsaoifnasifnaio')

        try {
            const responseData = await tfsconfigForm(req);
            //(responseData, "oihasoifsaiogsaiog")
            if (responseData.status_code === 200) {
                setRepositories(responseData.ResponseData.projectRepoData);
                settfsConfigID(responseData.ResponseData.tfs_config_id);
                setLoading(false);
                setKgRepoName()
                setShowModal("REPO")
            } else {
                setErrors("Something went wrong 1");
                setShowModal("TFS")
            }
        } catch (error) {
            //(error);
            setErrors("Something went wrong 7" + error);
            setShowModal("TFS")
        }
        finally {
            // KGBuild()
            setLoading(false)
        }
    };

    //  PS_MP_35 to PS_MP_49 insertRepo to call the api to insert form2 Repo data in database
    const insertRepo = async () => {
        // Disable button and set loading state
        // //
        setLoading(true);
        //(selectedRepo,"selected okay")

        const req = {
            key: key,
            next: "REPO",
            tfs_config_id: tfsConfigID,
            selected_repos: selectedRepo,
        };

        try {
            const responseData = await tfsconfigForm(req);

            if (responseData.status_code === 200) {
                settfsConfigID(responseData.ResponseData);
                if (configDB) {
                    setShowModal("DB")
                }
                else {
                    setShowModal("SUCCESS")
                }

            } else {
                setErrors("Something went wrong 3");
                setShowModal("REPO")
            }
        } catch (error) {
            setShowModal("REPO")
            setErrors("Something went wrong 5",error);

        } finally {
            setLoading(false);
            KGBuild()
        }
    };
    // PS_MP_50 to PS_MP_66 insertDBcreds to call the api to insert DB creds in database
    const insertDBCreds = async () => {
        setLoading(true);
        // //
        if (!serverName || !databaseName || !DBuserName || !password) {
            setErrors("Please enter a value in all required fields.");
            return;
        }
        const req = {
            tfs_config_id: tfsConfigID,
            server_name: serverName,
            database_name: databaseName,
            username: DBuserName,
            password: password,
            db_schema: DBschemaName,
            next: "DB",
            key: key,
        };

        try {
            const responseData = await tfsconfigForm(req);

            if (responseData.status_code === 200) {
                //(responseData.ResponseData.tfs_config_id, "prasanth");
                settfsConfigID(responseData.ResponseData.tfs_config_id);
                handleCancel()
                setShowModal("SUCCESS")

            } else {
                setErrors("Something went wrong 4");
                setShowModal("DB")
            }
        } catch (error) {
            //(error);
            setShowModal("DB")
            setErrors("Something went wrong 6");
        } finally {
            setLoading(false);
        }
    };
    // PS_MP_66,67 to handle regex 
    const tfsUsernameRegex =
        /^(?:[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+|[a-zA-Z0-9_.-]{8,64})$/;
        const projectURLRegex = /^(https?:\/\/)?[\w\-]+(\.[\w\-]+)*(:\d+)?(\/\S*)?$/;
    // PS_MP_68 to PS_MP_72  to handle the validation 
    const InsertUpadteTfs = () => {
        if (!tfsFormData.userName || !tfsFormData.personalAT || !tfsFormData.projectURL) {
            setErrors("Please fill out all the required fields");
            //(errors);
        } else {
            const validUser = tfsUsernameRegex.test(tfsFormData.userName);
            const validProjectUrl = projectURLRegex.test(tfsFormData.projectURL);
            //(validUser, validProjectUrl);

            if (validUser && validProjectUrl) {

                setErrors("");
                insertUserCred();
            } else {
                setErrors("Please provide a valid input");
            }
        }
    };
    // PS_MP_73 to 74 is update input field value in state variable
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name == "personalAT") {
            setPAT(value)
        }
        setTfsFormData({ ...tfsFormData, [name]: value });
        //(tfsFormData,"PAT");
        setErrors("");
    };
    //(PAT,"hofashuf")
    // PS_MP_76 and  77 is to validate input field values 
    const validateForm = () => {
        const validUser = tfsUsernameRegex.test(tfsFormData.userName);
        const validProjectUrl = projectURLRegex.test(tfsFormData.projectURL);
        return validUser && validProjectUrl;
    };
    // PS_MP_78 to call validation function and call api function to insert data
    const handleNextButtonClick = () => {
        // debugger
        if (validateForm()) {
            InsertUpadteTfs();
        } else {
            setErrors("Please provide valid input");
        }
    };
    // PS_MP_80 and 81 is to update the database is required or not 
    const handleConfigDB = () => {
        setconfigDB(true);
    };
    // PS_MP_82,83 update  set servername
    const handleServername = (event) => {
        setServerName(event.target.value);
    };
    // PS_MP_84,85 update  set Dbname
    const handleDbName = (event) => {
        setDatabaseName(event.target.value);
    };
    // PS_MP_86,87 update  set DBusername
    const handleDbUserName = (event) => {
        setDBuserName(event.target.value);
    };
    // PS_MP_88,89 update  set servername
    const handlePassword = (event) => {
        setPassword(event.target.value);
    };
    // PS_MP_90,91 update  set servername
    const handleDbSchema = (event) => {
        setDBschemaName(event.target.value);
    };
    // qj2z5yzu577jexjueytys73ax723ckvmodu7vkmavgnziw64d6ha
    // PS_MP_92 to 93 to update change repo
    const handleRepoChange = (e, repo) => {
        const isChecked = e.target.checked;
        //(selectedRepo, "length");
        if (isChecked) {
            setSelectedRepo([...selectedRepo, repo]);
        } else {
            setSelectedRepo(
                selectedRepo.filter((selected) => selected.Repo_Url !== repo.Repo_Url)
            );
        }
        //(selectedRepo, "")
    };
    // PS_MP_95 and 96 while click cancel clear the state variable
    const handleCancel = () => {
        setShowTfsPopUp(false)
        setShowModal("NONE")
        setDBschemaName("");
        setTfsFormData({
            userName: "",
            personalAT: "",
            projectURL: "",
        });
        setSelectedRepo([]);
        setDatabaseName("");
        setDBuserName("");
        setPassword("");
        setServerName("");
        setErrors("")
        setDBschema(false)
        setconfigDB(false)

    };

    const [loadThing, setLoadThing] = useState(false)

    const handleOptionChange = (option) => {
        setSelectedOption(option);
        setErrors(null); // Clear errors when an option is selected
        if (option === 'yes') {
          handleConfigDB();
        } else {
          setconfigDB(false);
        }
      };
      const handleNext = () => {
        if (selectedOption === null) {
          setErrors('Please select DB config option');
        } else {
          setErrors(null);
          insertRepo();
        }
      };
    // useEffect(() => {
    //     // Call the API immediately on mount
    //     kgBuildCheck()
    //     // Set up the interval to call the API every 10 minutes

    //     const intervalId = setInterval(() => {
    //         //("inside interval")
    //         kgBuildCheck();
    //     }, 10 * 60 * 1000); // 10 minutes in milliseconds

    //     // Clean up the interval on component unmount
    //     return () => clearInterval(intervalId);
    // }, []);




    return (
        <div className="container-fluid">

            {/* popup-1 start here */}
            {/* <button
                type="button"
                className="btn btn-primary"
                // data-bs-target="#Helpus-popup"
                onClick={() => setShowModal("TFS")} // Update the onClick event
            >
                {projectName}
            </button> */}

            {/* Racpad - Configurations modal starts here */}
            {showModal === "TFS" && ( // Update the condition for rendering the modal
                <div className="modal fade show" style={{ display: "block", background: "#000000cc" }}>
                    <div className="modal-dialog modal-dialog-centered custom-popup-width-box border-1">
                        <div className="modal-content p-3 border-0 bg-color-4">
                            <div className="modal-header border-0 pt-2 p-0 px-2">
                                <h5 className="modal-title text-color-1 font-22 font-bold">

                                    <img
                                        src="images-new/back-black-arrow.svg"
                                        alt="Back"
                                        class="me-2 icon-filter"
                                        onClick={() => setShowModal("NONE")}
                                    />

                                    {projectName}
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close close-icon-filter"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={handleCancel}
                                >
                                    {/* <img className="" src="images-new/close-popup.svg" alt="close-icon" /> */}
                                </button>
                            </div>
                            <div className="modal-body popup-scrl pt-2">
                                <div className="row">
                                    {/* Wizard starts here */}
                                    <div className="d-flex justify-content-center my-4 position-relative p-0">
                                        <span className="wizard-number-active font-18 font-semibold d-block d-flex align-items-center justify-content-center me-0 me-md-3 pt-1">
                                            1
                                        </span>
                                        <span className="wizard-text-active wizard-line-length font-15 font-semibold d-block d-flex align-items-center justify-content-center me-0 me-md-5 position-relative ms-2 ms-md-0">
                                            TFS
                                        </span>
                                        <span className="wizard-number font-18 font-semibold d-block d-flex align-items-center justify-content-center me-0 me-md-3 ms-5 pt-1">
                                            2
                                        </span>
                                        <span className="wizard-text font-15 font-semibold d-block d-flex align-items-center justify-content-center me-0 me-md-5 position-relative ms-2 ms-md-0">
                                            Repo
                                        </span>
                                       
                                    </div>
                                    {/* Wizard Ends here */}
                                    {/* content */}
                                    <div className="col-md-6 my-4 ps-1">
                                        <label
                                            htmlFor="exampleInputEmail1"
                                            className="form-label font-14 font-bold text-color-2"
                                        >
                                            User Name<span style={{ color: "red" }}>*</span>
                                        </label>
                                        <input
                                            type="userName"
                                            className="form-control bg-transparent text-color-4 custom-border-3 cust-search-input"
                                            id="exampleInputEmail1"
                                            name="userName"
                                            value={tfsFormData.userName}
                                            onChange={handleInputChange}
                                            aria-describedby="emailHelp"
                                            placeholder="Enter User Name"
                                        />
                                        {/* <p>{}</p> */}
                                    </div>
                                    <div className="col-md-6 my-4 pe-1 ps-1 ps-md-4">
                                        <label
                                            htmlFor="exampleInputEmail1"
                                            className="form-label font-14 font-bold text-color-2"
                                        >
                                            PAT<span style={{ color: "red" }}>*</span>
                                        </label>
                                        <input
                                            type="PAT"
                                            className="form-control bg-transparent text-color-4 custom-border-3 cust-search-input"
                                            // id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            name="personalAT"
                                            value={tfsFormData.personalAT}
                                            onChange={handleInputChange}
                                            placeholder="Enter Personal Access Token"
                                        />
                                    </div>

                                    <div className="col-md-6 mb-4 ps-1 mt-4 mt-md-0 ps-1">
                                        <label
                                            htmlFor="exampleInputEmail1"
                                            className="form-label font-14 font-bold text-color-1"
                                        >
                                            Project URL<span style={{ color: "red" }}>*</span>
                                        </label>
                                        <input
                                            type="projectURL"
                                            className="form-control bg-transparent text-color-4 custom-border-3 cust-search-input"
                                            // id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            name="projectURL"
                                            value={tfsFormData.projectURL}
                                            onChange={handleInputChange}
                                            placeholder="Enter Project URL"
                                        />
                                    </div>

                                    {errors && (

                                        <span style={{ color: "red" }}>{errors}</span>

                                    )}
                                </div>
                            </div>
                            <div className="modal-footer border-0 pe-1 pt-0 gap-3">
                                <button
                                    type="button"
                                    className="btn custom-outline-btn font-14 px-4 py-2 m-0 font-medium"
                                    data-bs-dismiss="modal"
                                    onClick={handleCancel}
                                // onClick={()=>setShowModal("REPO")}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn primary-btn font-14 font-semibold border-radius-5 color-white py-2 px-4 m-0"


                                    onClick={handleNextButtonClick}
                                >
                                    {loading ? 'Loading...' : 'Next'}
                                </button>

                            </div>
                        </div>
                    </div>
                </div>)}
            {/*Racpad - Configurations modal ends here*/}
            {/* popup-1 end here */}

            {/* poup-2 start here*/}

            {/*Racpad - Configurations modal starts here*/}
            {showModal === "REPO" && (
                <div
                    className="modal fade show"
                    style={{ display: "block", background: "#000000cc" }}
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="Racpad-Configurations-Select"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered custom-popup-box border-1">
                        <div className="modal-content p-4 border-0 bg-color-4">
                            <div className="modal-header border-0  p-0 ps-2">
                                <h5 className="modal-title text-color-1 font-22 font-bold pt-2">
                                    <img
                                        src="images-new/back-black-arrow.svg"
                                        alt="Back"
                                        class="me-2 icon-filter"
                                        onClick={() => setShowModal("TFS")}
                                    />
                                    Racpad - Configurations
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close close-icon-filter"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={handleCancel}
                                >
                                    {/* <img className="close-icon-filter" src="images-new/close-popup.svg" alt="close-icon" /> */}
                                </button>
                            </div>
                            <div className="modal-body popup-scrl pt-2 pe-0">
                                <div className="row">
                                    {/* Wizard starts here */}
                                    <div className="d-flex justify-content-center my-5 position-relative">
                                        <span className="wizard-num-active font-18 font-semibold d-block d-flex align-items-center justify-content-center me-3 pt-1 ">
                                            1
                                        </span>
                                        <span className="wizard-text-active wizard-line-length font-15 font-semibold d-block d-flex align-items-center justify-content-center me-5 position-relative">
                                            TFS
                                        </span>
                                        <span className="wizard-number-active font-18 font-semibold d-block d-flex align-items-center justify-content-center me-3 ms-0 ms-sm-5 pt-1">
                                            2
                                        </span>
                                        <span className="wizard-text-active  font-15 font-semibold d-block d-flex align-items-center justify-content-center me-5 position-relative">
                                            Repo
                                        </span>
                                       
                                      
                                    </div>
                                    {/* Wizard Ends here */}
                                    {/* content */}
                                    <h4 className="font-14 font-bold p-0 text-color-2">Select Repo:</h4>

                                    {repositories && repositories.length != 0 ? (repositories.map((repo, index) => (
                                        <div className="col-md-4 my-3 p-0" key={index} >
                                            <div className="form-border me-4">
                                                <input
                                                    className="form-check-input align-middle m-0 ms-2 mb-1 user-checkbox"
                                                    type="checkbox"
                                                    id={`checkbox-${index}`}
                                                    checked={selectedRepo.some(
                                                        (selected) => selected.Repo_Url === repo.Repo_Url
                                                    )}
                                                    onClick={(e) => handleRepoChange(e, repo)}
                                                />
                                                <label
                                                    className="form-check-label p-2 text-color-9"
                                                    htmlFor={`checkbox-${repo.Repo_Url}`}
                                                >
                                                    {repo.Repo_name}
                                                </label>
                                            </div>
                                        </div>
                                    ))) : (<span style={{ color: "green" }}>Fetching repo.. </span>)}
{/* 
                                    <div className="p-0">
                                        <div className="col-md-4 my-4  p-0">
                                            <h4 className="font-14 font-bold text-color-2">
                                                Would you like to configure DB?
                                            </h4>
                                            <div className="form-check form-check-inline bg-transparent">
                                                <input
                                                    className="form-check-input user-radio bg-transparent"
                                                    type="radio"
                                                    name="inlineRadioOptions"
                                                    id="inlineRadio1"
                                                    defaultValue="option1"
                                                    // onClick={handleConfigDB}
                                                    value="yes"
                                                    checked={selectedOption === 'yes'}
                                                    onChange={() => handleOptionChange('yes')}
                                                />
                                                <label
                                                    className="form-check-label text-color-2"
                                                    htmlFor="inlineRadio1"
                                                >
                                                    Yes
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input user-radio bg-transparent"
                                                    type="radio"
                                                    name="inlineRadioOptions"
                                                    id="inlineRadio2"
                                                    defaultValue={false}
                                                    // onClick={() => setconfigDB(false)}
                                                    value="no"
                                                    checked={selectedOption === 'no'}
                                                    onChange={() => handleOptionChange('no')}
                                                />
                                                <label
                                                    className="form-check-label text-color-2"
                                                    htmlFor="inlineRadio2"
                                                >
                                                    No
                                                </label>
                                            </div>
                                            {errors && (

                                                <p style={{ color: "red" }}>{errors}</p>

                                            )}
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="modal-footer border-0 pt-0 gap-3">
                                <button
                                    type="button"
                                    className="btn custom-outline-btn font-14 px-4 py-2 m-0 font-medium"
                                    data-bs-dismiss="modal"
                                    // onClick={handleCancel}/
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn primary-btn font-14 font-semibold border-radius-5 color-white py-2 px-4 m-0"
                                    onClick={handleNext}
                                    disabled={selectedRepo.length != 0 ? "" : true}
                                >
                                    {loading ? 'Loading...' : 'Save'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>)}
            {/*Racpad - Configurations modal ends here*/}
            {/* popup-2 end here */}
            {/* popup-3 start here */}

            {/*Racpad - Configurations modal starts here*/}
            {showModal === "DB" && (
                <div
                    className="modal fade show"
                    style={{ display: 'block', background: "#000000cc" }}
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="popup"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered custom-popup-width-box border-1">
                        <div className="modal-content py-3 px-2 border-0 bg-color-4">
                            <div className="modal-header border-0 pt-2 p-0 px-3">
                                <h5 className="modal-title text-color-1 font-22 font-bold">
                                    <img
                                        src="images-new/back-black-arrow.svg"
                                        alt="Back"
                                        class="me-2 icon-filter"
                                        onClick={() => setShowModal("REPO")}
                                    />
                                    Racpad - Configuration
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close close-icon-filter"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onCli
                                >
                                    {/* <img className="" src="images-new/close-popup.svg" alt="close-icon" /> */}
                                </button>
                            </div>
                            <div className="modal-body popup-scrl pt-2">
                                <div className="row">
                                    {/* Wizard starts here */}
                                    <div className="d-flex justify-content-center my-4 position-relative">
                                        <span className="wizard-num-active font-18 font-semibold d-block d-flex align-items-center justify-content-center me-3 pt-1">
                                            1
                                        </span>
                                        <span className="wizard-text-active wizard-line-length font-15 font-semibold d-block d-flex align-items-center justify-content-center me-5 position-relative">
                                            TFS
                                        </span>
                                        <span className="wizard-num-active font-18 font-semibold d-block d-flex align-items-center justify-content-center me-3 ms-0 ms-sm-5 pt-1">
                                            2
                                        </span>
                                        <span className="wizard-text-active wizard-line-length font-15 font-semibold d-block d-flex align-items-center justify-content-center me-5 position-relative">
                                            Repo
                                        </span>
                                        <span className="wizard-number-active font-18 font-semibold d-block d-flex align-items-center justify-content-center me-3 ms-0 ms-sm-5 pt-1">
                                            3
                                        </span>
                                        <span className="wizard-text-active font-15 font-semibold d-block d-flex align-items-center justify-content-center">
                                            DB
                                        </span>
                                    </div>
                                    {/* Wizard Ends here */}
                                    {/* content */}
                                    <div className="col-md-6 my-4 mb-0 mb-md-4">
                                        <label
                                            htmlFor="exampleInputEmail1"
                                            className="form-label font-14 font-bold text-color-2"
                                        >
                                            Server Name <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control custom-border-3 text-color-4 bg-transparent"
                                            id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            placeholder="Enter Server Name"
                                            onChange={handleServername}
                                        />
                                    </div>
                                    <div className="col-md-6 my-4 mb-0 mb-md-4">
                                        <label
                                            htmlFor="exampleInputEmail1"
                                            className="form-label font-14 font-bold text-color-2"
                                        >
                                            Database Name <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control custom-border-3 text-color-4 bg-transparent"
                                            id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            placeholder="Enter Database Name"
                                            onChange={handleDbName}
                                        />
                                    </div>
                                    <div className="col-md-6 mb-4 mt-4 mt-sm-0">
                                        <label
                                            htmlFor="exampleInputEmail1"
                                            className="form-label font-14 font-bold text-color-2"
                                        >
                                            User Name <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control custom-border-3 text-color-4 bg-transparent"
                                            id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            placeholder="Enter User Name"
                                            onChange={handleDbUserName}
                                        />
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <label
                                            htmlFor="exampleInputEmail1"
                                            className="form-label font-14 font-bold text-color-2"
                                        >
                                            Password <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control custom-border-3 text-color-4 bg-transparent"
                                            id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            placeholder="Enter Password"
                                            onChange={handlePassword}
                                        />
                                    </div>
                                    <div className="col-md-6 ps-2  p-0 mb-4 mb-sm-0">
                                        <h4 className="font-14 font-bold mt-1 text-color-2">
                                            Would you like to configure DB Schema?
                                        </h4>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className=" user-radio form-check-input bg-transparent"
                                                type="radio"
                                                name="inlineRadioOptions"
                                                id="inlineRadio1"
                                                defaultValue="option1"
                                                onClick={() => setDBschema(true)}
                                            />
                                            <label className="form-check-label text-color-2" htmlFor="inlineRadio1">
                                                Yes
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input user-radio bg-transparent"
                                                type="radio"
                                                name="inlineRadioOptions"
                                                id="inlineRadio2"
                                                defaultValue="option2"
                                                onClick={() => setDBschema(false)}
                                            />
                                            <label className="form-check-label text-color-2" htmlFor="inlineRadio2">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    {DBschema ? (
                                        <div className="col-md-6 mb-4">
                                            <label
                                                htmlFor="exampleInputEmail1"
                                                className="form-label font-14 font-bold text-color-2"
                                            >
                                                DB Schema
                                            </label>
                                            <input
                                                type="email"
                                                className="form-control custom-border-3 text-color-4 bg-transparent"
                                                id="exampleInputEmail1"
                                                aria-describedby="emailHelp"
                                                placeholder="Enter DB Schema"
                                                onChange={handleDbSchema}
                                            />
                                        </div>
                                    ) : null}
                                    {errors && (

                                        <span style={{ color: "red" }}>{errors}</span>

                                    )}
                                </div>
                            </div>
                            <div className="modal-footer border-0 pt-0 gap-3">
                                <button

                                    type="button"
                                    className="btn custom-outline-btn font-14 px-4 py-2 m-0 font-medium"
                                    data-bs-dismiss="modal"
                                    onClick={handleCancel}

                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn primary-btn font-14 font-semibold border-radius-5 color-white py-2 px-4 m-0"
                                    onClick={insertDBCreds}
                                >
                                    {loading ? 'Saving...' : 'Save'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>)}

            {showModal === "SUCCESS" && (

                <div
                    className="modal fade show"
                    style={{ display: "block", background: "#000000cc" }}
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="finish-popup"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered custom-box border-1">
                        <div className="modal-content p-3 border-0 bg-color-4">
                            <div className="modal-header border-0 p-0 d-flex justify-content-end">
                                <button
                                    type="button"
                                    className="btn-close close-icon-filter me-0"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    {/* <img src="images-new/close-popup.svg" alt="close-icon" /> */}
                                </button>
                            </div>
                            <div className="modal-body popup-scrl pt-0 ">
                                {/* content */}
                                <div className="text-center">
                                    {/* <img src="images-new/finish-icon.svg" alt="finish-icon" /> */}
                                    <span className="color-green p-3 finish-decor">
                                        <img src="images/success-green-tick.svg" alt="finish-icon"></img>
                                    </span>
                                   
                                    <h4 className="font-24 font-bold mt-4 text-color-1">Great!</h4>
                                    <h5 className="font-16 font-bold mt-4 mb-4 text-color-4">
                                        TFS and database configured successfully
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn primary-btn font-14 font-semibold border-radius-5 color-white py-2 px-5  mt-4"
                                        //   data-bs-dismiss="modal"
                                        //   aria-label="Close"
                                        onClick={() => setShowModal("CLOSE")}
                                    >
                                        {loading ? 'Building KG...' : 'Ok'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}

        </div>
    );
}