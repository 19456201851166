/**
 * PS_CGAI_13.1,PS_CGAI_13.2,PS_CGAI_13.3 Import the hooks and function from React and following file
 */

import { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { getChatHistory, fetchChat, getPromptFieldJson } from "../AI Service/Api";
import UserContext from "../Auth/useContext";
import Loader from "../Other-Components/loader";

import { AddFileOrFolderSample } from "./AddFileStuctureSample";
import { isAuth } from "../Auth/authConfig";
import UserProfileComponent from "./UserProfileComponent";
import { useMsal, useAccount, useIsAuthenticated } from "@azure/msal-react";
import { getSessionItem } from "../util-services/encrypt-storage";
import axios from "axios";
import { sendMessage } from "@microsoft/signalr/dist/esm/Utils";

import {insertMessage} from "../AI Service/Api";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
 


/** 
 * PS_CGAI_13.4 to PS_CGAI_13.22 ,Declare All the state variables needed for the functions.

 * PS_CGAI_13.53 to PS_CGAI_13.57, Onclick of uparrow button desc of the data's will take place.
 * PS_CGAI_13.58 to PS_CGAI_13.62, onclick of downarrow button asc of the data will take place.
 * PS_CGAI_13.63 to PS_CGAI_13.66, Fetch the data according to the field searched
 * PS_CGAI_13.67 to PS_CGAI_13.70 ,Shoul navigate to the chat page when click on the view button
 * PS_CGAI_13.92 to PS_CGAI_13.94 ,Onclick of Ok button set the isPromptopenPopup to false
 * PS_CGAI_13.103 to PS_CGAI_13.108,onchange event for filter fields
 */

export function ChatHistory({ navOpen }) {
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("createdDateTime Desc");
  const [count, setCount] = useState(10);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [approvalStatus, setApprovalStatus] = useState("");
  const [codegenStatus, setCodegenStatus] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [filterAlert, setFilterAlert] = useState("");
  const [shownRecords, setShownRecords] = useState(count);
  const [totalRecords, setTotalRecords] = useState(Number);
  const [approvalStatusData, setApprovalStatusData] = useState("");
  const [codegenStatusData, setCodegenStatusData] = useState("");
  const [promptMessageJson, setPromptMessageJson] = useState([]);
  const [emailID, setEmailID] = useState("");
  const [bindChatHistory, setbindChatHistory] = useState();
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState(false);
  const [isAscendingPrompt, setIsAscendingPrompt] = useState(true);
  const [isAscendingType, setIsAscendingType] = useState(true);
  const [isAscendingChat, setIsAscendingChat] = useState(true);
  const [isAscendingDateTime, setIsAscendingDateTime] = useState(true);
  const [isLoading, setIsLoading] = useState(true)
  const [isCopied, setIsCopied] = useState(false);
 

  const [isAscendingApprovalStatus, setIsAscendingApprovalStatus] =
    useState(true);
  const [isAscendingCodegenStatus, setIsAscendingCodegenStatus] =
    useState(true);
  const [isPromptFieldPopupOpen, setIsPromptFieldPopupOpen] = useState(true);
  const [records, setRecords] = useState()
  const [flag, setFlag] = useState("")
  const [filterFlag, setFilterFlag] = useState(false);
  const userDetails = useContext(UserContext);
  const { instance, accounts } = useMsal();
  const [isSharePopupOpen, setIsSharePopupOpen] = useState(true)
  const [utcDateTime, setUtcDateTime] = useState(new Date());
  const [shareDate, setShareDate] = useState("")
  const [sharePromptName, setSharePromptName] = useState("")
  const [shareUrl, setShareUrl] = useState("")
  const [shareChatID, setShareChatID] = useState("")
  const token = getSessionItem("access-token");
  const [chatContent, setChatContent] = useState("")
  const [profileImage, setProfileImage] = useState("")
  const share = window.location.href;
  const url = new URL(share);
  const baseUrl = url.origin; // This will give you the base URL without the pathname
  const [editIndex, setEditIndex] = useState()
  const [chatId, setChatId]= useState("")

  //(baseUrl,99);
  //   //(share, "888");

  //(shareChatID,"hhhh");
  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  // let obj={
  //   promptName:"",
  //   url:"",
  // }

  // const [shareContent,setShareContent]=useState(obj)


  const [userData, setuserData] = useState({});

  /**
   *PS_CGAI_13.24 Declare an variable history and assign useHistory Hook to it.
   */
  const history = useHistory();

  /**
   *PS_CGAI_13.23 Give the useEffect and call the function fetchChatHistory  
 with dependencies search,sort and count.
   */
  useEffect(() => {
    fetchChatHistory();
    getChatMessages()
  }, [sort, count]);

  useEffect(() => {
    if (userDetails) {
      setuserData(userDetails);
      fetchChatHistory();
    }
  }, [userDetails]);

  useEffect(() => {
    profileImg()
    setDate(utcDateTime)
    fetchPromptFieldJson()
  }, []);



  useEffect(() => {
    //(accounts, "account");
    if (!isAuth(accounts)) {
      history.push("/");
    }
  }, []);


  function setDate(utcDateTime) {
    // Parse the input date string
    const date = new Date(utcDateTime);

    // Define options for formatting the date
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };

    // Use Intl.DateTimeFormat to format the date
    const formatter = new Intl.DateTimeFormat('en-US', options);

    // Extract parts and assemble the custom format
    const parts = formatter.formatToParts(date);
    const month = parts.find(part => part.type === 'month').value;
    const day = parts.find(part => part.type === 'day').value;
    const year = parts.find(part => part.type === 'year').value;
    const hour = parts.find(part => part.type === 'hour').value;
    const minute = parts.find(part => part.type === 'minute').value;
    const meridian = parts.find(part => part.type === 'dayPeriod').value;

    // Construct the custom formatted string
    const formattedDate = `${month}/${day}/${year} ${hour}:${minute} ${meridian}`;
    //(formattedDate, "OOOO");
    setShareDate(formattedDate)

    // return formattedDate;
  }

  /** 
   * PS_CGAI_13.25 to PS_CGAI_13.39 Call the fetchChatHistory Function where getChatHistory  
  function will be triggerd and data will get from the backend
   */
  const fetchChatHistory = async (obj = false) => {
    try {
      let chatHistoryObj = {
        userEmailID: userDetails?.email, //userData?.email,
        search: search,
        sort: sort,
        count: count,
        fromDate: fromDate,
        toDate: toDate,
        approvalStatusName: approvalStatus,
        codegenStatusName: codegenStatus,
      };
      chatHistoryObj = obj ? obj : chatHistoryObj;

      if (
        chatHistoryObj.fromDate != "" ||
        chatHistoryObj.toDate != "" ||
        chatHistoryObj.approvalStatusName != ""
      ) {
        setFilterFlag(true);
      } else {
        setFilterFlag(false);
      }
      setIsLoading(false)
      //(chatHistoryObj, "NNNN");
      let response = await getChatHistory(obj ? obj : chatHistoryObj);
      //(response, "chatHistory");

      if (response.status === 200) {
        setIsLoading(true)
        let record = response.data.ResponseData[0].length - shownRecords;
        setShownRecords(record + shownRecords);
        setbindChatHistory(response.data.ResponseData[0]);
        setApprovalStatusData(response.data.ResponseData[2]);
        setCodegenStatusData(response.data.ResponseData[3]);
        setTotalRecords(response.data.ResponseData[1][0].chatCount);
        // setRecords(response.data.ResponseData[0].length)
      }
    } catch (error) {
      //(error);
    }
  };
  let currentDate = new Date();
  const formattedDate = currentDate.toISOString().split("T")[0];

  /**
   * This function will format the date which has been given in IST with time
   * @param {pass the current date} date
   * @returns the formatted date
   */

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear(),
      hours = d.getHours(),
      minutes = d.getMinutes(),
      ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    if (hours < 10) {
      hours = "0" + hours;
    }

    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    return (
      [month, day, year].join("/") +
      " " +
      [hours, minutes].join(":") +
      " " +
      ampm
    );
  };
  /**
   *
   * @param {approvalStatus Data from the backend will be stored} approvalStatusData
   * * PS_CGAI_13.96 to PS_CGAI_13.102, bind the approval status choices dynamically
   * @returns the filter with binded data's
   */

  function bindApprovalStatus(approvalStatusData) {
    if (approvalStatusData && Array.isArray(approvalStatusData)) {
      return approvalStatusData.map((value, index) => (
        <option
          value={value.approvalStatusName}
          id={value.approvalStatusID}
          key={index}
        >
          {value.approvalStatusName}
        </option>
      ));
    }
  }

  function bindCodegenStatus(codegenStatusData) {
    if (codegenStatusData && Array.isArray(codegenStatusData)) {
      return codegenStatusData.map((value, index) =>
        {if(value.codegenStatusName!='Abort'){
            return(<option
            value={value.codegenStatusName}
            id={value.codegenStatusID}
            key={index}
          >
            {value.codegenStatusName}
          </option>)
        }
        }
      );
    }
  }
  function convertDate(val, index){
    const date = new Date(val);
    // Get year, month, and day
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const day = date.getUTCDate().toString().padStart(2, '0');
 
    // Combine into desired format
    const formattedDate = `${year}-${month}-${day}`;
 
    // console.log(formattedDate);
    if(index=='from'){
      setFromDate(formattedDate);
    }
    else if(index=='to'){
      setToDate(formattedDate)
    }
  }
  async function profileImg() {
    try {
      let profileImage = await axios.get(
        `https://graph.microsoft.com/v1.0/users/${userDetails.email}/photo/$value`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      )
      const reader = new FileReader();
      reader.readAsDataURL(profileImage.data);
      await timeout(500);
      profileImage = reader.result;
      //(profileImage,"jjjj");
      setProfileImage(profileImage)
      //(profileImage,profileImage.config.url,"ooooo")
      // setProfileImage(profileImage)

    }
    catch (error) {
      let profileImage = "images/Default/default-profile-image.png";
      //(profileImage, "ooooo")
      setProfileImage(profileImage)
    }
  }


  function bindChatContent(chatContent) {
    if (chatContent && Array.isArray(chatContent)) {
      return chatContent.map((value, index) => (

        <div className="col-md-12 py-3 px-3">
          <span className="d-flex">
            <span className="d-block">
              <img className style={{ height: "44px", width: "44px", borderRadius: "50%" }} src={value.role === "user" ? profileImage : "images/Default/asami-bot.svg"} alt="profile-pic" />
            </span>
            <span className="d-block ms-3 mt-2">
              <h5 className="font-18 font-bold text-color-1">{value.role === "user" ? "You" : "Asami"}</h5>
              <p className="font-medium font-16 text-color-10">{value.content}</p>
            </span>
          </span>
        </div>


      ));
    }
  }

  /**
   * PS_CGAI_13.47 to PS_CGAI_13.52, Trigger the loadmore functionality and
   * add the 10 more content to the grid.
   */
  const loadmoreClick = () => {
    let countValue = count + 10;
    setCount(countValue);
    if (shownRecords >= totalRecords) {
      setIsLoadMoreDisabled(true);
    }
  };
  /**
   *  * PS_CGAI_13.109 to PS_CGAI_13.119, on submit do the validation and filter the contents
   */
  function filterValidation() {
    let flag = 0;

    if (approvalStatus != "") {
      flag++;
    }
    if (codegenStatus !== "") {
      flag++;
    }
    if (fromDate != "") {
      flag++;
    }
    if (toDate != "") {
      flag++;
    }

    if (flag != 0) {
      setShowFilter(false);
      setFilterAlert("");
      fetchChatHistory();
    } else {
      setFilterAlert("alert");
    }
  }

  /**
   * @param {Id of the particular prompt} id
   * * PS_CGAI_13.68 to PS_CGAI_13.91,onclick of the promptName bind the json information of the particular prompt in the popup
   */
  const fetchPromptFieldJson = async (id) => {
    try {
      const response = await getPromptFieldJson({ chatID: id });
      //(response, "kjhg");
      const parsedJson = JSON.parse(response.data.ResponseData.content);
      if(parsedJson){
      setPromptMessageJson(parsedJson);
      setIsPromptFieldPopupOpen(false);
      }
    } catch (error) {
      //(error);
    }
  };

  /**
   * PS_CGAI_13.120 to PS_CGAI_13.123,on cancel empty the state variable and close the filter.
   */

  const getChatMessages = async (chatID) => {
    //(chatID,"OOOOOOOOO");
    // sethiddenObjValues({
    //   ...hiddenObjValues,
    //   binding: false,
    // });

    try {
      let result = await fetchChat({ chatID: chatID });
      //(result.ResponseData.currentChat,"LLLLLLLLLLLLLL");
      setChatContent(result.ResponseData.currentChat)
    }
    catch (error) {
      //(error)

    }
  }

  const cancelFilter = () => {
    setShowFilter(false);
    setApprovalStatus("");
    setCodegenStatus("");
    setFromDate("");
    setToDate("");
    setFilterAlert("");
    // setShow("");

    let chatHistoryObj = {
      userEmailID: userDetails?.email,
      search: search,
      sort: sort,
      count: count,
      fromDate: "",
      toDate: "",
      approvalStatusName: "",
      codegenStatusName: "",
    };
    fetchChatHistory(chatHistoryObj);
  };
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [editedName, setEditedName] = useState("");
  const handleEditClick = (index, name) => {
    setEditingRowIndex(index);
    setEditedName(name);
  };
 
  const handleNameChange = (e) => {
    setEditedName(e.target.value);
  };
 
  async function handlesubmit(chatID, chatName){
    try{
      let response= await insertMessage({
        "editID": chatID,
        "editName": chatName,
      })
      // console.log(response)
    }
    catch(e){
      console.log(e)
    }
  }
 
  const handleSaveClick = () => {
    // Perform save operation with the edited name for the row with the given index
    // const updatedChatHistory = bindChatHistory.map((chat, i) =>{
    //   i === index ? { ...chat, chatName: editedName } : chat;
 
    // });
    const updatedChatHistory = bindChatHistory.map((chat, i) => {
      if (i === editIndex) {
        const updatedChat = { ...chat, chatName: editedName };
        handlesubmit(updatedChat.chatID, editedName)
        setChatId(updatedChat.chatID);
        // console.log("newwwwwwww", updatedChat.chatID);
        // console.log("chatttttttttttt", editIndex)
        return updatedChat;
      } else {
        return chat;
      }
    });
   
    // console.log(updatedChatHistory,"updated")
    // console.log(`Saving ${editedName} for row ${editIndex}`);
    setbindChatHistory(updatedChatHistory)
    setEditingRowIndex(null);
    setEditedName("");
  };
 
  const handleCancelClick = () => {
    setEditingRowIndex(null);
    setEditedName("");
  };
  //(shareUrl, "???")


  /**
   * @param {bindChatHistory state variable which has the grid data's} bindChatHistory
   * PS_CGAI_13.40 to PS_CGAI_13.46,set the state variables with the data's and bind it to the grid
   * @returns the binded data in the grid to the user
   */
  const handleCopyClick = () => {
    const chatUrl = `${baseUrl}/chat?chatID=${shareChatID}&share=true`;
 
    navigator.clipboard.writeText(chatUrl).then(() => {
      history.push('/chat-history');
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  }
  function bindChatHistoryData(bindChatHistory) {
    if (bindChatHistory != undefined) {
      return bindChatHistory?.map((value,index) => {
        return (
          <tr key={index}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
 
              <td>
                <span>{value.chatName.length > 10 ? `${value.chatName.substring(0, 10)}...` : value.chatName}</span>
                  <img
                    src="images-new/edit-icon.svg"
                    alt="bot-happy"
                    // className="chatname-edit"
                    className="icon-filter ms-3 mb-1  cursor-pointer"
                    style={{height:'15px'}}
                    onClick={()=> {setEditIndex(index);handleEditClick(index, value.chatName)}}
                    data-bs-toggle="modal" data-bs-target="#edit-chat-name"
                  />
</td>                  
 
                            </div>
 
            <td>
              <a
                className="text-decoration-none link-color"
                data-bs-toggle="modal"
                data-bs-target="#specifiedprompts-popup"
                style={{cursor:'pointer'}}
                onClick={(e) => {
                  let chatID = `${value.chatID}`;
                  fetchPromptFieldJson(chatID);
                  setIsPromptFieldPopupOpen(false);
 
                }}
              >
 
                {value.chatType === 'General' ? 'Ask Asami' : value.promptName}
              </a>
            </td>

            <td>
              {/* <a
                className="text-decoration-none link-color"
                data-bs-toggle="modal"
                data-bs-target="#specifiedprompts-popup"
                onClick={(e) => {
                  let chatID = `${value.chatID}`;
                  fetchPromptFieldJson(chatID);
                  setIsPromptFieldPopupOpen(false);

                }}
              > */}
                {value.chatType}
              {/* </a> */}
            </td>

            <td>{formatDate(value.createdDateTime)}</td>
            {userDetails?.role === "User" ? (
              // <td className="text-center">
              //   <span
              //     className={`table-status ${value.approvalStatusName === "Rejected"
              //       ? "danger-bg"
              //       : value.approvalStatusName === "Approved"
              //         ? "success-bg"
              //         : value.approvalStatusName === "Pending"
              //           ? "pending-bg"
              //           : null
              //       } my-2`}
              //     data-bs-toggle="tooltip"
              //     data-bs-placement="right"
              //     title={`${value.approvalStatusName === "Approved"
              //       ? "Approved"
              //       : value.approvalStatusName === "Rejected"
              //         ? "Rejected"
              //         : "Pending"
              //       }`}
              //   />
              // </td>
              <></>
            ) : (
              <td className="text-center">
                {" "}
                <span
                  className={`table-status ${value.codegenStatusName === "Abort"
                    ? "danger-bg"
                    : value.codegenStatusName === "Success"
                      ? "success-bg"
                      : "pending-bg"
                    } my-2`}
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  title={`${value.codegenStatusName === "Success"
                    ? "Success"
                    : value.codegenStatusName === "Abort"
                      ? "Abort"
                      : "In Progress"
                    }`}
                />{" "}
              </td>
            )}

            <td class="text-center ">
              <img src="images-new/eye-img.svg" 
                title="View"
                
                style={{marginRight:"29%",cursor:"pointer"}}
                onClick={() => {
                  const pathname = `/chat`;
                  const search = `?chatID=${value.chatID}`;
                 
                  const state = value.chatType === 'General'
                    ? {
                        yesFixButton: true,
                        getAskAsamiChat: true,
                        hidePrompts: true,
                        sendMessage: false,
                      }
                    : {
                        getAskAsamiChat: true,
                        yesFixButton: false
                      };
               
                  // Store the state in sessionStorage
                  sessionStorage.setItem('chatState', JSON.stringify(state));
               
                  // Construct the full URL
                  const fullURL = `${pathname}${search}`;
               
                  // Use replaceState to update the URL without navigation
                  window.history.replaceState(null, '', fullURL);
               
                  // Force a reload
                  window.location.reload();
                }}

              />
              <img src="images/Default/share-icon-nav.svg"
                title="Share"
                style={{cursor:"pointer"}}
                data-bs-toggle="modal"
                data-bs-target="#share-convo-popup-chatHistory"
                onClick={() => {
                  setSharePromptName(value.chatType === 'General' ? 'Ask Asami' : value.promptName);
                  setShareChatID(value.chatID)
                  getChatMessages(value.chatID)
                  setIsSharePopupOpen(true); // Assuming you want to open the popup here
                }}
              />
            </td>
          </tr>
        );
      });
    } else {
      return null;
    }
  }

  return (
    <>
    <div className="row justify-content-lg-end justify-content-center" style={{marginRight:"4%"}}>
      {!isLoading ? <Loader isLoading={isLoading} /> :
        <div className={navOpen === "Close" ? "col-md-11 ps-5" : "col-md-9"} id="chat-history-center" >
          <div className="container-fluid px-0" >
            <div className="row">
              <div className="col-md-12" >
                <div className="mt-5 mb-3 d-flex justify-content-between align-items-center" >
                  <h3 className="font-20 font-semibold text-color-1">Past Conversation</h3>
                  <div className="table-header d-flex align-items-center">
                    <div className="input-group transparent-grp">
                      <input
                        type="text"
                        className="form-control border-0 focus-none font-14 font-medium search-bg text-color-4 input-group custom-search-input cust-search-input"
                        placeholder="Search"
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            fetchChatHistory()
                          }
                        }}
                        value={search}

                      />
                      <button
                        className="btn focus-none search-bg"
                        type="button"
                        onClick={(e) => {
                          fetchChatHistory()
                        }}
                      >
                        <img
                          className="filter-icons"
                          src="images/Default/input-search.svg"
                          alt="input-search-icon"
                        />
                      </button>
                    </div>
                    <div className="dropdown ms-3 tbl-filter">
                      <button
                        type="button"
                        // className={`btn border-0 font-16 font-semibold align-items-center d-flex transparent-grp py-2 theme-focus custom-filter-fix`}
                        className={`btn font-16 font-semibold align-items-center  btn ms-2 font-16 font-semibold align-items-center inter-btn d-flex transparent-grp py-2 theme-focus search-bg`}
                        
                        // data-bs-toggle="dropdown"
                        style={{'right':'15px'}}
                        onClick={() => {
                          if (showFilter) {
                            setShowFilter(false);
                          } else {
                            setShowFilter(true);
                          }
                        }}
                        
                      >
                        <img
                          className="filter-icons"
                          src="images/Default/adv-filter.svg"
                          alt="filter-icon"
                        />
                      </button>
                      {/* advanced filter starts here */}
                      <span
                        className="dropdown-menu-end p-3 filter-dropdown-bg w-filter-dropdown bg-color"
                        style={{ display: showFilter ? "block" : "none", top:'70%'}}
                        data-bs-popper="none"
                        data-popper-placement="bottom-end"


                      // style={{
                      //   position: "absolute",
                      //   inset: "0px auto auto 0px",
                      //   margin: "0px",
                      //   transform: "translate(-300px, 38px)",

                      // }}
                      >
                        <span className="d-flex justify-content-between align-items-center mb-3">
                          <span className="font-18  font-semibold text-color-1">
                            Advanced Filter
                          </span>
                          <span
                            onClick={() => {
                              setShowFilter(false);
                            }}
                          >
                            <img
                              src="images/Default/flter-close-icon.svg"
                              alt="filter-close-icon"
                              className="cursor-pointer close-icon-filter"
                            />
                          </span>
                        </span>
                        {userDetails?.role === "User" ? (
                          // <span className="row ">
                          //   <span className="mb-3 d-block col-md-12">
                          //     <span className="mb-3">
                          //       <label
                          //         htmlFor="from"
                          //         className="form-label font-14 font-medium grey-v1"
                          //       >
                          //         Status
                          //       </label>
                          //       <select
                          //         className="form-select"
                          //         id="approvalStatusName"
                          //         value={approvalStatus}
                          //         onChange={(e) => {
                          //           setApprovalStatus(e.target.value);
                          //         }}
                          //       >
                          //         <option selected>Open this select menu</option>
                          //         {bindApprovalStatus(approvalStatusData)}
                          //       </select>
                          //     </span>
                          //   </span>
                          // </span>
                          <></>
                        ) : (

                          <span className="mb-3 d-block col-md-12">
                            <span className="mb-3">
                            <span className="d-block font-semibold font-16 text-color-9">
                                Asami Status
                              </span>
                              <select
                                className="form-select dashboard-select w-100 custom-form py-2 font-14 me-0 me-md-3 font-regular order-2 order-md-0 bg-color text-color-1 custom-border-5"
                                value={codegenStatus}
                                onClick={(e)=>e.currentTarget.classList.toggle('open')}
                                onChange={(e) => {
                                  setCodegenStatus(e.target.value);
                                }}
                              >
                                <option selected>Select</option>
                                {bindCodegenStatus(codegenStatusData)}
                              </select>
                            </span>
                          </span>
                        )}
                        <span className="mb-2 d-block">
                          <span className="d-block font-semibold font-16 text-color-9">Date Range</span>
                          <span className="row mb-2">
                            <span className="mb-3 d-block col-md-6">
                              <span className="mb-3">
                              <div className="date-picker-container">
                                <label htmlFor="from" className="form-label text-color-9 font-15 font-semibold">From</label>
                                <div className='position-relative'>
                                  <DatePicker
                                    id="from"
                                    selected={fromDate}
                                    onChange={(date) => {
                                      convertDate(date,'from')
                                      setToDate(null);
                                    }}
                                    className="form-control input-field font-14 font-regular border-radius-3 text-color-1 bg-color-4 custom-border-3"
                                    dateFormat="MM-dd-yyyy"
                                    placeholderText="mm-dd-yyyy"
                                  />
                                  <img 
                                    className="calender-icon" 
                                    src="images/calender-icon.svg" 
                                    alt="calendar-icon"
                                    onClick={() => document.getElementById('from').focus()}
                                  />
                                </div>
                                </div>
                              </span>
                            </span>
                            <span className="mb-3 d-block col-md-6">
                              <span className="mb-3">
                                <label htmlFor="to" className="form-label text-color-9 font-15 font-semibold">To</label>
                                <div className='position-relative'>
                                  <DatePicker
                                    id="to"
                                    selected={toDate}
                                    onChange={(date) => convertDate(date,'to')}
                                    minDate={fromDate}
                                    className="form-control input-field font-14 font-regular border-radius-3 text-color-1 bg-color-4 custom-border-3"
                                    dateFormat="MM-dd-yyyy"
                                    placeholderText="mm-dd-yyyy"
                                  />
                                  <img 
                                    className="calender-icon" 
                                    src="images/calender-icon.svg" 
                                    alt="calendar-icon"
                                    onClick={() => document.getElementById('to').focus()}
                                  />
                                </div>
                              </span>
                            </span>
                          </span>
                        </span>
                        <span className="w-100 d-flex justify-content-end">
                          <button
                            className="btn custom-outline-btn font-14 font-semibold px-3 me-3"
                            onClick={() => {
                              cancelFilter();
                            }}
                          >
                            Clear
                          </button>
                          <button
                            className="btn primary-btn font-14 font-semibold border-radius-5 px-3 py-2 color-white"
                            onClick={() => {
                              setFlag("filter")
                              {
                                filterValidation();
                              }
                            }}
                          >
                            Apply
                          </button>
                        </span>
                        {filterAlert == "alert" ? (
                          <div className="span-color">
                            Select one filter!
                          </div>
                        ) : null}
                      </span>
                      {/* advanced filter ends here */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 " >
                <div className="table-responsive rounded-3 transparent-grp">
                  <table className="table theme-table font-regular font-14 mb-0">
                    <thead>
                      <tr>
                        <th
                          onClick={() => {
                            setIsAscendingChat(!isAscendingChat);
                            setSort(
                              isAscendingChat
                                ? "chatName Desc"
                                : "chatName Asc"
                            );
                          }}
                        >
                          Chat Name
                          <span className="cursor-pointer">
                            {" "}
                            <img
                              className="icon-filter"
                              src={
                                isAscendingChat
                                  ? "images/Default/sort-up.svg"
                                  : "images/Default/sort-down.svg"
                              }
                              alt={isAscendingChat ? "sort-up" : "sort-down"}
                            />
                          </span>
                        </th>
                        <th
                          onClick={() => {
                            setIsAscendingPrompt(!isAscendingPrompt);
                            setSort(
                              isAscendingPrompt
                                ? "promptName Desc"
                                : "promptName Asc"
                            );
                          }}
                        >
                          Prompt
                          <span className="cursor-pointer">
                            {" "}
                            <img
                              className="icon-filter"
                              src={
                                isAscendingPrompt
                                  ? "images/Default/sort-up.svg"
                                  : "images/Default/sort-down.svg"
                              }
                              alt={isAscendingPrompt ? "sort-up" : "sort-down"}
                            />
                          </span>
                        </th>

                        <th
                          onClick={() => {
                            setIsAscendingType(!isAscendingType);
                            setSort(
                              isAscendingType
                                ? "promptName Desc"
                                : "promptName Asc"
                            );
                          }}
                        >
                          Type
                          <span className="cursor-pointer">
                            {" "}
                            <img
                              className="icon-filter"
                              src={
                                isAscendingType
                                  ? "images/Default/sort-up.svg"
                                  : "images/Default/sort-down.svg"
                              }
                              alt={isAscendingType ? "sort-up" : "sort-down"}
                            />
                          </span>
                        </th>

                        <th
                          onClick={() => {
                            setIsAscendingDateTime(!isAscendingDateTime);
                            setSort(
                              isAscendingDateTime
                                ? "createdDateTime Desc"
                                : "createdDateTime Asc"
                            );
                          }}
                        >
                          Date & Time
                          <span className="cursor-pointer">
                            {" "}
                            <img
                              className="icon-filter"
                              src={
                                isAscendingDateTime
                                  ? "images/Default/sort-up.svg"
                                  : "images/Default/sort-down.svg"
                              }
                              alt={isAscendingDateTime ? "sort-up" : "sort-down"}
                            />
                          </span>
                        </th>

                        {userDetails?.role === "User" ? (
                          // <th
                          //   className="text-center"
                          //   onClick={() => {
                          //     setIsAscendingApprovalStatus(
                          //       !isAscendingApprovalStatus
                          //     );
                          //     setSort(
                          //       isAscendingApprovalStatus
                          //         ? "approvalStatusName Desc"
                          //         : "approvalStatusName Asc"
                          //     );
                          //   }}
                          // >
                          //   Approval Status
                          //   <span className="cursor-pointer">
                          //     {" "}
                          //     <img
                          //       src={
                          //         isAscendingApprovalStatus
                          //           ? "images/Default/sort-up.svg"
                          //           : "images/Default/sort-down.svg"
                          //       }
                          //       alt={
                          //         isAscendingApprovalStatus
                          //           ? "sort-up"
                          //           : "sort-down"
                          //       }
                          //     />
                          //   </span>
                          // </th>
                          <></>
                        ) : (
                          <th
                            className="text-center"
                            onClick={() => {
                              setIsAscendingCodegenStatus(
                                !isAscendingCodegenStatus
                              );
                              setSort(
                                isAscendingCodegenStatus
                                  ? "codegenStatusName Desc"
                                  : "codegenStatusName Asc"
                              );
                            }}
                          >
                            Asami Status
                            <span className="cursor-pointer">
                              {" "}
                              <img
                                className="icon-filter"
                                src={
                                  isAscendingCodegenStatus
                                    ? "images/Default/sort-up.svg"
                                    : "images/Default/sort-down.svg"
                                }
                                alt={
                                  isAscendingCodegenStatus
                                    ? "sort-up"
                                    : "sort-down"
                                }
                              />
                            </span>
                          </th>
                        )}
                        <th className="text-center">
                          Actions <span className="cursor-pointer"></span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {totalRecords !== 0 ? (
                        bindChatHistoryData(bindChatHistory)
                      ) : (
                        <tr>
                          <td colSpan={6} className="text-center py-5 vh-50">
                            <img
                              src="images/Default/no-record-img.svg"
                              alt="no-record-img"
                              className="no-record-img mb-5"
                            />
                            <span className="font-20 font-medium w-100 d-block">
                              Sorry! You don’t have any recent chat history.
                            </span>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <p className="font-regular font-14 text-color-9 mt-3 mb-4"> 
                  # of Records :{" "}
                  <span className="font-bold font-14 text-color-1">
                    {totalRecords > shownRecords ? shownRecords : totalRecords}
                  </span>{" "}
                  out of{" "}
                  <span className="font-bold font-14 text-color-1">
                    {totalRecords}
                  </span>
                </p>
                <div className="text-center mb-5">
                  <button
                    className="btn gradient-btn  border-radius-4 font-14 font-semibold py-2 px-3"
                    style={{ color: "#865BE7" }}
                    onClick={() => {
                      loadmoreClick();
                    }}
                    // disabled={isLoadMoreDisabled}
                    hidden={totalRecords <= 10 || isLoadMoreDisabled}
                  >
                    {" "}
                    <span className="color-gradient">Load More</span>
                  </button>
                </div>
              </div>


            </div>
          </div>

          {/* request log grid  ends  */}
        </div>}
      </div>

      <div hidden={isPromptFieldPopupOpen}>
        <div
          className="modal fade"
          id="specifiedprompts-popup"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-hidden="true"
        >
          {/* Modal content */}
          <div className="modal-dialog modal-dialog-centered custom-specifiedprompts-width border-0">
            <div className="modal-content p-3 border-0 bg-color-4">
              <div className="modal-header border-0 py-2 px-2">
                <h5 className="modal-title font-22 font-bold text-color-1">
                  Specified Prompts
                </h5>
                <button
                  type="button"
                  className="btn-close close-icon-filter"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setIsPromptFieldPopupOpen(true);
                  }}
                />
              </div>
              <div className="modal-body popup-scrl">
                <div className="row">
                  {Object.entries(promptMessageJson).map(([key, value]) => (
                    <>
                      {key.trim() === "Any existing file structure" ? (
                        <div className="col-md-12 mb-2" key={key}>

                          <label className="font-14 font-semibold text-color-9">
                            {key !== "" ? key : "User Request"}:
                          </label>
                          <span className="font-14 font-semibold text-color-15">
                            <AddFileOrFolderSample props={value} />
                          </span>
                        </div>
                      ) : key.trim() === "Tech Stack" ||
                        key.trim() === "Type Of Code" ? (
                        <div className="col-md-6 mb-3" key={key}>
                          <label className="font-14 font-semibold text-color-9">
                            {key}:
                          </label>
                          <span className="font-14 font-semibold text-color-15">
                            {value}
                          </span>
                        </div>
                      ) : (
                        <div className="col-md-12 mb-2" key={key}>

                          <label className="font-14 font-semibold text-color-9">
                            {key !== "" ? key : "User Request"}:
                          </label>
                          <span className="font-14 font-semibold text-color-15">
                            {value}
                          </span>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>

              <div className="modal-footer border-0 d-flex justify-content-center pt-0">
                <button
                  type="button"
                  className="btn primary-btn text-white font-14 px-4 py-2 font-medium theme-focus"
                  data-bs-dismiss="modal"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div hidden={isSharePopupOpen}> */}
      <div class="row justify-content-center">
        <div className="modal fade" id="share-convo-popup-chatHistory" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true" >
          <div className="modal-dialog modal-dialog-centered custom-popup-width border-0">
            <div className="modal-content p-3 border-0 bg-color">
              <div className="modal-header border-0 pt-2 px-1 pb-0">
                <h5 className="modal-title text-color-1 font-20 font-bold">Share Conversation Link</h5>
                <button type="button" className="btn-close close-icon-filter" data-bs-dismiss="modal" aria-label="Close" onClick={() => { history.push("/chat-history") }} />
              </div>
              <div className="modal-body pt-2">
                <div className="row border border-radius-2 custom-border-5">
                  <div className="custom-convo-scroll">
                    {/*conversation*/}
                    {bindChatContent(chatContent)}
                    {/*conversation*/}
                    {/*buttons*/}
                  </div>
                  <div className="d-flex justify-content-between align-items-center custom-border-top py-3">
                    <div>
                      <h5 className="font-16 font-medium text-color-1 mb-0" id="promptNameDisplay">{sharePromptName}</h5>
                      <span className="font-14 font-medium text-color-10">{shareDate}</span>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn primary-btn font-14 font-semibold border-radius-5 color-white py-2 px-3" data-bs-dismiss="modal" aria-label="Close"
                        onClick={() => handleCopyClick()}
                      >
                        <img className="me-2" src="images/Default/link-icon.svg" alt="link-icon" />
                        Copy Link
                      </button>
                    </div>
                  </div>
                  {/*buttons*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="edit-chat-name" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                      aria-hidden="true">
                      <div class="modal-dialog modal-dialog-centered custom-popup-issues border-1">
                        <div class="modal-content p-4 border-0 bg-color-4">
                          <div class="modal-header border-0 p-0">
                            <h5 class="modal-title text-color-1 font-22 font-bold ">Edit Chat Name</h5>
                            <button type="button" class="custom-close me-0 bg-transparent" data-bs-dismiss="modal" aria-label="Close">
                              <img class="close-icon-filter" src="./images-new/close-icon.svg" alt="close-icon"/>
                              </button>
                          </div>
                
                          <div class="modal-body popup-scrl pt-2">
                            <div class="row">
                
                              <div class="col-md-12 col-sm-12 mt-3 mb-5 p-0">
                                <label for="chat-name" class="form-label label-color font-15 font-semibold text-color-2 mb-2">Chat Name</label>

                                  <input type="text" class="form-control bg-color-4 text-color-4 custom-border-3 cust-search-input" id="exampleInputEmail1" aria-describedby="emailHelp"
                                  value={editedName}
                                  onChange={handleNameChange}
                                  placeholder="Enter Chat Name"/>

                              </div>
                
                
                
                            </div>
                          </div>
                          <div class="modal-footer border-0 gap-3">
                            <button type="button" class="btn custom-outline-btn font-14 px-4 pb-2 m-0 font-medium"
                             onClick={handleCancelClick}
                              data-bs-dismiss="modal">Cancel</button>
                            <button type="button"
                              class="btn primary-btn font-14 font-semibold border-radius-5 color-white pb-2 px-4 m-0"
                              onClick={()=>{handleSaveClick()}}
                              data-bs-dismiss="modal">Save</button>

            </div>
          </div>
        </div>
      </div>
      {isCopied && (
        <div className="notification-bar">
          <span className="check-icon">✓</span>
          Copied conversation URL to Clipboard!
        </div>
      )}
    </>
  );
};