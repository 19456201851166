import { useEffect, useRef, useState } from "react"




const FewShotExapmle = ({ index, userContent, assistantContent, onEdit, onDelete }) => {

    //("Component is getting rendered",userContent, assistantContent)
    const [newUserContent , setNewUserContent] = useState(userContent)
    const [newAssistantContent , setNewAssistantContent] = useState(assistantContent)
    const [showDropDown , setShowDropDown] = useState(false)
    const [showPopUp , setShowPopUp] = useState(false)

    const [editOrNot , setEditOrNot] = useState(false)


    //(userContent,"\nHel0 therer\n" ,assistantContent)
    // useEffect(()=> {
    //     setNewAssistantContent(assistantContent)
    //     setNewUserContent(userContent)
    // },[])

    const hanldeDropDown = () => {
        if (showDropDown == true){
            setShowDropDown(false)
        }
        else {
            setShowDropDown(true)
        }
    }


    const handlePopUp = () => {
        if (showDropDown == true){
            setShowDropDown(false)
        }
        else {
            setShowDropDown(true)
        }
    }

    //(newUserContent,"   \n asfsaf \n     " , newAssistantContent)

    useEffect(()=>{
        //(newUserContent,"   \n asfsaf \n     " , newAssistantContent)
    },[newAssistantContent, newUserContent])

    // useEffect(()=> {
    //     if(editOrNot){
    //          
    //         setNewUserContent(userContent)
    //         setNewAssistantContent(assistantContent)
    //     }
    // },[editOrNot])


    const userRef = useRef()
    const assistantRef = useRef()

    //("haslifhiasf",index, userContent, assistantContent, onEdit, onDelete)
    const emptyTextFields = () => {
        // userRef.current.value = ''
        // assistantRef.current.value = ''
        setShowPopUp(false)
    }

    return (
        <>


            {/* <div class="col-md-4"> */}
                <div class="example-container rounded-3 bg-color custom-border-3 p-4 overflow-hidden">
                    <div class="d-flex justify-content-between align-items-center">
                        <h4 class="font-bold font-14 m-0 text-color-9">Few Shot User Input</h4>
                        <div class="fewShots-drop-down position-relative">
                        <button class="border-0 bg-none bg-transparent" type="button" onClick={(e)=> hanldeDropDown()}>
                            <img src="images-new/btn-dot.svg" alt="more" />
                        </button>
                        <ul class="font-regular font-13 color-black more-options-dpd fewShot-dd ps-0 position-absolute p-2 ps-2 rounded-3 bg-color-2" style={{"display": showDropDown? "block" : "none"}}>
                            {/* <li class="dropdown-item cursor-pointer mb-1 d-flex align-items-center gap-2" data-bs-toggle="modal" data-bs-target="#examplePopup-2" onClick={(e)=> setShowDropDown(false)}> */}
                            <li class="dropdown-item custom-dropdown-li cursor-pointer mb-1 d-flex align-items-center gap-2 text-color-2"  onClick={(e)=> {setShowDropDown(false); setShowPopUp(true)}}>
                                {/* <button className="align-items-center gap-2" > */}
                                    <img src="images-new/view-eye-iocn-violet.svg" alt="view" />
                                    <span>View</span>
                                {/* </button> */}
                            </li>
                            <li class="dropdown-item custom-dropdown-li cursor-pointer mb-1 d-flex align-items-center gap-2 text-color-2" onClick={(e)=> {
                                setShowDropDown(false); setShowPopUp(true) ; setEditOrNot(true) ; setNewAssistantContent(assistantContent) ; setNewUserContent(userContent)}}>
                                <img src="images-new/edit-icons.svg" alt="Edit" />
                                {/* <button > */}
                                    <span>Edit</span>
                                {/* </button> */}
                            </li>
                            <li class="dropdown-item custom-dropdown-li cursor-pointer d-flex align-items-center gap-2 text-color-2" onClick={()=> onDelete(index)}>
                                <img src="images-new/delete-icons.svg" alt="Delete" />
                                {/* <button > */}
                                    <span>Delete</span>
                                {/* </button> */}
                            </li>
                        </ul>
                        </div>
                    </div>
                    <div class="font-regular font-14 text-color-6 mt-3">
                        <p class="text-truncate mx-w-350px m-0">{userContent}</p>
                    </div>
                    <h4 class="font-bold font-14 m-0 text-color-9 mt-4 mb-3 pt-2">Expected AI Response</h4>
                    <p class="mb-0 lh-lg text-color-6">
                        {assistantContent}
                    </p>
                </div>
            {/* </div> */}

            <div class="modal" style={{"display": showPopUp ? "block": "none"}} tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg example-popup">
                    <div class="modal-content bg-color-4">
                        <div class="modal-header border-0">
                            <h1 class="modal-title fs-5 text-color-1" id="staticBackdropLabel">Example</h1>
                            <button type="button" class="btn-close close-icon-filter" onClick={(e)=> {setShowPopUp(false)}}></button>
                        </div>
                        <div class="modal-body">
                            <div class="row row-gap-32px">
                                <div class="col-md-12">
                                    <label for="FewShotUserInput" class="form-label  font-15 font-semibold text-color-1 mb-2">Few Shot User Input</label>
                                    <textarea class="form-control input-field font-14 font-regular border-radius-3 bg-color-4 custom-border-3 text-color-4 resize-none shadow-none" id="FewShotUserInput" rows="4" placeholder="Enter Input" label="user" ref={userRef}  onChange={(e)=> setNewUserContent(e.target.value)}>{newUserContent}</textarea>
                                </div>
                                <div class="col-md-12">
                                    <label for="ExpectedAIResponse" class="form-label  font-15 font-semibold text-color-1 mb-2">Expected AI Response</label>
                                    <textarea class="form-control input-field font-14 font-regular border-radius-3 bg-color-4 custom-border-3 text-color-4 resize-none shadow-none" id="ExpectedAIResponse" rows="4" placeholder="Enter Input" label="assistant" ref={assistantRef}  onChange={(e)=> setNewAssistantContent(e.target.value)}>{newAssistantContent}</textarea>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer border-0">
                            <button type="button" class="btn custom-outline-btn font-14 px-4 py-2 me-3 font-bold" onClick={(e)=> {setShowPopUp(false)}}>Cancel</button>
                            <button type="button" class="btn primary-btn font-14 font-bold border-radius-5 color-white px-4 py-2" data-bs-dismiss="modal" onClick={()=> {onEdit(index , newUserContent , newAssistantContent); emptyTextFields()}}>Save</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}


export default FewShotExapmle










            {/* <div className="fewShots-card">
                <div class="few-shots-txt-area">
                    <label for="task" class="few-shot-text-area">User Example </label>
                    <textarea class="form-control input-field font-14 font-regular border-radius-3 bg-white resize-none" id="task" rows="4" placeholder="Example : Tell me a story"
                        name="value1"
                        value={value1}
                        onChange={(e) => onChange(id, e)}
                    ></textarea>
                </div>
                <div class="few-shots-txt-area">
                    <label for="task" class="few-shot-text-area">AI assistant</label>
                    <textarea class="form-control input-field font-14 font-regular border-radius-3 bg-white resize-none" id="task" rows="4" placeholder="Example : Tell me a story"
                        value={value2}
                        name="value2"
                        onChange={(e) => onChange(id, e)}
                    ></textarea>
                </div>
                <button onClick={() => onRemove(id)}>X</button>
            </div> */}