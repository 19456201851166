import { useEffect, useState } from "react";

const folderJson = {
  id: "",
  name: "folder",
  type: "folder",
  sub: [],
};

const fileJson = {
  id: "",
  name: "file",
  type: "file",
};

export function AddFileOrFolderSample(props) {
  useEffect(() => {
    setfileStructure(JSON.parse(props.props));
   
  },[]);
 
  const [fileStructure, setfileStructure] = useState();
  const [recordData, setrecordData] = useState();
  const [editStructName, seteditStructName] = useState("");

  const [editStructID, seteditStructID] = useState(0);

  const [selectedFolderID, setselectedFolderID] = useState(0);

  const bindFileStructure = (_fileStructure) => {
  
    return _fileStructure?.map((_struct, index) => {
      if (_struct.type === "folder") {
        return (
          <>
            <p className="mb-1 ">
              <span className="add-folder">
                <a
                  className="text-decoration-none custom-toggle font-10 font-medium collapsed "
                  data-bs-toggle="collapse"
                  href={"#collapseExample" + _struct.id}
                  role="button"
                  aria-expanded="false"
                  aria-controls={"collapseExample" + _struct.id}
                  selected_folder={false}
                  onClick={(e) => {
                    if (selectedFolderID == _struct.id) {
                      setselectedFolderID(0);
                      e.target.selected_folder = false;
                    } else if (!e.target.selected_folder) {
                      e.target.selected_folder = true;
                      setselectedFolderID(_struct.id);
                    } else {
                      e.target.selected_folder = false;
                    }
                  }}
                >
                  <img
                    className="me-3 collapse-arrow"
                    src="images/ai-images/file-dropdown-icon.svg"
                    alt="dropdown-icon"
                  />{" "}
                  <img
                    className="me-1"
                    src="images/ai-images/folder-icon.svg"
                    alt="folder-icon"
                  />
                  <> {_struct.name}</>
                </a>
                <span class="add-folder-actions">
                  <span
                    class="mx-2 cursor-pointer"
                    onClick={() => {
                      seteditStructID(_struct.id);
                      seteditStructName(_struct.name);
                    }}
                  ></span>
                  <span class="cursor-pointer">
                   
                  </span>
                </span>
              </span>

          
              <div className="collapse" id={"collapseExample" + _struct.id}>
                <div className="card card-body border-0 py-0 ps-4 pe-0">
                  {bindFileStructure(_struct?.sub)}
                </div>
              </div>
            </p>
          </>
        );
      } else {
        return (
          <>
            <div className="card card-body border-0 py-0 ps-4 pe-0">
              <div className="d-flex">
                <div className="d-block">
                  <img
                    className="file-custom-icon me-1"
                    src="images/ai-images/file-icon.svg"
                    alt="file"
                  />
                </div>
                <div className="d-block add-file">
                  <label className="font-10 font-medium">
                    <> {_struct.name}</>
                  </label>
                  <span class="add-file-actions">
                    <span class="mx-2 cursor-pointer "></span>
                    <span class="cursor-pointer">
                    
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      }
    });
  };
  return (
    <>
      <div className="col-md-12 mb-4">{bindFileStructure(fileStructure)}</div>
    </>
  );
}
