/**
 * PS_AAI_1.0 to PS_AAI_1.7 Declare all the import statements
 */


import { useEffect, useState, useContext } from "react";
import UserContext from "../Auth/useContext";
import { getModelConfig } from "../AI Service/Api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { deleteModelConfiguration } from "../AI Service/Api";
import { getAllModelConfig } from "../AI Service/Api";
import Loader from "../Other-Components/loader";
import { isAuth } from "../Auth/authConfig";
import { useMsal, useAccount, useIsAuthenticated } from "@azure/msal-react";
import PopUpComponent from "./Popup";


export function LlmConfigGrid(navOpen) {


  /**
   * PS_AAI_1.9 to PS_AAI_23 Declare all the state variables
   * PS_AAI_1.52 to PS_AAI_1.56 onclick of up arrow asceding sort wil happen
   * PS_AAI_1.57 to PS_AAI_1.61 onclick of down arrow button descending sort will happen
   * PS_AAI_1.62 to PS_AAI_1.65 ,on typing search will takes place
   */
  const [modelConfigData, setModelConifgData] = useState([])
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("modelName Desc");
  const [count, setCount] = useState(10);
  const [shownRecords, setShownRecords] = useState(count);
  const [totalRecords, setTotalRecords] = useState(Number);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState(false);
  const [isAscendingModelName, setIsAscendingModelName] = useState(true);
  const [isAscendingModelType, setIsAscendingModelType] = useState(true);
  const [isAscendingDisplayName, setIsAscendingDisplayName] = useState(true);
  const [isModelConfigPopupOpen, setIsModelConfigPopupOpen] = useState(true);
  const [userData, setuserData] = useState({});
  const [modelConfigurationID, setModelConfigurationID] = useState("")
  const [userEmailID, setUserEmailID] = useState("");
  const userDetails = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [flag, setFlag] = useState("")
  const [modelConfigJson, setModelConfigJson] = useState("")
  const [isPromptFieldPopupOpen, setIsPromptFieldPopupOpen] = useState(true);
  const { instance, accounts } = useMsal();
  const [showPopup, setShowPopup] = useState(false);
  const [promptIndex, setPromptIndex] = useState(null);
  const [popUpText, setPopUpText] = useState("");
  let [loader, setLoader] = useState(false);

  const history = useHistory();

  useEffect(() => {

    fetchModelConfig();
    //  //(modelConfigData,"LLLLLLLLL");
  }, [sort, count]);

  useEffect(() => {
    if (userDetails) {
      setuserData(userDetails);
      fetchModelConfig();
    }
  }, [userDetails, userData]);

  useEffect(() => {
    //(accounts, "account");
    if (!isAuth(accounts)) {
      history.push("/");
    }
  }, []);


  async function fetchOneModelConfig(modelConfigurationID) {
    //(modelConfigurationID,"bbb")
    let obj = {
      modelConfigurationID: modelConfigurationID,
      createdBy: userDetails.email
    }
    // setIsLoading(false)
    let result = await getModelConfig(obj);
    //((result, "qwertyqwerty"));
    // setIsLoading(true)
    const modelConfig = {
      ModelType: result.data.modelTypeName,
      ModelName: result.data.modelConfiguration.modelName,
      ModelDescription: result.data.modelConfiguration.modelDescription,
    };
    setModelConfigJson(modelConfig)
    setIsPromptFieldPopupOpen(false);
  }



  /**
   * PS_AAI_1.24 to PS_AAI_1.41 Fetch the modelConfigData and store it in a variable
   */
  const fetchModelConfig = async () => {
    //;
    try {
      let modelConfig = {
        search: search,
        sort: sort,
        count: count,
        createdBy: userDetails?.email,
        modelConfigurationID: ""
      };
      setIsLoading(false);
      let response = await getAllModelConfig(modelConfig);
      // setIsLoading(false)
      //(response,"OOOOOOOOO");
      if (response.status == 200) {
        setIsLoading(true)
        //(response.data.responseData,"ffffffffffffff");
        setModelConifgData(response.data.responseData);
        let record = response.data.responseData.length - shownRecords;
        //(record,"99999999");


        setShownRecords(record + shownRecords);


        setTotalRecords(response.data.modelCount);
      }
    } catch (error) {
      //(error);
    }
  };

  //(shownRecords,totalRecords,"7777");


  /**
   * PS_AAI_1.76 to PS_AAI_1.90 onclicking delete id will be stored in a state variable
   *  and soft deleted in the database and fetchModelConfig function will be called again
   * @param {} id  which needs to be deleted
   */


  const deleteModelConfig = async (id) => {


    //(id,"dddddddddd");
    let obj = {
      modelConfigurationID: id
    }
    setIsLoading(false)
    let response = await deleteModelConfiguration(obj)
    if (response.status == 200) {
      setShowPopup(false);
      fetchModelConfig()
    }
    setIsLoading(true)

  }
  //(isPromptFieldPopupOpen,"yyy")

  /**
   * PS_AAI_46 to PS__AI_50 when user clicks on loadmore count will be increased by 10 and will fetch the next 10 records from the grid
   */
  const loadmoreClick = () => {
    let countValue = count + 10;
    setCount(countValue);
    if (shownRecords >= totalRecords) {
      setIsLoadMoreDisabled(true);
    }
  };

  /**
   * PS_AAI_1.42 to PS_AAI_1.45 Bind the modelConfig data to the grid
   * @param {*} modelConfigData state variable which has modelConfigs
   * @returns binded grid
   */
  function bindModelConfigData(modelConfigData) {
    if (modelConfigData != undefined) {
      //(modelConfigData, "modelConfigData")
      return modelConfigData?.map((value) => {
        //(value,"ggg")
        return (
          <tr>
            <td className="ps-4"><a className="text-decoration-none link-color" data-bs-toggle="modal"
              data-bs-target="#specifiedprompts-popup" onClick={(e) => {
                let modelConfigurationID = value.modelConfiguration.modelConfigurationID;
                fetchOneModelConfig(modelConfigurationID);
                setIsPromptFieldPopupOpen(false);

              }} style={{ cursor: 'pointer' }} >{value.modelConfiguration.displayName}</a></td>
            <td className="ps-4">{value.modelConfiguration.modelName}</td>
            <td className="ps-4">{value.modelTypeName}</td>
            <td className="text-center">
              <button className="btn text-nowrap font-14 font-medium" onClick={() => { history.push(`/asami-llm-config?modelConfigurationID=${(value.modelConfiguration.modelConfigurationID)}`) }}><img src="images-new/edit-icons.svg" alt="Edit" /></button>
              <button className="btn text-nowrap font-14 font-medium" onClick={() => { setPopUpText(`delete ${value.modelConfiguration.displayName}`); setModelConfigurationID(value.modelConfiguration.modelConfigurationID); setShowPopup(true); }}><img src="images-new/delete-icons.svg" alt="Edit" /></button>
            </td>
          </tr>
        );
      });
    } else {
      return null;
    }
  }
  return (
    <>
      {!isLoading ? <Loader isLoading={isLoading} /> :
      
      <div className="container-fluid">
      <div className="row justify-content-lg-end justify-content-center">
        
      <div className={ navOpen.navOpen === "Close" ? "col-11" : "col-lg-9 cust-me-lg-4 col-11 mt-3"}>
          <div className="row custom-nav-tab-width">
            {/*heading & search*/}
            <div className="my-4 d-grid d-md-flex justify-content-md-between align-items-center custom-llm-config-top-spacing">
              <h3 className="font-20 font-bold text-color-1">LLM Configuration</h3>
              <div className="d-grid d-md-flex align-items-center">
                <div className="input-group transparent-grp w-100 search-bg">
                  <input type="text" className="form-control custom-search-input text-nowrap search-bg border-0 text-color-4 focus-none font-14 font-medium pe-0 ps-3" placeholder="Search"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        fetchModelConfig()
                      }
                    }}
                    value={search}
                  />
                  <button className="btn focus-none search-bg border-0" type="button"><img className="filter-icons" src="images/Default/input-search.svg" alt="input-search-icon" onClick={(e) => {
                    fetchModelConfig()
                  }} /></button>
                </div>
                <button type="button" className="btn primary-btn font-14 font-semibold border-radius-5 border-0  px-4 py-2 ms-3" style={{ width: "-webkit-fill-available" }} onClick={() => { history.push('/asami-llm-config') }}><img src="images\Default\add-white-icon.svg" alt="Add-icon" className="me-2" onClick={() => {
                  history.push(`/asami-llm-config`);

                }} />New Configuration</button>
              </div>
            </div>
            {/*heading & search*/}
            {/*grid starts here*/}
            <div className="col-md-12">
              <div className="table-responsive rounded-3 transparent-grp">
                <table className="table theme-table font-regular font-14 mb-0">
                  <thead>
                    <tr>

                    <th className="ps-4" onClick={() => {
                        setIsAscendingDisplayName(!isAscendingDisplayName);
                        setSort(
                          isAscendingDisplayName
                            ? "displayName Desc"
                            : "displayName Asc"
                        );
                      }}>Display Name<span className="cursor-pointer ps-1"> <img
                        className="filter-icons"
                        src={
                          isAscendingDisplayName
                            ? "images/Default/sort-up.svg"
                            : "images/Default/sort-down.svg"
                        }
                        alt={isAscendingDisplayName ? "sort-up" : "sort-down"} /></span></th>
                      <th className="ps-4"  onClick={() => {
                        setIsAscendingModelName(!isAscendingModelName);
                        setSort(
                          isAscendingModelName
                            ? "modelName Desc"
                            : "modelName Asc"
                        );
                      }}>Model Name<span className="cursor-pointer ps-1"> <img
                        className="filter-icons"
                        src={
                          isAscendingModelName
                            ? "images/Default/sort-up.svg"
                            : "images/Default/sort-down.svg"
                        }
                        alt={isAscendingModelName ? "sort-up" : "sort-down"} /></span></th>


                     


                      <th className="ps-4"  onClick={() => {
                        setIsAscendingModelType(!isAscendingModelType);
                        setSort(
                          isAscendingModelType
                            ? "modelName Desc"
                            : "modelName Asc"
                        );
                      }}>Model Type<span className="cursor-pointer ps-1"><img
                        className="filter-icons"
                        src={
                          isAscendingModelType
                            ? "images/Default/sort-up.svg"
                            : "images/Default/sort-down.svg"
                        }
                        alt={isAscendingModelType ? "sort-up" : "sort-down"} /></span></th>
                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>

                    {shownRecords !== 0 ? (
                      bindModelConfigData(modelConfigData)
                    ) : (
                      <tr>
                        <td colSpan={4} className="text-center py-5 vh-50">
                          <img
                            src="images/Default/no-record-img.svg"
                            alt="no-record-img"
                            className="no-record-img mb-5"
                          />
                          <span className="font-20 font-medium w-100 d-block text-color-1">
                            Sorry! You don’t have any configured LLM.
                          </span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <p className="font-regular font-14 text-color-1 mt-3 mb-4"># of Records : <span className="font-bold font-14 text-color-9"> {totalRecords > shownRecords ? shownRecords : totalRecords}</span> out of <span className="font-bold font-14 text-color-9">{totalRecords}</span></p>
              <div className="text-center mb-4">
                <button className="btn btn-gradient-outline-loadmore border-radius-4 font-14 font-semibold py-2 px-3" type="button" onClick={() => { loadmoreClick() }} hidden={totalRecords <= 10 || isLoadMoreDisabled}><span className="color-gradient" >Load More</span></button>
              </div>
              {popUpText && showPopup && (
                <PopUpComponent

                  text={popUpText}
                  promptID={modelConfigurationID}
                  yesFunction={deleteModelConfig}
                  noFunction={{ setShowPopup, setModelConfigurationID, setPromptIndex }}
                />
              )}
            </div>
            {/*grid ends here*/}
          </div>
      </div>
      </div>
      </div>
      
      }


      <div hidden={isPromptFieldPopupOpen}>
        <div
          className="modal fade"
          id="specifiedprompts-popup"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-hidden="true"
        >
          {/* Modal content */}
          <div className="modal-dialog modal-dialog-centered custom-specifiedprompts-width border-0">
            <div className="modal-content p-3 border-0">
              <div className="modal-header border-0 pt-2 pb-0">
                <h5 className="modal-title font-22 font-bold">
                  Specified Models
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setIsPromptFieldPopupOpen(true);
                  }}
                />
              </div>
              <div className="modal-body popup-scrl">
                <div className="row">
                  {Object.entries(modelConfigJson).map(([key, value]) => (
                    <>
                      {key.trim() === "Any existing file structure" ? (
                        <div className="col-md-12 mb-2" key={key}>

                          <label className="font-14 font-semibold grey-v1">
                            {key !== "" ? key : "User Request"}:
                          </label>
                          <span className="font-14 font-semibold grey-blue">
                          </span>
                        </div>
                      ) : key.trim() === "Tech Stack" ||
                        key.trim() === "Type Of Code" ? (
                        <div className="col-md-6 mb-3" key={key}>
                          <label className="font-14 font-semibold grey-v1">
                            {key}:
                          </label>
                          <span className="font-14 font-semibold grey-blue">
                            {value}
                          </span>
                        </div>
                      ) : (
                        <div className="col-md-12 mb-2" key={key}>

                          <label className="font-14 font-semibold grey-v1">
                            {key !== "" ? key : "User Request"}:
                          </label>
                          <span className="font-14 font-semibold grey-blue">
                            {value}
                          </span>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>

              <div className="modal-footer border-0 d-flex justify-content-center pt-0">
                <button
                  type="button"
                  className="btn theme-color-bg text-white font-14 px-4 py-2 font-medium theme-focus"
                  data-bs-dismiss="modal"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

}