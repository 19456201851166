// PS_MS_01: Import necessary modules
import React, { useState, useEffect } from 'react';
import { getStory, ExistData, similarityCheck } from "../../AI Service/Api";
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { ConfigPopup } from './ConfigPopUp';
import Loader from '../../Other-Components/loader';

// PS_MS_02 to PS_MS_68 - fetch the Manage story grid and entire functionality associated on that page
export function ManageStory(navOpen) {
  const location = useLocation();
  const history = useHistory()
  const { projectIdFromMP } = location.state || {};
  // PS_MS_04 - Declare the state variables
  let projectIdFromLocal = localStorage.getItem('projectIdFromMP');
  let projectNameFromLocal = localStorage.getItem('projectNameFromLocal');
  const [story, setStory] = useState([]);
  const [statusDropdown, setStatusDropdown] = useState([]);
  const [flag, setFlag] = useState("");
  const [filterAlert, setFilterAlert] = useState("");
  const [showFilter, setShowFilter] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [projectID, setProjectID] = useState(projectIdFromMP);
  const [searchStoryID, setSearchStoryID] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [endOffset, setEndOffset] = useState(10);
  const [workItemType, setWorkItemType] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [isAscendingStoryID, setIsAscendingStoryID] = useState(true);
  const [isAscendingWorkItem, setIsAscendingWorkItem] = useState(true);
  const [isAscendingAssignedTo, setIsAscendingAssignedTo] = useState(true);
  const [isAscendingStatus, setIsAscendingStatus] = useState(true);
  const [lengthOfRecords, setLengthOfRecords] = useState("");


  const [showConfigPop, setConfigPopUp] = useState(false)
  const [editScenario, setEditScenario] = useState("CONFIG")
  const [uniqueIdFromMP, setUniqueIdFromMp] = useState()
  

  const [url, setUrl] = useState()
  const [PAT, setPAT] = useState()


  // Sam-variables

  const [description, setDescription] = useState("");
  const [functions15, setFunctions15] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSimilarityCheckDone, setIsSimilarityCheckDone] = useState(false);

  const [repoName, setRepoName] = useState()
  const [repoUrl , setRepoUrl] = useState()
  

  // PS_MS_05 to PS_MS_22- useEffect will be rendered based on the dependency array
  useEffect(() => {
    fetchStory();
  }, [searchStoryID, workItemType, sortOrder, endOffset, showFilter]);

  const fetchInitialData = async () => {
     
    try {
      const reqBody = {
        projectID: projectIdFromLocal
      }
      let response = await ExistData(reqBody)
      //(response.Responsedata[0], "125")
      setUrl(response.Responsedata[0].project_url)
      setPAT(response.Responsedata[0].PAT)
      setRepoName(response.Responsedata[0].Repo_data[0].Repo_name)
      setRepoUrl(response.Responsedata[0].Repo_data[0].Repo_Url)


    }
    catch (error) {
      //(error)
    }
  }

  //(url, PAT, "palfkn")

  useEffect(() => {
    fetchInitialData()
    setWorkItemType("story")
  }, [])



  // PS_MS_06 - To fetch story details by passing the request payload to the getStory API
  const fetchStory = async () => {
    const data = {
      projectID: projectID,
      storyID: searchStoryID,
      filterStatus: filterStatus,
      fromDate: fromDate,
      toDate: toDate,
      workItemType: "story",
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      offsetValue: endOffset
    };

    let response = await getStory(data);
    setIsLoading(true);
    //(response.data, "prasanth")

    if (response.status == 200) {
      setStory(response.data[0].stories);
      setLengthOfRecords(response.data[0].stories.length);
      setTotalRecords(response.data[0].totalRecords);
      setStatusDropdown(response.data[0].statusDropdownResult);
    }

  };

  //(story, "cldsbacjdacj")


  // PS_MS_34 to PS_MS_36 - To check whether the user chooses atleast any one of the field
  function filterValidation() {
    let flag = 0;

    if (filterStatus != "") {
      flag++;
    }
    if (fromDate != "") {
      flag++;
    }
    if (toDate != "") {
      flag++;
    }

    if (flag != 0) {
      setShowFilter(false);
      setFilterAlert("");
      fetchStory();
    } else {
      setFilterAlert("alert");
    }
  }


  // PS_MS_42 to PS_MS_43 - To make the state variables null when the user clicks cancel in filter to fetch the initial grid
  const cancelFilter = () => {
    setShowFilter(false);
    setFilterStatus("");
    setFromDate("");
    setToDate("");
    setFilterAlert("");
  }

  const handleDescription = async () => {
    let obj = {
      "question": description,
      "projectName": projectNameFromLocal,
      "collectionName": repoName
    }
    setIsLoading(false)
    let response = await similarityCheck(obj);
    if (response.status == 200) {
      setIsLoading(true)
      //(response, "SimilarityCheckResponse")
      let content = response.data.Response
      //(content, "SimilarityCheckContent")
      setFunctions15(content);
      setIsSimilarityCheckDone(true);
      history.push({
        pathname: '/chat',
        state: { functions15: content , isSimilarityCheckDone: true , projectID : projectID , Repo_url : repoUrl , PAT : PAT }
      });
    }
  }

  return (
    <>
      {!isLoading ? (<div >
     
     <Loader isLoading={isLoading} />
   </div>): (
        <>
          <div className={navOpen.navOpen === "Close" ? "container-fluid second-component-manageProject-nav-close" : "container-fluid second-component-manageProject"}>
            <div className="row">
              <div className="col-md-12 px-4 pt-3">
                <h1 className="color-black-v1 font-24 font-bold mt-5 mb-4 d-flex align-items-center">
                  <a href="/ManageProject">
                    <img
                      src="images-new/back-black-arrow.svg"
                      className="me-3 mb-1"
                      alt="back-arrow"
                      title="Back"
                    />
                  </a>
                  <span>
                    <img
                      src="images-new/racpad-proj.svg"
                      alt="Racpad"
                      className="me-2 mb-1"
                    />
                    RACPad
                  </span>
                </h1>
                <div className="my-4 d-grid d-md-flex justify-content-md-between align-items-center">
                  <h2 className="font-bold font-20 mb-0 text-nowrap">
                    Manage Story ({story.length})
                  </h2>
                  <div className="table-header d-md-flex align-items-center">
                    <div className="p-2 px-3 border border-radius-35 d-flex justify-content-between me-3 mt-3 mt-md-0">
                      <span className="flex-nowrap text-nowrap">Integrated with</span>
                      <img src="./images-new/avalon-v2.svg" alt="iocn" className="ms-2" />
                    </div>
                    {/*search*/}
                    <div className="d-flex align-items-center mt-3 mt-md-0">
                      <div className="input-group transparent-grp w-225 me-3 m-0">
                        {/* PS_MS_23 to PS_MS_28 - when the user searches the story ID */}
                        <input
                          type="text"
                          className="form-control custom-search-input bg-transprent border-0 focus-none font-14 font-medium pe-0 ps-3"
                          placeholder="Search" onChange={(e) => {
                            setSearchStoryID(e.target.value);
                          }}
                          onKeyUp={(e) => {
                            if (e.key === "Enter") {
                              fetchStory()
                              setSearchStoryID("")

                            }
                          }}
                          value={searchStoryID}
                        />
                        <button className="btn focus-none" type="button" onClick={(e) => {
                          fetchStory()
                          setSearchStoryID("")
                        }}>
                          <img src="images-new/input-search.svg" alt="input-search-icon" />
                        </button>
                      </div>
                      <div className="dropdown me-2">
                        <button
                          type="button"
                          className="btn font-16 font-semibold align-items-center d-flex transparent-grp py-2 theme-focus inter-btn"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onClick={() => {
                            setShowFilter(true);
                          }}
                        >
                          <img src="images-new/adv-filter.svg" alt="filter-icon" />
                        </button>
                        {/* advanced filter starts here */}
                        <span className="dropdown-menu dropdown-menu-end p-3 filter-dropdown-bg w-filter-dropdown end-0" style={{ display: showFilter ? "block" : "none" }}>
                          <span className="d-flex justify-content-between align-items-center mb-3">
                            <span className="font-18  font-semibold">Advanced Filter</span>
                            <span className="" onClick={() => {
                              setShowFilter(false);
                            }}>
                              <img
                                src="images-new/flter-close-icon.svg"
                                alt="flter-close-icon"
                                className="cursor-pointer"
                              />
                            </span>
                          </span>
                          <span className="row ">
                            <span className="mb-3 d-block col-md-12">
                              <span className="mb-3">
                                <label
                                  htmlFor="from"
                                  className="form-label label-color font-15 font-semibold light-black-color"
                                >
                                  Status
                                </label>
                                <select className="form-select" id="model-type"
                                  value={filterStatus}
                                  onChange={(e) => {
                                    setFilterStatus(e.target.value);
                                  }}
                                  onClick={(e)=>e.currentTarget.classList.toggle('open')}>
                                  <option selected>Select</option>
                                  {statusDropdown.map((value, index) => (

                                    <option
                                      value={value.story_status}
                                      // id={value.approvalStatusID}
                                      key={index}
                                    >
                                      {value.story_status}
                                    </option>
                                  ))};
                                </select>
                              </span>
                            </span>
                          </span>
                          <span className="mb-2 d-block">
                            <span className="d-block font-semibold font-16 light-black-color">
                              Date Range
                            </span>
                          </span>
                          <span className="row mb-2">
                            <span className="mb-3 d-block col-md-6">
                              <span className="mb-3">
                                <label
                                  htmlFor="from"
                                  className="form-label label-color font-15 font-semibold light-black-color"
                                >
                                  From
                                </label>
                                <input
                                  type="date"
                                  className="form-control input-field font-14 font-regular border-radius-3 color-grey-v3"
                                  id="from"
                                  // max={toDate ? toDate : formattedDate}
                                  value={fromDate}
                                  onChange={(e) => {
                                    setToDate("");
                                    setFromDate(e.target.value);

                                  }}
                                />
                              </span>
                            </span>
                            <span className="mb-3 d-block col-md-6">
                              <span className="mb-3">
                                <label
                                  htmlFor="to"
                                  className="form-label label-color font-15 font-semibold"
                                >
                                  To
                                </label>
                                <input
                                  type="date"
                                  className="form-control input-field font-14 font-regular border-radius-3 color-grey-v3"
                                  id="to"
                                  value={toDate}
                                  onChange={(e) => {
                                    setToDate(e.target.value);
                                  }}
                                  // max={formattedDate}
                                  min={fromDate}
                                />
                              </span>
                            </span>
                          </span>
                          <span className="w-100 d-flex justify-content-end gap-3 mt-2">
                            <button className="btn btn-outline-secondary font-14 font-semibold px-3 m-0"
                              onClick={() => {
                                cancelFilter();
                              }}>
                              Cancel
                            </button>
                            <button className="btn primary-btn font-14 font-semibold border-radius-5 px-3 py-2 color-white m-0"
                              onClick={() => {
                                setFlag("filter")
                                {
                                  filterValidation();
                                }
                              }}>
                              Apply
                            </button>
                          </span>
                          {filterAlert == "alert" ? (
                            <div className="span-color">
                              Select,atleast one filter!
                            </div>
                          ) : null}
                        </span>
                        {/* advanced filter ends here */}
                      </div>
                      <div className="dropdown" style={{position:'relative'}}>
                    <button
                      id="end-point-config"
                      className="btn input-field font-14 story-drpdwn-btn font-regular border-radius-3 bg-transprent w-100 d-flex justify-content-between"
                      type="button"
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    >
                      <span className="issue-color font-medium font-14 d-flex">
                        <img
                          src="images-new/task-icon.svg"
                          alt="Issues"
                          className="me-2"
                        />
                        Story
                      </span>
                      <span className="ps-1">
                        <img
                          src="images-new/drpdwn-arrow.svg"
                          alt="dropdown-icon"
                          className="ms-1"
                        />
                      </span>
                    </button>
                    {isDropdownOpen && (
                      <ul className="dropdown-menu border-0 shadow-sm py-2 w-100 show" style={{position:'absolute', top: '100%', right: '0%', zIndex: '1000', display: 'block'}}>
                        <li className="font-14 font-medium color-black">
                          <a className="dropdown-item py-2 custom-dropdown-li" onClick={() => {
                            history.push({
                              pathname: "/ManageTasks",
                              state: {
                                projectIdFromMP: projectID,
                              }
                            })
                          }}>
                            <img
                              src="images-new/task-icon.svg"
                              alt="Tasks"
                              className="me-2 icon-width"
                            />
                            View by Task
                          </a>
                        </li>
                        <li className="font-14 font-medium color-black">
                          <a className="dropdown-item py-2 custom-dropdown-li" onClick={() => {
                            history.push({
                              pathname: "/ManageIssue",
                              state: {
                                projectIdFromMP: projectIdFromLocal,
                              }
                            })
                          }}>
                            <img
                              src="images-new/issues-icon.svg"
                              alt="Issues"
                              className="me-2"
                            />
                            View by Issue
                          </a>
                        </li>
                        {/* <li className="font-14 font-medium color-black">
                          <a className="dropdown-item py-2 custom-dropdown-li">
                            <img
                              src="images-new/story-icon.svg"
                              alt="Story"
                              className="me-2 icon-width"
                            />
                            View by Story
                          </a>
                        </li> */}
                      </ul>
                    )}
                  </div>
                    </div>
                  </div>
                  {/*search*/}
                </div>
                {/*grid starts here*/}
                <div className="col-md-12">
                  <div className="table-responsive rounded-3 transparent-grp mb-4">
                    <table className="table theme-table font-regular font-14 mb-0">
                      <thead>
                        {/* PS_MS_45 to PS_MS_52 - When the user clicks on particular column to sort */}
                        <tr className="grey-color-v5">
                          <th className="ps-5 py-4"
                            onClick={() => {
                              setSortColumn("project_story_key")
                              setIsAscendingStoryID(!isAscendingStoryID);
                              setSortOrder(
                                isAscendingStoryID
                                  ? "Desc"
                                  : "Asc"
                              );
                            }}>
                            Story ID
                            <span className="cursor-pointer ms-1">
                              <img src={
                                isAscendingStoryID
                                  ? "images-new/sort-up.svg"
                                  : "images-new/sort-down.svg"
                              } alt={isAscendingStoryID ? "sort-up" : "sort-down"} />
                            </span>
                          </th>

                          <th className="text-start"
                            onClick={() => {
                              setSortColumn("work_item")
                              setIsAscendingWorkItem(!isAscendingWorkItem);
                              setSortOrder(
                                isAscendingWorkItem
                                  ? "Desc"
                                  : "Asc"
                              );
                            }}>
                            Work Item
                            <span className="cursor-pointer ms-1">
                              <img src={
                                isAscendingWorkItem
                                  ? "images-new/sort-up.svg"
                                  : "images-new/sort-down.svg"
                              } alt={isAscendingWorkItem ? "sort-up" : "sort-down"} />
                            </span>
                          </th>

                          <th className="text-start">
                            Project Timeline
                            <span className="cursor-pointer ms-1">
                              <img src="images-new/sort-down.svg" alt="sort-down" />
                            </span>
                          </th>

                          <th className="text-center"
                            onClick={() => {
                              setSortColumn("assigned_to")
                              setIsAscendingAssignedTo(!isAscendingAssignedTo);
                              setSortOrder(
                                isAscendingAssignedTo
                                  ? "Desc"
                                  : "Asc"
                              );
                            }}>
                            Assigned To
                            <span className="cursor-pointer ms-1">
                              <img src={
                                isAscendingAssignedTo
                                  ? "images-new/sort-up.svg"
                                  : "images-new/sort-down.svg"
                              } alt={isAscendingAssignedTo ? "sort-up" : "sort-down"} />
                            </span>
                          </th>

                          <th className="text-start"
                            onClick={() => {
                              setSortColumn("Status")
                              setIsAscendingStatus(!isAscendingStatus);
                              setSortOrder(
                                isAscendingStatus
                                  ? "Desc"
                                  : "Asc"
                              );
                            }}>
                            Status
                            <span className="cursor-pointer ms-1">
                              <img src={
                                isAscendingStatus
                                  ? "images-new/sort-up.svg"
                                  : "images-new/sort-down.svg"
                              } alt={isAscendingStatus ? "sort-up" : "sort-down"} />
                            </span>
                          </th>

                          <th className="text-center pe-5">Actions</th>
                        </tr>
                      </thead>

                      <tbody>
                        {story.map((story, index) => (
                          <React.Fragment key={index}>
                            {index % 2 === 0 ?
                              <tr className="bg-white" key={index}>

                                <td className="ps-5 py-4">
                                  {story.isConfigured === true ? (
                                    <a href="#" className="text-decoration-none grey-color-v4" data-bs-toggle="modal" data-bs-target="#issues">
                                      {story.storyID}
                                    </a>
                                  ) : (
                                    <span>{story.storyID}</span>
                                  )}
                                </td>

                                <td className="text-start">{story.workItem}</td>
                                <td className="text-start">{story.ProjectTimeline}</td>
                                <td className="text-center">
                                  <img src="images-new/sample-profile-imgs.svg" alt="Profile" />{story.AssignedTo}
                                </td>
                                <td className="text-start">
                                  <span className="active-color">{story.Status}</span>
                                </td>
                                <td className="text-center pe-5">
                                  <button type="button" className="btn border-0">

                                    {story.isConfigured == true ? (
                                      <a onClick={() => {
                                        setEditScenario("update")
                                        setConfigPopUp(true)
                                        setUniqueIdFromMp(story.storyID)
                                        localStorage.setItem('uniqueIdFromLocal',story.storyID)
                                      }}>
                                        <img
                                          className="actions-right"
                                          src="images-new/edit-icons.svg"
                                          alt="icon"
                                        />
                                      </a>
                                    ) : (
                                      <a onClick={() => {
                                        setEditScenario("config")
                                        setConfigPopUp(true)
                                        setUniqueIdFromMp(story.storyID)
                                    localStorage.setItem('uniqueIdFromLocal',story.storyID)
                                      }}>
                                    <img
                                      className="actions-right"
                                      src="images-new/right-icon.svg"
                                      alt="icon"
                                    />
                                    </a>
                                    )}
                                  </button>
                                </td>
                              </tr> :
                              <tr key={index}>

                                <td className="ps-5">
                                  {story.isConfigured === true ? (
                                    <a href="#" className="text-decoration-none link-color" data-bs-toggle="modal" data-bs-target="#issues">
                                      {story.storyID}
                                    </a>


                                  ) : (
                                    <span>{story.storyID}</span>
                                  )}
                                </td>

                                <td className="text-start">{story.workItem}</td>
                                <td className="text-start">{story.ProjectTimeline}</td>
                                <td className="text-center">
                                  <img src="images-new/sample-profile-imgs.svg" alt="Profile" />{story.AssignedTo}
                                </td>
                                <td className="text-start">
                                  <span className="active-color">{story.Status}</span>
                                </td>
                                <td className="text-center pe-5">
                                  <button type="button" className="btn border-0">

                                    {story.isConfigured == true ? (
                                      <a onClick={() => {
                                        setEditScenario("update")
                                        setConfigPopUp(true)
                                        setUniqueIdFromMp(story.storyID)
                                        localStorage.setItem('uniqueIdFromLocal',story.storyID)
                                      }}>
                                      <img
                                        className="actions-right"
                                        src="images-new/edit-icons.svg"
                                        alt="icon"
                                      />
                                      </a>
                                    ) : (
                                      <a onClick={() => {
                                        setEditScenario("config")
                                        setConfigPopUp(true)
                                        setUniqueIdFromMp(story.storyID)
                                        localStorage.setItem('uniqueIdFromLocal',story.storyID)
                                      }}>
                                    <img
                                      className="actions-right"
                                      src="images-new/right-icon.svg"
                                      alt="icon"
                                    />
                                    </a>
                                    )}
                                  </button>
                                </td>
                              </tr>
                            }
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <p className="font-regular font-14 grey-blue my-3">
                      # of Records :{" "}
                      <span className="font-bold font-14 grey-v1">
                        {" "}
                        {story.length}
                      </span>{" "}
                      out of{" "}
                      <span className="font-bold font-14 grey-v1">
                        {totalRecords}
                      </span>
                    </p>
                  <div className="text-center mb-5">
                    {totalRecords > endOffset ?
                      <button className="btn btn-gradient-outline-loadmore border-radius-4 font-14 font-semibold py-2 px-3"
                        onClick={() => {
                          setEndOffset(prevEndOffset => prevEndOffset + 10);
                        }}
                        hidden={totalRecords < 10 || lengthOfRecords < 10} >
                        <span className="color-gradient">Load More</span>
                      </button> :
                      <div></div>
                    }
                  </div>
                </div>
                {/*grid starts here*/}
              </div>
              {/*manage request tab*/}
            </div>
            {/*Tab inner content end here*/}
          </div>
          <div className="modal fade" id="issues" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered custom-popup-issues border-1">
              <div className="modal-content p-3 border-0">
                <div className="modal-header border-0 p-0">
                  <h5 className="modal-title text-dark font-22 font-bold ">Add Description</h5>
                  <button type="button" className="custom-close me-0" data-bs-dismiss="modal" aria-label="Close"><img src="images-new/close-popup.svg" alt="close-icon" /></button>
                </div>
                <div className="modal-body popup-scrl pt-2">
                  <div className="row">
                    {/* content */}
                    <div className="col-md-12 col-sm-12 mt-4 p-0">
                      <label htmlFor="description" className="form-label label-color font-15 font-semibold color-black-v1 mb-2">Description</label>
                      <textarea className="form-control font-14 font-regular border-radius-3 resize-none" value={description} id="description" rows={5} placeholder="Description" defaultValue={""} onChange={(e)=> setDescription(e.target.value)} />
                    </div>
                  </div>
                </div>
                <div className="modal-footer border-0 gap-3">
                  <button type="button" className="btn btn-outline-secondary font-14 px-4 pb-2 m-0 font-medium" data-bs-dismiss="modal">Cancel</button>
                  <button type="button" className="btn primary-btn font-14 font-semibold border-radius-5 color-white pb-2 px-4 m-0" onClick={handleDescription}>Save</button>
                </div>
              </div>
            </div>
          </div>
          {showConfigPop ? <ConfigPopup projectIdFromMP={projectIdFromLocal} TypeOfData={editScenario} setShowPopUp={setConfigPopUp} typeOfGrid={"story"} uniqueIdFromMP={localStorage.getItem('uniqueIdFromLocal')} url={url} PAT={PAT} /> : <h1></h1>}
        </>
      )}
    </>
  )
}

export default ManageStory