import { getSessionItem } from "../util-services/encrypt-storage";
import axios from "axios"; //PC_PR_NO 38

export async function client(endpoint, body, requestType, cancelToken) {
  let access_token = getSessionItem("access-token");
  const token = getSessionItem("api-token");
  const userEmailID = getSessionItem("user-email");

  let headers = {
    "access-token": access_token,
    authorization: `Bearer ${token}`,
    "Access-Control-Allow-Origin": "*",
  };

  if (endpoint.includes('kroki.io')) {
    headers = {
      'Content-Type': 'text/plain',
    };
  } else {
    if (body instanceof FormData) {
      headers["Content-Type"] = "multipart/form-data";
      body.append("userEmailID", userEmailID);
    } else {
      headers["Content-Type"] = "application/json";
      body = { ...body, userEmailID };
      // console.log(body, "body")
    }
  }

  let url = `${endpoint}`;
  let configreq = {
    method: requestType,
    url: url,
    data: body,
    headers: headers,
  };

  // Only add cancelToken to config if it's provided and valid
  if (cancelToken && typeof cancelToken.throwIfRequested === 'function') {
    configreq.cancelToken = cancelToken;
  }

  const results = await axios(configreq);
  // console.log(results, "excelexcelexcel")
  return results;
}