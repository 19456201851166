//PS_AAI_7.1,Declare all the import statements
import { useEffect, useState, useContext } from "react";
// import { getInputTypeAndTechStack } from "../AI Service/Api";
import toggleContext from "./Context";
/**
 * PS_AAI_7.2,Declare all the state varaibles
 * PS_AAI_7.3 to PS_AAI_7.12 triggers fetchInputTypeTechStack function in useEffect which fetches the data from the database by an API call
 * PS_AAI_7.15 to PS_AAI_7.20 check for the required condition and bind the techstack using map function
 * @returns
 */
export default function DropdownComponent({ record, changeLoading }) {
 
  const [recordData, setRecordData] = useState();
  const toggle = useContext(toggleContext);
  const [dropdownOptions, setDropdownOptions] = useState();
 
  const [codeID, setcodeID] = useState();
  // const [error, seterror] = useState("");
  const [hideError, sethideError] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleMouseLeave = () => {
    setIsDropdownOpen(false); // This line would close the dropdown on mouse leave.
  };
 
  let fieldValuesObj = {
    imageUrl: "",
 
    text: "",
  };
  const [fieldValues, setFieldValues] = useState(fieldValuesObj);
  useEffect(() => {
    //(toggle,"toggle");
    //(toggle,"toggle");
    if (record) {
      setRecordData(record.record);
      if (record?.bindChoicesValues) {
        setDropdownOptions(record?.bindChoicesValues);
      }
    }
  }, []);
  useEffect(() => {
 
    if (record?.techstackID) {
      setcodeID(record.techstackID);
    }
  }, [codeID]);
 
 
  useEffect(()=>{
    // Function to handle clicking outside the dropdown to close it
    function handleClickOutside(event) {
      if (!event.target.closest('#choose-teck') && !event.target.closest('.dropdown-menu')) {
        setIsDropdownOpen(false);
      }
    }
 
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
 
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  },[record])
  /**
   *PS_AAI_7.6 to PS_AAI_7.14 fetch the options and techstack from the backend
   */
  // const fetchInputTypeAndTechStack = async () => {
  //   try {
  //     changeLoading(true);
  //     let result = await getInputTypeAndTechStack({
  //       promptFieldID: record?.record?.promptFieldID,
  //     });
  //     changeLoading(false);
 
  //     if (record?.record?.inputTypeRefCode == "TS") {
  //       setDropdownOptions(result.data.techstack);
  //     } else {
  //       setDropdownOptions(result.data.choices);
  //     }
 
  //     changeLoading(false);
  //   } catch (error) {
  //     //(error.message);
  //   }
  // };
  /**
 * PS_AAI_7.15 to PS_AAI_7.21,on click of the techstack ,check the condition and pass the values to handleInputFields function
 * @returns retun the dropdown either techStack or options
 */
  const bindDropdown = () => {
 
    if (record?.record?.inputTypeRefCode == "TS"||toggle) {
 
      return (
        <div>
          {toggle && (
           
            <div className="row pb-3">
              <div className="col-md-6 col-sm-12 mb-3">
                <div className="input-field pe-2">
                <ul className="list-unstyled stack-li-scroll mb-0" style={{cursor:'pointer'}}>
                <li className="border-bottom font-14 py-2 text-color-6" style={{cursor:'pointer'}}>Select</li>
                    {dropdownOptions?.map((value, index) => {
                      //(value,"value");
                      return (
                        <li
                          className={`${
                            index == dropdownOptions.length - 1
                              ? `border-bottom`
                              : ``
                          } font-14 py-2`}
                          style={{cursor:'pointer'}}
 
                        >
                          <span className="me-3">
                            <img
                              className="template-dropdown-icon"
                              src={value.techstackLogo}
                              alt="js-icon"
                            />
                          </span>
                          {value.techstackName}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          )}
          {!toggle && (
            <div className="dropdown w-100" onMouseLeave={handleMouseLeave}>
            <button
              className="text-start custom-dropdown-toggle theme-focus input-field py-2 px-3 w-100 bg-transparent font-14 font-medium text-color-6 custom-border-3 rounded-3"
              type="button"
              id="choose-teck"
              // Removed data-bs-toggle attribute here if it was present
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              aria-expanded={isDropdownOpen}
            >
                {fieldValues.text && fieldValues.imageUrl ? (
                  <span className="me-3">
                    <img
                      className="template-dropdown-icon"
                      src={fieldValues.imageUrl}
                      alt="js-icon"
                    />
                    {fieldValues.text}
                  </span>
                ) : (
                  "Select"
                )}
              </button>
              {isDropdownOpen && (
              <ul
              className="dropdown-menu w-100 py-0 bg-color px-2 common-dropdown-scroll-popup drop-down-tech-stack-fix"
              aria-labelledby="dropdownMenuButton1"
              style={{display : isDropdownOpen ? "block" : "none"}}
              >
                {dropdownOptions?.map((val, index) => {
                  return (
                    <li
                      className="py-2 text-color-6"
                      id={`${val?.typeOfCodeID}#${val?.techstackName}#${val?.techstackLogo}`}
                      // value={`${val?.typeOfCodeID}#${val?.techStackName}`}techstackID
 
                      key={val?.techstackID}
                      onClick={(e) => {
                        // if (e.target.value) {
                        sethideError(false);
                        let values = e.target?.id?.split("#");
 
                        // if (values[] == "Select") {
                        //   return null;
                        // } else {
 
                        setFieldValues((pre) => ({
                          ...pre,
                          ["text"]: values[1],
                          ["imageUrl"]: values[2],
                        }));
                     
 
                        // seterror("");
                        record.handleFieldValues(
                          recordData.labelName,
                          values[1],
                          recordData.inputValueOrder,
                          recordData.required,
                          values[0]
                        );
 
                        // }
 
                        // } else {
                        //   sethideError(true)
                        // }
                        setIsDropdownOpen(false);
                      }}
                    >
 
                      <a
                        className="dropdown-item custom-dropdown-li font-14 font-medium py-2 text-color-6"
                        id={`${val?.typeOfCodeID}#${val?.techstackName}#${val?.techstackLogo}`}
                        style={{cursor:'pointer'}}
                      >
                        <span
                          className="me-3 text-color-2"
                          id={`${val?.typeOfCodeID}#${val?.techstackName}#${val?.techstackLogo}`}
                        >
                          <img
                            className="template-dropdown-icon"
                            src={val.techstackLogo}
                            alt="js-icon"
                            id={`${val?.typeOfCodeID}#${val?.techstackName}#${val?.techstackLogo}`}
                          />
                        </span>
                        {val?.techstackName}
                      </a>
                    </li>
                  );
                })}
                {/**
                  *
               <option
                 className={recordData == null ? "border-bottom font-14 py-2" : "py-2"}
                 id={recordData?.labelName}
                 value={`${val?.typeOfCodeID}#${val?.techStackName}`}
               >
               <span className="me-3">
                 <img className="template-dropdown-icon" src={val.techStackLogo} alt="js-icon" />
     
               </span>
               
                 {val.techStackName}
               </option>
                  */}
                     {/* <li className="py-2"><a className="dropdown-item custom-dropdown-li font-14 font-medium py-2 color-secondary-black" > <span className="me-3"><img className="template-dropdown-icon" src="images/react-js-icon.svg" alt="js-icon" /></span>React JS</a></li>
                 <li className="py-2"><a className="dropdown-item custom-dropdown-li font-14 font-medium py-2 color-secondary-black" > <span className="me-3"><img className="template-dropdown-icon" src="images/linux-icon.svg" alt="linux-icon" /></span>Linux</a></li>
                 <li className="py-2"><a className="dropdown-item custom-dropdown-li font-14 font-medium py-2 color-secondary-black" > <span className="me-3"><img className="template-dropdown-icon" src="images/angular-icon.svg" alt="angular-icon" /></span>Angular</a></li> */}
              </ul>)}
            </div>
          )}
          {/* <span
            className="mandatory-star font-14 font-medium mb-0"
            style={{ color: "red" }}
          >
            {record?.stackError}
          </span> */}
        </div>
      );
    } else {
      return (
        <div className="dropdown w-100">
          <select
            className="form-select font-14 font-medium theme-focus input-field custom-border-3 text-color-4 bg-color-4"
            aria-label="Default select example"
            id="Tech-Stack"
            style={{cursor:"pointer"}}
            onClick={(e) => {
              if (e.target.value) {
                sethideError(false);
                record.handleFieldValues(
                  recordData.labelName,
                  e.target.value,
                  recordData.inputValueOrder,
                  recordData.required
                );
              }
              e.currentTarget.classList.toggle('open');
            }}
          >
            <option selected value="" >Select</option>
            {dropdownOptions?.map((val, index) => {
              return (
                <option
                  className="py-2"
                  id={val.choicesID}
                  value={val.choiceName}
                >
                  {val.choiceName}
                </option>
              );
            })}
          </select>
        </div>
      );
    }
  };
  /**
   * PS_AAI_26 to PS_AAI_30 ,Call the bindError function and bind the respective error to it
   * @returns error
   */
  const bindError = () => {
    if (recordData) {
      const err = `${recordData.inputValueOrder}`;
 
      if (record.validationErrors) {
        return (
          <>
            {hideError ? (
              <span
                className="mandatory-star font-14 font-medium mb-0"
                style={{ color: "red" }}
              >
                {record.validationErrors[err]}
              </span>
            ) : null}
          </>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  return (
    <>
      {recordData && recordData.labelName ? (
        <label
          class="form-label font-15 font-semibold text-color-2"
          for="Tech-Stack"
        >
          {recordData.labelName}
          {record && recordData?.required == "true" ? (
            <span class="color-red">*</span>
          ) : null}
        </label>
      ) : null}
      {dropdownOptions?.length > 0 ? bindDropdown() : null}
 
      {bindError()}
    </>
  );
}