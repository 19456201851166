import React, { useEffect } from "react";
export function Error(props) {
  useEffect(() => {
    props?.setErrorPage(true)
  }, [])
  return (
    <div>
      <meta charSet="UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>404</title>
      <link href="css/commontheme.css" rel="stylesheet" />
      <link href="css/font.css" rel="stylesheet" />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 text-center mt-4">
            <img src="images/404.svg" className="w-75" alt={404} />
            <p className="font20 font-semibold text-primary mt-4">
              It seems there is something wrong! will be alright soon.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Error;
