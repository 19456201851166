//PC_FORM_01
//Necessary imports are made here
import { useEffect, useState, useContext, useRef } from "react";
import { getBindParams, insertModelConfig } from "../AI Service/Api";
import UserContext from "../Auth/useContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getModelConfig } from "../AI Service/Api";
import { isAuth } from "../Auth/authConfig";
import Loader from "../Other-Components/loader";
import { useMsal, useAccount, useIsAuthenticated } from "@azure/msal-react";
import { left } from "@popperjs/core";

export function LlmConfigForm({ navOpen }) {
  //(navOpen, "navOpennnn")
  //PC_FORM_03
  //Necessary state variables are initialized here
  const [modelType, setModelType] = useState("");
  const [authTypeData, setAuthTypeData] = useState("");
  const [region, setRegion] = useState("");
  const [modelName, setModelName] = useState("");
  const [model, setModel] = useState("");
  const [awsOptions, setAwsOptions]=useState([])

  const [selectedModelType, setSelectedModelType] = useState("");
  const [selectedAuthTypeData, setSelectedAuthTypeData] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedModelName, setSelectedModelName] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [displayName, setDisplayName] = useState("")

  const [tableData, setTableData] = useState([]);
  const [regionMapping, setRegionMapping] = useState([]);

  const [newEndpoint, setNewEndpoint] = useState("");
  const [newApikey, setNewApikey] = useState("");
  const [newUsername, setNewUsername] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newAccessKey, setNewAccessKey] = useState("");
  const [newSecretAccessKey, setNewSecretAcessKey] = useState("");
  const [newRegion, setNewRegion] = useState("");


  // drop down states
  const [chooseModelDD, setChooseModelDD] = useState(false)
  const [modelNameDD, setModelNameDD] = useState(false)
  const [regionDD, setRegionDD] = useState(false)
  const [authTypeDD, setAuthTypeDD] = useState(false)
  const [modelTypeDD, setModelTypeDD] = useState(false)

  const ref1 = useRef("");
  const ref2 = useRef("");
  const ref3 = useRef("");
  const ref4 = useRef("");
  const ref5 = useRef("");
  const ref6 = useRef("");
  const ref7 = useRef("");
  const inputRef = useRef(null);
  
  const errorObj = {
    modelTypeError: "",
    authTypeError: "",
    regionError: "",
    modelNameError: "",
    modelError: "",
    modelDescriptionError: "",
    displayNameError: "",

    usernameError: "",
    passwordError: "",
    apikeyError: "",
    endpointError: "",
    accessKeyError: "",
    secretAccessKeyError: "",
    awsRegionError: ""
  };

  const authTypeObj = {
    authTypeID: "",
    modelEndpoint: "",
    APIKey: "",
    userName: "",
    password: "",
    accessKey: "",
    secretAccessKey: "",
    awsRegion: ""
  };

  const llmConfigObj = {
    authType: [],
    modelConfiguration: {
      modelConfigurationID: "",
      modelDescription: "",
      modelType: "",
      modelEndpoint: " ",
      authTypeID: "",
      authType: "",
      region: "",
      modelName: "",
      model: "",
      displayName: ""
    },
    modelTypeID: "",
    createdBy: "Prasanth.Chinnaraj@avasoft.com"
  }

  const [llmConfig, setllmConfig] = useState(llmConfigObj);
  const [authType, setAuthType] = useState(authTypeObj);
  const [errorData, setErrorData] = useState(errorObj)
  const [disableEdit, setDisableEdit] = useState(false)

  const [show, setShow] = useState("");

  const { accounts } = useMsal();
  const userDetails = useContext(UserContext);
  const history = useHistory();
  let [isLoading, setIsLoading] = useState(false);
  const search = window.location.search;
  const url = new URLSearchParams(search).get("modelConfigurationID")
  const [identity, setIdentity] = useState(url);
  let [flag, setflag] = useState("");


  useEffect(() => {

    //(llmConfig, "buahaha")
  }, [llmConfig])

  useEffect(() => {   if (inputRef.current) {     inputRef.current.value = '';     setSelectedModelName('');     setllmConfig({       ...llmConfig,       modelConfiguration: {         ...llmConfig.modelConfiguration,         modelName: '',       },     });   } }, [selectedModelType]);

  //PC_FORM_07
  //This useEffect routes to the login page if the user is not authorized
  useEffect(() => {
    if (!isAuth(accounts)) {
      history.push("/");
    }
  }, []);

  //PC_FORM_06
  //This useEffect is used to get the options to be binded in the dropdown
  useEffect(() => {
    //;
    // setIsLoading(false)
    fetchBindParams();
    if (identity !== null) {
      fetchOneModelConfig()
    }
  }, [identity]);

  //PC_FORM_08
  //This useEffect is used to clear the input fields on changing the options in the dropdown and updating the llmConfig object
  useEffect(() => {
    setTableData([])
    setSelectedModel("")
    setAuthType(authTypeObj);
    setSelectedModelName("")
    // setllmConfig({
    //   ...llmConfig, modelConfiguration: {
    //     ...llmConfig.modelConfiguration,
    //     modelName: ""
    //   }
    // })
    setllmConfig(prevConfig => ({
      ...prevConfig,
      authType: []
    }));
  }, [selectedModelType])

  useEffect(()=>{
    setAuthType(authTypeObj);
    setllmConfig(prevConfig => ({
      ...prevConfig,
      authType: []
    }));
  }, [selectedAuthTypeData])

  //PC_FORM_09
  //This useEffect is used to get unique regions to be binded in the dropdown
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Extract unique regions with their IDs
        const uniqueRegionsMap = new Map();
        regionMapping.forEach(item => {
          uniqueRegionsMap.set(item.region_id, item.region_name);
        });
        const uniqueRegions = Array.from(uniqueRegionsMap, ([region_id, region_name]) => ({
          region_id,
          region_name
        }));
        setRegion(uniqueRegions);
      } catch (error) {
        console.error('Error fetching dropdown data:', error);
      }
    };
    fetchData();
  }, [regionMapping]);

  //PC_FORM_10
  //This useEffect is used to get unique model names to be binded in the dropdown
  useEffect(() => {
    if (selectedRegion) {
      const uniqueModelNamesMap = new Map();
      regionMapping.filter(item => item.region_name === selectedRegion).forEach(item => {
        uniqueModelNamesMap.set(item.model_name_id, item.model_name)
      })
      const uniqueModelNames = Array.from(uniqueModelNamesMap, ([model_name_id, model_name]) => ({
        model_name_id, model_name
      }))
      setModelName(uniqueModelNames);
      setSelectedModelName('');
      setSelectedModel('')
      setModel([]);
    }
  }, [selectedRegion]);

  //PC_FORM_11
  //This useEffect is used to get unique models to be binded in the dropdown
  useEffect(() => {
    if (selectedModelName) {
      const uniqueModelsMap = new Map();
      regionMapping.filter(item => item.region_name === selectedRegion && item.model_name === selectedModelName).forEach(item => {
        uniqueModelsMap.set(item.model_id, item.model)
      })
      const uniqueModels = Array.from(uniqueModelsMap, ([model_id, model]) => ({
        model_id, model
      }))
      setSelectedModel('')
      setModel(uniqueModels);
    }
  }, [selectedRegion, selectedModelName]);

  useEffect(() => {
    if (llmConfig.modelConfiguration) {
      setSelectedRegion(llmConfig.modelConfiguration.region);
      setSelectedModelName(llmConfig.modelConfiguration.modelName);
      setSelectedModel(llmConfig.modelConfiguration.model);
      // setllmConfig(...llmConfig.authType)
    }
  }, [llmConfig]);

  //PC_FORM_12 to PC_FORM_24
  //This function is used to fetch the options to binded in the dropdown
  const fetchBindParams = async () => {
    try {
      //;
      setIsLoading(false);
      const response = await getBindParams({});
      //(response.data, "fetchbindparams")
      setAuthTypeData(response.data.AuthType)
      setModelType(response.data.ModelType)
      setModelName(response.data.modelName)
      setModel(response.data.model)
      setRegionMapping(response.data.region_mapping)
      setAwsOptions(response.data.bedrock_models)
      setIsLoading(true);
    } catch (error) {
      //("Fetch Bind Params:", error);
    }
  };
  async function fetchOneModelConfig() {
    //;
    let obj = {
      modelConfigurationID: identity,
      createdBy: userDetails.email
    }
    //(obj, "objobj")
    // setIsLoading(false)S
    let result = await getModelConfig(obj);

    //(result.data,result.data.modelConfiguration, "getModelConfig");

    // Update llmConfig with fetched data
    let updatedLlmConfig = {
      ...llmConfigObj,
      authType: result.data.authType,
      modelConfiguration: result.data.modelConfiguration,
      modelTypeID: result.data.modelTypeID
      // createdBy: result.data.createdBy
    };
    //(llmConfig,updatedLlmConfig, "afterupdating")


    setllmConfig(updatedLlmConfig);
    //   setllmConfig(prevConfig => ({
    //     ...prevConfig,
    //     authType:result.data.authType
    // }))
    // setllmConfig(...llmConfig, )
    setSelectedModelType(result.data.modelTypeName);
    setSelectedAuthTypeData(result.data.authType[0].authTypeName);
    setSelectedRegion(result.data.modelConfiguration.region)
    setSelectedModelName(result.data.modelConfiguration.modelName)
    setSelectedModel(result.data.modelConfiguration.model)

    setTableData(result.data.authType)
    // setIsLoading(true)
  }
  //(llmConfig, "afterupdating")
  //PC_FORM_25 to PC_FORM_39
  //This function is used to display the validation errors and inserts the model configurations in the database
//PC_FORM_25 to PC_FORM_39
  //This function is used to display the validation errors and inserts the model configurations in the database
  async function validateModelConfig(id) {
    ;
    let flag = 0;
    let validateObj = {
      modelTypeError: "",
      authTypeError: "",
      regionError: "",
      modelNameError: "",
      modelError: "",
      modelDescriptionError: "",
      displayNameError: "",
      usernameError: "",
      passwordError: "",
      apikeyError: "",
      endpointError: "",
      accessKeyError: "",
      secretAccessKeyError: "",
      awsRegionError: ""
    };
 
    const { modelConfiguration, authType } = llmConfig;
 
    // Common validation checks
    if (!modelConfiguration.modelType) {
      validateObj.modelTypeError = "Select the model Type";
      flag++;
    }
 
    if (!modelConfiguration.modelDescription) {
      validateObj.modelDescriptionError = "Model Description is required";
      flag++;
    }
 
    if (!modelConfiguration.modelName) {
      validateObj.modelNameError = "Model Name is required";
      flag++;
    }
 
    if (!modelConfiguration.displayName) {
      validateObj.displayNameError = "Display name is required";
      flag++;
    }
 
    if (!modelConfiguration.region) {
      validateObj.regionError = "Region is required";
      flag++;
    }
 
    if (!modelConfiguration.model) {
      validateObj.modelError = "Model is required";
      flag++;
    }
 
    if (!modelConfiguration.authTypeID) {
      validateObj.authTypeError = "AuthType is required";
      flag++;
    }
 
    // Specific validation for Open AI and Azure Open AI

  if (modelConfiguration.modelType === 'Open AI' || modelConfiguration.modelType === 'Azure Open AI') {
 
      if (!modelConfiguration.region) {
        validateObj.regionError = "Region is required";
        flag++;
      }
 
      if (!modelConfiguration.model) {
        validateObj.modelError = "Model is required";
        flag++;
      }
 
      if (!modelConfiguration.authTypeID) {
        validateObj.authTypeError = "AuthType is required";
        flag++;
      }
    }
 
    // Specific validation for AWS Bedrock
    if (modelConfiguration.modelType === 'AWS Bedrock') {
    //   if (!authType || authType.length === 0 || !authType[0].accessKey) {
    //     validateObj.accessKeyError = "Access Key is required";
    //     flag++;
    //   }
    //   if (!authType || authType.length === 0 || !authType[0].secretAccessKey) {
    //     validateObj.secretAccessKeyError = "Secret Access Key is required";
    //     flag++;
    //   }
    //   if (!authType || authType.length === 0 || !authType[0].awsRegion) {
    //     validateObj.awsRegionError = "AWS Region is required";
    //     flag++;
    //   }
      if (!modelConfiguration.region) {
        validateObj.regionError = "Region is required";
        flag--;
      }
 
      if (!modelConfiguration.model) {
        validateObj.modelError = "Model is required";
        flag--;
      }
 
      if (!modelConfiguration.authTypeID) {
        validateObj.authTypeError = "AuthType is required";
        flag--;
      }
    }
 
    setflag(flag);
    setErrorData(validateObj);

    const updatedConfig = {
      ...llmConfig,
      authType: tableData,
      modelConfiguration: {
        ...llmConfig.modelConfiguration
      }
    };

    if(tableData.length==0 && modelConfiguration.modelType === 'AWS Bedrock' ){
      validateObj.accessKeyError = "Access Key is required";
      flag++;
      validateObj.secretAccessKeyError = "Secret Access Key is required";
      flag++;
      validateObj.awsRegionError = "AWS Region is required";
      flag++;
    }

   
    if(tableData.length==0 && (modelConfiguration.modelType === 'Open AI' || modelConfiguration.modelType === 'Azure Open AI')){
      if (modelConfiguration.authType === 'API Key') {
          validateObj.endpointError = "Endpoint is required";
          flag++;
          validateObj.apikeyError = "APIKey is required";
          flag++;
    }
    if (modelConfiguration.authType === 'Basic Auth') {
        validateObj.usernameError = "Username is required";
        flag++;
        validateObj.passwordError = "Password is required";
        flag++;
    }
  }


 
    if (flag === 0) {
      // Ensure `llmConfig` is a structured copy if you're manipulating it directly

 
      // Ensure `llmConfig` is a structured copy if you're manipulating it directly
      let modelObject = { ...updatedConfig, createdBy: userDetails?.email };
 
      let response;
      if (!llmConfig.modelConfiguration.modelConfigurationID) {
        setIsLoading(false);
        response = await insertModelConfig(modelObject);
        setIsLoading(true);
      } else {
        setIsLoading(false);
        response = await insertModelConfig(modelObject);
        setIsLoading(true);
      }
      history.push("/llm-config-grid");
    }
  }

  //PC_FORM_39 to PC_FORM_41
  //This function is used to empty all the fields of the form and route to LLM Grid page
  const cancel = () => {
    history.push("/llm-config-grid")
    setllmConfig(llmConfigObj)
    setAuthType(authTypeObj)
    setModelType("")
    setModelName("")
    setModel("")
    setRegion("")
    setAuthTypeData("")
    setShow("")
    setDisplayName("")
  }

  //PC_FORM_63 to PC_FORM_66
  //This function is used to bind the options in the Model Type dropdown
  function bindModelType(modelType) {
    if (modelType) {
      return modelType?.map((value, index) => (
        <li value={value.modelTypeName} id={value.modelTypeID} key={index} class="font-14 font-medium text-color-2"
          on
          onClick={(e) => {
            setSelectedModelType(value.modelType);
            const modelTypeId = value.modelTypeID;
            const modelTypeName = value.modelType;
            setSelectedModelType(modelTypeName);
            setModelTypeDD(false)
            setllmConfig({
              ...llmConfig,
              modelTypeID: modelTypeId,
              modelConfiguration: {
                ...llmConfig.modelConfiguration,
                modelType: modelTypeName,
              },
            });
            //(value.modelType, "modelType")
          }} style={{ cursor: 'pointer' }}><a value={value.modelTypeName}
            id={value.modelTypeID}
            key={index} class="dropdown-item py-2 custom-dropdown-li custom-border-bottom text-color-2" style={{ color: "black" }} onClick={(e) => { setErrorData({ ...errorData, modelTypeError: "" }); setllmConfig({ ...llmConfig, modelTypeID: e.target.id }) }}>{value.modelType}
          </a>
        </li>
      ));
    }
  }

  //PC_FORM_67 to PC_FORM_70
  //This function is used to bind the options in the Authorization Type dropdown
  function bindAuthTypeData(authTypeData) {
    //(authTypeData, "authtYPEDATA")
    if (authTypeData) {
      return authTypeData?.map((value, index) => (
        <li value={value.authTypeID} id={value.authTypeID} key={index} class="font-14 font-medium text-color-2"
          onClick={(e) => {
            setSelectedAuthTypeData(value.authTypeName);
            setAuthTypeDD(false)
            setErrorData({ ...errorData, authTypeError: "" })
            //  setAuthType({ ...authType, authTypeID: value.authTypeID })
            setllmConfig({
              ...llmConfig,
              modelConfiguration: {
                ...llmConfig.modelConfiguration,
                authType: value.authTypeName,
                authTypeID: value.authTypeID,
              },
            })
          }} style={{ cursor: 'pointer' }}><a value={value.authTypeName}
            id={value.authTypeID}
            key={index} class="dropdown-item py-2 custom-dropdown-li custom-border-bottom text-color-2" onClick={(e) => {
            }}
          >{value.authTypeName}</a></li>
      ));
    }
  }
  // console.log(llmConfig.modelConfiguration,"modelconfigurat")
  //PC_FORM_71 to PC_FORM_73
  //This function is used to bind the options in the Regions dropdown
  const bindRegion = (region) => {
    if (region) {
      return region.map((value, index) => (
        <li
          key={index}
          className="font-14 font-medium color-black"
          onClick={() => {
            setSelectedRegion(value.region_name);
            setRegionDD(false)
            setErrorData({ ...errorData, regionError: "" });
            setllmConfig({
              ...llmConfig,
              modelConfiguration: {
                ...llmConfig.modelConfiguration,
                region: value.region_name,
              }
            });
          }} style={{ cursor: 'pointer' }}
        >
          <a
            className="dropdown-item py-2 custom-dropdown-li custom-border-bottom text-color-2"
            style={{ color: "black" }}
          >
            {value.region_name}
          </a>
        </li>
      ));
    }
    return null;
  };

  //PC_FORM_74 to PC_FORM_76
  //This function is used to bind the options in the Model Name dropdown
  // function bindModelName(modelName){
  //       if(selectedRegion){
  //           return modelName?.map((value, index) => (
  //             <li value={value.model_name} id={value.model_name_id} key={index} class="font-14 font-medium color-black" onClick={(e) => {
  //               setSelectedModelName(value.model_name);
  //             }}><a value={value.model_name}
  //               id={value.model_name_id}
  //               key={index} class="dropdown-item py-2 custom-dropdown-li border-bottom" style={{color:"black"}} onClick={(e) => { setErrorData({ ...errorData, modelNameError: "" }); 
  //               setllmConfig({ ...llmConfig, modelConfiguration: {
  //                      ...llmConfig.modelConfiguration,
  //                      modelName:value.model_name
  //              }}) }}>{value.model_name}
  //               </a>
  //               </li>
  //           ));
  //         }
  // }

  function bindModelName(modelName) {
  
    const options = selectedModelType === 'AWS Bedrock' ? awsOptions : modelName;
    //(options, "optionsoptions")
    if (options) {
      return options?.map((value, index) => (
        <li value={value.model_name} id={value.model_name_id} key={index} class="font-14 font-medium text-color-2" onClick={(e) => {
          setSelectedModelName(value.model_name);
          setModelNameDD(false)
        }} style={{ cursor: 'pointer' }}><a value={value.model_name}
          id={value.model_name_id}
          key={index} class="dropdown-item py-2 custom-dropdown-li custom-border-bottom text-color-2" style={{ color: "black" }} onClick={(e) => {
            setErrorData({ ...errorData, modelNameError: "" });
            setllmConfig({
              ...llmConfig, modelConfiguration: {
                ...llmConfig.modelConfiguration,
                modelName: value.model_name
              }
            })
          }}>{value.model_name}
          </a>
        </li>
      ));
    }

    return null;
  }

  //PC_FORM_77 to PC_FORM_79
  //This function is used to bind the options in the Choose Model dropdown
  function bindModel(model) {
    if (selectedModelName) {
      return model?.map((value, index) => (
        <li value={value.model} id={value.model_id} key={index} class="font-14 font-medium text-color-2" onClick={(e) => {
          setSelectedModel(value.model);
          setChooseModelDD(false)
        }} style={{ cursor: 'pointer' }}><a value={value.model}
          id={value.model_id}
          key={index} class="dropdown-item py-2 custom-dropdown-li text-color-2 custom-border-bottom" onClick={(e) => {
            setErrorData({ ...errorData, modelError: "" });
            setllmConfig({
              ...llmConfig, modelConfiguration: {
                ...llmConfig.modelConfiguration,
                model: value.model
              }
            })
          }}>{value.model}
          </a>
        </li>
      ));
    }
  }

  //PC_FORM_42 to PC_FORM_49
  //This function is used to add rows in the respective grid displayed
  const handleAddRow = () => {
    //;
    setDisableEdit(false)
    if (newEndpoint && newApikey) {
      const newRow = {
        APIKey: newApikey,
        modelEndpoint: newEndpoint
      };
      addAuthTypeRow(newRow, [ref1, ref2], [setNewEndpoint, setNewApikey]);
    }
    if (newUsername && newPassword) {
      const newRow = {
        userName: newUsername,
        password: newPassword
      };
      addAuthTypeRow(newRow, [ref3, ref4], [setNewUsername, setNewPassword]);
    }
    if (newAccessKey && newSecretAccessKey && newRegion) {
      const newRow = {
        accessKey: newAccessKey,
        secretAccessKey: newSecretAccessKey,
        awsRegion: newRegion
      };
      addAuthTypeRow(newRow, [ref5, ref6, ref7], [setNewAccessKey, setNewSecretAcessKey, setNewRegion]);
    }
  };

  const addAuthTypeRow = (rowData, refs, stateSetters) => {
    //;
    setErrorData(errorObj)
    setTableData(prevTableData => {
      const newTableData = [...prevTableData, rowData];
      return newTableData;
    });
    setllmConfig(prevConfig => ({
      ...prevConfig,
      authType: [...prevConfig.authType, rowData]
    }));
    refs.forEach(ref => ref.current.value = '');
    stateSetters.forEach(setter => setter(''));
  };

  //PC_FORM_50 to PC_FORM_53
  //This function is used to edit rows in the respective grid displayed
  const handleEditRow = (index) => {
    //;
    setDisableEdit(true)
    const selectedRowData = tableData[index];
    const updatedTableData = tableData.filter((_, i) => i !== index);
    setTableData(updatedTableData);
    //(updatedTableData, "updatedupdated")
    // Update authType based on the selected row data
    if (selectedRowData.newUsername && selectedRowData.newPassword) {
      const updatedAuthType = { ...authType, userName: selectedRowData.newUsername, password: selectedRowData.newPassword };
      setAuthType(updatedAuthType);
    } else if (selectedRowData.newAccessKey && selectedRowData.newSecretAccessKey && selectedRowData.newRegion) {
      const updatedAuthType = { ...authType, accessKey: selectedRowData.newAccessKey, secretAccessKey: selectedRowData.newSecretAccessKey, awsRegion: selectedRowData.newRegion };
      setAuthType(updatedAuthType);
    }

    // Set state variables for editing the row
    setNewEndpoint(selectedRowData.modelEndpoint);
    setNewApikey(selectedRowData.APIKey);
    setNewUsername(selectedRowData.userName);
    setNewPassword(selectedRowData.password);
    setNewAccessKey(selectedRowData.accessKey);
    setNewSecretAcessKey(selectedRowData.secretAccessKey);
    setNewRegion(selectedRowData.awsRegion)
  };

  //PC_FORM_54 to PC_FORM_62
  //This function is used to delete rows in the respective grid displayed
  const handleDeleteRow = (index) => {
    setTableData(prevTableData => {
      return prevTableData.filter((_, i) => i !== index);
    });
  };

  return (
    <>
      {!isLoading ? <Loader isLoading={isLoading} /> : (
        // <div>

        <>
          <div className={navOpen == "Close" ? "col-md-11 second-component-llmForm-nav-close" : "col-md-9 second-component-llmForm"} id="chat-history-center">
            <title>LLM Configurations</title>
            <div className="container-fluid llm-border">

              <div className="col-md-12">

                <div className="row">

                  <div className="col-md-12 mt-3" style={{ marginLeft: "4%" }}>

                    <h3 className="font-20 font-semibold text-color-1 mt-3 mb-3">LLM Configurations</h3>
                    <p className="font-15 font-medium text-color-13 mb-5">Customize your Language Model with ease using the LLM.</p>

                    {/*Forms starts here*/}
                    <div className="row d-flex justify-content-between mb-4">

                      {/*Model Type*/}
                      <div className="col-md-4 col-sm-12 mb-4">
                        <div className="dropdown card-fix-llm w-100" onMouseLeave={()=>setModelTypeDD(false)}>
                          <label htmlFor="model-type" className="form-label text-color-2 font-15 font-semibold">Model Type <span style={{ "color": "red" }}>*</span></label>
                          <button id="model-type" className="btn input-field font-14 font-regular border-radius-3 bg-color text-color-4 custom-border-3 cust-search-input w-100 d-flex justify-content-between theme-focus" type="button" onClick={(e) => { modelTypeDD ? setModelTypeDD(false) : setModelTypeDD(true) }}>
                            <span class="text-color-2" style={{ color: "text-color-9" }} value={llmConfig.modelTypeName}>{selectedModelType || 'Select'}</span>
                            <span className="ps-1">  <div
                            style={{ 
                              width: '10px',
                              height: '20px',
                              transform: modelTypeDD ? "rotate(180deg)" : "",
                              backgroundColor: "var(--select-arrow-color)",
                              WebkitMask: `url('images-new/dropdown-grey-icon.svg') no-repeat center / contain`,
                              mask: `url('images-new/dropdown-grey-icon.svg') no-repeat center / contain`,
                              transition: "transform 0.3s ease"
                            }} 
                            className="custom-dropdown-fix" 
                          /></span>
                          </button>
                          {modelTypeDD?<ul className="model-type dropdown-menu border-0 shadow-sm p-2 w-100 bg-color-2 " 
                          style={{
                            display : modelTypeDD ? "block" : "none" ,
                            maxHeight: "150%",  // Adjust this value as needed
                            overflowY: "auto"
                          }}
                          value={modelType.modelTypeName} >
                            {bindModelType(modelType)}
                          </ul>:null}

                          {errorData.modelTypeError ? (
                            <div className="span-color">
                              <h6>
                                {errorData.modelTypeError}
                              </h6>
                            </div>
                          ) : null}

                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12 mb-4">
                        <div className="dropdown card-fix-llm w-100">
                          <label htmlFor="end-point-config" className="form-label text-color-2 font-15 font-semibold">Display Name<span style={{ "color": "red" }}>*</span></label>
                          {/* <button id="end-point-config" className="btn input-field font-14 font-regular border-radius-3 bg-white w-100 d-flex justify-content-between" type="button" onClick={(e)=> {authTypeDD ? setAuthTypeDD(false) : setAuthTypeDD(true)}} aria-expanded="false">
                        <span className="color-grey-v3" style={{color:"black"}} value={llmConfig.authType}>{selectedAuthTypeData || 'Select'}</span>
                        <span className="ps-1"><img src="images-new/dropdown-grey-icon.svg" alt="dropdown-icon" /></span>
                      </button> */}
                          <input className="form-control input-field font-14  font-medium color-black border-radius-3  bg-color text-color-4 custom-border-3 cust-search-input resize-none theme-focus" style={{ cursor: "text" }} type="text" placeholder="Enter Display Name" id="description" defaultValue={""} value={llmConfig?.modelConfiguration?.displayName} onChange={(e) => {
                            setErrorData({ ...errorData, displayNameError: "" })
                            setllmConfig({
                              ...llmConfig,
                              modelConfiguration: {
                                ...llmConfig.modelConfiguration,
                                displayName: e.target.value,
                              },
                            });;
                          }} />

                          {errorData.displayNameError ? (
                            <div className="span-color">
                              <h6>
                                {errorData.displayNameError}
                              </h6>
                            </div>
                          ) : null}

                        </div>
                      </div>


                      {/*AuthType Details and Region dropdown*/}
                      {(selectedModelType !== 'AWS Bedrock') ? (
                        <>
                          {/*AuthType Details*/}
                          {/* <div className="col-md-4 col-sm-12 mb-4">
                    <div className="dropdown card-fix-llm w-100">
                      <label htmlFor="end-point-config" className="form-label text-color-2 font-15 font-semibold">Authorization Type <span style={{"color":"red"}}>*</span></label>
                      <button id="end-point-config" className="btn input-field font-14 font-regular border-radius-3 bg-white w-100 d-flex justify-content-between" type="button" onClick={(e)=> {authTypeDD ? setAuthTypeDD(false) : setAuthTypeDD(true)}} aria-expanded="false">
                        <span className="color-grey-v3" style={{color:"black"}} value={llmConfig.authType}>{selectedAuthTypeData || 'Select'}</span>
                        <span className="ps-1"><img src="images-new/dropdown-grey-icon.svg" alt="dropdown-icon" /></span>
                      </button>
                      <ul className="dropdown-menu border-0 shadow-sm p-2 w-100" style={{"display": authTypeDD ? "block" : "none"}}>
                          {bindAuthTypeData(authTypeData)}
                      </ul>
                     
                      {errorData.authTypeError ? (
                        <div className="span-color">
                           <h6>
                          {errorData.authTypeError}
                          </h6>
                        </div>
                      ) : null}
                   
                    </div>
                  </div> */}


                          {/*Regions*/}
                          <div className="col-md-4 col-sm-12 mb-4">
                            <div className="dropdown card-fix-llm w-100" onMouseLeave={()=>setRegionDD(false)}>
                              <label htmlFor="end-point-config" className="form-label text-color-2 font-15 font-semibold">Region <span style={{ "color": "red" }}>*</span></label>
                              <button id="end-point-config" className="btn input-field font-14 font-regular border-radius-3  bg-color text-color-4 custom-border-3 cust-search-input w-100 d-flex justify-content-between" type="button" onClick={(e) => { regionDD ? setRegionDD(false) : setRegionDD(true) }} >
                                <span className="text-color-2" style={{ color: "black" }} value={llmConfig.modelConfiguration.region}>{selectedRegion || 'Select'}</span>
                                <span className="ps-1"><div
                                style={{ 
                                  width: '10px',
                                  height: '20px',
                                  transform: regionDD? "rotate(180deg)" : "",
                                  backgroundColor: "var(--select-arrow-color)",
                                  WebkitMask: `url('images-new/dropdown-grey-icon.svg') no-repeat center / contain`,
                                  mask: `url('images-new/dropdown-grey-icon.svg') no-repeat center / contain`,
                                  transition: "transform 0.3s ease"
                                }} 
                                className="custom-dropdown-fix" 
                              /></span>
                              </button>
                              {regionDD ?<ul className="dropdown-menu border-0 shadow-sm p-2 w-100 bg-color-2 " 
                              style={{
                                display : regionDD ? "block" : "none" ,
                                maxHeight: "150%",  // Adjust this value as needed
                                overflowY: "auto"
                              }}
                              value={region.region_name}>
                                {bindRegion(region)}
                              </ul> : null}

                              {errorData.regionError ? (
                                <div className="span-color">
                                  <h6>
                                    {errorData.regionError}
                                  </h6>
                                </div>
                              ) : null}

                            </div>
                          </div>
                        </>
                      ) : null}

                  

          {(selectedModelType !== 'AWS Bedrock') ?(
            <>
                      {/*Model Names*/}
                      <div className="col-md-4 col-sm-12 mb-4">
                        <div className="dropdown card-fix-llm w-100" onMouseLeave={()=>setModelNameDD(false)}>
                          <label htmlFor="end-point-config" className="form-label text-color-2 font-15 font-semibold">Model Name <span style={{ "color": "red" }}>*</span></label>
                          <button id="end-point-config" className="btn input-field font-14 font-regular border-radius-3  bg-color text-color-4 custom-border-3 cust-search-input w-100 d-flex justify-content-between" type="button" onClick={(e) => { modelNameDD ? setModelNameDD(false) : setModelNameDD(true) }}>
                            <span className="text-color-2" style={{ color: "black" }} value={llmConfig.modelConfiguration.modelName}>{selectedModelName || 'Select'}</span>
                            <span className="ps-1"><div
                            style={{ 
                              width: '10px',
                              height: '20px',
                              transform: modelNameDD ? "rotate(180deg)" : "",
                              backgroundColor: "var(--select-arrow-color)",
                              WebkitMask: `url('images-new/dropdown-grey-icon.svg') no-repeat center / contain`,
                              mask: `url('images-new/dropdown-grey-icon.svg') no-repeat center / contain`,
                              transition: "transform 0.3s ease"
                            }} 
                            className="custom-dropdown-fix" 
                          /></span>
                          </button>
                          {modelNameDD?<ul className="dropdown-menu border-0 shadow-sm p-2 w-100 bg-color-2 "
                          style={{
                            display : modelNameDD ? "block" : "none" ,
                            maxHeight: "250%",  // Adjust this value as needed
                            overflowY: "auto"
                          }}
                          value={modelName.model_name}>
                            {bindModelName(modelName)}
                          </ul>:null}

                          {errorData.modelNameError ? (
                            <div className="span-color">
                              <h6>
                                {errorData.modelNameError}
                              </h6>
                            </div>
                          ) : null}

                        </div>
                      </div>
                      </>
                      ):(<>  <div className="col-md-4 col-sm-12 mb-4">
                      <div className="dropdown card-fix-llm w-100">
                        <label htmlFor="end-point-config" className="form-label text-color-2 font-15 font-semibold">Model ID<span style={{ "color": "red" }}>*</span></label>
                        {/* <button id="end-point-config" className="btn input-field font-14 font-regular border-radius-3 bg-white w-100 d-flex justify-content-between" type="button" onClick={(e)=> {authTypeDD ? setAuthTypeDD(false) : setAuthTypeDD(true)}} aria-expanded="false">
                      <span className="color-grey-v3" style={{color:"black"}} value={llmConfig.authType}>{selectedAuthTypeData || 'Select'}</span>
                      <span className="ps-1"><img src="images-new/dropdown-grey-icon.svg" alt="dropdown-icon" /></span>
                    </button> */}
                        <input className="form-control input-field font-14  font-medium color-black border-radius-3  bg-color text-color-4 custom-border-3 cust-search-input resize-none" style={{ cursor: "text" }} type="text" placeholder="Enter Model ID" id="description" defaultValue={""} ref={inputRef} value={selectedModelName} onChange={(e) => {
                          setErrorData({ ...errorData, modelNameError: "" })
                          setllmConfig({
                            ...llmConfig,
                            modelConfiguration: {
                              ...llmConfig.modelConfiguration,
                              modelName: e.target.value,
                            },
                          });;
                        }} />

                        {errorData.modelNameError ? (
                          <div className="span-color">
                            <h6>
                              {errorData.modelNameError}
                            </h6>
                          </div>
                        ) : null}

                      </div>
                    </div>
                    </>
                      )}





                      {/*Model Dropdown*/}
                      {(selectedModelType !== 'AWS Bedrock') ? (
                        <>
                          {/*Model*/}
                          <div className="col-md-4 col-sm-12 mb-4">
                            <div className="dropdown card-fix-llm w-100" onMouseLeave={()=>setChooseModelDD(false)}>
                              <label htmlFor="end-point-config" className="form-label text-color-2 font-15 font-semibold">Choose Model <span style={{ "color": "red" }}>*</span></label>
                              <button id="end-point-config" className="btn input-field font-14 font-regular border-radius-3  bg-color text-color-4 custom-border-3 cust-search-input w-100 d-flex justify-content-between" type="button"
                                //  data-bs-toggle="dropdown" aria-expanded="false"
                                onClick={(e) => { chooseModelDD ? setChooseModelDD(false) : setChooseModelDD(true) }}

                              >
                                <span className="text-color-2" style={{ color: "black" }} value={llmConfig.modelConfiguration.model}>{selectedModel || 'Select'}</span>
                                <span className="ps-1"><div
                                style={{ 
                                  width: '10px',
                                  height: '20px',
                                  transform: chooseModelDD ? "rotate(180deg)" : "",
                                  backgroundColor: "var(--select-arrow-color)",
                                  WebkitMask: `url('images-new/dropdown-grey-icon.svg') no-repeat center / contain`,
                                  mask: `url('images-new/dropdown-grey-icon.svg') no-repeat center / contain`,
                                  transition: "transform 0.3s ease"
                                }} 
                                className="custom-dropdown-fix" 
                              /></span>
                              </button>
                              {chooseModelDD?<ul className="dropdown-menu border-0 shadow-sm p-2 w-100 bg-color-2 "
                              style={{
                                display : chooseModelDD ? "block" : "none" ,
                                maxHeight: "250%",  // Adjust this value as needed
                                overflowY: "auto"
                              }}
                              value={modelName.model} >
                                {bindModel(model)}
                              </ul>:null}

                              {errorData.modelError ? (
                                <div className="span-color">
                                  <h6>
                                    {errorData.modelError}
                                  </h6>
                                </div>
                              ) : null}

                            </div>
                          </div>
                          {/*AuthType Details*/}
                          <div className="col-md-4 col-sm-12 mb-4">
                            <div className="dropdown card-fix-llm w-100" onMouseLeave={()=>setAuthTypeDD(false)}>
                              <label htmlFor="end-point-config" className="form-label text-color-2 font-15 font-semibold">Authorization Type <span style={{ "color": "red" }}>*</span></label>
                              <button id="end-point-config" className="btn input-field font-14 font-regular border-radius-3  bg-color text-color-4 custom-border-3 cust-search-input w-100 d-flex justify-content-between" type="button" onClick={(e) => { authTypeDD ? setAuthTypeDD(false) : setAuthTypeDD(true) }} >
                                <span className="text-color-2" style={{ color: "black" }} value={llmConfig.authType}>{selectedAuthTypeData || 'Select'}</span>
                                <span className="ps-1"><div
                                style={{ 
                                  width: '10px',
                                  height: '20px',
                                  transform: authTypeDD ? "rotate(180deg)" : "",
                                  backgroundColor: "var(--select-arrow-color)",
                                  WebkitMask: `url('images-new/dropdown-grey-icon.svg') no-repeat center / contain`,
                                  mask: `url('images-new/dropdown-grey-icon.svg') no-repeat center / contain`,
                                  transition: "transform 0.3s ease"
                                }} 
                                className="custom-dropdown-fix" 
                              /></span>
                              </button>
                              {authTypeDD?<ul className="dropdown-menu border-0 shadow-sm p-2 w-100 bg-color-2 "
                              style={{
                                display : authTypeDD ? "block" : "none" ,
                                maxHeight: "150%",  // Adjust this value as needed
                                overflowY: "auto"
                              }}>
                                {bindAuthTypeData(authTypeData)}
                              </ul>:null}

                              {errorData.authTypeError ? (
                                <div className="span-color">
                                  <h6>
                                    {errorData.authTypeError}
                                  </h6>
                                </div>
                              ) : null}

                            </div>
                          </div>
                        </>
                      ) : null}

                      {/*Model Description*/}
                      <div className="col-md-12 col-sm-12 mb-3">
                        <label htmlFor="description" className="form-label text-color-2 font-15 font-semibold">Model Description <span style={{ "color": "red" }}>*</span></label>
                        <textarea className="form-control input-field font-14 font-regular border-radius-3  bg-color text-color-4 custom-border-3 cust-search-input resize-none" rows={4} placeholder="Enter Model Description" id="description" defaultValue={""} value={llmConfig?.modelConfiguration?.modelDescription} onChange={(e) => {
                          setErrorData({ ...errorData, modelDescriptionError: "" })
                          setllmConfig({
                            ...llmConfig,
                            modelConfiguration: {
                              ...llmConfig.modelConfiguration,
                              modelDescription: e.target.value,
                            },
                          });;
                        }}
                        />
                        {errorData.modelDescriptionError ? (
                          <div className="span-color">
                            <h6>
                              {errorData.modelDescriptionError}
                            </h6>
                          </div>
                        ) : null}
                      </div>

                    </div>

                    {/*Azure Open AI Authorization Table using Basic Auth*/}
                    {selectedAuthTypeData === 'Basic Auth' && (selectedModelType === 'Azure Open AI' || selectedModelType === 'Open AI') ? (
                      <div className="col-md-12">
                        <div className="table-responsive rounded-3 transparent-grp mb-5">
                          <table className="table theme-table font-regular font-14 mb-0">
                            <thead>
                              <tr>
                                <th className="ps-4">Username</th>
                                <th className="ps-4">Password</th>
                                <th className="text-center">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="ps-4">
                                  <input type="text" className="form-control inline-table-input-w input-field font-14 font-regular border-radius-3 bg-color text-color-4 custom-border-3 cust-search-input" id="value" placeholder="Enter Username" ref={ref3} value={newUsername} onChange={(e) => setNewUsername(e.target.value)} />
                                  {errorData.usernameError ? (
                                    <div className="span-color">
                                      <h6>
                                        {errorData.usernameError}
                                      </h6>
                                    </div>
                                  ) : null}
                                </td>

                                {/* {errorData.usernameError ? (
                            <div className="span-color">
                               <h6>
                              {errorData.usernameError}
                              </h6>
                            </div>
                          ) : null} */}

                                <td className="ps-4">
                                  <input type="etext" className="form-control inline-table-input-w input-field font-14 font-regular border-radius-3 bg-color text-color-4 custom-border-3 cust-search-input" id="value" placeholder="Enter Password" ref={ref4} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                                  {errorData.passwordError ? (

                                    <div className="span-color">
                                      <h6>
                                        {errorData.passwordError}
                                      </h6>
                                    </div>
                                  ) : null}
                                </td>
                                <td className="text-center">
                                  <button className="btn purple-plus-icon-decor" onClick={handleAddRow}><img class="purple-plus-icon" src="images/purple-plus-icon.svg" /></button>
                                </td>

                                {/* {errorData.passwordError ? (

                            <div className="span-color">
                              <h6>
                              {errorData.passwordError}
                              </h6>
                            </div>
                          ) : null} */}

                              </tr>
                              {tableData.map((rowData, index) => (
                                <tr key={index}>
                                  <td className="ps-4">{rowData.userName}</td>
                                  <td className="ps-4">{rowData.password}</td>
                                  <td className="text-center">
                                    <button className="btn me-3 p-0" disabled={disableEdit} onClick={() => handleEditRow(index)}><img src="images-new/edit-icons.svg" alt="Edit" /></button>
                                    <button className="btn  p-0" disabled={disableEdit} onClick={() => handleDeleteRow(index)}><img src="images-new/delete-icons.svg" alt="Delete" /></button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : null}

                    {/*Azure Open AI Authorization Table using API Key*/}
                    {selectedAuthTypeData === 'API Key' && (selectedModelType === 'Azure Open AI' || selectedModelType === 'Open AI') ? (
                      <div className="col-md-12">
                        <div className="table-responsive rounded-3 transparent-grp mb-5">
                          <table className="table theme-table font-regular font-14 mb-0">
                            <thead>
                              <tr>
                                <th className="ps-4">End Point</th>
                                <th className="ps-4">API Key</th>
                                <th className="text-center">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="ps-4">
                                  <input type="text" className="form-control inline-table-input-w input-field font-14 font-regular border-radius-3 bg-color text-color-4 custom-border-3 cust-search-input" id="value" placeholder="Enter End Point" ref={ref1} value={newEndpoint} onChange={(e) => setNewEndpoint(e.target.value)} />
                                  {errorData.endpointError ? (
                                    <div className="span-color">
                                      <h6>
                                        {errorData.endpointError}
                                      </h6>
                                    </div>
                                  ) : null}
                                </td>

                                {/* {errorData.endpointError ? (
                          <div className="span-color">
                            <h6>
                            {errorData.endpointError}
                            </h6>
                          </div>
                        ) : null} */}

                                <td className="ps-4">
                                  <input type="text" className="form-control inline-table-input-w input-field font-14 font-regular border-radius-3 bg-color text-color-4 custom-border-3 cust-search-input" id="value" placeholder="Enter API Key" ref={ref2} value={newApikey} onChange={(e) => setNewApikey(e.target.value)} />
                                  {errorData.apikeyError ? (
                                    <div className="span-color">
                                      <h6>
                                        {errorData.apikeyError}
                                      </h6>
                                    </div>
                                  ) : null}
                                </td>

                                {/* {errorData.apikeyError ? (
                          <div className="span-color">
                            <h6>
                            {errorData.apikeyError}
                            </h6>
                          </div>
                        ) : null} */}

                                <td className="text-center">
                                  <button onClick={handleAddRow} className="btn purple-plus-icon-decor"><img class="purple-plus-icon" src="images/purple-plus-icon.svg" /></button>
                                  
                                </td>
                              </tr>
                              {tableData.map((rowData, index) => (
                                <tr key={index}>
                                  <td className="ps-4">{rowData.modelEndpoint}</td>
                                  <td className="ps-4">{rowData.APIKey}</td>
                                  <td className="text-center">
                                    <button className="btn me-3 p-0" disabled={disableEdit} onClick={() => handleEditRow(index)}><img src="images-new/edit-icons.svg" alt="Edit" /></button>
                                    <button className="btn p-0" disabled={disableEdit} onClick={() => handleDeleteRow(index)}><img src="images-new/delete-icons.svg" alt="Delete" /></button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : null}

                    {/*Bedrock Authorization Table*/}
                    {(selectedModelType === 'AWS Bedrock') ? (
                      <div className="col-md-12">
                        <div className="table-responsive rounded-3 transparent-grp mb-5">
                          <table className="table theme-table font-regular font-14 mb-0">
                            <thead>
                              <tr>
                                <th className="ps-4">Access Key</th>
                                <th className="ps-4">Secret Access Key</th>
                                <th className="ps-4">AWS Region</th>
                                <th className="text-center">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="ps-4">
                                  <input type="text" className="form-control inline-table-input-w input-field font-14 font-regular border-radius-3 bg-color text-color-4 custom-border-3 cust-search-input" id="value" placeholder="Enter Access Key" ref={ref5} value={newAccessKey} onChange={(e) => setNewAccessKey(e.target.value)} />
                                  {errorData.accessKeyError ? (
                                    <div className="span-color">
                                      <h6>
                                        {errorData.accessKeyError}
                                      </h6>
                                    </div>
                                  ) : null}
                                </td>

                                {/* {errorData.accessKeyError ? (
                          <div className="span-color">
                            <h6>
                            {errorData.accessKeyError}
                            </h6>
                          </div>
                        ) : null} */}

                                <td className="ps-4">
                                  <input type="text" className="form-control inline-table-input-w input-field font-14 font-regular border-radius-3 bg-color text-color-4 custom-border-3 cust-search-input" id="value" placeholder="Enter Secret Access Key" ref={ref6} value={newSecretAccessKey} onChange={(e) => setNewSecretAcessKey(e.target.value)} />
                                  {errorData.secretAccessKeyError ? (
                                    <div className="span-color">
                                      <h6>
                                        {errorData.secretAccessKeyError}
                                      </h6>
                                    </div>
                                  ) : null}
                                </td>

                                {/* {errorData.secretAccessKeyError ? (
                          <div className="span-color">
                            <h6>
                            {errorData.secretAccessKeyError}
                            </h6>
                          </div>
                        ) : null} */}

                                <td className="ps-4">
                                  <input type="text" className="form-control inline-table-input-w input-field font-14 font-regular border-radius-3 bg-color text-color-4 custom-border-3 cust-search-input" id="value" placeholder="Choose region" ref={ref7} value={newRegion} onChange={(e) => setNewRegion(e.target.value)} />
                                  {errorData.awsRegionError ? (
                                    <div className="span-color">
                                      <h6>
                                        {errorData.awsRegionError}
                                      </h6>
                                    </div>
                                  ) : null}
                                </td>

                                {/* {errorData.regionError ? (
                          <div className="span-color">
                            <h6>
                            {errorData.regionError}
                            </h6>
                          </div>
                        ) : null} */}

                                <td className="text-center">
                                  <button onClick={handleAddRow} className="btn purple-plus-icon-decor"><img class="purple-plus-icon" src="images/purple-plus-icon.svg" /></button>
                                </td>
                              </tr>
                              {tableData.map((rowData, index) => (
                                <tr key={index}>
                                  <td className="ps-4">{rowData.accessKey}</td>
                                  <td className="ps-4">{rowData.secretAccessKey}</td>
                                  <td className="ps-4">{rowData.awsRegion}</td>
                                  <td className="text-center">
                                    <button className="btn me-3 p-0" disabled={disableEdit} onClick={() => handleEditRow(index)}><img src="images-new/edit-icons.svg" alt="Edit" /></button>
                                    <button className="btn" disabled={disableEdit} onClick={() => handleDeleteRow(index)}><img src="images-new/delete-icons.svg" alt="Delete" /></button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : null}

                    {/*buttons*/}
                    <div className="row d-flex justify-content-center custom-bg mb-5">
                      <div className="col-md-12 py-3">
                        <div className="d-flex justify-content-end">
                          <div>
                            <button type="button" disabled={disableEdit} className="btn custom-outline-btn font-14 font-semibold border-radius-5 me-3 py-2 px-4" onClick={() => { cancel() }}>Cancel</button>
                            <button type="button" disabled={disableEdit} className="btn primary-btn font-14 font-semibold border-radius-5 color-white py-2 px-4" onClick={() => { validateModelConfig() }}>Save</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*buttons*/}

                  </div>

                </div>

              </div>
            </div>
          </div>
        </>
        // </div>
      )}
    </>
  )
}