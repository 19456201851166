import { useEffect, useState, useContext } from "react"; //PS_CGAI_8.1 import the necessary imports
import toggleContext from "./Context";

/**
 * PS_CGAI_8.2 to PS_CGAI_8.7 bind the values from the object and returns the text field
 * PS_CGAI_8.8 to PS_CGAI_8.11 using onchenge get the values and pass to the parent component using hansleFieldValues()
 * @param {props from the parent component as object} param0
 * @returns the text field to the parent component
 */
export function Text({ record }) {
  const [recordData, setRecordData] = useState();
  const [hideError, sethideError] = useState(true);
  const toggle = useContext(toggleContext);
  useEffect(() => {
    if (record) {
      setRecordData(record.record);
    }
  }, [record]);
  const bind = () => {
    // console.log(record)
    if (recordData) {
      const err = `${recordData.inputValueOrder}`;
     
      if (record?.validationErrors) {
      
        return (
          <>
            {hideError ? (
              <span
                className="mandatory-star font-14 font-medium mb-0"
                style={{ color: "red" }}
              >
                {record.validationErrors[err]}
              </span>
            ) : null}
          </>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  return (
    <>
      {record && recordData?.labelName ? (
        <label
          class="font-15 font-semibold text-color-14 "
          for="Code-similar"
        >
          {recordData.labelName}
          {record && recordData?.required == "true" ? (
            <span class="color-red">*</span>
          ) : null}
        </label>
      ) : null}

      <input
        class="form-control font-14 font-medium theme-focus bg-transparent text-color-4 cust-search-input custom-border-3 input-field w-50 mb-3 rounded-3"
        disabled={toggle}
        placeholder={
          recordData?.placeHolder ? recordData.placeHolder : "Enter the Text"
        }
        id={recordData ? recordData.labelName : null}
        name="9489C4D4-BCF0-4E45-9954-335F97060A41"
        value={record?.fieldValue}
        onChange={(e) => {
          if (e.target.value) {
            sethideError(false);
            record?.handleFieldValues(
              e.target.id,
              e.target.value,
              recordData.inputValueOrder,
              recordData.required
            );

            toggle?.functions(e);
          } else {
            record?.handleFieldValues(
              e.target.id,
              e.target.value,
              recordData.inputValueOrder,
              recordData.required
            );

            sethideError(true);
          }
        }}
      />
      {bind()}
    </>
  );
}
