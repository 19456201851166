import { validateD2Diagram, generateD2Diagram } from "./Api";

let lastValidatedCode = '';
let lastReplacedCode = '';

export const fetchAndValidateDiagram = async (code, layout) => {
  try {
    let updatedCode = code;
    let codeChanged = false;

    if (code !== lastValidatedCode) {
      console.log("Validating new code...");
      const validateResponse = await validateD2Diagram(code);
      console.log("Validation response:", validateResponse);
      updatedCode = validateResponse.updatedCode;
      lastValidatedCode = code;
      lastReplacedCode = updatedCode;
      codeChanged = updatedCode !== code;
    } else {
      console.log("Using cached code...");
      updatedCode = lastReplacedCode;
    }

    // Generate the diagram
    const finalCode = `${updatedCode}\n\ndirection: ${layout}`;
    console.log("Generating diagram with code:", finalCode);
    const diagramSvg = await generateD2Diagram(finalCode);
    console.log("Generated diagram SVG:", diagramSvg);

    if (!diagramSvg) {
      throw new Error("Generated diagram SVG is null or undefined");
    }

    return {
      diagramSvg,
      finalCode: codeChanged ? updatedCode : code,
      error: null
    };
  } catch (error) {
    console.error('Error fetching diagram:', error);
    let errorMessage = 'Failed to generate diagram. Please check your D2 code and try again.';
    if (error.response && error.response.data && error.response.data.error) {
      errorMessage = `Error in D2 code: ${error.response.data.error.message}`;
    } else if (error.message) {
      errorMessage = error.message;
    }
    
    return {
      diagramSvg: null,
      finalCode: code,
      error: errorMessage
    };
  }
};