import React, { useState, useEffect } from "react";

let index = 0;

export function set_Index_zero() {
  index = 0;
}

export const TextStream = ({ text }) => {
  const [displayedText, setDisplayedText] = useState("");

  const typingDelay = 15; // You can adjust this for faster or slower typing

  useEffect(() => {
    const typeInterval = setInterval(() => {
      if (index < text.length) {
        setDisplayedText((prevText) => prevText + text[index]);

        index++;
      } else {
        clearInterval(typeInterval);
      }
    }, typingDelay);

    return () => clearInterval(typeInterval);
  }, [text]);

  return <>{displayedText}</>;
};

export default TextStream;
