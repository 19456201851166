//PS_CGAI_7.1 import the necessary imports
import { useEffect, useState, useContext } from "react";
import { getInputTypeAndTechStack } from "../AI Service/Api";
import toggleContext from "./Context";

/**
 * PS_CGAI_7.2 to PS_CGAI_7.4 check the props in useEffect and set the object to recordData and call funtcion to get techstacks from bacKend
 * PS_CGAI_7.19 to PS_CGAI_7.25 get the values from the onchange and pass to the parent component and aslo pass the typeof code ID aslo to disable the type of code
 * @param {props from the parent component as object} param0
 * @returns
 */
export default function TechStack({ record }) {
  const [recordData, setRecordData] = useState();
  const toggle = useContext(toggleContext);
  const [techstack, settechstack] = useState();
  const [codeID, setcodeID] = useState();
  const [error, seterror] = useState("");
  const [hideError, sethideError] = useState(true);

  let fieldValuesObj = {
    imageUrl: "",

    text: "",
  };
  const [fieldValues, setFieldValues] = useState(fieldValuesObj);
  useEffect(() => {
    //(toggle,"toggle");
    if (record) {
      setRecordData(record.record);
    }
    fetchInputTypeAndTechStack();
  }, []);
  useEffect(() => {
    if (record?.techstackID) {
      setcodeID(record.techstackID);
    }
  }, [codeID]);

  /**
   * PS_CGAI_7.5 to PS_CGAI_7.13 get the tech stach from the backend and set state the techstace to techstack variable
   */
  const fetchInputTypeAndTechStack = async () => {
    try {
      let result = await getInputTypeAndTechStack();

      settechstack(result.data.techStack);
    } catch (error) {
      //(error.message);
    }
  };

  /**
   * PS_CGAI_7.14 to PS_CGAI_7.18 using the techstack map in to it and bind the dropdown options to the select tag
   * @returns the techstack in options as dropdown
   */
  const bindtechstack = () => {
    return techstack?.map((val, index) => {
      return (
        <>
          <option
            className={
              recordData == null ? "border-bottom font-14 py-2" : "py-2"
            }
            id={recordData?.labelName}
            value={`${val?.typeOfCodeID}#${val?.techStackName}`}
          >
            <span className="me-3">
              <img
                className="template-dropdown-icon"
                src={val.techStackLogo}
                alt="js-icon"
              />
            </span>

            {val.techStackName}
          </option>
        </>
      );
    });
  };
  /**
   * 
   * @returns <div className="row pb-3">
        <div className="col-md-6 col-sm-12 mb-3">
          <div className="input-field pe-2">
            <ul className="list-unstyled stack-li-scroll mb-0">
              <li className="border-bottom font-14 py-2">JavaScript</li>
              <li className="border-bottom font-14 py-2">PHP</li>
              <li className="border-bottom font-14 py-2">Angular</li>
              <li className="border-bottom font-14 py-2">Linux</li>
              <li className="border-bottom font-14 py-2">Apache</li>
              <li className="border-bottom font-14 py-2">PHP</li>
              <li className="border-bottom font-14 py-2">Angular</li>
              <li className="border-bottom font-14 py-2">Linux</li>
              <li className="font-14 py-2">JavaScript</li>
            </ul>
          </div>
        </div>
      </div>
   */

  const bind = () => {
    if (recordData) {
      const err = `${recordData.inputValueOrder}`;

      if (record.validationErrors) {
        return (
          <>
            {hideError ? (
              <span
                className="mandatory-star font-14 font-medium mb-0"
                style={{ color: "red" }}
              >
                {record.validationErrors[err]}
              </span>
            ) : null}
          </>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  return (
    <>
      {recordData && recordData.labelName ? (
        <label
          class="form-label font-15 font-semibold color-secondary-black"
          for="Tech-Stack"
        >
          {recordData.labelName}
          {record && recordData?.required == "true" ? (
            <span class="color-red">*</span>
          ) : null}
        </label>
      ) : null}
      {/* {toggle ? (
        <div className="row pb-3">
          <div className="col-md-6 col-sm-12 mb-3">
            <div className="input-field pe-2">
              <ul className="list-unstyled stack-li-scroll mb-0">
                <li className="border-bottom font-14 py-2">Select</li>
                {
                  techstack?.map((value, index) => {

                    return <li className={`${index == techstack.length - 1 ? `border-bottom` : ``} font-14 py-2`}

                    >
                      <span className="me-3">
                        <img className="template-dropdown-icon" src={value.techstackLogo} alt="js-icon" />

                      </span>
                      {value.techstackName}</li>
                  })
                }

              </ul>
            </div>
          </div>
        </div>
      ) : (
        <select
          class="form-select font-14 font-medium theme-focus input-field"
          aria-label="Default select example"
          id="inputTypeID"
          value={record?.fieldValue}
          name="A7D11CE3-3855-4239-9A29-726B88A20165"
          placeholder={recordData?.placeHolder ? recordData.placeHolder : null}
          onClick={(e) => {
            toggle?.functions(e);
            if (e.target.value == "Select") {
              return null;
            } else {
              var values = e.target.value?.split("#");

              record.handleFieldValues(
                recordData.labelName,
                values[1],
                recordData.inputValueOrder,
                recordData.required,
                values[0]
              );
            }
          }}
        >
          <option className="border-bottom font-14 py-2" selected>
            Select
          </option>

          {bindtechstack()}
        </select>
      )} */}
      {toggle && (
        <div className="row pb-3">
          <div className="col-md-6 col-sm-12 mb-3">
            <div className="input-field pe-2">
              <ul className="list-unstyled stack-li-scroll mb-0">
                <li className="border-bottom font-14 py-2">Select</li>
                {techstack?.map((value, index) => {
                  return (
                    <li
                      className={`${
                        index == techstack.length - 1 ? `border-bottom` : ``
                      } font-14 py-2`}
                    >
                      <span className="me-3">
                        <img
                          className="template-dropdown-icon"
                          src={value.techStackLogo}
                          alt="js-icon"
                        />
                      </span>
                      {value.techStackName}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      )}
      {!toggle && (
        <div className="dropdown w-100">
          <button
            className="text-start custom-dropdown-toggle theme-focus input-field py-2 px-3 w-100 bg-white font-14 font-medium color-secondary-black"
            type="button"
            id="choose-teck"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {fieldValues.text && fieldValues.imageUrl ? (
              <span className="me-3">
                <img
                  className="template-dropdown-icon"
                  src={fieldValues.imageUrl}
                  alt="js-icon"
                />
                {fieldValues.text}
              </span>
            ) : (
              "Select"
            )}
          </button>
          <ul
            className="dropdown-menu w-100 py-0"
            aria-labelledby="dropdownMenuButton1"
            style={{}}
          >
            {techstack?.map((val, index) => {
              {
              }
              return (
                <li
                  className="py-2"
                  id={`${val?.typeOfCodeID}#${val?.techstackName}#${val?.techstackLogo}`}
                  // value={`${val?.typeOfCodeID}#${val?.techstackName}`}techstackID

                  key={val?.techstackID}
                  onClick={(e) => {
                    // if (e.target.value) {
                    sethideError(false);
                    let values = e.target?.id?.split("#");

                    // if (values[] == "Select") {
                    //   return null;
                    // } else {

                    setFieldValues((pre) => ({
                      ...pre,
                      ["text"]: values[1],
                      ["imageUrl"]: values[2],
                    }));
                    if (record?.techStackID !== values[1]) {
                      seterror("");
                      record.handleFieldValues(
                        recordData.labelName,
                        values[1],
                        recordData.inputValueOrder,
                        recordData.required,
                        values[0]
                      );
                    }
                    // }
                    else {
                      seterror("Please select Different Tech stack to convert");
                    }
                    // } else {
                    //   sethideError(true)
                    // }
                  }}
                >
                  <a
                    className="dropdown-item custom-dropdown-li font-14 font-medium py-2 color-secondary-black"
                    id={`${val?.typeOfCodeID}#${val?.techstackName}#${val?.techstackLogo}`}
                  >
                    <span
                      className="me-3"
                      id={`${val?.typeOfCodeID}#${val?.techstackName}#${val?.techstackLogo}`}
                    >
                      <img
                        className="template-dropdown-icon"
                        src={val.techstackLogo}
                        alt="js-icon"
                        id={`${val?.typeOfCodeID}#${val?.techstackName}#${val?.techstackLogo}`}
                      />
                    </span>
                    {val?.techstackName}
                  </a>
                </li>
              );
            })}
            {/**
             * 
          <option
            className={recordData == null ? "border-bottom font-14 py-2" : "py-2"}
            id={recordData?.labelName}
            value={`${val?.typeOfCodeID}#${val?.techstackName}`}
          >
          <span className="me-3">
            <img className="template-dropdown-icon" src={val.techstackLogo} alt="js-icon" />

          </span>
          
            {val.techstackName}
          </option>
             */}
            {/* <li className="py-2"><a className="dropdown-item custom-dropdown-li font-14 font-medium py-2 color-secondary-black" > <span className="me-3"><img className="template-dropdown-icon" src="images/react-js-icon.svg" alt="js-icon" /></span>React JS</a></li>
            <li className="py-2"><a className="dropdown-item custom-dropdown-li font-14 font-medium py-2 color-secondary-black" > <span className="me-3"><img className="template-dropdown-icon" src="images/linux-icon.svg" alt="linux-icon" /></span>Linux</a></li>
            <li className="py-2"><a className="dropdown-item custom-dropdown-li font-14 font-medium py-2 color-secondary-black" > <span className="me-3"><img className="template-dropdown-icon" src="images/angular-icon.svg" alt="angular-icon" /></span>Angular</a></li> */}
          </ul>
        </div>
      )}
      <span
        className="mandatory-star font-14 font-medium mb-0"
        style={{ color: "red" }}
      >
        {error}
      </span>
      {bind()}
    </>
  );
}
